import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: KeywordState = {
  loading: false,
  keywordById: {},
  keywordTree: {},
};

const keywordSlice = createSlice({
  name: 'KEYWORD',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_KEYWORDS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_KEYWORDS:OK']: (state, action: PayloadAction<getKeywordsResponse>) => ({
      ...state,
      loading: false,
      ...action.payload.data,
    }),
    ['CREATE_KEYWORD']: (state, action: PayloadAction<KeywordDTO>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE_TREE']: (state, action: PayloadAction<KeywordTreeDTO>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE_TREE:OK']: (state, action: PayloadAction<KeywordTreeDTO>) => {
      return {
        ...state,
        loading: false,
        keywordTree: action.payload.tree,
      };
    },
    ['RENAME_KEYWORD']: (state, action: PayloadAction<KeywordDTO>) => ({
      ...state,
      loading: true,
    }),
    ['RENAME_KEYWORD:OK']: (state, action: PayloadAction<KeywordDTO>) => {
      const { id, name } = action.payload;
      return {
        ...state,
        loading: false,
        keywordById: {
          ...state.keywordById,
          [id]: {
            ...state.keywordById[id],
            name,
          },
        },
      };
    },
    ['DELETE_KEYWORD']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
    ['DELETE_KEYWORD:OK']: (state, action: PayloadAction<number>) => {
      const { [action.payload]: keyword, ...rest } = state.keywordById;
      return {
        ...state,
        loading: false,
        keywordById: rest,
      };
    },
  },
});

export default keywordSlice;
