import React from 'react';
import cn from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';

export type LinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  className?: string;
  href?: string;
  target?: string;
};

export const Link = ({ className, children, target, ...linkProps }: LinkProps): JSX.Element => {
  const theme = useTheme();
  return (
    <a
      className={cn(className)}
      style={{ color: theme.palette.primary.main }}
      {...linkProps}
      target={target ? target : undefined}
    >
      {children}
    </a>
  );
};
