import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import cn from 'classnames';

export type VerticalAlign = 'top' | 'bottom';
export type HorizontalAlign = 'left' | 'right';

export type ModalProps = {
  open: boolean;
  onClose: () => void;
  anchorEl?: Element;
  vAlign?: VerticalAlign;
  hAlign?: HorizontalAlign;
  gap?: number;
  className?: string;
  children?: React.ReactNode;
};

export const Modal = ({
  open,
  onClose,
  anchorEl,
  vAlign = 'top',
  hAlign = 'left',
  gap = 8,
  className,
  children,
}: ModalProps): JSX.Element => {
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const [position, setPosition] = React.useState<
    { top?: number; right?: number; left?: number; bottom?: number } | undefined
  >();

  React.useEffect(() => {
    const onMouseDown = (event: MouseEvent) => {
      const current = dialogRef.current;

      if (event.target instanceof HTMLDivElement && !current?.contains(event.target)) {
        onClose();
      }
    };

    window.addEventListener('mousedown', onMouseDown);
    return () => {
      window.removeEventListener('mousedown', onMouseDown);
    };
  }, []);

  React.useEffect(() => {
    setPosition(undefined);

    if (anchorEl) {
      const availWidth = window.document.body.clientWidth;
      const availHeight = getDocumentHeight();
      const anchorRect = anchorEl.getBoundingClientRect();
      const position = {
        ...(vAlign == 'bottom'
          ? { bottom: availHeight - anchorRect.bottom + anchorRect.height + gap }
          : { top: anchorRect.top + anchorRect.height + gap }),
        ...(hAlign == 'left' ? { left: anchorRect.left } : { right: availWidth - anchorRect.right }),
      };

      setPosition(position);
    }
  }, [open, gap]);

  return (
    <StyledDiv className={cn({ open: open && position })}>
      <div ref={dialogRef} style={position} className={cn(className, 'absolute')}>
        {children}
      </div>
    </StyledDiv>
  );
};

const getDocumentHeight = () => {
  const body = window.document.body;
  const html = window.document.documentElement;

  return Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
};

const StyledDiv = styled.div`
  ${tw`hidden fixed inset-0 overflow-auto max-h-screen z-50`}

  &.open {
    ${tw`block`}
  }
`;
