import React from 'react';

interface IconProps {
  stroke: string;
}

function Icon({ stroke = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke={stroke} strokeWidth="1.063" transform="translate(-30 -230)">
          <g transform="translate(0 138)">
            <g transform="translate(30 90)">
              <g transform="translate(0 2)">
                <path d="M16.027 10.533l-5.402 5.405-2.656.53.531-2.655 5.405-5.405a1.497 1.497 0 012.119 0l.006.006a1.499 1.499 0 01-.003 2.119zM5.84 14.344H1.59A1.063 1.063 0 01.529 13.28V1.594c0-.587.476-1.063 1.063-1.063h10.625c.587 0 1.062.476 1.062 1.063v4.781M5.84 3.719h4.25M3.716 6.906h6.375M3.716 10.094h5.312"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
