import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SignIn, { SignInForm, SignInProps } from '../components/SignIn/SignIn';
import { validationErrorTransformer } from '../store/api';
import { ValidatedField } from '../store/api.d';
import { selectAuthValidationErrors } from '../store/auth/selector';
import authSlice from '../store/auth/slice';
import { AuthState, LoginRequest } from '../store/auth/types';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';

interface SingInPageProps {
  login: (data: LoginRequest) => void;
  clearValidationMessages: () => void;
  auth: AuthState;
  validationErrors: ValidatedField[];
}

type ProcessingState = 'IDLE' | 'PROCESSING';

const SignInPage: React.FC<SingInPageProps> = ({ login, clearValidationMessages, auth, validationErrors }) => {
  const [processing, setProcessingState] = useState<ProcessingState>('IDLE');
  const history = useHistory();

  const sign_in_handler = (form: SignInForm): void => {
    if (processing !== 'PROCESSING') {
      setProcessingState('PROCESSING');
      login(form);
    }
  };
  const on_change_handler = (): void => {
    auth.validationError && clearValidationMessages();
  };

  const forgot_password_handler = () => {
    history.push('/forgot-password');
  };

  const signup_handler = () => {
    history.push('/signup');
  };

  let info: SignInProps = {
    sign_in_handler: sign_in_handler,
    on_change_handler: on_change_handler,
    forgot_password_handler: forgot_password_handler,
    signup_handler: signup_handler,
  };
  if (auth.validationError) {
    info = { ...info, validation_error: validationErrorTransformer(validationErrors) };
  }

  return <SignIn {...info}></SignIn>;
};

const mapStateToProps = (state: AppState) => ({
  auth: state.auth,
  validationErrors: selectAuthValidationErrors(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  login: (data: LoginRequest) => dispatch(authSlice.actions['LOGIN'](data)),
  clearValidationMessages: () => dispatch(authSlice.actions['CLEAR:VALIDATION']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage);
