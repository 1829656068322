import { apiCall, APIValidationError } from '../api';
import { baseURL } from '../root-saga';
import { Result, Await } from '../api.d';
import { put, select, takeLatest } from '@redux-saga/core/effects';
import errorSlice from '../error/slice';
import phaseSlice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import formSlice from '../form/slice';
import screenSlice from '../screen/slice';
import { selectCurrentRole } from '../selectors';
import paperStatusSlice from '../paper-status/slice';
import bidSlice from '../bid/slice';
import permissionSlice from '../permission/slice';
import { Role } from '../conference/types';
import i18next from '../../i18n';

function* syncState() {
  yield put(formSlice.actions.GET_FORMS()); // Refresh forms definitions in order to sync first_used_at field TODO might be an overkill, think how to improve it
  yield put(screenSlice.actions['GET:SCREENS']()); // Need to refresh discussion enabled parameter
}

function* syncSettings(phaseType: PhaseType) {
  switch (phaseType) {
    case 'bidding': {
      // Update subset bidding enabled
      yield put(screenSlice.actions['GET:SCREENS']());

      // Now update paper statuses by re-fetching information
      yield put(paperStatusSlice.actions['GET:PAPER_STATUS_ROLE']());

      // Update bid options
      yield put(bidSlice.actions['GET:BID_OPTIONS']());
      break;
    }
    case 'discussion': {
      // Update subset bidding enabled
      yield put(screenSlice.actions['GET:SCREENS']());

      // Now update permissions by re-fetching information
      const role: Role = yield select(selectCurrentRole);
      yield put(permissionSlice.actions['GET_PERMISSIONS']({ roleId: role.id }));
      break;
    }
  }
}

const getPhases = async (): Promise<Result<GetPhasesResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetPhasesResponse>(`${baseURL}/api/phases`, init);
};

function* getPhasesSaga() {
  const result = (yield getPhases()) as Await<ReturnType<typeof getPhases>>;

  switch (result.type) {
    case 'ok':
      yield put(phaseSlice.actions['GET:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updatePhase = async (data: PhaseUpdateDTO): Promise<Result<UpdatePhasesResponse, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PATCH',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<UpdatePhasesResponse>(`${baseURL}/api/phases/${id}`, init);
};

function* updatePhaseSaga(data: PayloadAction<PhaseUpdateDTO>) {
  const result = (yield updatePhase(data.payload)) as Await<ReturnType<typeof updatePhase>>;

  switch (result.type) {
    case 'ok':
      const { settings, ...phase } = result.value.data;
      yield syncSettings(phase.type);
      yield put(phaseSlice.actions['UPDATE:OK'](phase));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Settings saved.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const createPhase = async (data: PhaseDTO): Promise<Result<CreatePhasesResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<CreatePhasesResponse>(`${baseURL}/api/phases`, init);
};

function* createPhaseSaga(data: PayloadAction<PhaseDTO>) {
  const result = (yield createPhase(data.payload)) as Await<ReturnType<typeof createPhase>>;

  switch (result.type) {
    case 'ok':
      yield syncState();
      yield syncSettings(data.payload.type);
      yield put(phaseSlice.actions['CREATE:OK'](result.value));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Phase created.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(phaseSlice.actions['CREATE:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deletePhase = async (phaseId: number): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };

  return apiCall<{ data: any }>(`${baseURL}/api/phases/${phaseId}`, init);
};

function* deletePhaseSaga(action: PayloadAction<number>) {
  const result = (yield deletePhase(action.payload)) as Await<ReturnType<typeof deletePhase>>;

  switch (result.type) {
    case 'ok':
      yield syncState();
      yield put(phaseSlice.actions['DELETE:OK'](action.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const createPhaseEvent = async (data: PhaseEventDTO): Promise<Result<{ data: PhaseEvent }, APIValidationError>> => {
  const { phase_id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<{ data: PhaseEvent }>(`${baseURL}/api/phases/${phase_id}/events`, init);
};

function* createPhaseEventSaga(data: PayloadAction<PhaseEventDTO>) {
  const result = (yield createPhaseEvent(data.payload)) as Await<ReturnType<typeof createPhaseEvent>>;

  switch (result.type) {
    case 'ok':
      yield syncState();
      yield put(phaseSlice.actions['CREATE:EVENT:OK'](result.value.data));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Event created successfully.'), severity: 'success' }),
      );
      break;
    case 'validation-error':
      yield put(phaseSlice.actions['CREATE:EVENT:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updatePhaseEvent = async (data: PhaseEventDTO): Promise<Result<CreatePhasesResponse, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<CreatePhasesResponse>(`${baseURL}/api/phases/events/${id}`, init);
};

function* updatePhaseEventSaga(data: PayloadAction<PhaseEventDTO>) {
  const result = (yield updatePhaseEvent(data.payload)) as Await<ReturnType<typeof updatePhaseEvent>>;

  switch (result.type) {
    case 'ok':
      yield syncState();
      yield put(phaseSlice.actions['UPDATE:EVENT:OK'](result.value));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Event saved successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(phaseSlice.actions['UPDATE:EVENT:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deletePhaseEvent = async (id: number): Promise<Result<CreatePhasesResponse, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };

  return apiCall<CreatePhasesResponse>(`${baseURL}/api/phases/events/${id}`, init);
};

function* deletePhaseEventSaga(data: PayloadAction<number>) {
  const result = (yield deletePhaseEvent(data.payload)) as Await<ReturnType<typeof deletePhaseEvent>>;

  switch (result.type) {
    case 'ok':
      yield syncState();
      yield put(phaseSlice.actions['UPDATE:EVENT:OK'](result.value));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Event deleted successfully.'), severity: 'success' }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(phaseSlice.actions.GET, getPhasesSaga),
  takeLatest(phaseSlice.actions.UPDATE, updatePhaseSaga),
  takeLatest(phaseSlice.actions.CREATE, createPhaseSaga),
  takeLatest(phaseSlice.actions.DELETE, deletePhaseSaga),
  takeLatest(phaseSlice.actions['CREATE:EVENT'], createPhaseEventSaga),
  takeLatest(phaseSlice.actions['UPDATE:EVENT'], updatePhaseEventSaga),
  takeLatest(phaseSlice.actions['DELETE:EVENT'], deletePhaseEventSaga),
];
