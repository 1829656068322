import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  selectAuth,
  selectCurrentConference,
  selectCurrentTrack,
  selectIsEmailVerified,
  selectIsLoading,
} from '../store/selectors';
import { AppState } from '../store/state';
import { getRouteByName, RouteDefinition, RouteName } from './routes';
import Loading from '../components/Loading/Loading';
import { AppDispatch } from '../store/store';
import { generatePath } from 'react-router';
import history from '../store/history';
import { Conference, Track } from '../store/conference/types';
import { can } from '../helpers/permissions';

interface RedirectOption {
  routeName: RouteName;
  params?: object;
}
const redirectTo = (dest: RedirectOption) => {
  const path = generatePath(getRouteByName(dest.routeName).path, dest.params);
  return <Redirect to={path} />;
};

interface RedirectorProps {
  isLoading: boolean;
  isLoggedIn: boolean;
  isImpersonated: boolean;
  isEmailVerified: boolean;
  currentTrack: Track | null;
  currentConference: Conference | null;
  routeToAccess: RouteDefinition;
  children?: React.ReactNode;
}
const Redirector: React.FC<RedirectorProps> = ({
  isLoading,
  isLoggedIn,
  isImpersonated,
  isEmailVerified,
  currentTrack,
  currentConference,
  routeToAccess,
  children,
}) => {
  const setRedirectOption = (r: RedirectOption | 'NO_REDIRECT') => {
    redirection = r;
  };

  let redirection: RedirectOption | 'NO_REDIRECT' = 'NO_REDIRECT';
  const path = history.location.pathname;

  /*
   ****** helps debug ******* /

  let str = isLoading ? '-Loading ' : '-Not Loading ';
  if (routeToAccess === undefined) {
    str = str.concat('-Undefined route ');
  } else {
    str = str.concat(routeToAccess.logged_access ? '-Logged access ' : '-Not logged acess ');
    str = str.concat(routeToAccess.anonymous_access ? '-Anonymous access ' : '-Not anonymous acess ');
    str = str.concat('-' + routeToAccess.path + ' ');
    str = str.concat(!!auth.loggedIn ? '-Logged In ' : '-Not logged in ');
  }
  console. log(str); */

  if (isLoading) {
    return <Loading />;
  }

  if (routeToAccess === undefined) {
    setRedirectOption({ routeName: 'RouteConferences' });
  } else if (routeToAccess.name === 'RouteRoot') {
    setRedirectOption({ routeName: 'RouteConferences' });
  } else if (
    ['RouteEmailVerifyPage', 'RouteSendEmailVerificationPage'].includes(routeToAccess.name) &&
    isEmailVerified
  ) {
    setRedirectOption({ routeName: 'RouteConferences' });
  } else if (routeToAccess.anonymous_access && routeToAccess.logged_access) {
    setRedirectOption('NO_REDIRECT');
  } else if (routeToAccess.logged_access && !isLoggedIn) {
    if (routeToAccess.name != 'RouteLogout') {
      localStorage.setItem('next', path);
    }
    setRedirectOption({ routeName: 'RouteLogin' });
  } else if (!routeToAccess.logged_access && isLoggedIn) {
    setRedirectOption({ routeName: 'RouteConferences' });
  } else if (routeToAccess.email_verified_access && !isEmailVerified) {
    setRedirectOption({ routeName: 'RouteSendEmailVerificationPage' });
  } else if (routeToAccess.track_selected_access && !currentTrack) {
    setRedirectOption({ routeName: 'RouteConferences' });
  } else if (routeToAccess.permission && !can(routeToAccess.permission)) {
    if (routeToAccess.track_selected_access) {
      setRedirectOption({
        routeName: 'RouteConferenceHome',
        params: { conference: currentConference?.slug, track: currentTrack?.slug },
      });
    } else {
      setRedirectOption({ routeName: 'RouteConferences' });
    }
  } else if (!routeToAccess.impersonated_access && isImpersonated) {
    setRedirectOption({
      routeName: 'RouteConferenceHome',
      params: { conference: currentConference?.slug, track: currentTrack?.slug },
    });
  } else {
    setRedirectOption('NO_REDIRECT'); // This means render the component within fragment
  }

  // @ts-ignore
  //console.log('Redirect to: ' + (typeof redirection === 'object' ? redirection.routeName : redirection));

  return (
    <React.Fragment>
      {redirection !== 'NO_REDIRECT' && redirectTo(redirection)}
      {redirection === 'NO_REDIRECT' && children && children}
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => ({
  isLoading: selectIsLoading(state),
  isLoggedIn: selectAuth(state).loggedIn,
  isImpersonated: !!selectAuth(state).impersonatedTrackId,
  isEmailVerified: selectIsEmailVerified(state),
  currentTrack: selectCurrentTrack(state),
  currentConference: selectCurrentConference(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(Redirector);
