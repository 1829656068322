import moment from 'moment/moment';
import spacetime from 'spacetime';

export const getUserOffset = (timezone: string | null): string => {
  return moment
    .utc()
    .utcOffset(spacetime.now(timezone ?? undefined).offset())
    .format('Z');
};

export const transform = (defaultValue: Date | undefined, utcOffset: string): Date => {
  // Take input UTC datetime and add the offset with respect to local time zone
  const dateOffset = moment.utc(defaultValue).add((defaultValue ?? new Date()).getTimezoneOffset(), 'minutes');

  // Transform into target time zone
  dateOffset.utcOffset(utcOffset);

  // UTC-transform the resultant date by ripping off the timezone part
  const dateStrUTC = dateOffset.format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + 'Z';

  return new Date(dateStrUTC);
};
