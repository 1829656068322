import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Answer,
  AnswersDTO,
  createFormResponse,
  FormDTO,
  FormState,
  getFormsResponse,
  Question,
  storeAnswersRequest,
  updateFormResponse,
  updateVisibilityAction,
} from './types';
import { getCompositeAnswerId } from '../../helpers/answers';

export const initialState: FormState = {
  loading: false,
  formById: {},
  questionById: {},
  answerById: {},
  visibilityByFormId: {},
};

const formSlice = createSlice({
  name: 'FORM',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_FORMS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_FORMS:OK']: (state, action: PayloadAction<getFormsResponse>) => ({
      ...state,
      loading: false,
      ...action.payload.data,
    }),
    ['UPDATE_FORM']: (state, action: PayloadAction<FormDTO>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE_FORM:OK']: (state, action: PayloadAction<updateFormResponse>) => {
      const { form, questionById, visibilities } = action.payload.data;

      // Combine
      const otherQuestionsById: { [key: number]: Question } = {};
      Object.values(state.questionById)
        .filter((question) => question.form_id != form.id)
        .forEach((question) => (otherQuestionsById[question.id] = question));

      return {
        ...state,
        loading: false,
        formById: {
          ...state.formById,
          [form.id]: form,
        },
        questionById: { ...otherQuestionsById, ...questionById },
        visibilityByFormId: { ...state.visibilityByFormId, [form.id]: visibilities },
      };
    },
    ['GET:ANSWERS:OK']: (state, action: PayloadAction<AnswersDTO>) => {
      const answerById: { [key: string]: Answer } = {};
      Object.values(action.payload.answerById).forEach((answer) => {
        const id = getCompositeAnswerId(answer.question_id, answer.answerable_id);
        answerById[id] = answer;
      });
      return {
        ...state,
        answerById: {
          ...state.answerById,
          ...answerById,
        },
        loading: false,
      };
    },
    ['DELETE_ANSWERS']: (state, action: PayloadAction<number[]>) => {
      const answerIds = action.payload;

      const answerById = { ...state.answerById };
      for (const answerId of answerIds) {
        delete answerById[answerId];
      }

      return {
        ...state,
        answerById,
      };
    },
    ['UPDATE_VISIBILITY']: (state, action: PayloadAction<updateVisibilityAction>) => {
      return {
        ...state,
      };
    },
    ['CREATE_FORM']: (state, action: PayloadAction<FormDTO>) => {
      return {
        ...state,
      };
    },
    ['CREATE_FORM:OK']: (state, action: PayloadAction<createFormResponse>) => {
      const { form, visibilities } = action.payload.data;
      return {
        ...state,
        formById: { ...state.formById, [form.id]: form },
        visibilityByFormId: { ...state.visibilityByFormId, [form.id]: visibilities },
      };
    },
    ['DELETE_FORM']: (state, action: PayloadAction<number>) => {
      return {
        ...state,
      };
    },
    ['DELETE_FORM:OK']: (state, action: PayloadAction<number>) => {
      const { [action.payload]: form, ...rest } = { ...state.formById };
      return {
        ...state,
        formById: rest,
      };
    },
    ['STORE_ANSWERS']: (state, action: PayloadAction<storeAnswersRequest>) => {
      return {
        ...state,
        loading: true,
      };
    },
    ['STORE_ANSWERS:OK']: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    ['STORE_ANSWERS:KO']: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
  },
});

export default formSlice;
