import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import cn from 'classnames';
import { RadioButton } from '../RadioButton';
import useTheme from '@material-ui/core/styles/useTheme';
import { nanoid } from 'nanoid';

export type SelectRadioButtonProps = {
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  value?: string;
  onChange?: (value: string) => void;
  options?: { id: string; label: string }[];
  dense?: boolean;
  className?: string;
  disabled?: boolean;
};

export const SelectRadioButton = ({
  label,
  required,
  value,
  onChange,
  options = [],
  //dense,
  //fullWidth,
  error,
  //helperText,
  className,
  disabled,
}: SelectRadioButtonProps): JSX.Element => {
  const theme = useTheme();
  const inputId = nanoid();
  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={cn(className, { error })}>
        {label && <label>{required ? `${label} *` : label}</label>}
        {options?.map((option, index) => (
          <RadioButton
            key={index}
            id={`${inputId}-${option.id}`}
            label={option.label}
            checked={option.id == value}
            onChange={() => onChange?.(option.id)}
            disabled={disabled}
          />
        ))}
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  &.error {
    label {
      color: ${(props) => props.theme.palette.error.main};
    }
    input {
      border-color: ${(props) => props.theme.palette.error.main};
    }
`;
