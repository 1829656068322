import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorState, OpenSnackBarDTO } from './types';
import { ValidatedField } from '../../store/api.d';

export const initialState: ErrorState = {
  openSnackBar: false,
  message: '',
  severity: 'info',
};

const errorSlice = createSlice({
  name: 'ERROR',
  initialState,
  reducers: {
    reset: () => initialState,
    ['OPEN:SNACKBAR']: (state, action: PayloadAction<OpenSnackBarDTO>) => ({
      ...state,
      openSnackBar: true,
      message: action.payload.message,
      severity: action.payload.severity,
    }),
    ['CLOSE:SNACKBAR']: (_state) => ({
      ..._state,
      openSnackBar: false,
    }),
    ['SET:VALIDATION_ERRORS']: (state, action: PayloadAction<ValidatedField[]>) => ({
      ...state,
      validationErrors: action.payload,
    }),
    ['CLEAR:VALIDATION_ERRORS']: (state) => ({
      ...state,
      validationErrors: undefined,
    }),
  },
});

export default errorSlice;
