import { getSignedURL } from '../store/submission/sagas';
import { downloadFile } from './download';

export async function downloadFileS3(path: string, filenameOriginal: string): Promise<void> {
  const result = await getSignedURL({ filename: path, filename_original: filenameOriginal, command: 'GetObject' });
  switch (result.type) {
    case 'ok':
      const { signedUrl } = result.value.data;

      // Create an anchor a trigger a download of file pointed by signedUrl
      downloadFile(signedUrl, '_blank');
      break;
  }
}
