import React, { useRef, useState } from 'react';
import 'react-complex-tree/lib/style-modern.css';
import { Tree, TreeItem, TreeItemIndex, ControlledTreeEnvironment, TreeRef } from 'react-complex-tree';
import styled from 'styled-components';
import EditIcon from '../../icons/Edit';
import SimpleDialog from '../dialogs/SimpleDialog/SimpleDialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnUp } from '@fortawesome/free-solid-svg-icons';
import TrashCan from '../../icons/TrashCan';
import { useTranslation } from 'react-i18next';

/**
 * The format of dataTree must be:
 * 
 * const dataTree = {
    root: { // This is the root node: must has as children the parent nodes that has children
      index: 'root',
      children: ['child1'],
      data: 'Root item',
      isFolder: true,
      canRename: true,
    },
    child1: { // This is a parent node: it has children
      index: 'child1',
      children: ['child3', 'child4'],
      data: 'proyectos (CAR)',
      isFolder: true,
      canRename: true,
    },
    child2: { // This is a child node: it has no children
      index: 'child2',
      children: [],
      data: 'JAEIntroAIHUB21-10',
      isFolder: true,
      canRename: true,
    },
    child3: { // This is a child node: it has no children
      index: 'child3',
      children: [],
      data: 'JAEIntroAIHUB22-10',
      isFolder: true,
      canRename: true,
    },
  };
 */
export interface KeywordsTreeProps {
  dataTree: Record<TreeItemIndex, TreeItem>;
  expandItemsValue?: TreeItemIndex[];
  handleExpandedItemsChange?: (expandItems: TreeItemIndex[]) => void;
  onDrop: (items: Record<TreeItemIndex, TreeItem>) => void;
  onRenameItem: (id: number, name: string) => void;
  onDelete: (id: number) => void;
}
const KeywordsTree: React.FC<KeywordsTreeProps> = ({
  dataTree,
  expandItemsValue,
  handleExpandedItemsChange,
  onDrop,
  onRenameItem,
  onDelete,
}) => {
  const { t, i18n } = useTranslation();
  let firstItemIndex: TreeItemIndex | undefined = undefined;
  if (dataTree.root.children && dataTree.root.children.length > 0) {
    firstItemIndex = dataTree.root?.children[0];
  }

  const [focusedItem, setFocusedItem] = useState<TreeItemIndex | undefined>(firstItemIndex);
  const [expandedItemsInternal, setExpandedItemsInternal] = useState<TreeItemIndex[]>([]);

  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>(() => {
    return firstItemIndex ? [firstItemIndex] : [];
  });
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  const isControlled = expandItemsValue !== undefined;

  const expandedItems = isControlled ? expandItemsValue : expandedItemsInternal;

  const moveNode = (source: TreeItem, target: TreeItem) => {
    const srcNode = source;
    const parentNode = Object.values(dataTree).find((item) => item.children?.includes(srcNode.index));
    const targetItem = target;
    if (parentNode && parentNode.index !== targetItem.index) {
      const children = dataTree[targetItem.index].children ?? [];
      const newTreeItems = {
        ...dataTree,
        [parentNode.index]: {
          ...parentNode,
          children: (parentNode.children ?? []).filter((item) => item !== srcNode.index),
        },
        [targetItem.index]: {
          ...dataTree[targetItem.index],
          children: [...children, srcNode.index],
        },
      };
      onDrop(newTreeItems);
    }
  };

  const ref = useRef<TreeRef>(null);

  return (
    <div>
      <StyledTree>
        <style>
          {`
          :root {
            --rct-item-height: 40px;
          }
        `}
        </style>
        <ControlledTreeEnvironment
          items={dataTree}
          getItemTitle={(item) => item.data}
          viewState={{
            ['tree-1']: {
              focusedItem,
              expandedItems,
              selectedItems,
            },
          }}
          onFocusItem={(item) => setFocusedItem(item.index)}
          onExpandItem={(item) => {
            const newValue =
              item.children && item.children.length > 0 ? [...expandedItems, item.index] : [...expandedItems];
            isControlled ? handleExpandedItemsChange?.(newValue) : setExpandedItemsInternal(newValue);
          }}
          onCollapseItem={(item) => {
            const newValue = expandedItems.filter((expandedItemIndex) => expandedItemIndex !== item.index);
            isControlled ? handleExpandedItemsChange?.(newValue) : setExpandedItemsInternal(newValue);
          }}
          onSelectItems={(items) => setSelectedItems(items)}
          canDragAndDrop={true}
          canReorderItems={true}
          canDropOnFolder={true}
          canDropOnNonFolder={true}
          canDropAt={(items, target) => true}
          onDrop={(items, target) => {
            const canDrop =
              (target.targetType === 'item' && target.depth == 0) ||
              (target.targetType === 'between-items' && target.parentItem === 'root') ||
              (target.targetType === 'between-items' && target.depth === 1);
            if (canDrop) {
              const srcNode = items[0];
              const targetIdx = target.targetType === 'item' ? target.targetItem : target.parentItem;
              moveNode(srcNode, dataTree[targetIdx]);
            }
          }}
          canDrag={(items) => {
            if (!items.length) {
              return false;
            } else {
              return (items[0].children ?? []).length == 0;
            }
          }}
          onRenameItem={(item, name) => onRenameItem(parseInt(item.index.toString()), name)}
          renderItemTitle={(item) => (
            <div className="flex justify-between w-full items-center">
              {item.item.children?.length == 0 && <div className="mask"></div>}
              <div
                className={`${
                  dataTree.root.children && !dataTree.root.children.includes(item.item.index) ? '' : 'font-bold'
                }`}
              >
                {item.title}
              </div>
              <div className="flex items-center justify-end" style={{ minWidth: '16rem' }}>
                <div
                  className={`mr-5 ${item.item.children?.length == 0 ? '' : 'disabled'}`}
                  onClick={() => {
                    if (item.item.children?.length == 0) {
                      setDialog(
                        <SimpleDialog
                          open={true}
                          handleClose={() => setDialog(undefined)}
                          handleOk={() =>
                            onDelete(typeof item.item.index === 'string' ? parseInt(item.item.index) : item.item.index)
                          }
                          title={t('Remove keyword?')}
                        >
                          <p className="text-sm mb-2">
                            {t(
                              `Are you sure you want to remove {{keywordName}} keyword? This action can't be undone.`,
                              {
                                keywordName: item.title,
                              },
                            )}
                          </p>
                        </SimpleDialog>,
                      );
                    }
                  }}
                >
                  <StyledTrash className="flex items-center">
                    <TrashCan /> <div className="ml-1">{t('Delete')}</div>
                  </StyledTrash>
                </div>
                <div
                  className={`flex mr-5 ${
                    dataTree.root.children && !dataTree.root.children.includes(item.item.index) ? '' : 'disabled'
                  }`}
                  onClick={(e) => {
                    if (dataTree.root.children && !dataTree.root.children.includes(item.item.index)) {
                      moveNode(item.item, dataTree.root);
                    }
                  }}
                >
                  <FontAwesomeIcon icon={faArrowTurnUp} style={{ transform: 'scaleX(-1)' }} />{' '}
                  <div className="ml-1">{t('Level up')}</div>
                </div>

                <div
                  onClick={(event) => {
                    event.stopPropagation();
                    ref.current?.startRenamingItem(item.item.index);
                  }}
                  className="flex mr-4"
                >
                  <EditIcon /> <div className="ml-1">{t('Edit name')}</div>
                </div>
              </div>
            </div>
          )}
        >
          <Tree treeId="tree-1" rootItem="root" treeLabel="Tree Example" ref={ref} />
        </ControlledTreeEnvironment>
      </StyledTree>
      {dialog}
    </div>
  );
};

export default KeywordsTree;

const StyledTree = styled.div`
  max-width: 896px;

  .rct-tree-item-button-isFolder {
    background-color: #e3e5e6;
    margin-bottom: 8px;
  }
  .rct-tree-items-container[role='group'] {
    margin-left: 25px;
  }
  .rct-tree-item-title-container-dragging-over .rct-tree-item-button,
  .rct-tree-item-title-container-dragging-over .rct-tree-item-button .mask {
    background-color: #d3ddf7;
  }
  .rct-tree-item-li .rct-tree-items-container .rct-tree-item-title-container-dragging-over .rct-tree-item-button {
    background-color: #e3e5e6;
  }
  .rct-tree-item-button > div > div {
    line-height: 1.3;
    padding: 6px 0;
    max-width: 80%;
  }
  .rct-tree-item-arrow-isFolder {
    margin-top: -5px;
  }
  .rct-tree-items-container .rct-tree-item-li .rct-tree-items-container .rct-tree-item-li > div > div svg {
    visibility: hidden;
  }
  .mask {
    width: 10px;
    height: 11px;
    background-color: #e3e5e6;
    position: absolute;
    left: 7px;
    z-index: 10;
  }
  .disabled {
    opacity: 0.2;
    pointer-events: none;
    visibility: hidden;
  }
  .rct-tree-root-renaming {
    .rct-tree-item-arrow {
      visibility: hidden;
    }
  }
`;

const StyledTrash = styled.div`
  svg {
    width: 14px;
  }
`;
