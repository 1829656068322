import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SignUp, { SignUpForm } from '../components/SignUp/SignUp';
import { validationErrorTransformer } from '../store/api';
import { ValidatedField } from '../store/api.d';
import { selectAuthValidationErrors } from '../store/auth/selector';
import authSlice from '../store/auth/slice';
import { SignUpDTO } from '../store/auth/types';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';

interface SignUpPageProps {
  signUpAction: (data: SignUpDTO) => void;
  validationErrors: ValidatedField[];
  clearValidationMessages: () => void;
}

const SignUpPage: React.FC<SignUpPageProps> = ({ signUpAction, validationErrors, clearValidationMessages }) => {
  const history = useHistory();

  const login_handler = () => {
    validationErrors && clearValidationMessages();
    history.push('/login');
  };

  const on_change_handler = (): void => {
    validationErrors && clearValidationMessages();
  };

  const signup_handler = (form: SignUpForm) => {
    const data = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      password: form.password,
    };
    signUpAction(data);
  };

  return (
    <SignUp
      login_handler={login_handler}
      signup_handler={signup_handler}
      validation_error={validationErrorTransformer(validationErrors)}
      on_change_handler={on_change_handler}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  validationErrors: selectAuthValidationErrors(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signUpAction: (data: SignUpDTO) => dispatch(authSlice.actions['SIGNUP'](data)),
  clearValidationMessages: () => dispatch(authSlice.actions['CLEAR:VALIDATION']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpPage);
