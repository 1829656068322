import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import authSlice from '../store/auth/slice';
import { VerifyEmailDTO } from '../store/auth/types';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';

interface EmailVerificationPageProps {
  verifyEmailAction: (data: VerifyEmailDTO) => void;
}

const EmailVerificationPage = ({ verifyEmailAction }: EmailVerificationPageProps) => {
  const { id, token }: { id: any; token: string } = useParams();
  const queryParams = useLocation().search;

  useEffect(() => {
    const data = {
      id: id as number,
      token: token,
      queryParams: queryParams,
    };
    verifyEmailAction(data);
  }, []);
  return <div>Verifying...</div>;
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  verifyEmailAction: (data: VerifyEmailDTO) => dispatch(authSlice.actions['VERIFY_EMAIL'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerificationPage);
