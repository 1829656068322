import React from 'react';

interface Props {
  position: number;
  title: string;
  description: string;
  required: boolean;
  error: boolean;
}

const QuestionHeader: React.FC<Props> = ({ position, title, description, required, error }) => {
  return (
    <div>
      <div className={`font-bold questionTitle ${error ? 'text-red-600' : ''}`}>
        {position + '. ' + (title ?? 'Untitled') + (required ? ' *' : '')}
      </div>
      <div className="questionText italic">{description}</div>
    </div>
  );
};

export default QuestionHeader;
