import React from 'react';
import QuestionComponent from '../QuestionComponent/QuestionComponent';
import { Answer, Question, Form, AnswerDTO } from '../../store/form/types';
import { OpenSnackBarDTO } from '../../store/error/types';
import { ValidatedField } from '../../store/api.d';
import { validationErrorTransformer } from '../../store/api';
import { Mode } from '../../store/review/types';
import { isEmptyAnswer } from '../../helpers/form';

interface Props {
  form: Form;
  questions: Question[];
  answerByQuestionId: { [p: number]: Answer };
  answerQuestionHandler?: (data: AnswerDTO) => void;
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  validationErrors?: ValidatedField[];
  mode: Mode;
  canViewFeedback?: boolean;
  numDefaultQuestions: number;
}

const BaseForm: React.FC<Props> = ({
  form,
  questions,
  answerByQuestionId,
  answerQuestionHandler,
  openSnackBarAction,
  validationErrors,
  mode,
  canViewFeedback = false,
  numDefaultQuestions,
}) => {
  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  return (
    <div>
      {questions
        .sort((q1, q2) => q1.position - q2.position)
        .map((question, index) => {
          const answer = answerByQuestionId[question.id];

          const markEmptyAnswers = (!answer || isEmptyAnswer(answer)) && question.required;

          return (
            <div key={`${question.id}-${index}`}>
              {mode == 'view' && index > 0 && (
                <hr className="mt-6 mb-6 border-t-0 border-b-2 border-dotted border-gray-300" />
              )}
              <QuestionComponent
                position={index + 1 + numDefaultQuestions}
                question={question}
                answer={answer}
                onChange={(data) => answerQuestionHandler?.({ answer: data, question_id: question.id })}
                openSnackBarAction={openSnackBarAction}
                error={errors[`question_${question.id}`]}
                disabled={mode == 'view' || form.visibility !== 'write'}
                markEmptyAnswers={markEmptyAnswers && canViewFeedback}
              />
            </div>
          );
        })}
    </div>
  );
};

export default BaseForm;
