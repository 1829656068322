import React from 'react';
import { GRID_COL_INDEX_ATTRIBUTE, GridCellProps } from '@progress/kendo-react-grid';
import { ConflictDTO } from '../../store/bid/types';
import { Checkbox } from '../ui';

interface Props extends GridCellProps {
  postConflict: (data: ConflictDTO) => void;
}

const ConflictCell: React.FC<Props> = (props) => {
  const field = props.field || '';
  const value = props.dataItem[field];
  const { submission_id } = props.dataItem;

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      style={props.style}
    >
      <Checkbox
        defaultChecked={value}
        onChange={(event) => props.postConflict({ submission_id, conflict: event.target.checked })}
      />
    </td>
  );
};

export default ConflictCell;
