import React, { useState } from 'react';
import { Button, Link, Card, Checkbox } from '../ui';
import GroupIcon from '../../icons/Group';
import { OpenSnackBarDTO } from '../../store/error/types';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import CloseIcon from '../../icons/Close';
import { getRouteByPath } from '../../router/routes';
import { naturalSortKeywords } from '../../helpers/keywords';
import { useTranslation, Trans } from 'react-i18next';

interface Props {
  selectedIds: number[];
  keywordById: { [key: number]: Keyword };
  keywordTree: { [key: number]: number[] };
  maxAllowed?: number;
  sectionTitle?: boolean;
  onSelected: (selectedIds: number[]) => void;
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  onBackClick?: () => void;
}

const KeywordPicker: React.FC<Props> = ({
  selectedIds: initSelectedIds,
  keywordById,
  keywordTree,
  maxAllowed,
  sectionTitle = true,
  onSelected,
  openSnackBarAction,
  onBackClick,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [parentSelectedId, setParentSelectedId] = useState<number | undefined>(() => {
    const keys = Object.keys(keywordTree);
    if (!keys.length) return undefined;
    const key = keys[0] as unknown as number;
    return keywordById[key].id;
  });
  const [selectedIds, setSelectedIds] = useState<number[]>(initSelectedIds);

  const theme = useTheme();
  const routeByPath = getRouteByPath(window.location.pathname).name;

  const keywordName = parentSelectedId ? keywordById[parentSelectedId].name : '';

  return (
    <ThemeProvider theme={theme}>
      <div className="text-sm max-w-4xl" style={{ flexGrow: 1 }}>
        {sectionTitle && <h2 className="font-bold text-xl mb-8">{t('Keywords')}</h2>}
        {Object.keys(keywordTree).length > 0 ? (
          <>
            <div className="mb-10">
              <div className="flex items-end mb-4">
                <div>{t('Keywords selected (drag and drop keywords to reorder):')}</div>
              </div>
              <ul>
                {selectedIds.map((id, index) => {
                  const child = keywordById[id];
                  const parent = child.parent_id ? keywordById[child.parent_id] : undefined;
                  return (
                    <StyledKeywords
                      key={index}
                      draggable
                      onDragStart={(e) => {
                        e.dataTransfer.setData('text/plain', JSON.stringify({ id: child.id }));
                      }}
                      onDragOver={(e) => {
                        e.preventDefault();
                        e.dataTransfer.dropEffect = 'copy';
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                        const data = JSON.parse(e.dataTransfer.getData('text/plain'));
                        const { id: origId } = data;
                        const destId = child.id;

                        const origIndex = selectedIds.indexOf(origId);
                        const destIndex = selectedIds.indexOf(destId);

                        const newArray = [...selectedIds];
                        if (origIndex !== -1 && destIndex !== -1) {
                          newArray[origIndex] = destId;
                          newArray[destIndex] = origId;
                        }
                        setSelectedIds(newArray);
                      }}
                    >
                      <div className="flex w-full cursor-pointer justify-between bg-transparent mb-1">
                        <div className="flex w-full py-2 px-4 cursor-pointer">
                          <GroupIcon />
                          <Link className="ml-3">{parent?.name}</Link>{' '}
                          <span className="ml-1"> {`> ${child.name}`} </span>
                        </div>

                        <button
                          onClick={(e) => {
                            const newArray = selectedIds.filter((item) => item !== id);
                            setSelectedIds(newArray);
                          }}
                          className="bg-transparent p-2.5 border-l-2 border-white border-solid"
                        >
                          <CloseIcon />
                        </button>
                      </div>
                    </StyledKeywords>
                  );
                })}
              </ul>
            </div>
            <StyledColumnWrapper className="grid grid-flow-col auto-cols-max mt-3 gap-5 mb-3">
              <Card>
                <p>
                  <strong>{t('Category')}</strong>
                </p>
                <div className="border-b border-gray-200 mt-3 mb-3" />
                <ol>
                  {Object.keys(keywordTree).map((id, index) => {
                    const parent = keywordById[id as unknown as number];
                    const selected: boolean = parentSelectedId === parent.id;
                    const item = (
                      <Link
                        className="cursor-pointer"
                        onClick={(e) => {
                          setParentSelectedId(parent.id);
                        }}
                      >
                        {parent.name}
                      </Link>
                    );
                    return (
                      <li className="mt-1 mb-3" key={index}>
                        {selected ? <strong>{item}</strong> : item}
                      </li>
                    );
                  })}
                </ol>
              </Card>
              {parentSelectedId && (
                <Card>
                  <p>
                    <Trans i18nKey="keywordsRelatedWithParent">
                      <strong>Keywords</strong> related with <strong>{{ keywordName }}</strong>
                    </Trans>
                  </p>
                  <div className="border-b border-gray-200 mt-3 mb-3" />
                  <div className="column flex flex-col items-start">
                    {naturalSortKeywords(keywordTree[parentSelectedId].map((id) => keywordById[id])).map(
                      (child, index) => {
                        const id = child.id;
                        const checked = selectedIds.includes(id);
                        return (
                          <Checkbox
                            className="my-2"
                            key={index}
                            label={child.name}
                            checked={checked}
                            onChange={(e) => {
                              let newArray;
                              if (checked) {
                                // Remove item
                                newArray = selectedIds.filter((item) => item !== id);
                              } else if (maxAllowed && selectedIds.length >= maxAllowed) {
                                // Add item if maxAllowed not exceeded.
                                openSnackBarAction?.({
                                  message: t(`You can not pick more than {{maxAllowed}} keywords`, {
                                    maxAllowed: maxAllowed,
                                  }),
                                  severity: 'warning',
                                });
                                return;
                              } else {
                                // maxAllowed not exceeded, throw feedback and return.
                                newArray = [...selectedIds, id];
                              }
                              setSelectedIds(newArray);
                            }}
                          />
                        );
                      },
                    )}
                  </div>
                </Card>
              )}
            </StyledColumnWrapper>
          </>
        ) : (
          <p className="text-md mt-5" style={{ width: '44rem' }}>
            {t('There are no keywords.')}
          </p>
        )}
      </div>

      {/* FOOTER BUTTONS */}
      <StyledButtonsWrapper
        style={routeByPath == 'RouteKeywords' ? { backgroundColor: 'rgb(237, 243, 255)', maxWidth: '56rem' } : {}}
      >
        {onBackClick && (
          <Button variant="text" className="cursor-pointer mx-2" onClick={onBackClick}>
            <span className=" font-bold text-red-600 ">{t('Cancel')}</span>
          </Button>
        )}
        {Object.keys(keywordTree).length > 0 && (
          <Button
            className="w-48 mx-2"
            onClick={() => {
              onSelected(selectedIds);
              onBackClick && onBackClick();
            }}
          >
            {t('Save')}
          </Button>
        )}
      </StyledButtonsWrapper>
    </ThemeProvider>
  );
};

export default KeywordPicker;

const StyledKeywords = styled.li`
  > div {
    /*
    margin-bottom: 0.5rem;
    width: max-content;
    padding: 0.5rem 1rem;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
    border-radius: 18px;
    */
    background-color: rgba(229, 231, 235, 0.7);
  }
`;

const StyledColumnWrapper = styled.div`
  grid-template-columns: repeat(2, minmax(0, 1fr));

  > div {
    height: 22rem;
    overflow-y: scroll;
    padding: 0;

    > p {
      padding: 1.25rem 1.25rem 0 1.25rem;
      position: sticky;
      top: 0;
      background-color: #fff;
    }

    > div {
      position: sticky;
      top: 40px;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0.75rem;
      background-color: #fff;
    }

    > ol,
    .column {
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      padding-top: 1rem;
    }
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  z-index: 2;
`;
