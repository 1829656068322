import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

export interface EmptyDashboardProps {
  className?: string;
}
const EmptyDashboard: React.FC<EmptyDashboardProps> = ({ className }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`text-center ${className}`}>
      <FontAwesomeIcon icon={faCircleExclamation} className="text-5xl" />
      <p className="text-center mt-4 mb-8">{t('There are no data yet.')}</p>
    </div>
  );
};

export default EmptyDashboard;
