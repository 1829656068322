import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPermissionsDTO, getPermissionsResponse, PermissionState } from './types';

export const initialState: PermissionState = {
  loading: false,
  allPermissions: [],
  byRoleId: {},
};

const permissionSlice = createSlice({
  name: 'PERMISSION',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_PERMISSIONS']: (state, _action: PayloadAction<GetPermissionsDTO>) => ({
      ...state,
      loading: true,
    }),
    ['GET_PERMISSIONS:OK']: (state, action: PayloadAction<getPermissionsResponse>) => ({
      ...state,
      loading: false,
      allPermissions: action.payload.data.allPermissions,
      byRoleId: action.payload.data.byRoleId,
    }),
  },
});

export default permissionSlice;
