import React from 'react';
import { Link } from '../ui';
import { useTranslation } from 'react-i18next';

export interface SendVerificationProps {
  verify_email_handler: (email: string | undefined) => void;
  logout_handler: () => void;
  email: string;
}

const SendEmailVerification: React.FC<SendVerificationProps> = (info) => {
  const { verify_email_handler, logout_handler, email } = info;
  const { t, i18n } = useTranslation();

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">{t('Email verification')}</h2>
      <p>
        {t('You should have received an email to')}
        <b> {email} </b>
        {t('with the verification link')}.
      </p>
      <div className="flex justify-between mt-3">
        <Link onClick={() => verify_email_handler(undefined)} className="font-semibold text-xs cursor-pointer">
          {t('Resend link')}
        </Link>
        <Link onClick={logout_handler} className="font-semibold text-xs cursor-pointer">
          {t('Logout')}
        </Link>
      </div>
    </div>
  );
};

export default SendEmailVerification;
