import React, { useEffect, useState } from 'react';
import { Checkbox } from '../ui';
import { AppState } from '../../store/state';
import { selectBidState, selectConference, selectPaperStatusState, selectScreenState } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import Loading from '../../components/Loading/Loading';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import FileUploadUpdater from '../FileUploadUpdater/FileUploadUpdater';
import { postSubsetBids } from '../../store/bid/sagas';
import { PaperStatus } from '../../store/paper-status/types';
import { BidOption } from '../../store/bid/types';
import BidOptionsManager from '../BidOptionsManager/BidOptionsManager';
import PaperStatusPicker from '../PaperStatusPicker/PaperStatusPicker';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  roleId: number;
  screenByName: { [key: string]: Screen };
  paperStatusById: { [key: number]: PaperStatus };
  paperStatusRole: { [key: number]: number[] };
  bidOptionsById: { [key: number]: BidOption };
  onChange?: (settings: BidSettings) => void;
}

const PhaseSettingsBidding: React.FC<Props> = ({
  loading,
  roleId,
  screenByName,
  paperStatusById,
  paperStatusRole,
  bidOptionsById,
  onChange,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const initSettings = (): BidSettings => {
    return {
      subset_bidding_enabled: screenByName[`Subset Bidding`].settings.enabled,
      paper_status_ids: Object.values(paperStatusById)
        .filter((paperStatus) => paperStatus.category != 'rejection')
        .filter((paperStatus) =>
          paperStatusRole[paperStatus.id] ? paperStatusRole[paperStatus.id].includes(roleId) : false,
        )
        .map((paperStatus) => paperStatus.id),
      // Filter bid options by role
      bid_options: Object.values(bidOptionsById).filter((value) => value.role_id === roleId),
    };
  };

  const [settings, setSettings] = useState<BidSettings>(initSettings);

  useEffect(() => {
    onChange?.(settings); // Just a silly way of take out settings to outside at the very beginning
  }, []);

  const settingsChangeHandler = (settings: BidSettings) => {
    setSettings(settings);
    onChange?.(settings); // Call external callback
  };

  if (loading) {
    return <Loading />;
  }

  const bidding = (
    <fieldset className="border border-solid pt-2 pb-6 px-4 border-gray-300 rounded-md">
      <div className="mt-4">
        <Checkbox
          label={t('Enable subset bidding')}
          checked={settings.subset_bidding_enabled}
          onChange={(e) => settingsChangeHandler({ ...settings, subset_bidding_enabled: e.target.checked })}
          className="ml-4 mb-4"
        />
        {settings.subset_bidding_enabled && (
          <div className="px-4">
            <FileUploadUpdater
              doPreview={(file) => postSubsetBids({ file, dry_run: true })}
              doSave={(file) => postSubsetBids({ file, dry_run: false })}
              templateName="subset-bidding"
            />
          </div>
        )}
      </div>
      <div>
        {/* PAPER STATUS */}
        <PaperStatusPicker
          paperStatusIds={settings.paper_status_ids}
          paperStatusById={paperStatusById}
          onChange={(paperStatusIds) => settingsChangeHandler({ ...settings, paper_status_ids: paperStatusIds })}
          phaseType="bidding"
        />

        {/* BID OPTIONS */}
        <BidOptionsManager
          bidOptions={settings.bid_options}
          onChange={(bidOptions) => settingsChangeHandler({ ...settings, bid_options: bidOptions })}
        />
      </div>
    </fieldset>
  );

  return (
    <ThemeProvider theme={theme}>
      <StyledWrapper className="text-sm max-w-4xl" style={{ width: '33rem' }}>
        <div className="mt-8 mb-5">{bidding}</div>
      </StyledWrapper>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).loading,
  screenByName: selectScreenState(state).screenByName,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  paperStatusRole: selectPaperStatusState(state).paperStatusRole,
  bidOptionsById: selectBidState(state).bidOptions.byId,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PhaseSettingsBidding);

const StyledWrapper = styled.div`
  .minMax {
    input {
      text-align: center;
      padding: 6px 0;
    }
  }

  .addButton {
    margin: 0 auto 0 0.5rem;
  }

  .disabled {
    opacity: 0.5;
  }
`;
