import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { MIN_PASSWORD_LENGTH, passwordValidationRule } from '../../helpers/password';
import { Button, InputText, Link } from '../ui';
import { useTranslation } from 'react-i18next';
import { InputValidationError } from '../types';

export interface ResetPasswordForm {
  password: string;
  password_confirmation: string;
}

export interface ResetPasswordProps {
  reset_password_handler: (form: ResetPasswordForm) => void;
  login_handler: () => void;
}

const ResetPassword: React.FC<ResetPasswordProps> = ({ reset_password_handler, login_handler }) => {
  const { handleSubmit, errors, getValues, control } = useForm<ResetPasswordForm>();
  const { t, i18n } = useTranslation();

  let passwordProps: InputValidationError = {};
  let passwordConfirmationProps: InputValidationError = {};

  const isPasswordConfirmationEquals = (passwordConfirmation: string) => {
    return passwordConfirmation === getValues('password');
  };

  const frontend_validation_rules = {
    password: passwordValidationRule,
    password_confirmation: {
      required: 'Enter your password',
      validate: {
        isEqualsToPassword: isPasswordConfirmationEquals,
      },
    },
  };

  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.password) {
      let message;
      switch (errors.password.type) {
        case 'minLength': {
          message = t('The password must be at least {{length}} characters.', {
            length: MIN_PASSWORD_LENGTH.toString(),
          });
          break;
        }
        default: {
          message = errors.password.message;
        }
      }
      passwordProps = {
        ...passwordProps,
        error: true,
        helperText: message,
      };
    }
    if (errors.password_confirmation) {
      let message;
      switch (errors.password_confirmation.type) {
        case 'isEqualsToPassword': {
          message = t('Password confirmation does not match');
          break;
        }
        default: {
          message = errors.password_confirmation.message;
        }
      }
      passwordConfirmationProps = {
        ...passwordConfirmationProps,
        error: true,
        helperText: message,
      };
    }
  }

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">{t('Reset password')}</h2>
      <form onSubmit={handleSubmit(reset_password_handler)} className="flex flex-col">
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.password}
          render={({ onChange, value }) => (
            <InputText
              type="password"
              label={t('New password')}
              autoFocus
              onChange={onChange}
              value={value}
              className="mb-7"
              error={passwordProps.error}
              helperText={passwordProps.helperText}
            />
          )}
        />

        <Controller
          name="password_confirmation"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.password_confirmation}
          render={({ onChange, value }) => (
            <InputText
              type="password"
              label={t('Confirm new password')}
              onChange={onChange}
              value={value}
              className="mb-7"
              error={passwordConfirmationProps.error}
              helperText={passwordConfirmationProps.helperText}
            />
          )}
        />

        <Button type="submit" className="mb-5 bg-blue-700">
          <span className="text-white">{t('Reset password')}</span>
        </Button>
      </form>
      <Link href="javascript:void(0)" onClick={login_handler} className="font-semibold text-xs mb-5">
        {t('Back to login')}
      </Link>
    </div>
  );
};

export default ResetPassword;
