import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import authSlice from '../store/auth/slice';
import useQuery from '../helpers/useQuery';

interface Props {
  logout: () => void;
}

const LogoutPage: React.FC<Props> = ({ logout }) => {
  const query = useQuery();
  const next = query.get('next');

  useEffect(() => {
    if (next) {
      localStorage.setItem('next', next);
    }
    logout();
  }, []);

  return <Loading />;
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logout: () => dispatch(authSlice.actions.LOGOUT()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);
