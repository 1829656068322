import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { ReactElement } from 'react';

// I needed to prepare this module urgently and it is just dropped here
// But We may have to think to move all dialogs to KendoDialog and
// Consider building an own Kendo Theme

export type RichDialogProps = {
  visible: boolean;
  title: string | ReactElement<any>;
  closehandler: any;
  children: JSX.Element;
  btn1text: string;
  btn1handler: React.MouseEventHandler<HTMLButtonElement>;
};

const RichDialog = ({
  visible,
  title,
  closehandler,
  children,
  btn1text,
  btn1handler,
}: RichDialogProps): JSX.Element => {
  return (
    <div>
      {visible && (
        <Dialog onClose={closehandler} width="60%" height="80%">
          {title}
          <div className="text-md ">{children}</div>
          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={btn1handler}
            >
              {btn1text}
            </button>
          </DialogActionsBar>
        </Dialog>
      )}
    </div>
  );
};

export default RichDialog;
