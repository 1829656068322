import { Checkbox, InputText } from '../ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: OptionDatas;
  textHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => void;
  checkboxHandler: (event: React.ChangeEvent<HTMLInputElement>, index: number | undefined) => void;
  name?: string;
  index?: number;
  max?: number;
}
interface OptionDatas {
  textLength?: number;
  markdown?: boolean;
  latex?: boolean;
}

const InputTextComponent: React.FC<Props> = ({ placeholder, index, value, textHandler, checkboxHandler, name }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-96 flex flex-col items-start py-2 input-text">
      <InputText
        placeholder={placeholder}
        label={t('Character limit')}
        type="number"
        className="w-96"
        defaultValue={value?.textLength}
        name={name}
        fullWidth
        onChange={(e) => textHandler(e, index)}
      />
      {/*
        <Checkbox
          onChange={(e) => checkboxHandler(e, index)}
          checked={value?.markdown}
          name="markdown"
          label="Enable markdown"
        />
      */}
      <Checkbox
        onChange={(e) => checkboxHandler(e, index)}
        checked={value?.latex}
        name="latex"
        label={t('Enable latex')}
        className="mt-2"
      />
    </div>
  );
};

export default InputTextComponent;
