import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardState, getDashboardRequest, getDashboardResponse } from './types';

export const initialState: DashboardState = {
  data: {},
  loading: true,
};

const dashboardSlice = createSlice({
  name: 'DASHBOARD',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET']: (state, action: PayloadAction<getDashboardRequest>) => ({
      ...state,
      loading: true,
    }),
    ['GET:OK']: (state, action: PayloadAction<getDashboardResponse>) => ({
      ...state,
      data: { ...state.data, ...action.payload.data },
      loading: false,
    }),
  },
});

export default dashboardSlice;
