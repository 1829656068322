import React from 'react';

export type COLOR_TYPE = 'info' | 'success' | 'warning';

interface Props {
  className?: string;
  colorType?: COLOR_TYPE;
  value: number;
}

const Badge: React.FC<Props> = ({ className, colorType, value }) => {
  let color: string;

  switch (colorType) {
    case 'info':
      color = 'bg-blue-600';
      break;
    case 'success':
      color = 'bg-green-600';
      break;
    case 'warning':
      color = 'bg-red-600';
      break;
    default:
      color = 'bg-blue-600';
      break;
  }

  return (
    <div
      className={`text-white h-5 text-xs leading-none py-1 px-1.5 font-semibold rounded-full w-max ${className} ${color}`}
    >
      {value}
    </div>
  );
};

export default Badge;
