import React from 'react';

interface IconProps {
  className?: string;
  fill?: string;
  stroke?: string;
  strokeWidth?: number;
  rectangleStroke?: string;
}

function Icon({
  className,
  fill = '#fff',
  stroke = '#9e9e9e',
  strokeWidth = 1.2,
  rectangleStroke = '#9e9e9e',
}: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" className={className}>
      <g fill={fill} fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-976 -525)">
          <g transform="translate(110 285)">
            <g transform="translate(0 240)">
              <g transform="translate(866)">
                <rect
                  width="39"
                  height="39"
                  x="0.5"
                  y="0.5"
                  stroke={rectangleStroke}
                  strokeWidth={strokeWidth}
                  rx="4"
                ></rect>
                <g stroke={stroke} strokeLinecap="round" strokeWidth={strokeWidth} transform="translate(8 8)">
                  <path d="M12.5 8v9" transform="rotate(-90 12.5 12.5)"></path>
                  <path d="M12.5 8v9"></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
