import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      width: 'max-content',
      maxWidth: 400,
      overflow: 'auto',
      maxHeight: 600,
      margin: '10px',
      padding: '4px',
      fontSize: '0.875rem',
    },
  }),
);

export default useStyles;
