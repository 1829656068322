import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { selectConference, selectInfo, selectKeywordState } from '../../store/selectors';
import { ConferenceState } from '../../store/conference/types';
import infoSlice from '../../store/info/slice';
import Loading from '../../components/Loading/Loading';
import { formatKeywordColumn } from '../../helpers/table';
import { useTranslation } from 'react-i18next';

interface Props {
  info: Info;
  loading: boolean;
  conferenceState: ConferenceState;
  keywordById: { [key: number]: Keyword };
  getInfoAction: (data: InfoGetDTO) => void;
}

const PeoplePage: React.FC<Props> = ({ loading, info, conferenceState, keywordById, getInfoAction }) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'users';

  useEffect(() => {
    if (conferenceState.currentTrackId && Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  const chairInfo = info as ChairInfo;

  const { byId } = chairInfo.users;
  const { roleById } = conferenceState;
  /* Initialize fields */
  const inputData: unknown[] = [];
  Object.values(byId).forEach((register, index) => {
    const newRegister = { ...register };
    newRegister.roles = newRegister.role_ids.map((id) => roleById[id].description).join(';');

    formatKeywordColumn(newRegister, keywordById);

    inputData.push(newRegister);
  });

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('People')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getInfoAction({ friendlyName })}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  loading: selectInfo(state).loading,
  conferenceState: selectConference(state),
  keywordById: selectKeywordState(state).keywordById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PeoplePage);
