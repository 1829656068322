import React from 'react';

interface IconProps {
  stroke: string;
}

function Icon({ stroke = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke={stroke} strokeWidth="1.063" transform="translate(-30 -320)">
          <g transform="translate(0 138)">
            <g transform="translate(30 180)">
              <g transform="translate(0 2)">
                <path d="M7.47 1.727a1.386 1.386 0 002.06 0l.67-.735a1.388 1.388 0 012.415 1l-.05.992a1.387 1.387 0 001.453 1.455l.992-.05a1.388 1.388 0 01.998 2.415l-.738.666a1.388 1.388 0 000 2.06l.738.666a1.388 1.388 0 01-1 2.416l-.992-.05a1.387 1.387 0 00-1.457 1.456l.05.992a1.388 1.388 0 01-2.409.998l-.667-.737a1.388 1.388 0 00-2.06 0l-.669.737a1.388 1.388 0 01-2.412-.997l.05-.992a1.387 1.387 0 00-1.456-1.456l-.991.05A1.387 1.387 0 01.992 10.2l.737-.666a1.388 1.388 0 000-2.06l-.737-.67a1.387 1.387 0 01.998-2.412l.991.05a1.387 1.387 0 001.458-1.46l-.047-.992A1.388 1.388 0 016.804.992l.666.735z"></path>
                <circle cx="8.5" cy="8.501" r="3.188"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
