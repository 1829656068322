import React from 'react';
import cn from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';

export type TabsProps = {
  className?: string;
  children: React.ReactNode;
};

export const Tabs = ({ className, children }: TabsProps): JSX.Element => {
  const theme = useTheme();
  return (
    <div className={cn(className, 'flex border-b')} style={{ borderColor: theme.palette.secondary.dark }}>
      {children}
    </div>
  );
};
