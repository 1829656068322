import React, { FormEvent } from 'react';
import { Invitation, InvitationStatus, RespondInvitationRequest } from '../../store/auth/types';
import { Button, RadioButton, TextArea } from '../ui';
import { useTranslation } from 'react-i18next';

export interface InvitationFormProps {
  token: string;
  invitation: Invitation;
  sendHandler: (data: RespondInvitationRequest) => void;
}

const InvitationForm: React.FC<InvitationFormProps> = ({ token, invitation, sendHandler }) => {
  const { t, i18n } = useTranslation();
  const [radioValue, setRadioValue] = React.useState<InvitationStatus>('Accepted');
  const [textValue, setTextValue] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioValue((event.target as HTMLInputElement).value as InvitationStatus);
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextValue(event.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    sendHandler({
      token: token,
      status: radioValue,
      comment: textValue,
    });
  };

  return (
    <div className="text-justify">
      <p className="mb-5">
        {t('Dear')} {invitation.full_name},
      </p>
      <p className="mb-5">
        {t("You've been invited to participate in the")} <b>{invitation.conference_name}</b>.
      </p>
      <p className="mb-5">
        {t('This invitation applies to the track')} <span className="italic">{invitation.track_name}</span>{' '}
        {t('with a role of')} <span className="italic">{invitation.role_description}</span>.
      </p>
      <p className="mb-5">{t('Choose whether you want to accept or decline the invitation.')}</p>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <div className="flex flex-col mb-5">
          <RadioButton
            name="options"
            label={t('Accept')}
            checked={radioValue === 'Accepted'}
            value="Accepted"
            onChange={handleRadioChange}
            className="mb-3"
          />
          <RadioButton
            name="options"
            label={t('Decline')}
            checked={radioValue === 'Declined'}
            value="Declined"
            onChange={handleRadioChange}
          />
        </div>
        <TextArea label={t('Write a comment about your decision.')} value={textValue} onChange={handleTextChange} />
        <Button type="submit" className="mt-5 bg-blue-700">
          <span className="text-white">{t('Send')}</span>
        </Button>
      </form>
    </div>
  );
};

export default InvitationForm;
