import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { AppDispatch } from '../store/store';
import SubmissionDetail from '../components/SubmissionDetail/SubmissionDetail';
import { getSubmissionsRequest, Submission } from '../store/submission/types';
import {
  selectCurrentRole,
  selectCurrentUser,
  selectKeywordState,
  selectReviewState,
  selectRoleRelations,
  selectSubmissionState,
  selectTrackRoles,
} from '../store/selectors';
import { Answer, Answerable, Form, Question } from '../store/form/types';
import { FormTypeEnum } from '../store/form/types.d';
import Loading from '../components/Loading/Loading';
import { useParams } from 'react-router-dom';
import { getRouteByName } from '../router/routes';
import { fillRoutePath } from '../helpers/path';
import history from '../store/history';
import { getFormsData } from '../helpers/form';
import submissionSlice from '../store/submission/slice';
import { AppState } from '../store/state';
import { Review } from '../store/review/types';
import reviewSlice from '../store/review/slice';
import { Role } from '../store/conference/types';
import { User } from '../store/user/types';
import { AssignmentPermissionManager, SubmissionPermissionManager } from '../helpers/permissions';
import SimpleDialog from '../components/dialogs/SimpleDialog/SimpleDialog';
import { MultiSelect } from '../components/ui';
import styled from 'styled-components';
import SendEmailDialog from '../components/dialogs/SendEmailDialog/SendEmailDialog';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  submissionsById: { [key: string]: Submission };
  reviewsById: { [key: string]: Review };
  keywordState: KeywordState;
  user: User;
  roleId?: number;
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  getReviewsAction: () => void;
  roleById: { [key: string]: Role };
  emailRoleIds: number[];
  identityRoleIds: number[];
}

const SubmissionDetailPage = ({
  loading,
  submissionsById,
  reviewsById,
  keywordState,
  user,
  roleId,
  getSubmissionsAction,
  getReviewsAction,
  roleById,
  emailRoleIds,
  identityRoleIds,
}: Props) => {
  const { t, i18n } = useTranslation();
  const params: any = useParams();
  const { id: external_id } = params;

  const isChair = roleId ? roleById[roleId].type == 'chair' : false;

  const [isRecipientOpen, setIsRecipientOpen] = React.useState<boolean>(false);
  const [isEmailOpen, setIsEmailOpen] = React.useState<boolean>(false);
  const [value, setValue] = React.useState<string[]>([]);

  useEffect(() => {
    if (isChair) {
      if (Object.keys(submissionsById).length === 0) {
        getSubmissionsAction({ friendlyName: 'submissions' });
      }
      if (Object.keys(reviewsById).length === 0) {
        getReviewsAction();
      }
    }
  }, []);

  if (loading || !roleId) {
    return <Loading />;
  }

  const getRecipientsOptions = (submission: Submission, reviews: Review[]): { id: string; label: string }[] => {
    const options: { id: string; label: string }[] = [];
    const emailRoles = emailRoleIds.map((roleId) => roleById[roleId]);
    if (emailRoles.find((role) => role.type == 'author') && submission.authors.length > 0) {
      options.push({
        id: 'contact-authors',
        label: t('Contact authors'),
      });
    }
    reviews
      .filter((review) => emailRoleIds.includes(review.role_id))
      .forEach((review) => {
        const role = roleById[review.role_id];
        options.push({
          id: review.person_role_id.toString(),
          label: review.person.full_name
            ? review.person.full_name + ' ' + '(' + role.description + ')'
            : `${t('Reviewer')} #${review.external_id}`,
        });
      });
    return options;
  };

  const optionsToIds = (submission: Submission) => {
    const ids: number[] = [];
    value.forEach((option) => {
      if (option === 'contact-authors') {
        submission.authors.filter((author) => author.is_primary).forEach((author) => ids.push(author.person_role_id));
      } else {
        ids.push(parseInt(option));
      }
    });
    return ids;
  };

  const handleCloseRecipientModal = () => {
    setIsRecipientOpen(false);
    setValue([]);
  };

  // Given id must match with external_id field
  const findSubmission = Object.values(submissionsById).find((submission) => submission.external_id == external_id);
  if (!findSubmission) {
    return <div>{t('Submission #{{id}} not found', { id: external_id })}</div>;
  }

  const authorRole = Object.values(roleById).find((role) => role.type == 'author');
  if (!authorRole) throw Error('Author role does not exist.');

  // Get Submission type forms
  const submissionFormsData = [findSubmission].map((submission) => {
    const isOwner = new SubmissionPermissionManager(submission).isAuthor(user.person.id, roleById[roleId]);
    return getFormsData(
      FormTypeEnum.Submission,
      ['read', 'write'],
      roleId,
      isOwner ? null : authorRole.id,
      user.person.id,
      submission,
    );
  });
  const submission = submissionFormsData[0].answerable as Submission;

  // Get Review type forms
  const assignments = Object.values(reviewsById).filter((assignment) => assignment.submission_id == submission.id);
  const reviewFormsData: {
    answerable: Answerable;
    formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
  }[] = assignments.map((assignment) => {
    const isOwner = new AssignmentPermissionManager(assignment).isAuthor(user.person.id, roleId);
    return getFormsData(
      FormTypeEnum.Review,
      ['read', 'write'],
      roleId,
      isOwner ? null : assignment.role_id,
      user.person.id,
      assignment,
    );
  });

  const role = roleById[roleId];
  const spm = new SubmissionPermissionManager(submission);
  const canEdit = spm.canEdit(
    user,
    role,
    submissionFormsData[0].formsData.map((value) => value.form),
  );
  const canViewDiscussion = spm.canViewDiscussion(role);

  return (
    <>
      <SubmissionDetail
        submission={submission}
        keywordById={keywordState.keywordById}
        roleById={roleById}
        formsData={submissionFormsData.concat(reviewFormsData)}
        onEditClick={
          canEdit
            ? () => history.push(fillRoutePath(getRouteByName('RouteEditSubmission').path, { id: external_id }))
            : undefined
        }
        onSendEmailClick={() => setIsRecipientOpen(true)}
        onViewDiscussionClick={
          canViewDiscussion
            ? () => history.push(fillRoutePath(getRouteByName('RouteDiscussions').path, { submissionId: external_id }))
            : undefined
        }
        identityRoleIds={identityRoleIds}
        currentRoleId={roleId}
        emailRoleIds={emailRoleIds}
        personId={user.person.id}
      />
      {isRecipientOpen && (
        <SimpleDialog
          title={t('Send email')}
          closeOnOk={false}
          handleClose={handleCloseRecipientModal}
          handleOk={() => {
            setIsRecipientOpen(false);
            setIsEmailOpen(true);
          }}
          disableOkButton={!value.length}
        >
          <StyledSimpleDialog className="test">
            <MultiSelect
              label={t('Recipients')}
              required
              value={value}
              options={getRecipientsOptions(submission, assignments)}
              onChange={setValue}
            />
          </StyledSimpleDialog>
        </SimpleDialog>
      )}
      {isEmailOpen && (
        <SendEmailDialog
          title={t('Send email')}
          modelIds={[submission.id]}
          recipientType="submission:ids"
          recipientIds={optionsToIds(submission)}
          handleClose={() => {
            handleCloseRecipientModal();
            setIsEmailOpen(false);
          }}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectReviewState(state).loading,
  submissionsById: selectSubmissionState(state).submissionsById,
  keywordById: selectKeywordState(state).keywordById,
  reviewsById: selectReviewState(state).reviewsById,
  keywordState: selectKeywordState(state),
  user: selectCurrentUser(state),
  roleId: selectCurrentRole(state)?.id,
  roleById: selectTrackRoles(state),
  emailRoleIds: selectRoleRelations(state, 'send-email'),
  identityRoleIds: selectRoleRelations(state, 'identity'),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  getReviewsAction: () => dispatch(reviewSlice.actions.GET_REVIEWS()),
});

const StyledSimpleDialog = styled.div`
  > div > div {
    min-width: 12rem;
  }
  label {
    margin-top: 1rem;
  }
  .MuiDialog-paperWidthSm {
    max-width: 1440px;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionDetailPage);
