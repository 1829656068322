import React from 'react';
import SubmissionsPage from './SubmissionsPage';
import ReviewsPage from './ReviewsPage';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { connect } from 'react-redux';
import { selectCurrentRole } from '../store/selectors';
import { Role } from '../store/conference/types';
import DashboardPage from './chair/DashboardPage';

interface HomePageProps {
  role: Role | null;
}

const HomePage: React.FC<HomePageProps> = ({ role }) => {
  if (role?.type === 'author') {
    return <SubmissionsPage />;
  } else if (role?.type === 'reviewer') {
    return <ReviewsPage />;
  } else if (role?.type === 'chair') {
    return <DashboardPage />;
  } else {
    return <div>Welcome home!</div>;
  }
};

const mapStateToProps = (state: AppState) => ({
  role: selectCurrentRole(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
