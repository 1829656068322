import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import authSlice from '../store/auth/slice';
import { LoginOAuthDTO, OAuthFields } from '../store/auth/types';
import { AppDispatch } from '../store/store';
import { AppState } from '../store/state';
import { selectAuth } from '../store/selectors';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { getRouteByName } from '../router/routes';

interface AuthCallbackPageProps {
  loginOAuthAction: (data: LoginOAuthDTO) => void;
  signup?: OAuthFields;
}

const AuthCallbackPage = ({ loginOAuthAction, signup }: AuthCallbackPageProps) => {
  const { provider } = useParams() as { provider: string };
  const queryParams = useLocation().search;

  useEffect(() => {
    if (!signup) {
      loginOAuthAction({ queryParams, provider });
    } else {
      history.push(fillRoutePath(getRouteByName('RouteLogin').path));
    }
  }, []);

  return <div></div>;
};

const mapStateToProps = (state: AppState) => ({
  signup: selectAuth(state).signup,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loginOAuthAction: (data: LoginOAuthDTO) => dispatch(authSlice.actions['LOGIN_OAUTH'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthCallbackPage);
