import React from 'react';
import Grade from '../../../../icons/Grade';
import GradeOutline from '../../../../icons/GradeOutline';
import useTheme from '@material-ui/core/styles/useTheme';

interface Props {
  minValue: number;
  maxValue: number;
  value: number;
  onClickHandler?: (value: number) => void;
  error?: boolean;
  helperText?: string;
}
export const LinearScale: React.FC<Props> = ({ minValue, maxValue, value, onClickHandler, error, helperText }) => {
  const theme = useTheme();
  const stars = Array(maxValue - minValue + 1)
    .fill(0)
    .map((_, index) => {
      const scaleValue = minValue > 0 ? index + 1 : index + minValue;
      const star =
        value != null && scaleValue <= value ? (
          <Grade width="20" height="20" key={index} />
        ) : (
          <GradeOutline width="20" height="20" key={index} />
        );

      return (
        <div key={index} className="grid justify-items-center gap-y-1">
          <div className={onClickHandler ? 'cursor-pointer' : ''} onClick={() => onClickHandler?.(scaleValue)}>
            {star}
          </div>
          <div>{scaleValue}</div>
        </div>
      );
    });

  return (
    <div>
      <div className="flex">{stars}</div>
      {helperText && (
        <div className="text-sm" style={{ color: error ? theme.palette.error.main : theme.palette.text.primary }}>
          {helperText}
        </div>
      )}
    </div>
  );
};
