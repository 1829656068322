import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { Role } from '../../../store/conference/types';
import styled from 'styled-components';
import { InputText } from '../../ui';
import { useTranslation } from 'react-i18next';

interface Props extends SimpleDialogProps {
  initRole: Role;
  handleRoleOk: (role: Role) => void;
}

const RoleDialog: React.FC<Props> = ({ initRole, handleRoleOk, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [role, setRole] = useState<Role>(initRole);
  const title = initRole.id ? t('Edit role') : t('New Reviewer role');

  return (
    <SimpleDialog
      {...rest}
      title={title}
      handleOk={() => {
        // We call the external callback function depending on whether it's for creation or editing
        handleRoleOk(role);
      }}
      disableOkButton={!role.description}
    >
      <StyledDialogContent>
        <InputText
          label={t('Description') + ' ' + '(e.g., PC, SPC...)'}
          required
          defaultValue={role?.description}
          onChange={(e) => {
            setRole({ ...role, description: e.target.value });
          }}
          className="mb-3"
          fullWidth={true}
        />
      </StyledDialogContent>
    </SimpleDialog>
  );
};

export default RoleDialog;

const StyledDialogContent = styled.div`
  label {
    width: 20rem !important;
  }
`;
