import React from 'react';
import styled from 'styled-components';

export type COLOR_TYPE = 'success' | 'warning';

interface Props {
  className?: string;
  message: string;
  colorType?: COLOR_TYPE;
}

const ChipMessage: React.FC<Props> = ({ className, message, colorType = 'success' }) => {
  let color: string;

  switch (colorType) {
    case 'success':
      color = 'bg-green-600';
      break;
    case 'warning':
      color = 'bg-red-600';
      break;
  }

  return <StyledSpan className={`${className} ${color}`}>{message}</StyledSpan>;
};

export default ChipMessage;

const StyledSpan = styled.span`
  color: white;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 13px;
`;
