import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { OAuthFields } from '../../store/auth/types';
import { Button, InputText, Link } from '../ui';
import PrivacyCheckbox from '../PrivacyCheckbox/PrivacyCheckbox';
import { Checkbox } from '../SignUp/Checkbox';
import { InputValidationError } from '../types';
import { useTranslation } from 'react-i18next';

export interface SignUpOAuthForm {
  first_name: string;
  last_name: string;
}

export interface SignUpOAuthProps {
  validation_error?: {
    first_name?: string;
    last_name?: string;
    email?: string;
  };
  login_handler?: () => void;
  signup_handler: (form: SignUpOAuthForm) => void;
  on_change_handler: () => void;
  signupForm?: OAuthFields;
}

const SignUpOAuth: React.FC<SignUpOAuthProps> = (props) => {
  const { handleSubmit, errors, control } = useForm<SignUpOAuthForm>();
  const { login_handler, signup_handler, validation_error, signupForm } = props;
  const [modalOptions, setModalOptions] = React.useState({
    isOpen: false,
    title: '',
    text: '',
    // eslint-disable-next-line prettier/prettier
    callback: () => {},
    hideCancel: false,
  });
  const { t, i18n } = useTranslation();

  let firstNameProps: InputValidationError = {};
  let lastNameProps: InputValidationError = {};

  const frontend_validation_rules = {
    first_name: { required: t('Enter your first name') },
    last_name: { required: t('Enter your last name') },
    email: {
      required: t('Enter your email address'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('Invalid email address'),
      },
    },
  };
  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.first_name) {
      firstNameProps = {
        ...firstNameProps,
        error: true,
        helperText: errors.first_name.message,
      };
    }
    if (errors.last_name) {
      lastNameProps = {
        ...lastNameProps,
        error: true,
        helperText: errors.last_name.message,
      };
    }
  } else {
    // Backend Validation
    if (validation_error) {
      if (validation_error.first_name) {
        firstNameProps = {
          ...firstNameProps,
          error: true,
          helperText: validation_error.first_name,
        };
      }
      if (validation_error.last_name) {
        lastNameProps = {
          ...lastNameProps,
          error: true,
          helperText: validation_error.last_name,
        };
      }
    }
  }

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">
        {t('Create account from')} {signupForm?.provider}
      </h2>
      <form onSubmit={handleSubmit(signup_handler)} className="flex flex-col">
        <Controller
          name="first_name"
          control={control}
          defaultValue={signupForm?.first_name}
          rules={frontend_validation_rules.first_name}
          render={({ onChange, value }) => (
            <InputText
              label={t('First name')}
              autoFocus
              value={value}
              onChange={onChange}
              className="mb-3"
              error={firstNameProps.error}
              helperText={firstNameProps.helperText}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          defaultValue={signupForm?.last_name}
          rules={frontend_validation_rules.last_name}
          render={({ onChange, value }) => (
            <InputText
              label={t('Last name')}
              value={value}
              onChange={onChange}
              className="mb-3"
              error={lastNameProps.error}
              helperText={lastNameProps.helperText}
            />
          )}
        />
        <InputText disabled label="E-mail" value={signupForm?.email} className="mb-7" />
        <div className="flex">
          <div>
            <Checkbox type="checkbox" label="" className="mb-10" required />
          </div>
          <div className="ml-2">
            {t('I accept the') + ' '}
            <span
              className="cursor-pointer underline"
              onClick={() => setModalOptions({ ...modalOptions, isOpen: true })}
            >
              {t('privacy policy')}
            </span>{' '}
            *
          </div>
        </div>
        <Button type="submit" className="mb-5 bg-blue-700">
          <span className="text-white">{t('Sign up')}</span>
        </Button>
      </form>
      <Link href="javascript:void(0)" onClick={login_handler} className="font-semibold text-xs mb-5">
        {t('Already have an account? Sign in')}
      </Link>
      <PrivacyCheckbox
        isOpen={modalOptions.isOpen}
        closehandler={() => setModalOptions({ ...modalOptions, isOpen: false })}
        btn1handler={() => setModalOptions({ ...modalOptions, isOpen: false })}
      />
    </div>
  );
};

export default SignUpOAuth;
