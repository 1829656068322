import RichDialog from '../RichDialog/RichDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  closehandler: () => void;
  btn1handler: () => void;
}

const PrivacyCheckbox: React.FC<Props> = ({ isOpen, closehandler, btn1handler }) => {
  const { t, i18n } = useTranslation();

  return (
    <RichDialog
      visible={isOpen}
      btn1text={t('OK')}
      closehandler={closehandler}
      btn1handler={btn1handler}
      title={<div className="text-blue-500 text-xl mb-3 font-bold">{t('Privacy Policy')}</div>}
    >
      <div className="">
        <div className="font-bold">{t('Privacy Policy for Brainful Labs, SL')}</div>

        <div>
          {t(
            `At Chairingtool, accessible from chairingtool.com, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Chairingtool and how we use it.`,
          )}
        </div>

        <div>
          {t(
            `If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us at support@brainful-labs.com.`,
          )}
        </div>

        <div>
          {t(
            `This Privacy Policy applies only to our online activities and is valid for users of our web service with regards to the information that they uploaded in Chairingtool. This policy is not applicable to any information collected offline or via channels other than this website.`,
          )}
        </div>

        <div className="font-bold mt-2">{t('Consent')}</div>

        <div>
          {t('By acepting the terms on registering, you hereby consent to our Privacy Policy and agree to its terms.')}
        </div>

        <div className="font-bold mt-2">{t('Information we collect')}</div>

        <div>
          {t(
            `The personal information that you are asked to provide, are solely used to apply for submissions in conferences through chairingtool.`,
          )}
        </div>
        <div>
          {t(
            `When you register for an Account, we ask for your contact information, including items such as name, company name, address, email address, and telephone number.`,
          )}
        </div>

        <div className="font-bold mt-2">{t('How we use your information')}</div>

        <div>{t('We use the information we collect in various ways, including to:')}</div>

        <ul>
          <li>{t('Provide, operate, with your submission our website')}</li>
          <li>
            {t(
              `Communicate with you, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes`,
            )}
          </li>
          <li>{t('Send you emails')}</li>
          <li>{t('Find and prevent fraud')}</li>
        </ul>
        <div className="font-bold mt-2">{t('Log Files')}</div>

        <div>
          {t(
            `Chairingtool follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information.`,
          )}
        </div>

        <div className="font-bold mt-2">{t(`CCPA Privacy Rights (Do Not Sell My Personal Information)`)}</div>

        <div>{t('Under the CCPA, among other rights, California consumers have the right to:')}</div>
        <div>
          {t(
            `Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.`,
          )}
        </div>
        <div>
          {t('Request that a business delete any personal data about the consumer that a business has collected.')}
        </div>
        <div>
          {t("Request that a business that sells a consumer's personal data, not sell the consumer's personal data.")}
        </div>
        <div>
          {t(
            `If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.`,
          )}
        </div>

        <div className="font-bold mt-2">{t('GDPR Data Protection Rights')}</div>

        <div>
          {t(
            `We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:`,
          )}
        </div>
        <div>
          {t(
            `The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.`,
          )}
        </div>
        <div>
          {t(
            `The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.`,
          )}
        </div>
        <div>
          {t(
            `The right to erasure – You have the right to request that we erase your personal data, under certain conditions.`,
          )}
        </div>
        <div>
          {t(
            `The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.`,
          )}
        </div>
        <div>
          {t(
            `The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.`,
          )}
        </div>
        <div>
          {t(
            `The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.`,
          )}
        </div>
        <div>
          {t(
            `If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us.`,
          )}
        </div>

        <div className="font-bold mt-2">{t(`Children's Information`)}</div>

        <div>
          {t(
            `Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.`,
          )}
        </div>

        <div>
          {t(
            `Chairingtool does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.`,
          )}
        </div>
      </div>
    </RichDialog>
  );
};

export default PrivacyCheckbox;
