import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

type Props = {
  onBackClick: () => void;
  children?: React.ReactNode;
  tabTitle?: string;
  backButton?: boolean;
};

const GridTabLayout: React.FC<Props> = ({ onBackClick, children, tabTitle, backButton = true }) => {
  return (
    <div className="gridtab-layout-wrapper">
      {backButton && (
        <div className="flex mb-8 items-center">
          <button onClick={onBackClick} className="bg-blue-700 text-white px-1.5 py-0.5 h-6 leading-none">
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>

          {tabTitle && <h2 className="font-bold text-xl ml-8">{tabTitle}</h2>}
        </div>
      )}

      <div className="gridtab-layout-children">{children}</div>
    </div>
  );
};

export default GridTabLayout;
