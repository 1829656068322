import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#0044F0' }: IconProps): JSX.Element {
  return (
    <svg
      id="Componente_30_2"
      data-name="Componente 30 – 2"
      xmlns="http://www.w3.org/2000/svg"
      width="18.897"
      height="15"
      viewBox="0 0 18.897 15"
    >
      <path
        id="Trazado_3804"
        data-name="Trazado 3804"
        d="M1343.836,5128.823a16.192,16.192,0,0,0,3.118-3.827s-3.543-6.5-9.449-6.5a8.309,8.309,0,0,0-3.3.7l.91.91a6.9,6.9,0,0,1,2.386-.425,8.813,8.813,0,0,1,6.106,2.905,15.075,15.075,0,0,1,1.724,2.067c.094.13.165.236.236.342-.071.106-.142.213-.236.343a15.1,15.1,0,0,1-1.724,2.067c-.2.2-.4.39-.614.579Z"
        transform="translate(-1328.057 -5117.496)"
        fill={color}
      />
      <path
        id="Trazado_3805"
        data-name="Trazado 3805"
        d="M1341.111,5126.027a4.213,4.213,0,0,0,.236-1.394,4.138,4.138,0,0,0-4.134-4.134,4.192,4.192,0,0,0-1.394.237l.98.968c.13-.012.272-.024.413-.024a2.954,2.954,0,0,1,2.953,2.953c0,.142-.012.284-.024.414Z"
        transform="translate(-1327.764 -5117.133)"
        fill={color}
      />
      <path
        id="Trazado_3806"
        data-name="Trazado 3806"
        d="M1338.047,5127.143l.98.968a4.193,4.193,0,0,1-1.394.236,4.138,4.138,0,0,1-4.134-4.134,4.209,4.209,0,0,1,.236-1.394l.969.98c-.012.129-.024.271-.024.413a2.954,2.954,0,0,0,2.953,2.953C1337.775,5127.167,1337.917,5127.155,1338.047,5127.143Z"
        transform="translate(-1328.184 -5116.714)"
        fill={color}
      />
      <path
        id="Trazado_3807"
        data-name="Trazado 3807"
        d="M1332.957,5121.6c-.213.188-.413.378-.614.579a15.068,15.068,0,0,0-1.724,2.067c-.094.13-.165.236-.236.342.071.106.142.213.236.343a15.1,15.1,0,0,0,1.724,2.067,8.814,8.814,0,0,0,6.106,2.905,6.9,6.9,0,0,0,2.386-.425l.909.909a8.3,8.3,0,0,1-3.3.7c-5.905,0-9.449-6.5-9.449-6.5a16.182,16.182,0,0,1,3.118-3.827Z"
        transform="translate(-1329 -5117.087)"
        fill={color}
      />
      <path
        id="Trazado_3808"
        data-name="Trazado 3808"
        d="M1344.823,5132.649l-14.173-14.173.827-.827,14.173,14.173Z"
        transform="translate(-1328.701 -5117.649)"
        fill={color}
      />
    </svg>
  );
}

export default Icon;
