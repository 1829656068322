import React from 'react';
import cn from 'classnames';
import { Checkbox } from '../Checkbox';
import { nanoid } from 'nanoid';

export interface MultiSelectCheckboxProps {
  label?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  value?: string[];
  onChange?: (value: string[]) => void;
  options?: { id: string; label: string }[];
  dense?: boolean;
  className?: string;
}

export const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = ({
  label,
  required,
  value,
  onChange,
  options = [],
  dense,
  fullWidth,
  error,
  helperText,
  className,
}) => {
  const inputId = nanoid();
  return (
    <div className={cn(className, { error })}>
      {label && <label>{required ? `${label} *` : label}</label>}
      {options?.map((option, index) => (
        <Checkbox
          key={index}
          id={`${inputId}-${option.id}`}
          label={option.label}
          checked={value?.includes(option.id) || false}
          onChange={() => {}}
          onClick={(e) => {
            let ret;
            if (!value) ret = [option.id];
            else if (value.includes(option.id)) {
              ret = value.filter((el) => el != option.id);
            } else {
              ret = [...value, option.id];
            }
            onChange?.(ret);
          }}
          className="mr-4"
        />
      ))}
    </div>
  );
};

export default MultiSelectCheckbox;
