import React, { FC } from 'react';
import styled from 'styled-components';
import { Role } from '../../store/conference/types';
import { Form } from '../../store/form/types';
import { OpenSnackBarDTO } from '../../store/error/types';
import DateTimeWithOffset from '../DateTimeWithOffset/DateTimeWithOffset';
import EditIcon from '../../icons/Edit';
import ViewNewIcon from '../../icons/ViewNew';
import { Link } from '../ui';
import FormPermissionsPlain from '../FormPermissionsPlain/FormPermissionsPlain';
import useTheme from '@material-ui/core/styles/useTheme';
import { isPastDate } from '../../helpers/date';
import { useTranslation } from 'react-i18next';
import { getActionName, getFormName } from '../../helpers/translations';

interface Props {
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  initEvent: PhaseEvent;
  actionDefinitions: PhaseActionType[];
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  onEditClick?: () => void;
  onViewResultingClick?: () => void;
  onConflictClick?: (formId: number) => void;
  permissionConflicts: PermissionConflict[];
}

const PhaseEvent: FC<Props> = ({
  roleById,
  formById,
  initEvent,
  actionDefinitions,
  onEditClick,
  onViewResultingClick,
  onConflictClick,
  permissionConflicts,
}) => {
  const { t, i18n } = useTranslation();
  const event = initEvent;

  /* Map actions to its display component */
  const switchActions: JSX.Element[] = [];
  const formPermissionActions: JSX.Element[] = [];

  const theme = useTheme();

  event.actions.forEach((action, index) => {
    let el;
    switch (action.type) {
      case 'form_permission_change':
        const { form_id, permissions } = action.body.params;
        const form = formById[form_id];

        // Find a conflict form to show bullet warning
        const conflict = permissionConflicts.find(
          (formConflict) =>
            formConflict.form_id == form.id && Object.keys(permissions).includes(formConflict.role_id.toString()),
        );

        el = (
          <div className="flex mb-4 relative" key={`formdata-$(index)`}>
            <p className="w-56">{getFormName(form.name, form.is_default, t)}</p>
            {conflict && (
              <span
                className="font-semibold px-2 rounded-full text-sm ml-1.5 text-black cursor-pointer absolute bg-yellow-300"
                style={{
                  top: '-6px',
                  left: '-28px',
                }}
                onClick={() => onConflictClick?.(form.id)}
              >
                !
              </span>
            )}

            <div className="w-56">
              <FormPermissionsPlain roleById={roleById} permissions={permissions} form={form} />
            </div>
          </div>
        );

        formPermissionActions.push(el);
        break;
      default:
        const display_name = getActionName(action.type, t);

        el = (
          <p key={index} className="mb-1">
            {action.body.params.value ? t('Enable') : t('Disable')} {display_name}
          </p>
        );

        switchActions.push(el);
        break;
    }
  });

  let actionsMsg = '';

  if (
    Object.keys(actionDefinitions).length > 0 &&
    Object.keys(actionDefinitions).length ===
      event.actions.filter((action, index) => action.body.params.value === true).length
  ) {
    actionsMsg = t('Enable all states');
  }

  if (
    Object.keys(actionDefinitions).length > 0 &&
    Object.keys(actionDefinitions).length ===
      event.actions.filter((action, index) => action.body.params.value === false).length
  ) {
    actionsMsg = t('Disable all states');
  }

  return (
    <StyledPhase>
      <StyledEventWrapper>
        <div className={`${isPastDate(new Date(event.starts_at ?? '')) ? 'executedEvent' : ''}`}>
          <div className="flex justify-between">
            <span className="font-semibold text-md leading-7">{event.name}</span>

            <span className="font-semibold text-md leading-7">
              <DateTimeWithOffset date={event.starts_at} showTime={true} showUtc={false} />
            </span>
          </div>

          <hr />

          <StyledFlexBox className="flex justify-between text-sm">
            <div className="w-80">
              <div className="flex mb-1">
                <h3 className="font-semibold">{t('Actions')}</h3>
              </div>
              {actionsMsg === '' ? <div>{switchActions}</div> : <div>{actionsMsg}</div>}
            </div>

            <div className="flex flex-col" style={{ flexGrow: 1 }}>
              <div className="flex mb-1">
                <h3 className="w-56">{t('Forms')}</h3>
                <h3 style={{ flexGrow: 1 }}>{t('Permissions')}</h3>
              </div>

              {formPermissionActions}
            </div>
          </StyledFlexBox>
        </div>

        {/* FOOTER BUTTONS */}
        <div className="flex justify-between mt-6 text-sm">
          <Link className="flex items-center cursor-pointer" onClick={onEditClick}>
            <EditIcon color={theme.palette.primary.main} className="mr-1" />
            {t('Edit')}
          </Link>

          <Link className="flex items-center cursor-pointer" onClick={onViewResultingClick}>
            <ViewNewIcon />
            <span className="ml-1">{t('View resulting state')}</span>
          </Link>
        </div>
      </StyledEventWrapper>
    </StyledPhase>
  );
};

export default PhaseEvent;

const StyledPhase = styled.div`
  .executedEvent {
    opacity: 0.4;
  }
`;

const StyledEventWrapper = styled.div`
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 2px solid #e3e5e6;

  hr {
    border-top-width: 2px;
    border-top-style: dotted;
    margin: 1rem auto;
  }

  .in-progress-label {
    height: max-content;
    background-color: #fbeb02;
    padding: 2px 8px;
    border-radius: 12px;
    margin-bottom: 0.2rem;
    visibility: visible;
  }
  .invisible-in-progress-label {
    margin-bottom: 0.2rem;
    visibility: hidden;
    width: 0;
    height: 0;
    padding: 0;
  }
`;

const StyledFlexBox = styled.div`
  h3 {
    font-weight: 600;
  }
`;
