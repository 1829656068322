import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { rootReducer } from './root-reducer';

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  /**
   * Whether to enable Redux DevTools integration. Defaults to `true`.
   *
   * Additional configuration can be done by passing Redux DevTools options
   */
  devTools: true, // TODO Set boolean based on environment
});

export function runSaga(rootSaga: Saga<any[]>): void {
  sagaMiddleware.run(rootSaga);
}

export type AppDispatch = typeof store.dispatch;
