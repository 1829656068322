import { PayloadAction } from '@reduxjs/toolkit';
import {
  CreateLabelDTO,
  DeleteLabelDTO,
  GetLabelsResponse,
  Label,
  LabelCreateResponse,
  LabelDeleteResponse,
  LabelState,
  LabelUpdateResponse,
  PickLabelDTO,
  postLabelPickResponse,
  UnpickLabelDTO,
  UpdateLabelDTO,
} from './types';
import { put, select, takeLatest } from '@redux-saga/core/effects';
import errorSlice from '../error/slice';
import { LabelDialogFields } from '../../components/LabelDialog/LabelDialog';
import { TableFriendlyName } from '../info/types';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import labelSlice from './slice';
import { selectLabelState, selectTable } from '../selectors';
import { baseURL } from '../root-saga';
import { TableState } from '../table/types';

const getLabels = async (): Promise<Result<GetLabelsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<GetLabelsResponse>(`${baseURL}/api/labels`, init);
};

function* getLabelsSaga() {
  const result = (yield getLabels()) as Await<ReturnType<typeof getLabels>>;

  switch (result.type) {
    case 'ok':
      yield put(labelSlice.actions['LABEL:GET:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postLabelPick = async (
  labelsByRowId: { [key: number]: number[] },
  tableFriendlyName: TableFriendlyName,
): Promise<Result<postLabelPickResponse, APIValidationError>> => {
  const body = {
    labels_by_row_id: labelsByRowId,
    friendly_name: tableFriendlyName,
  };
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(body),
  };

  return apiCall<postLabelPickResponse>(`${baseURL}/api/labels/pick`, init);
};

function* postLabelPickSaga(action: PayloadAction<PickLabelDTO>) {
  const { labelsByRowId, tableFriendlyName } = action.payload;

  const result = (yield postLabelPick(labelsByRowId, tableFriendlyName)) as Await<ReturnType<typeof postLabelPick>>;

  switch (result.type) {
    case 'ok':
      yield put(
        labelSlice.actions['LABEL:PICK:OK']({
          labelIdsByRecordId: labelsByRowId,
          tableFriendlyName: tableFriendlyName,
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* postLabelUnpickSaga(action: PayloadAction<UnpickLabelDTO>): Generator<any, void, any> {
  const { recordId, labelId, tableFriendlyName } = action.payload;

  const labelState: LabelState = yield select(selectLabelState);

  const tableState: TableState = yield select(selectTable);
  const classModel = tableState.tablesSettings[tableFriendlyName].model;

  const label_ids = labelState.labelables[classModel][recordId];
  const labelIds = label_ids.filter((value: number) => value != labelId);
  const labelsByRowId: { [key: number]: number[] } = { [recordId]: labelIds };

  yield put(
    labelSlice.actions['LABEL:PICK']({
      labelsByRowId,
      tableFriendlyName,
    }),
  );
  return;
}

const postLabel = async (
  data: LabelDialogFields,
  tableFriendlyName: TableFriendlyName,
  currentTrackId: number | null,
): Promise<Result<LabelCreateResponse, APIValidationError>> => {
  const body = {
    name: data.name,
    background_color: data.backgroundColor,
    text_color: data.textColor,
    friendly_name: tableFriendlyName,
    track_id: currentTrackId,
  };
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(body),
  };

  return apiCall<LabelCreateResponse>(`${baseURL}/api/labels`, init);
};

function* postLabelCreateSaga(action: PayloadAction<CreateLabelDTO>) {
  const { data, tableFriendlyName, currentTrackId, rowIds } = action.payload;
  const result = (yield postLabel(data, tableFriendlyName, currentTrackId)) as Await<ReturnType<typeof postLabel>>;

  switch (result.type) {
    case 'ok':
      const label = result.value.data as Label;
      yield put(labelSlice.actions['LABEL:CREATE:OK'](label));
      if (rowIds && rowIds.length > 0) {
        const tableState: TableState = yield select(selectTable);
        const classModel = tableState.tablesSettings[tableFriendlyName].model;
        const labelByIds: { [key: number]: number[] } = {};
        const labelState: LabelState = yield select(selectLabelState);
        rowIds.forEach((rowId) => {
          const labelIds = labelState.labelables[classModel][rowId] ?? [];
          labelByIds[rowId] = [...labelIds, label.key];
        });
        yield put(
          labelSlice.actions['LABEL:PICK']({
            labelsByRowId: labelByIds,
            tableFriendlyName: tableFriendlyName,
          }),
        );
      }
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updateLabel = async (
  labelId: number,
  data: LabelDialogFields,
): Promise<Result<LabelUpdateResponse, APIValidationError>> => {
  const body = {
    name: data.name,
    background_color: data.backgroundColor,
    text_color: data.textColor,
  };
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(body),
  };

  return apiCall<LabelUpdateResponse>(`${baseURL}/api/labels/${labelId}`, init);
};

function* updateLabelSaga(action: PayloadAction<UpdateLabelDTO>) {
  const { labelId, data } = action.payload;
  const result = (yield updateLabel(labelId, data)) as Await<ReturnType<typeof updateLabel>>;

  switch (result.type) {
    case 'ok':
      yield put(labelSlice.actions['LABEL:UPDATE:OK']({ labelId: labelId, data: data }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deleteLabel = async (labelId: number): Promise<Result<LabelDeleteResponse, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };

  return apiCall<LabelDeleteResponse>(`${baseURL}/api/labels/${labelId}`, init);
};

function* deleteLabelSaga(action: PayloadAction<DeleteLabelDTO>) {
  const { labelId, tableFriendlyName } = action.payload;
  const result = (yield deleteLabel(labelId)) as Await<ReturnType<typeof deleteLabel>>;

  switch (result.type) {
    case 'ok':
      yield put(labelSlice.actions['LABEL:DELETE:OK'](action.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(labelSlice.actions['LABEL:GET'], getLabelsSaga),
  takeLatest(labelSlice.actions['LABEL:PICK'], postLabelPickSaga),
  takeLatest(labelSlice.actions['LABEL:UNPICK'], postLabelUnpickSaga),
  takeLatest(labelSlice.actions['LABEL:CREATE'], postLabelCreateSaga),
  takeLatest(labelSlice.actions['LABEL:UPDATE'], updateLabelSaga),
  takeLatest(labelSlice.actions['LABEL:DELETE'], deleteLabelSaga),
];
