import React from 'react';
import { AppState } from '../../store/state';
import { connect } from 'react-redux';
import { selectCurrentUser } from '../../store/selectors';
import moment from 'moment';
import { getUserOffset, transform } from '../../helpers/timezone';
import { DateFormat, DateFormatEnum } from '../../store/user/types.d';

const kendoToMomentFormat = (format: DateFormat) => {
  return format.replace(/d/g, 'D'); // Replace d for D
};

interface Props {
  date: string | null;
  timezone: string | null;
  dateFormat: string | null;
  showTime?: boolean;
  showUtc?: boolean;
}

const DateTimeWithOffset: React.FC<Props> = ({ date, timezone, dateFormat, showTime, showUtc }) => {
  let str;
  if (date) {
    const utcOffset = getUserOffset(timezone);
    const dateWithOffset = transform(new Date(date), utcOffset);
    str = moment(dateWithOffset).format(kendoToMomentFormat(dateFormat ?? DateFormatEnum.DMY24));
    str = showTime ? `${str} ${showUtc ? utcOffset : ''}` : str.split(' ')[0];
  } else {
    str = 'Not defined';
  }
  return <>{str}</>;
};

const mapStateToProps = (state: AppState) => ({
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
});

export default connect(mapStateToProps)(DateTimeWithOffset);
