import React, { useState } from 'react';
import { BidOption, BidOptionDTO } from '../../store/bid/types';
import { Button, InputText } from '../ui';
import styled from 'styled-components';
import { validationErrorTransformer } from '../../store/api';
import { parsePositiveNumber } from '../../helpers/validation';
import TrashCan from '../../icons/TrashCan';
import { AddButton } from '../ui/inputs/AddButton/AddButton';
import EditIcon from '../../icons/Edit';
import { ValidatedField } from '../../store/api.d';
import useTheme from '@material-ui/core/styles/useTheme';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
  bidOptions: BidOption[];
  onChange: (bidOptions: BidOptionDTO[]) => void;
}

const BidOptionsManager: React.FC<Props> = ({ bidOptions, onChange }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const initBidOptions: BidOptionDTO = {
    id: 0,
    name: '',
    min: null,
    max: null,
  };

  const [removeMode, setRemoveMode] = React.useState<boolean>(false);
  const [bidOptionState, setBidOptionState] = React.useState<BidOptionDTO | undefined>(undefined);
  const [bidOptionIdxSelected, setBidOptionIdxSelected] = React.useState<number | undefined>(undefined);
  const [validationErrors, setValidationErrors] = useState<ValidatedField[]>([]);

  const updateBidOption = () => {
    if (bidOptionState) {
      const value = bidOptions.map((bidOption, idx) => {
        if (idx == bidOptionIdxSelected) {
          return bidOptionState;
        } else {
          return bidOption;
        }
      });
      onChange(value);
    }
  };

  const deleteBidOption = () => {
    const value = bidOptions.filter((bidOption, idx) => idx != bidOptionIdxSelected);
    onChange(value);
  };

  const addBidOption = () => {
    if (bidOptionState) {
      const value = [...bidOptions, bidOptionState];
      onChange(value);
    }
  };

  const isBidOptionValid = (): boolean => {
    const errors = [];
    if (!bidOptionState?.name) {
      errors.push({ name: 'name', message: t("This field can't be empty") });
    }
    if ((bidOptionState?.min ?? Number.NEGATIVE_INFINITY) > (bidOptionState?.max ?? Number.POSITIVE_INFINITY)) {
      errors.push({ name: 'min', message: t('Min is greater than max') });
    }
    if ((bidOptionState?.max ?? Number.POSITIVE_INFINITY) < (bidOptionState?.min ?? Number.NEGATIVE_INFINITY)) {
      errors.push({ name: 'max', message: t('Max is smaller than min') });
    }
    setValidationErrors(errors);
    return !errors.length;
  };

  const errors = validationErrors ? validationErrorTransformer(validationErrors) : {};

  /* Biddings screen settings */
  const tHeadBiddings = (
    <thead>
      <tr>
        <th style={{ width: '200px', wordWrap: 'break-word' }}></th>
        <th style={{ width: '65px', wordWrap: 'break-word' }}>{t('Min')}</th>
        <th style={{ width: '65px', wordWrap: 'break-word' }}>{t('Max')}</th>
        <th style={{ width: '59px', wordWrap: 'break-word' }}></th>
        <th style={{ width: '51px', wordWrap: 'break-word' }}></th>
      </tr>
    </thead>
  );

  // Bid option form used to add or edit
  const rowForm = (index: number) => (
    <StyledFormTr key={index}>
      {/* NAME INPUT */}
      <td>
        <StyledInputText
          className="w-46"
          defaultValue={bidOptionState?.name}
          onChange={(event) => {
            setBidOptionState((prevState) => {
              if (prevState) {
                return {
                  ...prevState,
                  name: event.target.value,
                };
              }
            });
          }}
          placeholder={t('Enter bid option name') + '...'}
          error={!!errors.name}
          helperText={errors.name}
        />
      </td>

      {/* MIN INPUT */}
      <td className="text-center">
        <StyledInputText
          className="w-12 minMax"
          defaultValue={bidOptionState?.min}
          onChange={(event) => {
            setBidOptionState((prevState) => {
              if (prevState) {
                return {
                  ...prevState,
                  min: parsePositiveNumber(event.target.value),
                };
              }
            });
          }}
          type="number"
          inputProps={{ min: 1 }}
          error={!!errors.min}
          helperText={errors.min}
        />
      </td>

      {/* MAX INPUT */}
      <td className="text-center">
        <StyledInputText
          className="w-12 minMax"
          defaultValue={bidOptionState?.max}
          onChange={(event) => {
            setBidOptionState((prevState) => {
              if (prevState) {
                return {
                  ...prevState,
                  max: parsePositiveNumber(event.target.value),
                };
              }
            });
          }}
          type="number"
          inputProps={{ min: 1 }}
          error={!!errors.max}
          helperText={errors.max}
        />
      </td>

      {/* SAVE BUTTON */}
      <td>
        <Button
          variant="text"
          onClick={() => {
            if (bidOptionState && isBidOptionValid()) {
              bidOptionIdxSelected != undefined ? updateBidOption() : addBidOption();
              setBidOptionState(undefined);
              setBidOptionIdxSelected(undefined);
              setValidationErrors([]);
            }
          }}
          style={{
            color: `${theme.palette.primary.main}`,
            padding: '0',
          }}
        >
          {t('Save')}
        </Button>
      </td>

      {/* CANCEL BUTTON */}
      <td>
        <StyledCancel
          onClick={() => {
            setBidOptionState(undefined);
            setBidOptionIdxSelected(undefined);
            setValidationErrors([]);
          }}
          variant="text"
        >
          {t('Cancel')}
        </StyledCancel>
      </td>
    </StyledFormTr>
  );

  // Bid option row list
  const rowList = (value: BidOption, index: number) => {
    const canRemove = !value.has_stored_data ?? !value.id;

    return (
      <tr key={index}>
        {/* NAME */}
        <td>
          <p className="my-2 text-sm pl-2">{value.name}</p>
          <hr style={{ borderStyle: 'dashed', borderColor: '#ccc' }} />
        </td>

        {/* MIN */}
        <td>
          <p className="mx-1 text-sm text-center">
            {value.min != null ? <span>{value.min}</span> : <span className="text-xs">{t('No limit')}</span>}
          </p>
        </td>

        {/* MAX */}
        <td>
          <p className="mx-1 text-sm text-center">
            {value.max != null ? <span>{value.max}</span> : <span className="text-xs">{t('No limit')}</span>}
          </p>
        </td>

        {/* EDIT BUTTON */}
        <td>
          <Button
            variant="text"
            onClick={() => {
              setBidOptionState(value);
              setBidOptionIdxSelected(index);
              setValidationErrors([]);
              setRemoveMode(false);
            }}
            style={{
              color: `${theme.palette.primary.main}`,
              padding: '0',
            }}
            className="mt-0.5"
          >
            <div className="flex items-center">
              <EditIcon color={theme.palette.primary.main} className="mr-1" />
              {t('Edit')}
            </div>
          </Button>
        </td>

        {/* REMOVE BUTTON */}
        <td>
          <Tooltip
            title={`${!canRemove ? t('You cannot remove because there are stored bids.') : ''}`}
            placement="top-end"
          >
            <div>
              <StyledCancel
                onClick={() => {
                  setBidOptionState(undefined);
                  setBidOptionIdxSelected(index);
                  setValidationErrors([]);
                  setRemoveMode(true);
                }}
                variant="text"
                disabled={!canRemove}
              >
                <TrashCan stroke={!canRemove ? '' : theme.palette.primary.main} />
              </StyledCancel>
            </div>
          </Tooltip>
        </td>
      </tr>
    );
  };

  // Bid option row remove confirm
  const rowRemove = (value: BidOptionDTO, index: number) => (
    <tr style={{ backgroundColor: '#eeeded' }}>
      <td colSpan={3}>
        <p className="pl-2">{t(`Do you really want to remove "{{name}}" option?`, { name: value.name })}</p>
      </td>

      {/* CONFIRM BUTTON */}
      <td>
        <Button
          variant="text"
          onClick={() => {
            deleteBidOption();
            setBidOptionIdxSelected(undefined);
            setRemoveMode(false);
          }}
          style={{
            color: `${theme.palette.primary.main}`,
            padding: '0',
          }}
        >
          {t('OK')}
        </Button>
      </td>

      {/* CANCEL BUTTON */}
      <td>
        <StyledCancel
          onClick={() => {
            setBidOptionIdxSelected(undefined);
            setRemoveMode(false);
          }}
          variant="text"
        >
          {t('Cancel')}
        </StyledCancel>
      </td>
    </tr>
  );

  return (
    <div className="mt-3 ml-4">
      <h2 className="font-bold px-0">{t('Bid options')}</h2>
      <p className="text-xs">{t('Min and max can be a number or you can leave it blank.')}</p>
      <div className="flex flex-col items-start py-2 px-0">
        {bidOptions.length > 0 ? (
          <table className="w-full table-fixed">
            {tHeadBiddings}

            <tbody>
              {bidOptions.map((value, index) => {
                if (index == bidOptionIdxSelected) {
                  // REMOVE DOUBLE CHECK OR EDIT FORM
                  return removeMode ? rowRemove(value, index) : rowForm(index);
                } else {
                  // BIDDING OPTIONS LIST
                  return rowList(value, index);
                }
              })}
            </tbody>
          </table>
        ) : (
          <p>{t('No bid options defined yet.')}</p>
        )}
      </div>

      {/* ADD BIDDING FORM */}
      {bidOptionState && bidOptionIdxSelected == undefined ? (
        <div className="flex flex-col items-start py-2 pr-0">
          <table className="w-full table-fixed">
            {tHeadBiddings}
            <tbody>{rowForm(bidOptions.length)}</tbody>
          </table>
        </div>
      ) : (
        <StyledAddButtonWrapper>
          <AddButton
            label={t('Add option')}
            className="font-medium text-sm ml-2"
            iconWidth="small-icon"
            onClick={() => {
              setBidOptionState(initBidOptions);
              setBidOptionIdxSelected(undefined);
              setValidationErrors([]);
            }}
          />
        </StyledAddButtonWrapper>
      )}
    </div>
  );
};

export default BidOptionsManager;

const StyledInputText = styled(InputText)`
  input {
    font-size: 0.86rem;
    padding: 6px 0 6px 8px;
  }

  .helperText {
    height: auto;
    font-size: 0.75rem;
  }
`;

const StyledCancel = styled(Button)`
  color: rgb(220 38 38);
  padding: 0;
`;

const StyledFormTr = styled.tr`
  td {
    vertical-align: top;

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-width: 2px;
    }

    .error input {
      border-width: 1px;
    }
  }
`;

const StyledAddButtonWrapper = styled.div`
  .addButton {
    margin-left: 6px;
  }
`;
