import { put, takeLatest } from '@redux-saga/core/effects';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { baseURL } from '../root-saga';
import {
  BidDTO,
  ConflictDTO,
  GetBidOptionsResponse,
  GetBidsResponse,
  PostBidResponse,
  PostBidsSubmissionsResponse,
} from './types';
import errorSlice from '../error/slice';
import bidSlice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { FileUploadUpdaterDTO, UploadFeedbackResponse } from '../submission/types';

const getBidOptions = async (): Promise<Result<GetBidOptionsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetBidOptionsResponse>(`${baseURL}/api/bids/options`, init);
};

function* getBidOptionsSaga() {
  const result = (yield getBidOptions()) as Await<ReturnType<typeof getBidOptions>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['GET:BID_OPTIONS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const getBids = async (): Promise<Result<GetBidsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetBidsResponse>(`${baseURL}/api/bids`, init);
};

function* getBidsSaga() {
  const result = (yield getBids()) as Await<ReturnType<typeof getBids>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['GET:BIDS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postBid = async (data: BidDTO): Promise<Result<PostBidResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<PostBidResponse>(`${baseURL}/api/bids`, init);
};

function* postBidSaga(data: PayloadAction<BidDTO>) {
  const result = (yield postBid(data.payload)) as Await<ReturnType<typeof postBid>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['POST:BID:OK'](data.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postConflict = async (data: ConflictDTO): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/bids/conflict`, init);
};

function* postConflictSaga(data: PayloadAction<ConflictDTO>) {
  const result = (yield postConflict(data.payload)) as Await<ReturnType<typeof postConflict>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['POST:CONFLICT:OK'](data.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export const postSubsetBids = async (
  data: FileUploadUpdaterDTO,
): Promise<Result<UploadFeedbackResponse, APIValidationError>> => {
  const { file, dry_run } = data;

  const formData = new FormData();
  formData.append('file', file);

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<UploadFeedbackResponse>(`${baseURL}/api/bidding/subset?dry_run=${dry_run ? 1 : 0}`, init);
};

const getBidsSubmissions = async (): Promise<Result<PostBidsSubmissionsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<PostBidsSubmissionsResponse>(`${baseURL}/api/bids/submissions`, init);
};

function* getBidsSubmissionsSaga() {
  const result = (yield getBidsSubmissions()) as Await<ReturnType<typeof getBidsSubmissions>>;

  switch (result.type) {
    case 'ok':
      yield put(bidSlice.actions['GET:BIDS:SUBMISSION:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(bidSlice.actions['GET:BID_OPTIONS'], getBidOptionsSaga),
  takeLatest(bidSlice.actions['GET:BIDS'], getBidsSaga),
  takeLatest(bidSlice.actions['POST:BID'], postBidSaga),
  takeLatest(bidSlice.actions['POST:CONFLICT'], postConflictSaga),
  takeLatest(bidSlice.actions['GET:BIDS:SUBMISSION'], getBidsSubmissionsSaga),
];
