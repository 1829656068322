import React, { useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { selectEmailState } from '../../store/selectors';
import { EmailSendDTO, EmailTemplate } from '../../store/email/types';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import AddEmailTemplateDialog from '../../components/AddEmailTemplateDialog/AddEmailTemplateDialog';
import emailSlice from '../../store/email/slice';
import Loading from '../../components/Loading/Loading';
import TrashCan from '../../icons/TrashCan';
import EditIcon from '../../icons/Edit';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import EmailTemplateDialog from '../../components/dialogs/EmailTemplateDialog/EmailTemplateDialog';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  templatesById: { [key: number]: EmailTemplate };
  createEmailTemplateAction: (data: EmailTemplate) => void;
  deleteEmailTemplateAction: (id: number) => void;
}

const EmailTemplatesPage: React.FC<Props> = ({
  loading,
  templatesById,
  createEmailTemplateAction,
  deleteEmailTemplateAction,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="flex mb-8 items-center">
        <div className="font-bold text-xl">{t('Email templates')}</div>
        <AddButton
          label={t('New template')}
          onClick={() =>
            setDialog(
              <AddEmailTemplateDialog
                title={t('New template')}
                handleOk={(emailTemplate) => createEmailTemplateAction(emailTemplate)}
                handleClose={() => setDialog(undefined)}
              />,
            )
          }
        />
      </div>
      <StyledWrapper className="mt-3 mb-5 max-w-4xl text-sm">
        <ul>
          {Object.values(templatesById).map((template, index) => (
            <StyledPaperStatus key={template.id}>
              <div className="flex w-full justify-between bg-transparent mb-2">
                <div className="flex w-full py-2 px-4">
                  <span>{template.name}</span>
                </div>
                <button
                  className="bg-transparent p-2.5 border-l-2 border-white border-solid flex items-center"
                  onClick={() =>
                    setDialog(
                      <EmailTemplateDialog
                        initialValue={template}
                        title={t('Edit template')}
                        handleClose={() => {
                          setDialog(undefined);
                        }}
                      />,
                    )
                  }
                >
                  <EditIcon color={theme.palette.text.primary} className="-mt-0.5 inline-block" />
                  <span className="ml-1">{t('Edit')}</span>
                </button>
                <button
                  className="bg-transparent p-2.5 border-l-2 border-white border-solid flex items-center"
                  onClick={() => {
                    setDialog(
                      <SimpleDialog
                        open={true}
                        handleClose={() => setDialog(undefined)}
                        handleOk={() => deleteEmailTemplateAction(template.id)}
                        title={t('Remove email template?')}
                      >
                        <p className="text-sm mb-2">
                          {t(
                            `Are you sure you want to remove email template "{{templateName}}"? Take into account that this action can't be undone.`,
                            { templateName: template.name },
                          )}
                        </p>
                      </SimpleDialog>,
                    );
                  }}
                >
                  <TrashCan />
                  <span className="ml-1">{t('Delete')}</span>
                </button>
              </div>
            </StyledPaperStatus>
          ))}
        </ul>
      </StyledWrapper>
      {dialog}
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectEmailState(state).loading,
  templatesById: selectEmailState(state).templatesById,
  emailRedux: selectEmailState(state).email as EmailSendDTO | undefined,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createEmailTemplateAction: (data: EmailTemplate) => dispatch(emailSlice.actions['TEMPLATES:CREATE'](data)),
  updateEmailTemplateAction: (data: EmailTemplate) => dispatch(emailSlice.actions['TEMPLATES:UPDATE'](data)),
  deleteEmailTemplateAction: (id: number) => dispatch(emailSlice.actions['TEMPLATES:DELETE'](id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplatesPage);

const StyledWrapper = styled.div`
  flex-direction: column;
  flex-grow: 1;
  border-top: 0;

  .k-iframe {
    height: 22rem !important;
  }

  .k-editor-toolbar {
    padding: 10px 14px;
  }

  .k-editor-content {
    padding: 10px;
  }
`;

const StyledPaperStatus = styled.li`
  > div {
    /*
    margin-bottom: 0.5rem;
    width: max-content;
    padding: 0.5rem 1rem;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
    border-radius: 18px;
    */
    background-color: #e3e5e6;
  }
`;
