import { put, takeLatest } from '@redux-saga/core/effects';
import { Await } from '../api.d';
import errorSlice from '../error/slice';
import { getDashboardApiCall } from './api';
import dashboardSlice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

function* getDashboardSaga(action: PayloadAction<getDashboardRequest>) {
  const result = (yield getDashboardApiCall(action.payload)) as Await<ReturnType<typeof getDashboardApiCall>>;
  switch (result.type) {
    case 'ok':
      yield put(dashboardSlice.actions['GET:OK']({ data: result.value.data }));
      break;
    case 'error':
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: result.value, severity: 'error' }));
      break;
    default:
      break;
  }
  return;
}

export default [takeLatest(dashboardSlice.actions.GET, getDashboardSaga)];
