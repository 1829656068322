// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
import countries from './countries.json';

export interface CountryType {
  code: string;
  label: string;
  phone: string;
}

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode: string): string {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
    : isoCode;
}

export const countryFromLabel = (label: string | null): CountryType | null => {
  if (!label) return null;

  const filtered = countries.filter((countryType) => {
    return countryType.label === label;
  });

  if (!filtered) return null;
  else return filtered[0];
};

export const countryFromCode = (countryCode: string | null): CountryType | null => {
  if (!countryCode) return null;

  const filtered = countries.filter((countryType) => {
    return countryType.code === countryCode;
  });

  if (!filtered) return null;
  else return filtered[0];
};

export const countryCodeFromLabel = (label: string | null): string | null => {
  const country = countryFromLabel(label);

  if (!country) return null;
  else return country.code;
};

export const labelFromCountryCode = (countryCode: string | null): string | null => {
  const country = countryFromCode(countryCode);

  if (!country) return null;
  else return country.label;
};

export default countries;
