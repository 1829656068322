import React from 'react';
import FileUploadUpdater from '../../components/FileUploadUpdater/FileUploadUpdater';
import { postReviews } from '../../store/review/sagas';
import { AppState } from '../../store/state';
import { selectInfo, selectReviewState, selectSubmissionState } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
}

const BulkImportPage: React.FC<Props> = ({ loading }) => {
  const { t, i18n } = useTranslation();

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Bulk import')}</h2>

      <StyledUploaderWrapper className="mb-2 flex flex-col h-full">
        <FileUploadUpdater
          doPreview={(file) => postReviews({ file, dry_run: true })}
          doSave={(file) => postReviews({ file, dry_run: false })}
          templateName="assignments"
        />
      </StyledUploaderWrapper>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading || selectSubmissionState(state).loading || selectReviewState(state).loading,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

const StyledUploaderWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
      input,
      a {
        font-size: 0.875rem;
      }
    }

    .buttonWrapper {
      margin: 2rem 0;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(BulkImportPage);
