import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

import { FileUpload } from '../../store/submission/types';
import FileUploaderS3 from '../FileUploaderS3/FileUploaderS3';

interface Props extends GridCellProps {}

const FileUploadCell: React.FC<Props> = (props) => {
  //const field = props.field || '';
  const field = 'file_upload';
  const value: FileUpload = props.dataItem[field] || '';

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      {value ? <FileUploaderS3 initUpload={value} extensions={[]} disabled={true} /> : undefined}
    </td>
  );
};

export default FileUploadCell;
