import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const Loading: React.FC = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh', backgroundColor: theme.palette.background.default }}
    >
      <Grid item xs={3}>
        <CircularProgress color="primary" />
      </Grid>
    </Grid>
  );
};

export default Loading;
