import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

const ActionsCell: React.FC<GridCellProps> = ({ dataItem, field, ...props }) => {
  // It seems this function is called many times if the data is aggregated.
  if ('aggregates' in dataItem) return <td></td>;

  const value = dataItem[field || ''];

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      {value}
    </td>
  );
};

export default ActionsCell;
