import React from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import ResetPassword, { ResetPasswordForm } from '../components/ResetPassword/ResetPassword';
import useQuery from '../helpers/useQuery';
import authSlice from '../store/auth/slice';
import { ResetPasswordDTO } from '../store/auth/types';
import { AppDispatch } from '../store/store';

interface ResetPasswordPageProps {
  resetPasswordAction: (data: ResetPasswordDTO) => void;
}

const ResetPasswordPage = ({ resetPasswordAction }: ResetPasswordPageProps) => {
  const history = useHistory();
  const { token }: { token: string } = useParams();
  const query = useQuery();
  const email = query.get('email');

  const login_handler = () => {
    history.push('/login');
  };

  const reset_password_handler = (form: ResetPasswordForm) => {
    const data = {
      ...form,
      email: email as string,
      token: token,
    };
    resetPasswordAction(data);
  };

  return <ResetPassword reset_password_handler={reset_password_handler} login_handler={login_handler} />;
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetPasswordAction: (data: ResetPasswordDTO): void => {
    dispatch(authSlice.actions['RESET_PASSWORD'](data));
  },
});

export default connect(null, mapDispatchToProps)(ResetPasswordPage);
