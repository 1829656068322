import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SimpleDialog from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import { VerifyCode } from '../../MainEmailForm/MainEmailForm';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import userSlice from '../../../store/user/slice';
import { connect } from 'react-redux';
import { isValidEmailFormat } from '../../../helpers/email';
import authSlice from '../../../store/auth/slice';
import { getUserByEmail } from '../../../store/user/sagas';
import { OpenSnackBarDTO } from '../../../store/error/types';
import errorSlice from '../../../store/error/slice';
import { UserAlias } from '../../../store/user/types';
import { selectUser } from '../../../store/selectors';
import { useTranslation } from 'react-i18next';

interface Props {
  initEmail?: string | null;
  handleClose: () => void;
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  postAddUserAliasAction: (data: VerifyCode) => void;
  sendVerificationEmailAction: (email: string | undefined) => void;
  userAliasById: { [key: number]: UserAlias };
}

const AddProfileEmailDialog: React.FC<Props> = ({
  initEmail,
  handleClose,
  openSnackBarAction,
  postAddUserAliasAction,
  sendVerificationEmailAction,
  userAliasById,
}) => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState<string>(initEmail ?? '');
  const [verificationCode, setVerificationCode] = useState<string>('');
  const [showCodeVerification, setShowCodeVerification] = useState<boolean>(false);

  useEffect(() => {
    const user = Object.values(userAliasById).find((userAlias) => userAlias.email === email);
    if (user) {
      handleClose();
    }
  }, [userAliasById]);

  const handleOkFirstStep = async () => {
    const res = await getUserByEmail(email);
    switch (res.type) {
      case 'ok':
        const { data } = res.value;
        if (data.length > 0) {
          // Case user found
          openSnackBarAction?.({
            message: t(
              'This email address already exists in the system. If it belongs to you, please send an email to support@brainful-labs.com to request account merging.',
            ),
            severity: 'warning',
          });
        } else {
          // Case user not found
          setShowCodeVerification(true);
          sendVerificationEmailAction(email);
        }
        break;
      case 'validation-error':
        break;
      case 'error':
        break;
    }
  };

  const handleOkSecondStep = () => {
    postAddUserAliasAction({ email, code: verificationCode });
  };

  const isDisabledOkButton =
    !isValidEmailFormat(email) || (!!initEmail && !verificationCode) || (showCodeVerification && !verificationCode);

  return (
    <SimpleDialog
      title={t('Add email address')}
      open={true}
      handleClose={handleClose}
      handleOk={showCodeVerification ? handleOkSecondStep : handleOkFirstStep}
      closeOnOk={false}
      disableOkButton={isDisabledOkButton}
      okButtonText={showCodeVerification ? t('Verify code') : t('Continue')}
    >
      <StyledInputWrapper>
        {showCodeVerification ? (
          <div>
            <p className="mb-2">
              {t('An email with verification code was sent to')} <span className="font-bold">{email}</span>
            </p>
            <InputText
              label={t('Enter code')}
              required
              defaultValue={verificationCode}
              onChange={(e) => {
                setVerificationCode(e.target.value);
              }}
              className="inputWrapper"
            />
          </div>
        ) : (
          <InputText
            label={t('Email')}
            required
            defaultValue={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="inputWrapper"
          />
        )}
      </StyledInputWrapper>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  userAliasById: selectUser(state).data.user_alias_by_id,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
  postAddUserAliasAction: (data: VerifyCode) => dispatch(userSlice.actions['POST:ALIAS'](data)),
  sendVerificationEmailAction: (email: string | undefined) =>
    dispatch(authSlice.actions['SEND_VERIFICATION_EMAIL'](email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProfileEmailDialog);

const StyledInputWrapper = styled.div`
  .inputWrapper {
    width: 30rem;
    display: flex;
    flex-direction: row;

    label {
      margin-bottom: 0;
      margin-right: 1rem;
      width: 7.3rem;
      align-self: center;
      padding-top: 0;
    }

    .helperText {
      margin-top: 0;
    }
  }
  .warning-message {
    margin-left: 6.7rem;
    max-width: 23rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;
