import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function Icon(): JSX.Element {
  const theme = useTheme();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={theme.palette.primary.main} transform="translate(-283 -90)">
          <g transform="translate(283 90)">
            <g>
              <path d="M24.438 18.417h-6.022v6.02a1.417 1.417 0 01-2.832 0v-6.02H9.563a1.417 1.417 0 010-2.834h6.02v-6.02a1.417 1.417 0 012.833 0v6.02h6.021a1.417 1.417 0 010 2.834M17 0C7.626 0 0 7.626 0 17c0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
