import React from 'react';
import { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { StyledGrid, StyledGridWrapper } from '../MyGridCore/MyGridCore';

interface Props {
  children: React.ReactNode;
}

const MyTableGridWrapper: React.FC<Props> = ({ children }) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledGridWrapper className="flex h-full">
        <StyledGrid className="k-grid" style={{ height: '100%', flexGrow: 1, flexShrink: 1, border: 0 }}>
          {children}
        </StyledGrid>
      </StyledGridWrapper>
    </ThemeProvider>
  );
};

export default MyTableGridWrapper;
