import React from 'react';
import { selectCurrentUser, selectCurrentConference, selectConference } from '../../store/selectors';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import ConferenceRequestForm, {
  ConferenceRequestFormFields,
} from '../../components/ConferenceRequestForm/ConferenceRequestForm';
import { validationErrorTransformer } from '../../store/api';
import conferenceSlice from '../../store/conference/slice';
import { ValidatedField } from '../../store/api.d';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { Conference } from '../../store/conference/types';
import { useTranslation } from 'react-i18next';

interface ConferenceCreatePageProps {
  loading: boolean;
  timezone: string | null;
  dateFormat: string | null;
  currentConference: Conference | null;
  validationError?: ValidatedField[];
  updateConferenceAction: (data: ConferenceRequestFormFields) => void;
  clearValidationErrors: () => void;
}

const SettingsConferencePage: React.FC<ConferenceCreatePageProps> = ({
  loading,
  timezone,
  dateFormat,
  currentConference,
  validationError,
  updateConferenceAction,
  clearValidationErrors,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  let valError = undefined;
  if (validationError) {
    valError = validationErrorTransformer(validationError) as unknown as ConferenceRequestFormFields;
  }

  const save_handler = (form: ConferenceRequestFormFields) => {
    if (currentConference) {
      updateConferenceAction({
        ...form,
        id: currentConference.id,
      });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* PAGE TITLE */}
        <StyledTitle>{t('Conference settings')}</StyledTitle>
      </div>
      <div className="w-full" style={{ flexGrow: 1 }}>
        <ConferenceRequestForm
          loading={loading}
          timezone={timezone}
          dateFormat={dateFormat}
          initConference={currentConference}
          validation_error={valError}
          save_handler={save_handler}
          clearValidationErrors={() => clearValidationErrors()}
        />
      </div>
    </ThemeProvider>
  );
};

const StyledTitle = styled.h2`
  margin-right: 0.75rem;
  font-size: 1.25rem;
  font-weight: 700;
  width: 100%;
`;

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).requestLoading,
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
  validationError: state.conference.validationError,
  currentConference: selectCurrentConference(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateConferenceAction: (data: ConferenceRequestFormFields) =>
    dispatch(conferenceSlice.actions.UPDATE_CONFERENCE(data)),
  clearValidationErrors: () => dispatch(conferenceSlice.actions['CLEAR_VALIDATION_ERRORS']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsConferencePage);
