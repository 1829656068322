import { apiCall, APIValidationError } from '../api';
import { Result } from '../api.d';
import { getDiscussionResponse, MessageDTO, postMessageResponse } from './types';

const baseURL = process.env.REACT_APP_API_URL;

export const getDiscussion = async (
  submissionId: number,
): Promise<Result<getDiscussionResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getDiscussionResponse>(`${baseURL}/api/submissions/${submissionId}/discussion`, init);
};

export const postMessage = async (data: MessageDTO): Promise<Result<postMessageResponse, APIValidationError>> => {
  const { submission_id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };
  return apiCall<postMessageResponse>(`${baseURL}/api/submissions/${submission_id}/discussion`, init);
};

export const deleteMessage = async (id: number): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };
  return apiCall<{ data: any }>(`${baseURL}/api/discussions/messages/${id}`, init);
};

export const editMessage = async (data: MessageDTO): Promise<Result<{ data: any }, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PATCH',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };
  return apiCall<{ data: any }>(`${baseURL}/api/discussions/messages/${id}`, init);
};

export const subscribe = async (
  submissionId: number,
): Promise<Result<{ data: { subscribed: boolean } }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
  };
  return apiCall<{ data: { subscribed: boolean } }>(
    `${baseURL}/api/submissions/${submissionId}/discussion/subscribe`,
    init,
  );
};
