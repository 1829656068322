import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Button } from '@progress/kendo-react-buttons';
import { Window } from '@progress/kendo-react-dialogs';
import { Upload, UploadFileInfo } from '@progress/kendo-react-upload';
import { TabStrip, TabStripTab, TabStripSelectEventArguments } from '@progress/kendo-react-layout';
import { EditorUtils } from '@progress/kendo-react-editor';
import { insertImageFiles } from './utils';
import styled from 'styled-components';

/*
  From Kendo: https://www.telerik.com/kendo-react-ui/components/editor/images/
*/

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const InsertImageDialog = (props: { onClose?: any; view?: any; imageNode?: any }) => {
  const [files, setFiles] = React.useState([]);

  let src: HTMLInputElement | null;
  let altText: HTMLInputElement | null;
  let title: HTMLInputElement | null;
  let width: HTMLInputElement | null;
  let height: HTMLInputElement | null;

  const onTabSelect = (event: TabStripSelectEventArguments) => {
    setFiles([]);
  };

  const onClose = () => {
    props.onClose.call(undefined);
  };

  const onAddFiles = (event: any) => {
    setFiles(
      event.newState.map((f: UploadFileInfo) => f.getRawFile && f.getRawFile()).filter((f: UploadFileInfo) => f),
    );
  };

  const onInsert = () => {
    const { view, imageNode } = props;
    const nodes = view.state.schema.nodes;
    const nodeType = nodes[imageNode];
    const position = null;
    const data = {
      src: src ? src.value : null,
      title: title ? title.value : null,
      alt: altText ? altText.value : null,
      width: width ? width.value : null,
      height: height ? height.value : null,
    };

    const attrs = Object.keys(data)
      // @ts-ignore
      .filter((key) => data[key] !== null && data[key] !== '')
      // @ts-ignore
      .reduce((acc, curr) => Object.assign(acc, { [curr]: data[curr] }), {});

    if (files.length) {
      insertImageFiles({ view, files, nodeType, position, attrs });
    } else {
      const newImage = nodeType.createAndFill(attrs);
      EditorUtils.insertNode(view, newImage, true);
    }

    view.focus();
    onClose();
  };

  const { view, imageNode } = props;
  const state = view && view.state;

  let attrs: any = {};

  if (state && state.selection.node && state.selection.node.type === state.schema.nodes[imageNode]) {
    attrs = state.selection.node.attrs;
  }

  const fields = (
    <React.Fragment>
      <div className="k-edit-label">
        <label htmlFor="k-editor-image-width">Width (px)</label>
      </div>
      <div className="k-edit-field">
        <input
          type="text"
          className="k-textbox"
          id="k-editor-image-width"
          defaultValue={attrs.width}
          ref={(e) => (width = e)}
          placeholder="You can leave it blank"
        />
      </div>
    </React.Fragment>
  );

  const buttons = (
    <div className={'text-right'} style={{ clear: 'both' }}>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onInsert} themeColor={'primary'}>
        {attrs.src ? 'Update' : 'Insert'}
      </Button>
    </div>
  );

  return ReactDOM.createPortal(
    <StyledWindow title="Insert Image" onClose={onClose} initialWidth={500} modal={true}>
      <div className="k-edit-form-container">
        <div className="k-edit-label">
          <label htmlFor="k-editor-image-width">Image</label>
        </div>
        {attrs.src ? (
          <div className="k-edit-field">
            <input
              type="text"
              className="k-textbox"
              id="k-editor-image-url"
              defaultValue={attrs.src}
              ref={(e) => (src = e)}
              disabled={/^data:image/.test(attrs.src || '')}
              autoFocus={true}
            />
          </div>
        ) : (
          <div className="k-edit-field">
            <Upload
              batch={false}
              multiple={false}
              defaultFiles={[]}
              withCredentials={false}
              onAdd={onAddFiles}
              autoUpload={false}
              showActionButtons={false}
            />
          </div>
        )}

        {fields}
        {buttons}
      </div>
      <style>{`.k-dropzone { width: 100%; }`}</style>
    </StyledWindow>,
    document.body,
  );
};

const StyledWindow = styled(Window)`
  box-shadow: var(--kendo-elevation-8, 0 16px 18px rgba(0, 0, 0, 0.28), 0 4px 16px rgba(0, 0, 0, 0.12));
  height: max-content !important;

  .k-edit-form-container {
    margin-top: 1rem;
    margin-bottom: 1.6rem;
  }

  .k-dropzone-hint {
    font-size: 0.775rem;
  }
  .k-button {
    margin-left: 5px;
  }
  .k-textbox::placeholder {
    color: #ccc;
    opacity: 0.9; /* Firefox */
  }
  .k-textbox::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: #ccc;
  }
`;
