import React from 'react';
import { InputText, Select } from '../ui';
import styled from 'styled-components';
import { Role } from '../../store/conference/types';
import { useTranslation } from 'react-i18next';
import { getRoleName } from '../../helpers/translations';

interface AddPersonFormFields {
  first_name: string;
  last_name: string;
  email: string;
  role: number;
}

interface Props {
  FData: AddPersonFormFields;
  onChange: (value: AddPersonFormFields) => void;
  showExtraFields: boolean;
  roleById: { [key: string]: Role };
  errors: { [k: string]: string };
}

const AddPersonForm: React.FC<Props> = ({ FData, onChange, showExtraFields, roleById, errors }) => {
  const { t, i18n } = useTranslation();
  return (
    <div style={{ width: '30.7rem' }}>
      <StyledInputWrapper>
        <InputText
          label={t('Email')}
          required
          value={FData.email}
          onChange={(e) => onChange({ ...FData, email: e.target.value })}
          error={!!errors.email}
          helperText={errors.email}
          className="inputWrapper"
          disabled={showExtraFields}
        />
      </StyledInputWrapper>

      <StyledSelectWrapper className="selectWrapper">
        <Select
          label={t('Role')}
          required
          value={FData.role.toString()}
          onChange={(value) => onChange({ ...FData, role: parseInt(value) })}
          options={Object.values(roleById)
            .filter((role) => role.type !== 'author')
            .map((role) => ({ id: role.id.toString(), label: getRoleName(role, t) }))}
          defaultItem={{ label: t('Choose role') + '...' }}
          error={!!errors.role}
          helperText={errors.role}
          disabled={showExtraFields}
        />
      </StyledSelectWrapper>

      {showExtraFields && (
        <>
          <p className="mt-6 mb-2">
            {t("We don't have this email in our records yet. We need extra information to complete this process.")}
          </p>
          <StyledInputWrapper>
            <InputText
              required
              label={t('First name')}
              value={FData.first_name}
              onChange={(e) => onChange({ ...FData, first_name: e.target.value })}
              className="inputWrapper"
            />
          </StyledInputWrapper>

          <StyledInputWrapper>
            <InputText
              required
              label={t('Last name')}
              value={FData.last_name}
              onChange={(e) => onChange({ ...FData, last_name: e.target.value })}
              className="inputWrapper"
            />
          </StyledInputWrapper>
        </>
      )}
    </div>
  );
};

const StyledSelectWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;

  .labelSelect {
    margin-right: 0.8rem;
    width: 10.3rem;
    align-self: flex-start;
    margin-top: 0.8rem;
  }

  > span:nth-of-type(1) {
    width: 28%;
    margin-right: 0.5rem;
  }

  > span:nth-of-type(2) {
    margin-bottom: 0;
    margin-right: 0;
  }

  .dropdown-wrapper {
    width: 100%;
  }

  .helperText {
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
`;

const StyledInputWrapper = styled.div`
  margin-bottom: 1rem;

  .inputWrapper {
    display: flex;
    flex-direction: row;

    label {
      margin-right: 1rem;
      width: 7.3rem;
      align-self: flex-start;
      margin-top: 0.8rem;
    }

    > div {
      width: 74%;
    }

    .helperText {
      margin-top: 0;
    }
  }
`;

export default AddPersonForm;
