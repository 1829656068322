import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import { MultiSelect } from '../../ui';
import { selectFormState } from '../../../store/selectors';
import { Form, Question } from '../../../store/form/types';
import { FormTypeEnum } from '../../../store/form/types.d';

interface Props extends Omit<SimpleDialogProps, 'handleOk'> {
  formById: { [key: number]: Form };
  questionById: { [key: number]: Question };
  handleOk?: (uploadOptions: number[]) => void;
}

const DownloadSubmissionsDialog: React.FC<Props> = ({ formById, questionById, handleOk, ...props }) => {
  const options: { id: string; label: string }[] = [{ id: '0', label: 'Paper upload' }];

  const [value, setValue] = useState<string[]>([]);

  /* Build multiselect options */
  Object.values(formById)
    .filter((form) => form.type == FormTypeEnum.Submission)
    .map((form) => {
      Object.values(questionById)
        .filter((question) => question.form_id == form.id && question.type == 'FileUpload')
        .forEach((question) => options.push({ id: question.id.toString(), label: question.title ?? '' }));
    });
  return (
    <SimpleDialog
      {...props}
      title="Download submissions"
      handleOk={() => {
        const uploadOptions = value.map((option) => parseInt(option));
        value.length && handleOk?.(uploadOptions);
      }}
    >
      <MultiSelect
        value={value}
        onChange={(newValue) => setValue(newValue)}
        label="Which files will be included in the download?"
        placeholder="Choose options"
        options={options}
      />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  formById: selectFormState(state).formById,
  questionById: selectFormState(state).questionById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadSubmissionsDialog);
