import { Form, FormVisibilityOption } from '../store/form/types';
import { FormTypeEnum } from '../store/form/types.d';
import { Role } from '../store/conference/types';

const createActionBody = (
  type: PhaseActionType,
  args: FormPermissionActionArgs | SwitchActionArgs,
): { body: { params: Object } } => {
  /* Get action params given type */
  let params;
  switch (type) {
    case 'form_permission_change':
      const { form_id, role_id, visibility, to_role_id } = args as FormPermissionActionArgs;
      params = {
        form_id: form_id,
        permissions: { [role_id]: [{ to_role_id: to_role_id, visibility: visibility }] },
      };
      break;
    default:
      const { value } = args as SwitchActionArgs;
      params = { value };
      break;
  }
  return {
    body: {
      params,
    },
  };
};

const createNewAction = (type: PhaseActionType, data: FormPermissionActionArgs | SwitchActionArgs): PhaseAction => {
  /* Get action params given type */
  let args;
  switch (type) {
    case 'form_permission_change':
      args = data as FormPermissionActionArgs;
      break;
    default:
      args = data as SwitchActionArgs;
      break;
  }
  return {
    id: 0,
    type: type,
    ...createActionBody(type, args),
  };
};

export const removeAction = (index: number, args: Object, actions: PhaseAction[]): PhaseAction[] => {
  const findAction = actions[index];

  let newActions: PhaseAction[];
  switch (findAction.type) {
    case 'form_permission_change':
      const { roleId, toRoleId } = args as { roleId: string; toRoleId: string | null };
      // Remove array
      const permissions: {
        [key: string]: { to_role_id: string | null; visibility: FormVisibilityOption }[];
      } = {
        ...findAction.body.params.permissions,
        [roleId]: findAction.body.params.permissions[roleId].filter(
          (value: { to_role_id: string | null; visibility: FormVisibilityOption }) => value.to_role_id != toRoleId,
        ),
      };
      // If resulting array is empty then delete the key
      if (!permissions[roleId].length) {
        delete permissions[roleId];
      }

      if (!Object.keys(permissions).length) {
        // If no keys in permissions then delete the action
        newActions = actions.filter((a, idx) => idx != index);
      } else {
        // Update permissions field
        newActions = actions.map((action, idx) =>
          idx === index
            ? { ...action, body: { ...action.body, params: { ...action.body.params, permissions } } }
            : action,
        );
      }
      break;
    default:
      newActions = actions.filter((a, idx) => idx != index);
      break;
  }
  return newActions;
};

export const addAction = (
  type: PhaseActionType,
  data: FormPermissionActionArgs | SwitchActionArgs,
  actions: PhaseAction[],
): PhaseAction[] => {
  const newAction = createNewAction(type, data);

  const findIndex = actions.findIndex(
    (action) =>
      action.type === 'form_permission_change' && action.body.params.form_id === newAction.body.params.form_id,
  );

  let newActions: PhaseAction[];
  if (newAction.type === 'form_permission_change' && findIndex >= 0) {
    const findAction = actions[findIndex];

    const findActionPermissions: {
      [key: string]: { to_role_id: string | null; visibility: FormVisibilityOption }[];
    } = { ...findAction.body.params.permissions };

    // Merge existing action with the new one
    Object.keys(newAction.body.params.permissions).forEach((key) => {
      if (findActionPermissions[key]) {
        findActionPermissions[key] = [...findActionPermissions[key], ...newAction.body.params.permissions[key]];
      } else {
        findActionPermissions[key] = newAction.body.params.permissions[key];
      }
    });

    const merge = {
      ...findAction,
      body: {
        ...findAction.body,
        params: {
          ...findAction.body.params,
          permissions: findActionPermissions,
        },
      },
    };

    newActions = actions.map((action, index) => (index === findIndex ? merge : action));
  } else {
    newActions = [...actions, newAction];
  }

  return newActions;
};

export const initialFormPermissionActions = (
  phase: Phase,
  roleById: { [key: string]: Role },
  formById: { [key: number]: Form },
): FormPermissionSettings => {
  const defaultForm = Object.values(formById).find((form) => form.type == FormTypeEnum.Submission && form.is_default);
  if (!defaultForm) throw Error('Default dorm does not exist.');

  const authorRole = Object.values(roleById).find((role) => role.type == 'author');
  if (!authorRole) throw Error('Author role does not exist.');

  let start: PhaseAction[] = [];
  let end: PhaseAction[] = [];

  switch (phase.type) {
    case 'submission':
      start = addAction(
        'form_permission_change',
        {
          form_id: defaultForm.id,
          role_id: authorRole.id,
          to_role_id: null,
          visibility: 'write',
        },
        start,
      );
      end = addAction(
        'form_permission_change',
        {
          form_id: defaultForm.id,
          role_id: authorRole.id,
          to_role_id: null,
          visibility: 'read',
        },
        end,
      );
      break;
    case 'review':
      start = addAction(
        'form_permission_change',
        {
          form_id: defaultForm.id,
          role_id: phase.role_id as number, // This can't be null at this point
          to_role_id: authorRole.id,
          visibility: 'read',
        },
        start,
      );
      break;
    case 'discussion':
      const reviewerRoles = Object.values(roleById).filter((role) => role.type == 'reviewer');
      reviewerRoles.forEach((role) => {
        start = addAction(
          'form_permission_change',
          {
            form_id: defaultForm.id,
            role_id: role.id,
            to_role_id: authorRole.id,
            visibility: 'read',
          },
          start,
        );
      });
      break;
  }
  return { start, end };
};
