import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dashboardSlice from '../../store/dashboard/slice';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import Loading from '../../components/Loading/Loading';
import styled from 'styled-components';
import { TableDataStateChangeDTO } from '../../store/table/types';
import tableSlice from '../../store/table/slice';
import history from '../../store/history';
import { getRouteByName } from '../../router/routes';
import { fillRoutePath } from '../../helpers/path';
import { selectKeywordState, selectPaperStatusState, selectTrackRoles } from '../../store/selectors';
import '../../components/Dashboard/Dashboard.styles.css';
import { Link } from '../../components/ui';
import { useParams } from 'react-router-dom';
import DashboardSubmissions from '../../components/Dashboard/DashboardSubmissions';
import DashboardReviewers from '../../components/Dashboard/DashboardReviewers';
import { Role } from '../../store/conference/types';
import DashboardSubmissionsAndReviewers from '../../components/Dashboard/DashboardSubmissionsAndReviewers';
import { PaperStatus } from '../../store/paper-status/types';
import DashboardBidding from '../../components/Dashboard/DashboardBidding';
import { useTranslation } from 'react-i18next';

interface DashboardPageSubmissionsProps {
  dashboardState: DashboardState;
  keywordById: { [key: number]: Keyword };
  roleById: { [key: string]: Role };
  paperStatusById: { [key: number]: PaperStatus };
  getDashboardAction: (data: getDashboardRequest) => void;
  dataStateChangeAction: (data: TableDataStateChangeDTO) => void;
}

const DashboardPage: React.FC<DashboardPageSubmissionsProps> = ({
  dashboardState,
  keywordById,
  roleById,
  paperStatusById,
  getDashboardAction,
  dataStateChangeAction,
}) => {
  const params: any = useParams();
  const tab = params.tab as DashboardSectionName;
  const { t, i18n } = useTranslation();
  const [dashboardSection, setDashboardSection] = React.useState<DashboardSectionName>(tab);
  const [targetRoleId, setTargetRoleId] = React.useState<string>(() => {
    const reviewerRoles = Object.values(roleById).filter((role) => role.type == 'reviewer');
    return reviewerRoles.length > 0 ? reviewerRoles[0].id.toString() : ''; // targetRoleId empty means there aren't any reviewer roles in the track
  });
  const [areaId, setAreaId] = React.useState<string>('');
  const [paperStatusId, setPaperStatusId] = React.useState<string>('');

  useEffect(() => {
    if (!tab) {
      history.replace(fillRoutePath(getRouteByName('RouteDashboard').path, { tab: 'submissions' }));
    } else if (Object.keys(dashboardState.data).length == 0) {
      const section: DashboardSectionName | 'all' = targetRoleId ? 'all' : 'submissions'; // If targetRoleId is empty (it means there aren't any reviewer roles in the track) we can only show the submissions tab
      getDashboardAction({
        section: section,
        targetRoleId: targetRoleId,
        areaId: areaId,
        paperStatusId: paperStatusId,
      });
    }
  }, []);

  const isLoading = dashboardState.loading;

  const handleChangeTargetRole = (roleId: string, areaId: string | '', paperStatusId: string | '') => {
    const roleHasChanged = roleId != targetRoleId;
    setTargetRoleId(roleId);
    setAreaId(areaId);
    setPaperStatusId(paperStatusId);
    getDashboardAction({
      section: roleHasChanged ? 'all' : tab,
      targetRoleId: roleId,
      areaId: areaId,
      paperStatusId: paperStatusId,
    });
  };

  const handleTabChange = (tabSlug: DashboardSectionName) => {
    setDashboardSection(tabSlug);
    history.replace(fillRoutePath(getRouteByName('RouteDashboard').path, { tab: tabSlug }));
  };

  let content;
  switch (dashboardSection) {
    case 'submissions':
      content = (
        <DashboardSubmissions
          dashboardState={dashboardState}
          keywordById={keywordById}
          dataStateChangeAction={dataStateChangeAction}
        />
      );
      break;
    case 'reviewers':
      content = (
        <DashboardReviewers
          dashboardState={dashboardState}
          keywordById={keywordById}
          roleById={roleById}
          roleId={targetRoleId}
          handleChangeTargetRole={handleChangeTargetRole}
        />
      );
      break;
    case 'submissions-and-reviewers':
      content = (
        <DashboardSubmissionsAndReviewers
          dashboardState={dashboardState}
          roleById={roleById}
          keywordById={keywordById}
          paperStatusById={paperStatusById}
          handleChangeTargetRole={handleChangeTargetRole}
          roleId={targetRoleId}
          areaId={areaId}
          paperStatusId={paperStatusId}
        />
      );
      break;
    case 'bidding':
      content = (
        <DashboardBidding
          dashboardState={dashboardState}
          roleById={roleById}
          handleChangeTargetRole={handleChangeTargetRole}
          roleId={targetRoleId}
        />
      );
      break;
  }

  return (
    <div>
      <div className="flex flex-col justify-end items-center w-full mx-auto" style={{ maxWidth: 1920 }}>
        <StyledNavigation className={`bg-white rounded-lg shadow-md mb-12 w-full dashboardMenuSticky`}>
          <ul>
            <li className={`px-6 ${dashboardSection == 'submissions' ? 'selected' : ''}`}>
              <Link
                onClick={() => {
                  handleTabChange('submissions');
                }}
              >
                {t('submissions')}
              </Link>
            </li>
            <li className={`px-6 ${dashboardSection == 'reviewers' ? 'selected' : ''}`}>
              <Link
                onClick={() => {
                  handleTabChange('reviewers');
                }}
              >
                {t('reviewers')}
              </Link>
            </li>
            <li className={`px-6 ${dashboardSection == 'submissions-and-reviewers' ? 'selected' : ''}`}>
              <Link
                onClick={() => {
                  handleTabChange('submissions-and-reviewers');
                }}
              >
                {t('submissions and reviewers')}
              </Link>
            </li>
            <li className={`px-6 ${dashboardSection == 'bidding' ? 'selected' : ''}`}>
              <Link
                onClick={() => {
                  handleTabChange('bidding');
                }}
              >
                {t('bidding')}
              </Link>
            </li>
          </ul>
        </StyledNavigation>

        {isLoading ? <Loading /> : content}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  dashboardState: state.dashboard,
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getDashboardAction: (data: getDashboardRequest) => dispatch(dashboardSlice.actions.GET(data)),
  dataStateChangeAction: (data: TableDataStateChangeDTO) => dispatch(tableSlice.actions['DATASTATE:CHANGE'](data)),
});

const StyledNavigation = styled.nav`
  ul {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 1rem;
    text-align: center;
    padding: 0 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  li {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;

    a {
      cursor: pointer;

      &:hover {
        font-weight: 600;
      }
    }
  }
  .selected {
    font-weight: 600;
    /*border-bottom: 3px solid #0044f0;*/
    background-color: #0044f0;
    border-radius: 5px;

    a {
      color: #ffffff !important;
    }
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
