import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import cn from 'classnames';
import { Button } from '../Button';
import { Modal, ModalProps } from '../../utils/Modal';
import { useTranslation } from 'react-i18next';

type SelectorProps = ModalProps & {
  ids: string[];
  onSave: (ids: string[]) => void;
  options: { id: string; label: string }[];
  open: boolean;
};

export const Selector = ({ ids, onSave, options, open, ...modalProps }: SelectorProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const inputRef = React.useRef<HTMLElement | null>(null);
  const [selectedIds, setSelectedIds] = React.useState<string[]>([]);

  const onSelectItem = (optionId: string) => {
    setSelectedIds(
      (ids) =>
        ids.includes(optionId)
          ? ids.filter((id) => id != optionId) // remove item
          : [...ids, optionId], // add item
    );
  };

  React.useEffect(() => {
    setSelectedIds(ids);
  }, [ids]);

  React.useEffect(() => {
    const interval = setTimeout(() => {
      const inputEl = inputRef.current;
      if (!inputEl) {
        return;
      }

      if (open) {
        inputEl.focus();
      } else {
        inputEl.blur();
      }
    });

    return () => clearInterval(interval);
  }, [open]);

  return (
    <Modal open={open} {...modalProps} className="bg-white rounded-md shadow-lg border border-gray-200 w-96 min-w-max">
      <div className="border-b border-gray-200 max-h-96 overflow-auto">
        {options.length > 0 ? (
          options.map((option, i) => (
            <StyledItem
              key={option.id}
              onClick={() => onSelectItem(option.id)}
              className={cn('item', { selected: selectedIds.includes(option.id), divider: i < options.length - 1 })}
            >
              {option.label}
            </StyledItem>
          ))
        ) : (
          <StyledItem>{t('No items found.')}</StyledItem>
        )}
      </div>
      {/* TODO: do we really need a SAVE button? */}
      <div className="p-4 text-center flex justify-center items-center">
        <Button variant="text" onClick={() => onSave(selectedIds)}>
          {t('OK')}
        </Button>
      </div>
    </Modal>
  );
};

const StyledItem = styled.div`
  ${tw`cursor-pointer py-1.5 px-4`}

  &.divider {
    ${tw`border-b border-gray-200`}
  }

  &.selected {
    ${tw`bg-gray-100 border-gray-200`}
  }

  &:hover {
    ${tw`text-gray-900`}
  }
`;
