import React, { useState } from 'react';
import { postReviewerRoleRequest, Role } from '../../store/conference/types';
import { AppState } from '../../store/state';
import { selectConference, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import Loading from '../../components/Loading/Loading';
import { connect } from 'react-redux';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import conferenceSlice from '../../store/conference/slice';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import tw from 'twin.macro';
import RoleCard from '../../components/RoleCard/RoleCard';
import RoleManagement from '../../components/RoleManagement/RoleManagement';
import RoleDialog from '../../components/dialogs/RoleDialog/RoleDialog';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  roleById: { [key: string]: Role };
  postRoleAction: (data: postReviewerRoleRequest) => {};
  deleteRoleAction: (id: number) => void;
}

type TabOption = 'mainRolesPage' | 'rolesPage';

const SettingsRolesPage: React.FC<Props> = ({ loading, roleById, postRoleAction, deleteRoleAction }) => {
  const { t, i18n } = useTranslation();
  const [tab, setTab] = React.useState<TabOption>('mainRolesPage');
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  const reviewerRoles = Object.values(roleById).filter((role) => role.type === 'reviewer');
  const [roleId, setRoleId] = React.useState<number | undefined>(
    reviewerRoles.length > 0 ? reviewerRoles[0].id : undefined,
  );

  const theme = useTheme();

  if (loading) {
    return <Loading />;
  }

  const initRole: Role = {
    id: 0,
    type: 'reviewer',
    description: '',
    rank: 0,
    track_id: 0,
  };

  const currentRole = Object.values(roleById).filter((role) => role.id === roleId)[0];

  /* Submissions screen settings */
  const authorRole = Object.values(roleById).find((role) => role.type == 'author');

  // Function to handle change edit tab page
  const handleTab = (roleId: number, tabName: TabOption) => {
    setTab(tabName);
    setRoleId(roleId);
  };

  // Function to handle the delete role dialog
  const handleDeleteRole = (role: Role) => {
    setDialog(
      <SimpleDialog
        open={true}
        handleClose={() => setDialog(undefined)}
        handleOk={() => {
          deleteRoleAction(role.id);
        }}
        title="Remove role?"
      >
        <p className="text-sm mb-2">
          {`Are you sure you want to remove ${role.description} role? Take into account that this action can't be undone.`}
        </p>
      </SimpleDialog>,
    );
  };

  /* Submissions screen settings */
  const submissions = authorRole && (
    <>
      <h2 className="font-bold text-xl mb-7">{t('Author')}</h2>

      <StyledCardWrapper>
        <RoleCard
          role={authorRole}
          handleEdit={() => handleTab(authorRole.id, 'rolesPage')}
          handleDeleteRole={undefined}
        />
      </StyledCardWrapper>
    </>
  );

  /* Reviews screen settings */
  const reviews = (
    <div>
      <div className="flex mb-7 items-center">
        <h2 className="font-bold text-xl">{t('Reviewer')}</h2>

        <AddButton
          label={t('Custom reviewer')}
          onClick={() => {
            setDialog(
              <RoleDialog
                initRole={initRole}
                handleRoleOk={(role) => postRoleAction({ description: role.description })}
                open={true}
                handleClose={() => setDialog(undefined)}
              />,
            );
          }}
        />
      </div>

      <StyledCardWrapper>
        {reviewerRoles.map((role, index) => (
          <RoleCard
            key={index}
            role={role}
            handleEdit={() => handleTab(role.id, 'rolesPage')}
            handleDeleteRole={() => handleDeleteRole(role)}
          />
        ))}
      </StyledCardWrapper>
    </div>
  );

  /* Tab pages */
  switch (tab) {
    case 'mainRolesPage':
      return (
        <ThemeProvider theme={theme}>
          <div className="text-sm w-full max-w-screen-2xl">
            <div className="mb-5">{submissions}</div>
            <div className="mt-12 mb-5">{reviews}</div>
          </div>

          {dialog}
        </ThemeProvider>
      );
    case 'rolesPage':
      return (
        <RoleManagement
          currentRole={currentRole}
          onCancel={() => {
            setTab('mainRolesPage');
          }}
        />
      );
  }
};

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).loading,
  roleById: selectTrackRoles(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  postRoleAction: (data: postReviewerRoleRequest) => dispatch(conferenceSlice.actions.POST_ROLE(data)),
  deleteRoleAction: (id: number) => dispatch(conferenceSlice.actions['DELETE:ROLE'](id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsRolesPage);

const StyledCardWrapper = styled.div`
  ${tw`grid gap-5 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5`}
`;
