import React from 'react';
import { Card } from '../ui';
import { Submission } from '../../store/submission/types';
import { ValidationStatus } from '../../store/form/types';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import { RowAction } from '../types';
import StatusLabel from '../StatusLabel/StatusLabel';
import { useTranslation } from 'react-i18next';

interface Props {
  submission: Submission;
  actions: RowAction[];
  canViewAuthors?: boolean;
  validationStatus?: { title: string; status: ValidationStatus };
}

const SubmissionCard: React.FC<Props> = ({ submission, actions, validationStatus, canViewAuthors = true }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledCard className="mx-2 h-full">
        <StyledTitle className="flex justify-between items-center mb-3">
          <div className="text-sm font-bold">
            #{submission.external_id} - {submission.title ?? t('Untitled paper')}
          </div>
        </StyledTitle>

        <StyledUList className="mb-2 square list-inside text-sm">
          {submission.authors.length > 0 && canViewAuthors && (
            <li className="mb-1">
              <strong>{t('Authors') + ': '}</strong>
              {submission.authors
                .map((author) => author.first_name + ' ' + author.last_name)
                .reduce((previousValue, currentValue) => previousValue + ', ' + currentValue)}
            </li>
          )}
          {validationStatus ? (
            <li className="mb-1">
              <StatusLabel label={validationStatus.title} validationStatus={validationStatus.status} />
            </li>
          ) : (
            <li className="mb-1">
              <strong>{t('Paper status') + ': '}</strong>
              <span>{submission.paper_status}</span>
            </li>
          )}
        </StyledUList>

        <div className="text-sm absolute bottom-4 flex justify-between w-full left-0 px-5 flex-wrap">
          {actions.map((action, index) => (
            <div key={index} className="mt-1">
              {action.element}
            </div>
          ))}
        </div>
      </StyledCard>
    </ThemeProvider>
  );
};

export default SubmissionCard;

const StyledTitle = styled.div`
  border-bottom: 1px dashed ${(props) => props.theme.palette.secondary.dark};
`;

const StyledUList = styled.ul`
  list-style-type: square;
  margin-bottom: 4rem;
`;

const StyledCard = styled(Card)`
  min-height: 16.6rem;
`;
