import { ThemeProvider } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, Color } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import errorSlice from '../store/error/slice';
import history from '../store/history';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import Redirector from './Redirector';
import { appRoutes } from './routes';
import theme from '../../src/theme';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { UserState } from '../store/user/types';
import { selectUser } from '../store/selectors';
import { availableLanguages } from '../i18n';

interface AppProps {
  openSnackBar: boolean;
  messageSnackBar: string;
  closeSnackBarAction: () => void;
  severitySnackBar: Color;
  userState: UserState;
}

const App = ({ openSnackBar, messageSnackBar, closeSnackBarAction, severitySnackBar, userState }: AppProps) => {
  const { i18n } = useTranslation();

  const user = userState.data;

  useEffect(() => {
    const browserLanguage = navigator.language.substring(0, 2);

    /*
      I check Redux for the language of the profile. 
      If not found, I check the browser language. 
      Finally, if it's not among the available languages, I use English.
    */
    const userLanguage = user.person.language
      ? user.person.language
      : availableLanguages.includes(browserLanguage)
      ? browserLanguage
      : 'en';

    i18n.changeLanguage(userLanguage);
  }, [user.person.language]);

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <Switch>
          {appRoutes.map((route) => {
            return (
              <Route exact key={route.name} path={route.path}>
                <StyledSnackbar open={openSnackBar} autoHideDuration={26000} onClose={() => closeSnackBarAction()}>
                  <Alert severity={severitySnackBar} onClose={() => closeSnackBarAction()}>
                    {messageSnackBar}
                  </Alert>
                </StyledSnackbar>
                <Redirector routeToAccess={route}>{route.fragment}</Redirector>
              </Route>
            );
          })}
          <Route path="*">
            <Redirect to="/not-found" />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  openSnackBar: state.error.openSnackBar,
  messageSnackBar: state.error.message,
  severitySnackBar: state.error.severity,
  userState: selectUser(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  closeSnackBarAction: () => dispatch(errorSlice.actions['CLOSE:SNACKBAR']()),
});

const StyledSnackbar = styled(Snackbar)`
  top: 4.6rem;
  bottom: inherit !important;
  left: 56.5% !important;
  border: 1px solid #eee9e9;
  border-radius: 5px;
`;

export default connect(mapStateToProps, mapDispatchToProps)(App);
