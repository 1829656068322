import useTheme from '@material-ui/core/styles/useTheme';
import React from 'react';

interface ThemePlayPageProps {}

const ThemePlayPage: React.FC<ThemePlayPageProps> = ({}) => {
  const theme = useTheme();
  return (
    <div style={{ height: '100vh' }}>
      <div className="flex flex-col h-full " style={{ backgroundColor: 'white', color: 'black' }}>
        <div className="flex font-bold text-xl">Paper as Background</div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.text.primary
          </div>

          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.text.secondary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.text.hint }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.text.hint
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.primary.light }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.primary.light
          </div>

          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.primary.main }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.primary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.primary.dark }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.primary.dark
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.secondary.light }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.secondary.light
          </div>

          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.secondary.main }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.secondary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.paper, color: theme.palette.secondary.dark }}
          >
            backgroundColor: theme.palette.background.paper
            <br />
            color: theme.palette.secondary.dark
          </div>
        </div>

        <div className="flex font-bold text-xl">Default as Background</div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{
              backgroundColor: theme.palette.background.default,
              color: theme.palette.text.primary,
            }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.text.primary
          </div>

          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.text.secondary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.hint }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.text.hint
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.primary.light }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.primary.light
          </div>

          <div
            className="flex-1 p-2"
            style={{
              backgroundColor: theme.palette.background.default,
              color: theme.palette.primary.main,
            }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.primary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.primary.dark }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.primary.dark
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.secondary.light }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.secondary.light
          </div>

          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.secondary.main }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.secondary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.background.default, color: theme.palette.secondary.dark }}
          >
            backgroundColor: theme.palette.background.default
            <br />
            color: theme.palette.secondary.dark
          </div>
        </div>

        <div className="flex font-bold text-xl">Primary as Background</div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.light, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.primary.light
            <br />
            color: theme.palette.text.primary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.primary.main
            <br />
            color: theme.palette.text.primary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.primary.dark
            <br />
            color: theme.palette.text.primary
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.light, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.primary.light
            <br />
            color: theme.palette.text.secondary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.primary.main
            <br />
            color: theme.palette.text.secondary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.primary.dark
            <br />
            color: theme.palette.text.secondary
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.light, color: theme.palette.secondary.main }}
          >
            backgroundColor: theme.palette.primary.light
            <br />
            color: theme.palette.secondary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main }}
          >
            backgroundColor: theme.palette.primary.main
            <br />
            color: theme.palette.secondary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.primary.dark, color: theme.palette.secondary.main }}
          >
            backgroundColor: theme.palette.primary.dark
            <br />
            color: theme.palette.secondary.main
          </div>
        </div>
        <div className="flex font-bold text-xl">Secondary as Background</div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.secondary.light
            <br />
            color: theme.palette.text.primary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.secondary.main
            <br />
            color: theme.palette.text.primary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.dark, color: theme.palette.text.primary }}
          >
            backgroundColor: theme.palette.secondary.dark
            <br />
            color: theme.palette.text.primary
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.secondary.light
            <br />
            color: theme.palette.text.secondary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.secondary.main
            <br />
            color: theme.palette.text.secondary
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.dark, color: theme.palette.text.secondary }}
          >
            backgroundColor: theme.palette.secondary.dark
            <br />
            color: theme.palette.text.secondary
          </div>
        </div>
        <div className="flex flex-1">
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.light, color: theme.palette.primary.main }}
          >
            backgroundColor: theme.palette.secondary.light
            <br />
            color: theme.palette.primary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.primary.main,
            }}
          >
            backgroundColor: theme.palette.secondary.main
            <br />
            color: theme.palette.primary.main
          </div>
          <div
            className="flex-1 p-2"
            style={{ backgroundColor: theme.palette.secondary.dark, color: theme.palette.primary.main }}
          >
            backgroundColor: theme.palette.secondary.dark
            <br />
            color: theme.palette.primary.main
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemePlayPage;
