import React from 'react';

interface IconProps {
  stroke?: string;
  width?: string;
  height?: string;
}

function Icon({ stroke = 'currentColor', width = '18', height = '18' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke={stroke} strokeWidth="1.063" transform="translate(-29 -184)">
          <g transform="translate(0 138)">
            <g transform="translate(30 45)">
              <g transform="translate(.5 2)">
                <circle cx="7.969" cy="6.375" r="3.719"></circle>
                <path d="M12.865 14.256a6.903 6.903 0 00-9.793 0"></path>
                <circle cx="7.969" cy="7.969" r="7.969"></circle>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
