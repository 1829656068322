import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
  }),
);

export default useStyles;
