import React, { useEffect } from 'react';
import {
  Editor,
  EditorTools,
  EditorChangeEvent,
  EditorMountEvent,
  ProseMirror,
  EditorPasteEvent,
  EditorUtils,
  PasteCleanupSettings,
} from '@progress/kendo-react-editor';
import { defaultMarkdownParser, defaultMarkdownSerializer } from 'prosemirror-markdown';
import cn from 'classnames';
import styled from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const { Bold, Italic, Link, Unlink, Undo, Redo, OrderedList, UnorderedList } = EditorTools;
const { pasteCleanup, sanitize, sanitizeClassAttr, sanitizeStyleAttr, removeAttribute, replaceImageSourcesFromRtf } =
  EditorUtils;

const pasteSettings: PasteCleanupSettings = {
  convertMsLists: true,
  stripTags: 'span|font|pre',
  attributes: {
    class: sanitizeClassAttr,
    style: sanitizeStyleAttr,

    // keep `width`, `height` and `src` attributes
    width: () => {},
    height: () => {},
    src: () => {},

    // Removes `lang` attribute
    // lang: removeAttribute,

    // removes other (unspecified above) attributes
    '*': removeAttribute,
  },
};

interface Props {
  label?: string;
  description?: string;
  error?: boolean;
  helperText?: string;
  ref?: any;
  defaultValue?: string;
  onChange?: (markdown: string) => void;
  required?: boolean;
  className?: string;
  editable?: boolean;
}

const MarkdownEditor = ({
  label,
  description,
  error,
  helperText,
  required,
  className,
  defaultValue, // This should be interpreted as an initial value
  onChange,
  editable = true,
}: Props): JSX.Element => {
  const theme = useTheme();

  const styles = `
    p {
        font-size: 0.875rem;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif
    }
  `;
  const [value, setValue] = React.useState(() => defaultMarkdownParser.parse(defaultValue ?? ''));

  useEffect(() => {
    setValue(defaultMarkdownParser.parse(defaultValue ?? ''));
  }, [defaultValue]);

  // Turn editor in read-only https://www.telerik.com/kendo-react-ui/components/editor/plugins/#toc-read-only
  const editableRef = React.useRef<boolean>(editable);
  const view = React.useRef<any>(null);

  const onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);

    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      new Plugin({
        key: new PluginKey('readonly'),
        props: { editable: () => editableRef.current },
        filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
      }),
    ];
    view.current = new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc: state.doc, plugins }),
      },
    );

    return view.current;
  };

  return (
    <StyledEditorWrapper className={cn(className, { error })}>
      {label && (
        <label className={`block text-sm mb-1.5 ${error ? theme.palette.error.main : ''}`}>
          {required ? `${label} *` : label}
        </label>
      )}
      {description && <p className="text-xs mb-3">{description}</p>}

      <div>
        <Editor
          // @ts-ignore
          value={value}
          onPasteHtml={(event: EditorPasteEvent) => {
            let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
            //html = html.replace(/(<([^>]+)>)/gi, ''); // Strip all HTML Tags

            // If the pasted HTML contains images with sources pointing to the local file system,
            // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
            if (event.nativeEvent.clipboardData) {
              html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
            }

            return html;
          }}
          onMount={onMount}
          onChange={(event: EditorChangeEvent) => {
            setValue(event.value);
            onChange?.(defaultMarkdownSerializer.serialize(event.value));
          }}
          contentStyle={{ height: 200 }}
          tools={
            editableRef.current
              ? [
                  [Bold, Italic, Link, Unlink],
                  [Undo, Redo],
                  [OrderedList, UnorderedList],
                ]
              : []
          }
          className={`ml-0 rounded-sm ${error ? theme.palette.error.main : 'border border-gray-200'}`}
        />
        {helperText && <StyledSpan className={`helperText text-sm pt-1.5`}>{helperText}</StyledSpan>}
      </div>
    </StyledEditorWrapper>
  );
};

const StyledSpan = styled.span`
  color: ${(props) => props.theme.palette.error.main};
  display: block;
`;

const StyledEditorWrapper = styled.div`
  .k-toolbar {
    /* border-color: #afb0b1;*/
  }
`;

export default MarkdownEditor;
