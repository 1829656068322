import React from 'react';

interface IconProps {
  opacity?: string;
}

function Icon({ opacity = '1' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity={opacity}>
        <g fill="#47505A" transform="translate(-1363 -105)">
          <g transform="translate(1363 105)">
            <path d="M6.352 7.545h-5.21A1.143 1.143 0 010 6.41V1.231C0 .606.514.095 1.143.095h5.21c.628 0 1.142.51 1.142 1.136v5.178c0 .625-.514 1.136-1.143 1.136"></path>
            <path d="M14.817 7.545h-5.21A1.143 1.143 0 018.466 6.41V1.231c0-.625.514-1.136 1.143-1.136h5.21c.628 0 1.142.51 1.142 1.136v5.178c0 .625-.514 1.136-1.143 1.136"></path>
            <path d="M6.352 15.96h-5.21A1.143 1.143 0 010 14.824V9.646C0 9.02.514 8.51 1.143 8.51h5.21c.628 0 1.142.51 1.142 1.136v5.178c0 .625-.514 1.136-1.143 1.136"></path>
            <path d="M14.817 15.96h-5.21a1.143 1.143 0 01-1.142-1.136V9.646c0-.625.514-1.136 1.143-1.136h5.21c.628 0 1.142.51 1.142 1.136v5.178c0 .625-.514 1.136-1.143 1.136"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
