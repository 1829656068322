import React from 'react';

interface IconProps {
  opacity?: string;
}

function Icon({ opacity = '1' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity={opacity}>
        <g fill="#47505A" transform="translate(-1389 -105)">
          <g transform="translate(1389 105)">
            <path d="M3.639 4H.452A.449.449 0 010 3.558V.442C0 .2.203 0 .452 0H3.64c.248 0 .452.199.452.442v3.116A.449.449 0 013.639 4"></path>
            <path d="M3.639 10H.452A.449.449 0 010 9.558V6.442C0 6.2.203 6 .452 6H3.64c.248 0 .452.199.452.442v3.116a.449.449 0 01-.452.442"></path>
            <path d="M3.639 16H.452A.449.449 0 010 15.558v-3.116C0 12.2.203 12 .452 12H3.64c.248 0 .452.199.452.442v3.116a.449.449 0 01-.452.442"></path>
            <path d="M16.937 3H5.972c-.36 0-.654-.124-.654-.277V1.277c0-.153.294-.277.654-.277h10.965c.36 0 .654.124.654.277v1.446c0 .153-.294.277-.654.277"></path>
            <path d="M16.94 9H5.97c-.358 0-.65-.124-.65-.277V7.277c0-.153.292-.277.65-.277H16.94c.358 0 .65.124.65.277v1.446c0 .153-.292.277-.65.277"></path>
            <path d="M16.94 15H5.97c-.358 0-.65-.124-.65-.277v-1.446c0-.153.292-.277.65-.277H16.94c.358 0 .65.124.65.277v1.446c0 .153-.292.277-.65.277"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
