import React from 'react';

function Icon(): JSX.Element {
  /*
    OLD GROUP ICON SVG CODE
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16"
      height="16"
      viewBox="0 0 10 12"
    >
      <defs>
        <path
          id="path-1"
          d="M7.344 9.344h2L6.656 12 4 9.344h2V4.656h1.344v4.688zM2.656 0l2.688 2.656h-2v4.688H2V2.656H0L2.656 0z"
        ></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1" opacity="0.5">
        <g transform="translate(-116 -446)">
          <g transform="translate(109 335)">
            <g transform="translate(0 105)">
              <g>
                <g transform="translate(7 6)">
                  <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-1"></use>
                  </mask>
                  <g fill="#47505A" mask="url(#mask-2)">
                    <path d="M0 0H72V72H0z" transform="translate(-31 -30)"></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  */

  /*
    NEW FREE SVG ICON FROM FONT AWESOME
    https://fontawesome.com/icons/right-left?f=classic&s=solid
    We have added width, height, fill, opacity and rotate 90 degrees
  */
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="14" height="14" fill="#47505A" opacity="0.5">
      <path
        transform="rotate(90 256 256)"
        d="M32 96l320 0V32c0-12.9 7.8-24.6 19.8-29.6s25.7-2.2 34.9 6.9l96 96c6 6 9.4 14.1 9.4 22.6s-3.4 16.6-9.4 22.6l-96 96c-9.2 9.2-22.9 11.9-34.9 6.9s-19.8-16.6-19.8-29.6V160L32 160c-17.7 0-32-14.3-32-32s14.3-32 32-32zM480 352c17.7 0 32 14.3 32 32s-14.3 32-32 32H160v64c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-96-96c-6-6-9.4-14.1-9.4-22.6s3.4-16.6 9.4-22.6l96-96c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 64H480z"
      />
    </svg>
  );
}

export default Icon;
