import React from 'react';
import { connect } from 'react-redux';
import ConferenceRequestForm, {
  ConferenceRequestFormFields,
} from '../components/ConferenceRequestForm/ConferenceRequestForm';
import { AppDispatch } from '../store/store';
import conferenceSlice from '../store/conference/slice';
import { validationErrorTransformer } from '../store/api';
import { AppState } from '../store/state';
import { ValidatedField } from '../store/api.d';
import { selectConference, selectCurrentUser } from '../store/selectors';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import Loading from '../components/Loading/Loading';
import { useTranslation } from 'react-i18next';

interface ConferenceCreatePageProps {
  loading: boolean;
  timezone: string | null;
  dateFormat: string | null;
  validationError?: ValidatedField[];
  postConferenceRequestAction: (data: ConferenceRequestFormFields) => void;
  clearValidationErrors: () => void;
}

const ConferencesCreatePage: React.FC<ConferenceCreatePageProps> = ({
  loading,
  timezone,
  dateFormat,
  validationError,
  postConferenceRequestAction,
  clearValidationErrors,
}) => {
  const theme = useTheme();

  const initConference = {
    id: 0,
    name: '',
    short_name: '',
    start_date: '',
    end_date: '',
    location: '',
    url: '',
    slug: '',
  };

  let valError = undefined;
  if (validationError) {
    valError = validationErrorTransformer(validationError) as unknown as ConferenceRequestFormFields;
  }

  const save_handler = (form: ConferenceRequestFormFields) => {
    postConferenceRequestAction(form);
  };
  const { t, i18n } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <div>
        {/* PAGE TITLE */}
        <StyledTitle>{t('Request a conference')}</StyledTitle>
      </div>
      <div className="w-full" style={{ flexGrow: 1 }}>
        <p className="mt-4">{t('Send us a request to host your conference at chairing tool beta.')}</p>
        <ConferenceRequestForm
          loading={loading}
          timezone={timezone}
          dateFormat={dateFormat}
          initConference={initConference}
          validation_error={valError}
          save_handler={save_handler}
          clearValidationErrors={() => clearValidationErrors()}
        />
      </div>
    </ThemeProvider>
  );
};

const StyledTitle = styled.h2`
  margin-right: 0.75rem;
  /*margin-bottom: 1.75rem;*/
  font-size: 1.25rem;
  font-weight: 700;
  border-bottom: 2px dotted ${(props) => props.theme.palette.secondary.dark};
  width: 100%;
`;

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).requestLoading,
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
  validationError: selectConference(state).validationError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  postConferenceRequestAction: (data: ConferenceRequestFormFields) =>
    dispatch(conferenceSlice.actions.POST_CONFERENCE_REQUEST(data)),
  clearValidationErrors: () => dispatch(conferenceSlice.actions['CLEAR_VALIDATION_ERRORS']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConferencesCreatePage);
