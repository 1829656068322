import React from 'react';
import tw from 'twin.macro';
import cn from 'classnames';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
  variant?: 'text' | 'outlined' | 'solid';
  roundedEdges?: 'rounded-none' | 'rounded' | 'rounded-full';
  ref?: any;
};

export const Button = ({
  disabled,
  className,
  icon,
  iconPosition = 'left',
  variant = 'solid',
  roundedEdges = 'rounded-full',
  children,
  ...buttonProps
}: ButtonProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledButton
        className={cn(className, variant, roundedEdges, {
          disabled,
          'right-icon': iconPosition == 'right',
        })}
        {...buttonProps}
      >
        {icon && <span className="-mx-2">{icon}</span>}
        <span>{children}</span>
      </StyledButton>
    </ThemeProvider>
  );
};

const StyledButton = styled.button`
  ${tw`
    inline-flex items-center justify-center h-9 whitespace-nowrap
    py-2 px-8 text-sm`}

  border-color: ${(props) => props.theme.palette.primary.main};
  &.text {
    ${tw`border-transparent`}
  }

  &.disabled {
    ${tw`pointer-events-none opacity-20`}
  }

  &.right-icon {
    ${tw`flex-row-reverse`}
  }
  &.solid {
    ${tw`font-semibold border-2`}
    color: #ffffff;
    background-color: ${(props) => props.theme.palette.primary.main};
  }
  &.outlined {
    ${tw`
      font-semibold border`}
    color: ${(props) => props.theme.palette.primary.main};
  }
  &.text {
    ${tw`
      font-semibold`}
  }
`;
