import React, { useState } from 'react';
import { AppState } from '../../store/state';
import { selectConference, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import Loading from '../../components/Loading/Loading';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { Button, Select } from '../../components/ui';
import conferenceSlice from '../../store/conference/slice';
import { Announcement, Role } from '../../store/conference/types';
import { Switch } from '@progress/kendo-react-inputs';
import HtmlTextEditor from '../../components/ui/inputs/HtmlTextEditor/HtmlTextEditor';
import { useTranslation } from 'react-i18next';
import { getRoleName } from '../../helpers/translations';

interface Props {
  loading: boolean;
  roleById: { [key: string]: Role };
  announcementById: { [key: string]: Announcement };
  postAnnouncementAction: (data: Announcement) => void;
}

const SettingsAnnouncementsPage: React.FC<Props> = ({
  loading,
  postAnnouncementAction,
  roleById,
  announcementById,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const selectOptions = Object.values(roleById)
    .filter((role) => role.type !== 'chair')
    .map((role) => ({ id: role.id.toString(), label: getRoleName(role, t) }));

  const emptyAnnouncement = (roleId: number) => {
    return { role_id: roleId, html: '<p></p>', is_published: false };
  };

  const initAnnouncement = (roleId: number): Announcement => announcementById[roleId] ?? emptyAnnouncement(roleId);

  const [roleId, setRoleId] = useState<number>(() => parseInt(selectOptions[0].id));
  const [announcement, setAnnouncement] = useState<Announcement>(() => initAnnouncement(roleId));

  const onRoleChange = (roleId: number) => {
    setRoleId(roleId);
    setAnnouncement(initAnnouncement(roleId));
  };

  const isEmpty = (a: Announcement) => {
    return a.html == emptyAnnouncement(roleId).html;
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="font-bold text-xl">{t('Announcements')}</div>
      <StyledWrapper className="mt-3 mb-5 max-w-5xl">
        <StyledSelectWrapper>
          <div className="flex flex-row">
            <Select
              label={t('Role')}
              value={roleId.toString()}
              onChange={(value) => onRoleChange(parseInt(value))}
              options={selectOptions}
            />
          </div>

          <div className="flex flex-row items-center justify-end">
            <p className="text-sm mr-2">{t('Published')}</p>
            <Switch
              checked={announcement.is_published}
              onChange={(event) => setAnnouncement({ ...announcement, is_published: event.target.value })}
              onLabel=""
              offLabel=""
              disabled={isEmpty(announcement)}
            />
          </div>
        </StyledSelectWrapper>

        <p className="mb-4">
          {t('Put any message or text here that you want the authors or reviewers to see on their homepage.')}
        </p>

        <HtmlTextEditor
          value={announcement.html}
          editable={true}
          onChange={(html) => {
            const newAnnouncement = {
              ...announcement,
              html,
            };
            setAnnouncement({
              ...newAnnouncement,
              is_published: !isEmpty(newAnnouncement) ? announcement.is_published : false,
            });
          }}
          className="w-full h-full"
        />
      </StyledWrapper>
      {/* FOOTER BUTTONS */}
      <StyledButtonsWrapper>
        <Button
          className="cursor-pointer"
          onClick={() =>
            postAnnouncementAction({ ...announcement, html: isEmpty(announcement) ? '' : announcement.html })
          }
          disabled={JSON.stringify(announcement) == JSON.stringify(initAnnouncement(roleId))}
        >
          <span className=" font-bold">{t('Save')}</span>
        </Button>
      </StyledButtonsWrapper>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectConference(state).loading,
  roleById: selectTrackRoles(state),
  announcementById: selectConference(state).announcementById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  postAnnouncementAction: (data: Announcement) => dispatch(conferenceSlice.actions.POST_ANNOUNCEMENT(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsAnnouncementsPage);

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  background-color: rgb(237, 243, 255);
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
`;

const StyledWrapper = styled.div`
  flex-direction: column;
  flex-grow: 1;
  border-top: 0;

  .k-iframe {
    height: 22rem !important;
  }

  .k-editor-toolbar {
    padding: 10px 14px;
  }

  .k-editor-content {
    padding: 10px;
  }
`;

const StyledSelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 2rem;
  justify-content: space-between;

  .labelSelect {
    align-self: center;
    margin-bottom: 0;
    margin-right: 1rem;
  }
`;
