import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import cn from 'classnames';
import CloseIcon from '../../icons/Close';

export type ChipProps = {
  className?: string;
  onClose?: () => void;
  children?: React.ReactNode;
};

export const Chip = ({ className, onClose, children }: ChipProps): JSX.Element => {
  return (
    <StyledDiv className={cn(className, { default: !className })}>
      <span className="leading-none mr-1">{children}</span>
      <button onClick={onClose} className={cn({ hidden: !onClose })}>
        <CloseIcon />
      </button>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  ${tw`inline-flex items-center rounded-full h-8 px-3 text-sm cursor-default whitespace-nowrap`}

  &.default {
    ${tw`bg-gray-200 text-gray-500`}
  }

  button {
    ${tw`-mr-1`}
  }
`;
