import React, { useEffect } from 'react';
import { Link, TextArea } from '../ui';
import 'katex/dist/katex.min.css';
import { AnswerJson, Format } from '../../store/form/types';
import TextAreaDisplay from '../TextAreaDisplay/TextAreaDisplay';
import { useTranslation } from 'react-i18next';

interface Props {
  value?: string;
  format?: Format;
  error?: boolean;
  helperText?: string;
  onChange?: (data: AnswerJson) => void;
  rows?: number;
  showFormatButtons?: boolean;
}

export const emptyAnswer = (): AnswerJson => ({ value: '', format: 'plain' });

const TextAreaLatex: React.FC<Props> = ({
  value,
  format,
  error,
  onChange,
  helperText,
  rows = 7,
  showFormatButtons = true,
}) => {
  const { t, i18n } = useTranslation();
  const [value_, setValue] = React.useState<string>(value ?? '');
  const [format_, setFormat] = React.useState<Format>(format || 'plain');

  useEffect(() => {
    setValue(value ?? '');
  }, [value]);

  return (
    <div>
      <div className={'' + (showFormatButtons ? '' : 'hidden')}>
        <Link
          className="mr-3 cursor-pointer"
          onClick={(e) => {
            setFormat('plain');
            onChange?.({ value: value_, format: 'plain' });
          }}
        >
          <span className={'text-sm' + (format_ === 'plain' ? ' font-semibold' : '')}>{t('Plain text')}</span>
        </Link>

        <Link
          className="cursor-pointer"
          onClick={(e) => {
            setFormat('latex');
            onChange?.({ value: value_, format: 'latex' });
          }}
        >
          <span className={'text-sm' + (format_ === 'latex' ? ' font-semibold' : '')}>LaTex</span>
        </Link>
      </div>
      <TextArea
        required
        cols={40}
        rows={rows}
        fullWidth
        value={value_}
        error={error}
        helperText={helperText}
        onChange={(e) => {
          const val = e.target.value;
          setValue(val);
          onChange?.({ value: val, format: format_ });
        }}
      />
      {format_ === 'latex' && showFormatButtons && (
        <div className="textAreaContentWrapper">
          <p className="font-medium">{t('Preview') + ':'}</p>
          <TextAreaDisplay value={value_} format={format_} />
        </div>
      )}
    </div>
  );
};

export default TextAreaLatex;
