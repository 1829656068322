function setItem(key: string, value: string | null) {
  if (value === null) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, value);
  }
  return null;
}

export let _token = localStorage.getItem('token') || null;
export let _roleId = getRoleId();

export function setToken(token: string | null): null {
  _token = token;
  return setItem('token', token);
}

function getRoleId(): number | null {
  const roleId = localStorage.getItem('roleId') || null;
  return roleId !== null ? parseInt(roleId) : roleId;
}

export function setRoleId(roleId: number | null): null {
  _roleId = roleId;
  return setItem('roleId', roleId !== null ? roleId.toString() : null);
}
