import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { Conference, Announcement, Role, Track } from '../store/conference/types';
import {
  selectCurrentConference,
  selectCurrentRole,
  selectCurrentTrack,
  selectCurrentUser,
  selectPaperStatusState,
  selectReviewState,
  selectSubmissionState,
  selectRoleRelations,
  selectTrackRoles,
  selectConference,
} from '../store/selectors';
import Loading from '../components/Loading/Loading';
import { Submission } from '../store/submission/types';
import { Answer, Answerable, Form, Question, ValidationStatus } from '../store/form/types';
import { FormTypeEnum } from '../store/form/types.d';
import { Link } from '../components/ui';
import { Review, viewModeType } from '../store/review/types';
import EditIcon from '../icons/Edit';
import GridIcon from '../icons/Grid';
import ListIcon from '../icons/List';
import { getFormsData } from '../helpers/form';
import SubmissionCard from '../components/SubmissionCard/SubmissionCard';
import MyGridRedux from '../components/MyGridRedux/MyGridRedux';
import PaperStatusChangeDialog from '../components/dialogs/PaperStatusChangeDialog/PaperStatusChangeDialog';
import { AssignmentPermissionManager, can } from '../helpers/permissions';
import { PaperStatus } from '../store/paper-status/types';
import styled from 'styled-components';
import history from '../store/history';
import { fillRoutePath } from '../helpers/path';
import { getRouteByName } from '../router/routes';
import { RowAction } from '../components/types';
import AnnouncementSidePanel from '../components/AnnouncementSidePanel/AnnouncementSidePanel';
import reviewSlice from '../store/review/slice';
import ViewNewIcon from '../icons/ViewNew';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName, getValidationStatusName } from '../helpers/translations';

interface Props {
  loading: boolean;
  conference: Conference | null;
  track: Track | null;
  role: Role | null;
  submissionsById: { [key: string]: Submission };
  personId: number;
  reviewsById: { [key: string]: Review };
  paperStatusById: { [key: number]: PaperStatus };
  roleCanView: number[];
  roleById: { [key: string]: Role };
  announcementById: { [key: string]: Announcement };
  viewMode: viewModeType;
  changeViewModeAction: (viewMode: viewModeType) => void;
}

const ReviewsPage: React.FC<Props> = ({
  loading,
  conference,
  track,
  role,
  submissionsById,
  personId,
  reviewsById,
  paperStatusById,
  roleCanView,
  roleById,
  announcementById,
  viewMode,
  changeViewModeAction,
}) => {
  const { t, i18n } = useTranslation();
  if (!conference || !track || !role || loading) {
    return <Loading />;
  }

  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  const getActions = (
    review: Review,
    formsData: { form: Form; questions: Question[]; answers: Answer[] }[],
  ): RowAction[] => {
    const actions: RowAction[] = [];

    /* Get the forms associated to this review. This forms must be of the same type and role */
    const forms = formsData.filter(({ form }) => form.visibility == 'write').map(({ form }) => form);

    const submission = Object.values(submissionsById).find((submission) => submission.id === review.submission_id);

    if (submission) {
      const callback = () => {
        history.push(
          fillRoutePath(getRouteByName('RouteDetailSubmission').path, {
            id: submission.external_id.toString(),
          }),
        );
      };
      actions.push({
        id: 'view-submission',
        label: t('View submission'),
        rowIdKey: '',
        callback: callback,
        element: (
          <Link className="cursor-pointer text-sm flex items-center" onClick={callback}>
            <ViewNewIcon />
            <span className="ml-1">{t('View submission')}</span>
          </Link>
        ),
      });
    }

    const canEdit = new AssignmentPermissionManager(review).canEdit(personId, role, forms);
    if (canEdit && submission) {
      const callback = () => {
        history.push(
          fillRoutePath(getRouteByName('RouteEditReview').path, {
            submissionId: submission?.external_id.toString(),
            reviewId: review.external_id.toString(),
          }),
        );
      };
      actions.push({
        id: 'edit-review',
        label: t('Edit Review'),
        rowIdKey: 'review_id',
        callback: callback,
        element: (
          <Link key={actions.length} className="flex items-center cursor-pointer" onClick={callback}>
            <EditIcon color={theme.palette.primary.main} className="mr-1" />
            {t('Edit Review')}
          </Link>
        ),
      });
    }

    if (can('CAN_EDIT_PAPER_STATUS')) {
      const callback = () =>
        setDialog(
          <PaperStatusChangeDialog
            initialOption={submissionsById[review.submission_id].paper_status_id as unknown as string}
            submissionIds={[review.submission_id]}
            open={true}
            handleClose={() => setDialog(undefined)}
          />,
        );
      actions.push({
        id: 'edit-paper-status',
        label: t('Edit paper status'),
        rowIdKey: 'id',
        callback: callback,
        element: (
          <Link key={actions.length} className="flex items-center cursor-pointer" onClick={callback}>
            <EditIcon color={theme.palette.primary.main} className="mr-1" />
            {t('Edit paper status')}
          </Link>
        ),
      });
    }
    return actions;
  };

  const theme = useTheme();

  if (loading) {
    return <Loading />;
  }

  const canViewAuthors =
    roleCanView?.map((roleId) => roleById[roleId]).filter((role) => role.type === 'author').length > 0;

  const userAssignments: {
    [key: number]: {
      answerable: Answerable;
      formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
    };
  } = {};
  Object.values(reviewsById)
    .filter((assignment) => new AssignmentPermissionManager(assignment).isAuthor(personId, role.id))
    .map((assignment) => getFormsData(FormTypeEnum.Review, ['read', 'write'], role.id, null, personId, assignment))
    .forEach((value) => {
      const assignment = value.answerable as Review;
      userAssignments[assignment.submission_id] = value;
    });

  const reviewActions: { [key: string]: RowAction[] } = {};
  const inputData: { [key: string]: Submission } = {};
  Object.values(submissionsById).forEach((submission) => {
    let validationStatus: ValidationStatus | undefined = undefined;
    let assignmentId: number | undefined = undefined;
    let actions: RowAction[] = [];
    if (userAssignments[submission.id]) {
      const assignment = userAssignments[submission.id].answerable as Review;
      const formsData = userAssignments[submission.id].formsData;
      const canEdit = new AssignmentPermissionManager(assignment).canEdit(
        personId,
        role,
        formsData.map(({ form }) => form),
      );
      validationStatus =
        canEdit && assignment.validation_status === 'Pending' ? assignment.validation_status : undefined;
      assignmentId = assignment.id;
      actions = getActions(assignment, formsData);
    }

    const newRegister = {
      ...submission,
      validation_status: validationStatus,
      review_id: assignmentId,
    };
    // @ts-ignore
    newRegister.authors_str = canViewAuthors
      ? newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';')
      : undefined;
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);
    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    // @ts-ignore
    inputData[submission.id] = newRegister;
    reviewActions[submission.id] = actions;
  });

  const announcement = announcementById[role.id];

  /* Decide what to display */
  let element;
  if (!Object.keys(inputData).length) {
    element = (
      <div className={`grid gap-5 ${announcement ? 'grid-cols-4' : 'grid-cols-5'}`}>
        <span>{t('No papers to review yet.')}</span>
      </div>
    );
  } else if (viewMode === 'table') {
    element = (
      <MyGridRedux
        friendlyName="submissions_to_review"
        inputData={Object.values(inputData).map((value) => {
          return {
            ...value,
            validation_status: value.validation_status
              ? getValidationStatusName(value.validation_status, t)
              : value.validation_status,
          };
        })}
        initRowActions={reviewActions}
      />
    );
  } else {
    element = (
      <StyledCardsWrapper className="flex justify-start flex-wrap grow">
        {Object.values(inputData).map((submission, index) => {
          return (
            <div key={index} className="mb-6 w-80">
              <SubmissionCard
                submission={submission}
                actions={reviewActions[submission.id]}
                validationStatus={
                  submission.validation_status
                    ? {
                        title: t('Review status'),
                        status: submission.validation_status,
                      }
                    : undefined
                }
                canViewAuthors={canViewAuthors}
              />
            </div>
          );
        })}
      </StyledCardsWrapper>
    );
  }

  return (
    <div className="flex h-full">
      <StyledContainer className="flex flex-col overflow-y-auto overflow-x-hidden mr-2">
        <div className="mb-6 flex justify-between">
          <h2 className="font-bold text-xl mb-4 mr-5">{t('Submissions to review')}</h2>
          <div className="flex mr-20">
            <div className="mt-1 mx-4 cursor-pointer" onClick={() => changeViewModeAction('cards')}>
              <GridIcon opacity={viewMode === 'cards' ? '1' : '0.5'} />
            </div>
            <div className="mt-1 cursor-pointer" onClick={() => changeViewModeAction('table')}>
              <ListIcon opacity={viewMode === 'table' ? '1' : '0.5'} />
            </div>
          </div>
        </div>
        {element}
      </StyledContainer>

      {announcement && <AnnouncementSidePanel conference={conference} announcement={announcement} />}

      {dialog}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectReviewState(state).loading,
  conference: selectCurrentConference(state),
  track: selectCurrentTrack(state),
  role: selectCurrentRole(state),
  submissionsById: selectSubmissionState(state).submissionsById,
  personId: selectCurrentUser(state).person.id,
  reviewsById: selectReviewState(state).reviewsById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  roleCanView: selectRoleRelations(state, 'identity'),
  roleById: selectTrackRoles(state),
  announcementById: selectConference(state).announcementById,
  viewMode: selectReviewState(state).viewMode,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeViewModeAction: (viewMode: viewModeType) => dispatch(reviewSlice.actions.CHANGE_VIEWMODE(viewMode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsPage);

const StyledCardsWrapper = styled.div`
  margin-left: -0.5rem;
  margin-right: -0.5rem;
`;

const StyledContainer = styled.div`
  flex-grow: 1;

  .custom-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 25%));
  }

  .custom-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 20%));
  }
`;
