export interface DataNode {
  index: string;
  children: string[];
  data: string;
  isFolder: boolean;
  canRename: boolean;
}

export const formatKeyword = (keywordId: number, keywordById: { [key: number]: Keyword }): string => {
  const keyword = keywordById[keywordId];

  let keywordString = keyword.name;

  if (keyword.parent_id) {
    const parent = keywordById[keyword.parent_id];
    keywordString = `${parent.name} -> ${keywordString}`;
  }

  return keywordString;
};

export const naturalSortKeywords = (keywords: Keyword[]): Keyword[] => {
  return keywords.sort((a, b) => {
    const nameA = a.name.toUpperCase(); // ignore upper and lowercase
    const nameB = b.name.toUpperCase(); // ignore upper and lowercase
    // Natural sort ref: https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
    return nameA.localeCompare(nameB, undefined, { numeric: true }); // natural sort
  });
};

export class KeywordsComplexTreeAdapter {
  toInternalId(keywordId: number): string {
    return keywordId.toString();
  }
  sortChildren(ids: number[], keywordById: { [key: number]: Keyword }): string[] {
    const keywords = ids.map((keywordId) => keywordById[keywordId]);

    return naturalSortKeywords(keywords).map((keyword) => this.toInternalId(keyword.id));
  }

  transform(
    keywordById: { [key: number]: Keyword },
    keywordTree: { [key: number]: number[] },
  ): { [index: string]: DataNode } {
    const dataTree: { [index: string]: DataNode } = {};

    dataTree['root'] = {
      index: 'root',
      children: this.sortChildren(
        Object.keys(keywordTree).map((value) => parseInt(value)),
        keywordById,
      ),
      data: 'Root item',
      isFolder: true,
      canRename: true,
    };

    Object.values(keywordById).map((keyword) => {
      const newNode: DataNode = {
        index: this.toInternalId(keyword.id),
        children: this.sortChildren(keywordTree[keyword.id] ?? [], keywordById),
        data: keyword.name,
        isFolder: true,
        canRename: true,
      };
      dataTree[newNode.index] = newNode;
    });

    return dataTree;
  }

  reverse(dataTree: { [index: string]: DataNode }): { [index: number]: number[] } {
    const tree: { [key: number]: number[] } = {};

    dataTree.root.children.forEach((childId) => {
      const _childId = parseInt(childId);
      tree[_childId] = dataTree[_childId].children.map((val) => parseInt(val));
    });

    return tree;
  }
}
