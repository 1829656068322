import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Label } from '../../store/label/types';
import LabelsArray from '../LabelsArray/LabelsArray';
import { TableFriendlyName } from '../../store/info/types';

interface LabelsCellProps extends GridCellProps {
  unpickLabelAction?: (recordId: number, labelId: number, tableFriendlyName: TableFriendlyName) => void;
  labelsById: { [key: number]: Label };
  tableFriendlyName: TableFriendlyName;
  labelableIdKey: string;
}

const LabelsCell: React.FC<LabelsCellProps> = ({
  dataItem,
  unpickLabelAction,
  labelsById,
  tableFriendlyName,
  labelableIdKey,
  ...props
}) => {
  // It seems this function is called many times if the data is aggregated.
  if ('aggregates' in dataItem) return <td></td>;

  const { label_ids: labelIds } = dataItem;
  const recordId = dataItem[labelableIdKey];
  const labelsData = labelIds.map((labelId: number) => labelsById[labelId]);

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      <LabelsArray
        labelsData={labelsData}
        unpickLabelAction={unpickLabelAction}
        recordId={recordId}
        tableFriendlyName={tableFriendlyName}
      />
    </td>
  );
};

export default LabelsCell;
