import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Props {
  utcOffset: string;
}

const UTCTooltip: React.FC<Props> = ({ utcOffset }) => {
  const { t, i18n } = useTranslation();
  return (
    <Tooltip title={t('The UTC offset can be adjusted from the user settings screen.')} placement="top">
      <StyledDateInfo className="text-sm ml-4 cursor-default">
        {t('Dates in UTC{{utcOffset}}', { utcOffset: utcOffset })}
      </StyledDateInfo>
    </Tooltip>
  );
};

const StyledDateInfo = styled.div`
  background-color: transparent;
  padding: 0 10px;
  color: #000;
  border-radius: 13px;
  font-size: 0.87rem;
  font-weight: 500;
  border: 1px solid #000;
`;

export default UTCTooltip;
