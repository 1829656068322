import { put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { gridSettingsProps } from '../../components/MyGridCore/types';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import tableSlice from './slice';
import {
  CustomColumnsUpdate,
  getAllSettingsResponse,
  GetCustomColumnsData,
  GetCustomColumnsDataResponse,
  patchSettingsResponse,
  TableSaveAsSettingsDTO,
  TableDeleteSettingsDTO,
  TablePatchSettingsDTO,
  TableResetSettingsDTO,
  TableState,
  TableSwitchFilterDTO,
  TableSaveSettingsDTO,
  saveSettingsResponse,
} from './types';
import { baseURL } from '../root-saga';
import errorSlice from '../error/slice';
import { selectTable } from '../selectors';
import i18next from '../../i18n';

const getAllSettings = async (): Promise<Result<getAllSettingsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getAllSettingsResponse>(`${baseURL}/api/tables`, init);
};

function* getAllSettingsSaga() {
  const result = (yield getAllSettings()) as Await<ReturnType<typeof getAllSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['ALL_SETTINGS:GET:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const patchSettings = async (
  gridSettings: gridSettingsProps,
  friendlyName: string,
): Promise<Result<patchSettingsResponse, APIValidationError>> => {
  const body = { table_settings: gridSettings };

  const init = {
    method: 'PATCH',
    auth: true,
    role: true,
    body: JSON.stringify(body),
  };

  return apiCall<patchSettingsResponse>(`${baseURL}/api/tables/${friendlyName}`, init);
};

function* patchSettingsSaga(action: PayloadAction<TablePatchSettingsDTO>) {
  const { gridSettings, friendlyName } = action.payload;
  const result = (yield patchSettings(gridSettings, friendlyName)) as Await<ReturnType<typeof patchSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['SETTINGS:PATCH:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const resetSettings = async (friendlyName: string): Promise<Result<getAllSettingsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getAllSettingsResponse>(`${baseURL}/api/tables/${friendlyName}/reset`, init);
};

function* resetSettingsSaga(action: PayloadAction<TableResetSettingsDTO>) {
  const { friendlyName } = action.payload;
  const result = (yield resetSettings(friendlyName)) as Await<ReturnType<typeof resetSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['ALL_SETTINGS:GET:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const saveAsSettings = async (
  data: TableSaveAsSettingsDTO,
): Promise<Result<getAllSettingsResponse, APIValidationError>> => {
  const { friendlyName, ...rest } = data;

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify({ name: rest.name, table_settings: rest.gridSettings }),
  };

  return apiCall<getAllSettingsResponse>(`${baseURL}/api/tables/${friendlyName}/saveAs`, init);
};

function* saveAsSettingsSaga(action: PayloadAction<TableSaveAsSettingsDTO>) {
  const result = (yield saveAsSettings(action.payload)) as Await<ReturnType<typeof saveAsSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['ALL_SETTINGS:GET:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const saveSettings = async (data: TableSaveSettingsDTO): Promise<Result<saveSettingsResponse, APIValidationError>> => {
  const { friendlyName } = data;

  const init = {
    method: 'POST',
    auth: true,
    role: true,
  };

  return apiCall<saveSettingsResponse>(`${baseURL}/api/tables/${friendlyName}/save`, init);
};

function* saveSettingsSaga(action: PayloadAction<TableSaveSettingsDTO>) {
  const result = (yield saveSettings(action.payload)) as Await<ReturnType<typeof saveSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['SETTINGS:PATCH:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

function* switchFilterSaga(action: PayloadAction<TableSwitchFilterDTO>) {
  const { friendlyName } = action.payload;
  const tableState: TableState = yield select(selectTable);
  const gridSettings: gridSettingsProps = tableState.tablesSettings[friendlyName].gridSettings;

  yield put(
    tableSlice.actions['SETTINGS:PATCH']({
      gridSettings: { ...gridSettings, advancedFilter: !gridSettings.advancedFilter },
      friendlyName,
    }),
  );
}

const loadSettings = async (
  personalSettingsId: number,
): Promise<Result<getAllSettingsResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
  };

  return apiCall<getAllSettingsResponse>(`${baseURL}/api/tables/${personalSettingsId}/load`, init);
};

function* loadSettingsSaga(action: PayloadAction<number>) {
  const id = action.payload;
  const result = (yield loadSettings(id)) as Await<ReturnType<typeof loadSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['ALL_SETTINGS:GET:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deleteSettings = async (
  data: TableDeleteSettingsDTO,
): Promise<Result<getAllSettingsResponse, APIValidationError>> => {
  const { friendlyName } = data;
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };

  return apiCall<getAllSettingsResponse>(`${baseURL}/api/tables/${friendlyName}`, init);
};

function* deleteSettingsSaga(action: PayloadAction<TableDeleteSettingsDTO>) {
  const result = (yield deleteSettings(action.payload)) as Await<ReturnType<typeof deleteSettings>>;

  switch (result.type) {
    case 'ok':
      yield put(tableSlice.actions['ALL_SETTINGS:GET:OK']({ ...result.value.data, keepFilters: true }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updateCustomColumns = async (
  data: CustomColumnsUpdate,
): Promise<Result<getAllSettingsResponse, APIValidationError>> => {
  const { friendlyName, customColumns } = data;
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify({ custom_columns: customColumns }),
  };

  return apiCall<getAllSettingsResponse>(`${baseURL}/api/custom-columns/${friendlyName}`, init);
};

function* updateCustomColumnsSaga(action: PayloadAction<CustomColumnsUpdate>) {
  const result = (yield updateCustomColumns(action.payload)) as Await<ReturnType<typeof updateCustomColumns>>;

  switch (result.type) {
    case 'ok':
      // We need to refresh table data.
      yield put(tableSlice.actions['ALL_SETTINGS:GET:OK']({ ...result.value.data, keepFilters: true }));
      yield put(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET']({ friendlyName: action.payload.friendlyName }));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t(`Aggregate columns updated`),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const getCustomColumnsData = async (
  data: GetCustomColumnsData,
): Promise<Result<GetCustomColumnsDataResponse, APIValidationError>> => {
  const { friendlyName } = data;
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetCustomColumnsDataResponse>(`${baseURL}/api/custom-columns/${friendlyName}/data`, init);
};

function* getCustomColumnsDataSaga(action: PayloadAction<GetCustomColumnsData>) {
  const result = (yield getCustomColumnsData(action.payload)) as Await<ReturnType<typeof getCustomColumnsData>>;

  switch (result.type) {
    case 'ok':
      yield put(
        tableSlice.actions['CUSTOMCOLUMNS:DATA:GET:OK']({
          friendlyName: action.payload.friendlyName,
          byId: result.value.data.customColumnsById,
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(tableSlice.actions['ALL_SETTINGS:GET'].type, getAllSettingsSaga),
  takeLatest(tableSlice.actions['SETTINGS:PATCH'].type, patchSettingsSaga),
  takeLatest(tableSlice.actions['SETTINGS:RESET'].type, resetSettingsSaga),
  takeLatest(tableSlice.actions.SWITCH_FILTER.type, switchFilterSaga),
  takeLatest(tableSlice.actions['SETTINGS:SAVE'].type, saveSettingsSaga),
  takeLatest(tableSlice.actions['SETTINGS:SAVE_AS'].type, saveAsSettingsSaga),
  takeLatest(tableSlice.actions['SETTINGS:LOAD'].type, loadSettingsSaga),
  takeLatest(tableSlice.actions['SETTINGS:DELETE'].type, deleteSettingsSaga),
  takeLatest(tableSlice.actions['CUSTOMCOLUMNS:UPDATE'].type, updateCustomColumnsSaga),
  takeLatest(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'].type, getCustomColumnsDataSaga),
];
