import React from 'react';
import { GRID_COL_INDEX_ATTRIBUTE, GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '../ui';

interface Props extends GridCellProps {
  onChangeHandler: () => void;
}

const AssignedCell: React.FC<Props> = ({ onChangeHandler, ...props }) => {
  const field = props.field || '';
  const value = props.dataItem[field];

  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      style={props.style}
    >
      <Checkbox checked={value} onChange={onChangeHandler} />
    </td>
  );
};

export default AssignedCell;
