import React from 'react';
import useStyles from './LabelsArray.styles';
import Chip from '@material-ui/core/Chip';
import { TableFriendlyName } from '../../store/info/types';
import { Label } from '../../store/label/types';

export interface LabelsArrayProps {
  labelsData: Label[]; // labels already picked by the user
  unpickLabelAction?: (recordId: number, labelId: number, tableFriendlyName: TableFriendlyName) => void;
  recordId: number;
  tableFriendlyName: TableFriendlyName;
}

const LabelsArray: React.FC<LabelsArrayProps> = ({ labelsData, unpickLabelAction, recordId, tableFriendlyName }) => {
  const classes = useStyles();
  return (
    <ul className={classes.root}>
      {labelsData.map((label) => {
        return (
          <li key={label.key}>
            <Chip
              style={{ backgroundColor: label.background_color, color: label.text_color }}
              label={label.label}
              onDelete={unpickLabelAction ? () => unpickLabelAction(recordId, label.key, tableFriendlyName) : undefined}
              className={classes.chip}
            />
          </li>
        );
      })}
    </ul>
  );
};

export default LabelsArray;
