import React from 'react';
import { connect } from 'react-redux';
import { Button, Link } from '../ui';
import { EmailFormState, EmailTemplate, Placeholder } from '../../store/email/types';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { GridColumnProps } from '@progress/kendo-react-grid';
import ActionsCell from '../ActionsCell/ActionsCell';
import { getEmailCount } from '../../store/email/sagas';
import Loading from '../Loading/Loading';
import ProgressBar from '../ProgressBar/ProgressBar';
import EmailDialog from '../dialogs/EmailDialog/EmailDialog';
import InvitationEmailForm from '../InvitationEmailForm/InvitationEmailForm';
import { BasicInvitationFormFields, InvitationDTO, PreviewRow } from '../../store/recruitment/types';
import recruitmentSlice from '../../store/recruitment/slice';
import emailSlice from '../../store/email/slice';
import { getInvitationEmailRender } from '../../store/recruitment/sagas';
import { ValidatedField } from '../../store/api.d';
import { INVITATION_INPUT_TYPE } from '../InvitationRecipientPicker/InvitationRecipientPicker';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import MyGridCore from '../MyGridCore/MyGridCore';
import { DEFAULT_FONT_SIZE } from '../MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';

interface Props {
  input?: BasicInvitationFormFields[] | number[];
  type: INVITATION_INPUT_TYPE;
  formState: EmailFormState;
  preview?: PreviewRow[];
  email: InvitationDTO;
  placeholders: Placeholder[];
  templatesById: { [key: number]: EmailTemplate };
  removePreviewAction: () => void;
  createInvitationsAction: (data: InvitationDTO) => void;
  validationErrors?: ValidatedField[];
  onClose: () => void;
  onBack: () => void;
}

const SendInvitationEmailForm: React.FC<Props> = ({
  input,
  type,
  formState,
  preview,
  email,
  removePreviewAction,
  createInvitationsAction,
  validationErrors,
  placeholders,
  onClose,
  onBack,
  templatesById,
}) => {
  const { t, i18n } = useTranslation();
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [emailHtml, setEmailHtml] = React.useState<string | undefined>(undefined);
  const theme = useTheme();

  switch (formState) {
    case 'COMPOSE':
      return (
        <InvitationEmailForm
          value={email}
          placeholders={placeholders}
          onSendHandler={(data) => {
            let args;
            switch (type) {
              case 'many':
              case 'single':
                args = { recipients: input as BasicInvitationFormFields[] };
                break;
              case 'ids':
                args = { ids: input as number[] };
                break;
            }
            createInvitationsAction({ mode: type, dry_run: true, ...data, ...args });
          }}
          validationErrors={validationErrors}
          onClose={onClose}
          back={onBack}
          isResend={type === 'ids'}
          templatesById={templatesById}
        />
      );
    case 'PREVIEW':
      const gridColumnProps: GridColumnProps[] = [];
      gridColumnProps.push(
        {
          field: 'from_name',
          title: t('From name'),
        },
        {
          field: 'from',
          title: t('From address'),
        },
        {
          field: 'recipient_name',
          title: t('Recipient name'),
        },
        {
          field: 'recipient_address',
          title: t('Recipient address'),
        },
        {
          field: 'subject',
          title: t('Subject'),
        },
        {
          field: 'body',
          title: t('Body'),
          cell: ActionsCell,
          filterable: false,
        },
      );

      const inputData: any[] = preview
        ? preview.map((previewRow) => ({
            ...previewRow,
            body: (
              <Link
                className="flex items-center cursor-pointer font-semibold"
                onClick={async () => {
                  if (email) {
                    const response = await getInvitationEmailRender({
                      email: previewRow.recipient_address,
                      target_role_id: previewRow.role_id,
                      first_name: previewRow.first_name,
                      last_name: previewRow.last_name,
                      body: email.body,
                      expiration_date: email.expiration_date,
                      concat: email.concat,
                    });
                    if (response.type === 'ok') {
                      setEmailHtml(response.value.data.html);
                      setShowDialog(true);
                    }
                  }
                }}
              >
                {t('Preview Invitation')}
              </Link>
            ),
          }))
        : [];

      return (
        <>
          <ThemeProvider theme={theme}>
            <div className="mt-10">
              <MyGridCore
                allGridData={inputData}
                gridSettings={{
                  gridColumnProps: gridColumnProps,
                  arrayFields: [],
                  advancedFilter: false,
                  DATA_ITEM_KEY: '',
                  SELECTED_FIELD: '',
                  LABELABLE_ITEM_KEY: '',
                  fontSize: DEFAULT_FONT_SIZE,
                  dataState: {},
                }}
                selectedState={{}}
                dateFormat={null}
                expandedState={{}}
                selectable={false}
                showActionsColumn={false}
              />
              <StyledButtons>
                <StyledCancel onClick={onClose} autoFocus variant="text">
                  {t('Cancel')}
                </StyledCancel>

                <Button type="button" variant="text" onClick={() => removePreviewAction()} className="font-semibold">
                  {t('Previous')}
                </Button>
                {preview && preview.length > 0 && (
                  <Button
                    type="button"
                    onClick={() => {
                      let args;
                      switch (type) {
                        case 'many':
                        case 'single':
                          args = { recipients: input as BasicInvitationFormFields[] };
                          break;
                        case 'ids':
                          args = { ids: input as number[] };
                          break;
                      }
                      createInvitationsAction({
                        mode: type,
                        dry_run: false,
                        subject: email.subject,
                        body: email.body,
                        expiration_date: email.expiration_date,
                        concat: email.concat,
                        ...args,
                      });
                    }}
                  >
                    {t('Send')}
                  </Button>
                )}
              </StyledButtons>

              {emailHtml && <EmailDialog show={showDialog} onClose={() => setShowDialog(false)} html={emailHtml} />}
            </div>
          </ThemeProvider>
        </>
      );
    case 'SENDING':
      return (
        <>
          <div className="mt-10">
            <ProgressBar
              title={t('Sending emails...')}
              fetchValue={async () => {
                if (email?.token && preview) {
                  const response = await getEmailCount(email.token);
                  if (response.type === 'ok') {
                    return Math.round((response.value.data.count / preview.length) * 100);
                  }
                }
                return 0;
              }}
            />
          </div>

          <StyledButtons>
            <StyledCancel onClick={onClose} autoFocus variant="text">
              {t('Close')}
            </StyledCancel>
          </StyledButtons>
        </>
      );
    case 'WAIT':
      return <Loading />;
  }
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  removePreviewAction: () => dispatch(emailSlice.actions['REMOVE:PREVIEW']()),
  createInvitationsAction: (data: InvitationDTO) => dispatch(recruitmentSlice.actions.CREATE_INVITATIONS(data)),
});

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0 0 0;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 10;
  padding-bottom: 1.5rem;
`;

const StyledCancel = styled(Button)`
  color: rgb(220 38 38);
  font-weight: 700;
`;

export default connect(mapStateToProps, mapDispatchToProps)(SendInvitationEmailForm);
