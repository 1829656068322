import React from 'react';

interface IconProps {
  stroke: string;
}
function Icon({ stroke = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
      <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke={stroke} strokeWidth="1.046" transform="translate(-30 -139)">
          <g transform="translate(0 138)">
            <g transform="translate(30)">
              <g transform="translate(0 1)">
                <path d="M7.323 8.736a.682.682 0 01-.68.684h-5.44a.681.681 0 01-.68-.684V1.211a.682.682 0 01.68-.684l5.44.01c.377 0 .68.307.68.683v7.516zM16.213 4.564a.676.676 0 01-.68.671h-5.44a.675.675 0 01-.68-.671V1.207a.675.675 0 01.678-.67l5.44-.01a.677.677 0 01.682.671v3.366zM7.323 15.547a.677.677 0 01-.681.673l-5.44-.01a.676.676 0 01-.679-.672v-3.354a.676.676 0 01.68-.672h5.44a.677.677 0 01.68.672v3.363zM10.093 16.22a.682.682 0 01-.68-.685V8.011a.682.682 0 01.68-.684h5.44a.682.682 0 01.68.684v7.515a.683.683 0 01-.68.685l-5.44.009z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
