module.exports = {
  purge: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: false, // or 'media' or 'class'
  theme: {
    extend: {
      colors: {
        primary: {
          light: '#FE61D3',
          main: '#ff00bb',
          dark: '#D00097',
          accent: '#FF31C6',
          'contrast-text': '#FF31C6',
        },
        secondary: {
          light: '#A4FE5A',
          main: '#74FF00',
          dark: '#69E800',
          accent: '#8AFF29',
          'contrast-text': '#00FF92',
        },
      },
    },
  },
  variants: {
    extend: {},
  },
  plugins: [require('@tailwindcss/forms')],
};
