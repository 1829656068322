import React, { useEffect } from 'react';
import { getSignedURL, putObjectS3 } from '../../store/submission/sagas';
import { downloadFileS3 } from '../../helpers/s3';
import {
  Upload,
  UploadFileInfo,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent,
} from '@progress/kendo-react-upload';
import { UploadFileStatus } from '@progress/kendo-react-upload/dist/npm/interfaces/UploadFileStatus';
import { v4 as uuidv4 } from 'uuid';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import { FileUpload } from '../../store/submission/types';
import { OpenSnackBarDTO } from '../../store/error/types';
import TrashCan from '../../icons/TrashCan';
import { PDFDocument } from 'pdf-lib';
import { useTranslation } from 'react-i18next';

interface Props {
  initUpload: FileUpload | null;
  onSuccess?: (data: FileUpload | null) => void;
  onError?: (message: string) => void;
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  maxSize?: number; // In MB
  maxPages?: number;
  extensions: string[];
  error?: boolean;
  disabled?: boolean;
}

const FileUploaderS3: React.FC<Props> = ({
  initUpload,
  onSuccess,
  openSnackBarAction,
  onError,
  maxSize,
  maxPages,
  extensions,
  error,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  const initFiles = (): Array<UploadFileInfo> => {
    return initUpload
      ? [
          {
            uid: uuidv4(),
            name: initUpload.filename_original,
            extension: '.' + initUpload.filename_original.split('.')[1],
            status: UploadFileStatus.Uploaded,
            progress: 0,
          },
        ]
      : [];
  };

  const [files, setFiles] = React.useState<Array<UploadFileInfo>>(initFiles);
  const [saveURL, setSaveURL] = React.useState('');
  const [filename, setFilename] = React.useState('');

  useEffect(() => {
    setFiles(initFiles());
    setSaveURL('');
    setFilename('');
  }, [initUpload]);

  const isValid = async (event: UploadOnAddEvent) => {
    // If file is a PDF
    if (maxPages && event.affectedFiles[0].extension === '.pdf' && extensions.includes('pdf')) {
      let pdfCountPages = 0;
      // Check number of pages
      const file = event.affectedFiles[0].getRawFile?.();
      if (file) {
        //https://pdf-lib.js.org/#modify-document
        //https://stackoverflow.com/questions/72881468/how-to-load-local-pdf-to-edit-it-with-pdflib
        const buffer = await file.arrayBuffer();
        const pdf = await PDFDocument.load(buffer);

        pdfCountPages = pdf.getPageCount();
      }

      if (pdfCountPages > maxPages) {
        const customErrorMessage = t(`Maximum number of {{maxPages}} pages exceeded.`, { maxPages: maxPages });

        const newState = [...event.newState];
        newState[0] = { ...newState[0], validationErrors: [customErrorMessage] };
        setFiles(newState);

        return false;
      }
    }

    return true;
  };

  const onAdd = async (event: UploadOnAddEvent) => {
    if (await isValid(event)) {
      // Get a Pre-signed URL for Amazon S3
      const result = await getSignedURL({ filename: event.newState[0].name, command: 'PutObject' });
      switch (result.type) {
        case 'ok':
          const { signedUrl, filename } = result.value.data;
          setFiles(event.newState);
          setSaveURL(signedUrl);
          setFilename(filename);
          break;
        case 'validation-error':
        case 'error':
          break;
      }
    }
  };

  const onRemove = (event: UploadOnRemoveEvent) => {
    setFiles(initFiles());
  };

  const onProgress = (event: UploadOnProgressEvent) => {
    setFiles(event.newState);
  };

  const onStatusChange = (event: UploadOnStatusChangeEvent) => {
    setFiles(event.newState);
  };

  const theme = useTheme();

  let content;
  if (!initUpload && disabled) {
    content = (
      <span>
        <span style={{ color: '#000000' }}>{t('No file uploaded')}</span>
      </span>
    );
  } else if (initUpload) {
    const { filename_original } = initUpload ?? {};
    const extension = initUpload?.filename.split('.')[1];
    content = (
      <div className="text-black flex">
        <div
          className="cursor-pointer"
          onClick={() => {
            const path = initUpload?.path ?? initUpload?.filename;
            path && filename_original && downloadFileS3(path, filename_original);
          }}
        >
          <span className={`k-icon k-i-file-${extension === 'docx' ? 'doc' : extension}`}></span>
          <span>{filename_original}</span>
        </div>
        {!disabled && (
          <div
            className="ml-5 cursor-pointer"
            onClick={() => {
              onSuccess?.(null);
              openSnackBarAction?.({
                message: t('Deletion of the upload will be effective right after save.'),
                severity: 'warning',
              });
            }}
          >
            <TrashCan />
          </div>
        )}
      </div>
    );
  } else {
    content = (
      <>
        <Upload
          autoUpload={false}
          batch={false}
          multiple={false}
          files={files}
          onAdd={onAdd}
          onRemove={onRemove}
          onProgress={onProgress}
          onStatusChange={onStatusChange}
          withCredentials={false}
          restrictions={{
            allowedExtensions: extensions.length > 0 ? extensions : undefined,
            maxFileSize: maxSize ? maxSize * 1000000 : undefined, // Transform MB -> Bytes
          }}
          saveUrl={(files) => {
            const file = files[0];
            const rawFile = file.getRawFile?.();
            return new Promise(() => {
              if (rawFile) {
                putObjectS3(saveURL, rawFile)
                  .then((response) => {
                    let newFile;
                    if (response.ok) {
                      // Get the MD5 hash from the response:
                      // https://docs.aws.amazon.com/AmazonS3/latest/userguide/checking-object-integrity.html#checking-object-integrity-etag-and-md5
                      // Need to do this from the server side: https://stackoverflow.com/questions/73155218/how-to-fix-this-error-i-m-getting-from-amazon-s3-etagmissing-no-access-to-etag
                      const etag = response.headers.get('ETag');
                      let md5 = '';
                      if (etag) {
                        // ETag is enclosed by double quota so it's something like '"ed098f3b9a687b6f149c35afd3d5cbaa"'
                        md5 = etag.substring(1, etag.length - 1);
                      }
                      onSuccess?.({ id: 0, filename, filename_original: file.name, md5 });
                    } else {
                      newFile = { ...file, status: UploadFileStatus.UploadFailed, progress: 0 };
                      setFiles([newFile]);
                    }
                    return { uid: file.uid };
                  })
                  .catch((reason) => {});
              }
            });
          }}
          removeUrl={(files) => {
            onSuccess?.(null);
            const file = files[0];
            return new Promise(() => {
              return { uid: file.uid };
            });
          }}
        />

        {files.length > 0 &&
          files[0].validationErrors &&
          files[0].validationErrors.length > 0 &&
          files[0].validationErrors.some((error) => error.includes('exceeded')) && (
            <p className="custom-validation-message">{files[0].validationErrors[0]}</p>
          )}
      </>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledUploadWrapper className="w-full fuploaderWrapper">{content}</StyledUploadWrapper>
    </ThemeProvider>
  );
};

export default FileUploaderS3;

const StyledUploadWrapper = styled.div`
  border-radius: 0.25rem;
  border-color: rgba(0, 0, 0, 0.23);
  position: relative;

  .k-dropzone {
    background-color: transparent;
  }

  .k-progressbar {
    display: none;
  }

  .k-actions {
    border-style: dashed;
  }

  .k-button {
    background-color: ${(props) => props.theme.palette.secondary.dark};
    border-radius: 0.25rem;
    border-color: ${(props) => props.theme.palette.text.hint};
  }
  .k-button:hover {
    background-color: ${(props) => props.theme.palette.secondary.dark};
    border-color: ${(props) => props.theme.palette.text.hint};
    opacity: 1;
  }

  .k-upload-selected {
    background-color: #2563eb;
    border-radius: 0.25rem;
    border-color: #2563eb;
  }
  .k-upload-selected:hover {
    background-color: #2563eb;
    border-color: #2563eb;
    opacity: 1;
  }

  .k-i-file-pdf,
  .k-i-file-zip,
  .k-i-file-doc {
    padding-right: 0.4rem;
    color: #000000;
  }
  .text-black {
    .cursor-pointer {
      display: flex;
      align-items: self-start;
    }
  }
  .custom-validation-message {
    font-size: 11px;
    line-height: 1;
    color: var(--red-error);
    text-align: center;
    padding: 8px 0;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .k-file-info {
    text-align: center;
  }
  .k-upload .k-upload-files .k-file-single {
    align-items: start;
  }
  .k-upload-actions {
    margin-top: -4px;
  }
`;
