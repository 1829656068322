import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Keyword } from '../../store/keyword/types';

interface Props extends GridCellProps {
  keywordById: { [key: number]: Keyword };
}

const KeywordsCell: React.FC<Props> = ({ dataItem, keywordById, ...props }) => {
  const keywordIds: number[] = dataItem.keyword_ids;
  let renderParent = false;
  let groupParent = '';
  return (
    <td
      colSpan={props.colSpan}
      className={props.className}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      <div className="my-2">
        {keywordIds.map((keywordId: number, index) => {
          const keyword = keywordById[keywordId];
          let projectTitleContent;

          if (keyword.parent_id) {
            const parent = keywordById[keyword.parent_id];
            renderParent = groupParent == '' || groupParent != parent.name ? true : false;
            groupParent = groupParent == '' || groupParent != parent.name ? parent.name : groupParent;

            projectTitleContent = <p className="font-semibold mt-2">{parent.name}</p>;

            return (
              <div key={index}>
                {renderParent && projectTitleContent}
                <ul className="ml-4">
                  <li className="list-disc">{keyword.name}</li>
                </ul>
              </div>
            );
          }
        })}
      </div>
    </td>
  );
};

export default KeywordsCell;
