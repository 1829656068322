import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DialogContentText from '@material-ui/core/DialogContentText';
import submissionSlice from '../../../store/submission/slice';
import { Submission } from '../../../store/submission/types';
import { InputText } from '../../ui';
import { useTranslation } from 'react-i18next';

interface Props extends SimpleDialogProps {
  submission: Submission;
  deleteSubmissionAction: (submissionId: number) => void;
}

const ChairDeleteSubmissionDialog: React.FC<Props> = ({ submission, deleteSubmissionAction, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const validationString = 'PERMANENTLY DELETE';
  return (
    <SimpleDialog
      {...rest}
      title={`${t('Delete submission')} #${submission.external_id}`}
      handleOk={() => deleteSubmissionAction(submission.id)}
      disableOkButton={!isValid}
    >
      <div>
        <StyledDialogContentText id="alert-dialog-description">
          {t("Are you sure you want to permanently delete this submission? This action can't be undone.")}
        </StyledDialogContentText>
        <StyledDialogContentText id="alert-dialog-description">
          {t('In order to proceed, type')} <b>{validationString}</b>:
        </StyledDialogContentText>
        <InputText onChange={(e) => setIsValid(e.target.value == validationString)} />
      </div>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteSubmissionAction: (submissionId: number) => dispatch(submissionSlice.actions.REMOVE_SUBMISSION(submissionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChairDeleteSubmissionDialog);

const StyledDialogContentText = styled(DialogContentText)`
  font-size: 0.875rem;
`;
