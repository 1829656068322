import React, { useEffect, useState } from 'react';
import { TableFriendlyName } from '../../store/info/types';
import { getSubmissionsRequest, Submission } from '../../store/submission/types';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import {
  selectBidState,
  selectKeywordState,
  selectPaperStatusState,
  selectSubmissionState,
  selectTable,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import submissionSlice from '../../store/submission/slice';
import { connect } from 'react-redux';
import Loading from '../../components/Loading/Loading';
import { PaperStatus } from '../../store/paper-status/types';
import { formatKeywordColumn } from '../../helpers/table';
import { SubmissionBid } from '../../store/bid/types';
import bidSlice from '../../store/bid/slice';
import { GetCustomColumnsData } from '../../store/table/types';
import tableSlice from '../../store/table/slice';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName } from '../../helpers/translations';

interface Props {
  loading: boolean;
  submissionsById: { [key: string]: Submission };
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  getCustomColumnsDataAction: (data: GetCustomColumnsData) => void;
  getBidsSubmissionsAction: () => void;
  submissionStats: { [key: number]: SubmissionBid };
}

const AllSubmissionsBiddingPage: React.FC<Props> = ({
  loading,
  submissionsById,
  getSubmissionsAction,
  keywordById,
  paperStatusById,
  customColumnsData,
  getCustomColumnsDataAction,
  getBidsSubmissionsAction,
  submissionStats,
}) => {
  const { t, i18n } = useTranslation();
  /* Outer table info */
  const friendlyName: TableFriendlyName = 'submissions_bids';

  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName });
    } else if (!customColumnsData[friendlyName]) {
      getCustomColumnsDataAction({ friendlyName });
    }
    if (Object.keys(submissionStats).length == 0) {
      getBidsSubmissionsAction();
    }
  }, []);

  const inputData: unknown[] = [];
  Object.values(submissionsById).forEach((submission, index) => {
    let newRegister = {
      ...submission,
    };

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);

    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    // Add bidding info
    const submissionBid = submissionStats[submission.id] ?? { id: submission.id, positive: 0, negative: 0, bids: 0 };
    newRegister = { ...newRegister, ...submissionBid };

    inputData.push(newRegister);
  });

  return (
    <>
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Submissions bids')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getBidsSubmissionsAction()}
          />
        )}
      </div>
      {dialog}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectBidState(state).loading,
  submissionsById: selectSubmissionState(state).submissionsById,
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  customColumnsData: selectTable(state).customColumnsData,
  submissionStats: selectBidState(state).submissionStats,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  getCustomColumnsDataAction: (data: GetCustomColumnsData) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
  getBidsSubmissionsAction: () => dispatch(bidSlice.actions['GET:BIDS:SUBMISSION']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSubmissionsBiddingPage);
