import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import { MenuProps } from '@progress/kendo-react-layout/dist/npm/menu/MenuProps';
import { Menu, MenuItemLink, MenuItemArrow } from '@progress/kendo-react-layout';
import styled, { ThemeProvider } from 'styled-components';

export const Kmenu = (props: MenuProps): JSX.Element => {
  const theme = useTheme();

  function renderMenuIconIfApplicable(props: any) {
    return props.item.icon ? <span className={`k-icon k-i-${props.item.icon}`} role="presentation" key="0" /> : null;
  }

  const defaultLinkRender = (props: any) => {
    return (
      <ThemeProvider theme={theme}>
        <StyledMenuItemLink url={props.item.url} opened={props.opened}>
          {[renderMenuIconIfApplicable(props), props.item.text, renderArrowIfApplicable(props)]}
        </StyledMenuItemLink>
      </ThemeProvider>
    );
  };
  function renderArrowIfApplicable(props: any) {
    return props.item.items && props.item.items.length > 0 ? (
      <MenuItemArrow itemId={props.itemId} verticalMenu={false} dir={props.dir} key="1" />
    ) : null;
  }

  let linkRender = props.linkRender;
  if (props.linkRender === undefined) {
    linkRender = defaultLinkRender;
  }

  return (
    <ThemeProvider theme={theme}>
      <StyledMenu {...props} linkRender={linkRender} />
    </ThemeProvider>
  );
};

const StyledMenu = styled(Menu)`
  .k-icon {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .k-menu-link {
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledMenuItemLink = styled(MenuItemLink)`
  .k-popup &:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.text.primary};
  }
`;
