import React from 'react';
import { Role } from '../../store/conference/types';
import { Form, FormVisibilityOption } from '../../store/form/types';
import FormRolePermission from '../FormRolePermission/FormRolePermission';
import { formDefinition } from '../FormPermissions/FormPermissions';

interface Props {
  roleById: { [key: string]: Role };
  permissions: { [key: string]: { to_role_id: number | null; visibility: FormVisibilityOption }[] | undefined };
  form: Form;
}

const FormPermissionsPlain: React.FC<Props> = ({ roleById, permissions, form }) => {
  const definition = formDefinition.find((value) => value.formType == form.type);
  if (!definition) throw Error('Form definition not found.');

  const permissionElement: JSX.Element[] = [];

  Object.keys(permissions)
    .map((roleId) => roleById[roleId])
    .map((role) => {
      let el;

      permissions[role.id]?.map((permissionsData, index) => {
        el = <FormRolePermission role={role} roleById={roleById} permissionsData={permissionsData} form={form} />;

        permissionElement.push(el);
      });
    });

  return (
    <div key={`form-${form.id}`} className={`block mb-1`}>
      {permissionElement}
    </div>
  );
};

export default FormPermissionsPlain;
