import React from 'react';
import useTheme from '@material-ui/core/styles/useTheme';
import styled, { ThemeProvider } from 'styled-components';
import { ListBox, ListBoxProps } from '@progress/kendo-react-listbox';
import { nanoid } from '@reduxjs/toolkit';

export const KlistBox = (props: ListBoxProps): JSX.Element => {
  const theme = useTheme();
  React.useEffect(() => {
    window.addEventListener('dbclick', (event) => {
      console.log(event);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div id={nanoid()}>
        <StyledListBox {...props} />
      </div>
    </ThemeProvider>
  );
};

const StyledListBox = styled(ListBox)`
  .k-icon {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .k-selected {
    background-color: ${(props) => props.theme.palette.primary.light};
    color: ${(props) => props.theme.palette.text.primary};
  }
  .k-list-item {
    padding-left: 1em;
    padding-right: 1em;
  }
`;
