import {
  EditAssignmentsForReviewerRequest,
  EditAssignmentsForSubmissionRequest,
  getReviewsResponse,
  ReviewState,
  ValidationStatusDTO,
  viewModeType,
} from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ReviewState = {
  loading: false,
  reviewsById: {},
  reviewerStats: {},
  submissionStats: {},
  viewMode: 'cards',
};

const reviewSlice = createSlice({
  name: 'REVIEW',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_REVIEWS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_REVIEWS:OK']: (state, action: PayloadAction<getReviewsResponse>) => ({
      ...state,
      loading: false,
      reviewsById: action.payload.data.reviewsById,
      reviewerStats: action.payload.data.reviewerStats,
      submissionStats: action.payload.data.submissionStats,
    }),
    ['UPDATE_STATUS']: (state, action: PayloadAction<ValidationStatusDTO>) => ({
      ...state,
      reviewsById: {
        ...state.reviewsById,
        [action.payload.review_id]: {
          ...state.reviewsById[action.payload.review_id],
          validation_status: action.payload.validation_status,
        },
      },
    }),
    ['REVIEW:DELETE']: (state, action: PayloadAction<number>) => {
      return {
        ...state,
      };
    },
    ['ASSIGNMENTS:SUBMISSON:EDIT']: (state, action: PayloadAction<EditAssignmentsForSubmissionRequest>) => {
      return {
        ...state,
      };
    },
    ['ASSIGNMENTS:REVIEWER:EDIT']: (state, action: PayloadAction<EditAssignmentsForReviewerRequest>) => {
      return {
        ...state,
      };
    },
    ['CHANGE_VIEWMODE']: (state, action: PayloadAction<viewModeType>) => {
      return {
        ...state,
        viewMode: action.payload,
      };
    },
  },
});

export default reviewSlice;
