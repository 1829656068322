import React from 'react';
import { Answer, Answerable, AnswerDTO, Form, Question } from '../../store/form/types';
import BaseForm from '../Form/BaseForm';
import { OpenSnackBarDTO } from '../../store/error/types';
import { Tabs, TabsItem } from '../ui';
import { ValidatedField } from '../../store/api.d';
import { Mode } from '../../store/review/types';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import Badge from '../ui/display/Badge/Badge';
import { formPendingQuestions } from '../../helpers/form';
import { getFormName } from '../../helpers/translations';
import { useTranslation } from 'react-i18next';

interface Props {
  data: {
    answerable: Answerable;
    formsData: { form: Form; questions: Question[]; answers: Answer[] }[];
  };
  validationErrors?: ValidatedField[];
  mode: Mode;
  canViewFeedback: boolean;
  formTabValue?: string;
  defaultSubmissionQuestions?: JSX.Element;
  numDefaultQuestions?: number;
  answerQuestionHandler?: (data: AnswerDTO) => void;
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  onFormTabChange?: (tabName: string) => void;
}

const TabForms: React.FC<Props> = ({
  data,
  validationErrors,
  mode,
  canViewFeedback,
  formTabValue,
  defaultSubmissionQuestions,
  numDefaultQuestions,
  answerQuestionHandler,
  openSnackBarAction,
  onFormTabChange,
}) => {
  const { t, i18n } = useTranslation();
  const { formsData, answerable } = data;
  // useState hooks
  // Controled/uncontroled form tab state
  const [internalFormTab, setInternalFormTab] = React.useState<string>(() => {
    return formTabValue ?? (formsData.length > 0 ? formsData[0].form.name : '');
  });

  const isControlled = formTabValue !== undefined;
  const formTab = isControlled ? formTabValue : internalFormTab;

  const theme = useTheme();

  const pendingQuestionsByFormId: { [key: number]: number } = {};

  formsData.forEach(({ form, questions, answers }) => {
    pendingQuestionsByFormId[form.id] = formPendingQuestions(form, questions, answers, answerable);
  });

  const handleTabChange = (tabName: string) => {
    if (isControlled) {
      onFormTabChange?.(tabName);
    } else {
      setInternalFormTab(tabName);
    }
  };

  return (
    <div>
      {formsData.length > 0 && (
        <>
          <ThemeProvider theme={theme}>
            <StyledHeaderTabs className="relative max-w-6xl text-sm header-tabs">
              <Tabs className="flex-grow">
                {formsData.map(({ form }, index) => {
                  const numPending = pendingQuestionsByFormId[form.id];
                  return (
                    <div key={index} className="flex">
                      <TabsItem
                        label={getFormName(form.name, form.is_default, t)}
                        selected={formTab == form.name}
                        onClick={() => {
                          handleTabChange(form.name);
                        }}
                      />
                      {canViewFeedback && numPending > 0 && (
                        <Badge colorType="warning" className="mt-1.5 -ml-2 badge" value={numPending} />
                      )}
                      <hr />
                    </div>
                  );
                })}
              </Tabs>
            </StyledHeaderTabs>
            <div className="flex place-items-center">
              <StyledQuestionFormWrapper className="w-full max-w-6xl text-sm">
                {formsData
                  .filter(({ form }) => form.name === formTab)
                  .map(({ form, questions, answers }, index) => {
                    const answerByQuestionId: { [key: number]: Answer } = {};
                    answers.forEach((answer) => {
                      answerByQuestionId[answer.question_id] = answer;
                    });
                    const numPending = pendingQuestionsByFormId[form.id];

                    return (
                      <div key={index}>
                        {form.is_default && defaultSubmissionQuestions}
                        <BaseForm
                          form={form}
                          questions={questions}
                          answerByQuestionId={answerByQuestionId}
                          answerQuestionHandler={answerQuestionHandler}
                          openSnackBarAction={openSnackBarAction}
                          validationErrors={validationErrors}
                          mode={mode}
                          canViewFeedback={canViewFeedback && numPending > 0}
                          numDefaultQuestions={form.is_default && numDefaultQuestions ? numDefaultQuestions : 0}
                        />
                      </div>
                    );
                  })}
              </StyledQuestionFormWrapper>
            </div>
          </ThemeProvider>
        </>
      )}
    </div>
  );
};

export default TabForms;

const StyledHeaderTabs = styled.header`
  background-color: #fff;

  > div {
    border-bottom: none;

    div.flex {
      position: relative;

      button {
        border-right-color: rgb(206, 203, 203);
        border-right-width: 1px;
        border-top-color: rgb(206, 203, 203);
        border-top-width: 1px;
        background-color: #e5e6ea;
        min-width: 150px;
        border-top-right-radius: 5px;
      }

      button:hover {
        opacity: 0.7;
      }

      button.selected {
        border-top-color: rgb(0, 68, 240);
        border-top-width: 3px;
        color: rgb(0, 68, 240);
        background-color: #f4f5f9;
        border-right-color: rgb(206, 203, 203);
        border-right-width: 1px;
        border-bottom-color: #f4f5f9 !important;
        border-bottom-width: 1px;
      }

      button.selected:hover {
        opacity: 1;
      }

      .badge {
        position: absolute;
        right: 4px;
        top: -17px;
        z-index: 1;
      }

      hr {
        border-color: #f4f5f9;
        border-bottom-width: 1px;
        position: absolute;
        bottom: -1px;
        width: 100%;
      }
    }
  }
`;

const StyledQuestionFormWrapper = styled.fieldset`
  background-color: ${(props) => props.theme.palette.secondary.light};
  padding: 1.5rem;
  margin-bottom: 3rem;
  min-height: 20rem;

  .k-dropdown {
    background-color: #fff;
  }

  .k-i-file-pdf,
  .k-i-file-zip,
  .k-i-file-doc {
    padding-right: 0.4rem;
  }
  .fuploaderWrapper {
    margin-bottom: 1rem;

    .text-black {
      width: 100%;
      max-width: max-content;
      justify-content: space-between;

      .cursor-pointer {
        span {
          word-wrap: anywhere;
        }
      }
    }
  }
  .questionText {
    color: #000;
  }

  .k-dropdown,
  .k-dropdowntree {
    width: 30rem;
    max-width: 69rem !important;
    min-width: 12.4rem !important;
  }
  .select-question,
  .radio-question,
  .multi-select-question {
    > div {
      align-items: start;

      label {
        margin-top: 1px;
        line-height: 1.3;
      }
    }
  }

  .multi-select-question,
  .radio-question {
    > div {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
`;
