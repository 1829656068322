import { EditorUtils } from '@progress/kendo-react-editor';

/*
  From Kendo: https://www.telerik.com/kendo-react-ui/components/editor/images/
*/

export const insertImageFiles = ({
  view,
  files,
  nodeType,
  position,
  attrs = {},
}: {
  view: any;
  files: any;
  nodeType: any;
  position?: { pos: number } | null;
  attrs?: Record<string, any>;
}): void => {
  if (EditorUtils.canInsert(view.state, nodeType)) {
    files.forEach((file: any) => {
      const reader = new FileReader();
      reader.onload = function (e: any) {
        const image = nodeType.createAndFill({
          ...attrs,
          src: e.target.result,
        });
        if (position) {
          view.dispatch(view.state.tr.insert(position.pos, image));
        } else {
          EditorUtils.insertNode(view, image, true);
        }
      };
      reader.readAsDataURL(file);
    });
  }
};
