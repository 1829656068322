import React from 'react';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { Theme } from '@material-ui/core';
import './Select.styles.css';

export type SelectProps = {
  label?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  value?: string;
  defaultItem?: { id?: string; label: string }; // https://www.telerik.com/kendo-react-ui/components/dropdowns/dropdownlist/default-item/
  onChange?: (value: string) => void;
  options?: { id: string; label: string }[];
  dense?: boolean;
  disabled?: boolean;
};

export const Select = ({
  label,
  required,
  value,
  defaultItem,
  onChange,
  options = [],
  dense = false,
  error,
  helperText,
  disabled,
}: SelectProps): JSX.Element => {
  const theme = useTheme();

  const defaultItemStyled = defaultItem
    ? { ...defaultItem, label: <span style={{ color: '#999999' }}>{defaultItem?.label}</span> }
    : undefined;

  return (
    <ThemeProvider theme={theme}>
      <StyledLabel
        className={`font-normal mb-2 text-sm labelSelect`}
        style={{ color: `${error ? theme.palette.error.main : 'inherit'}` }}
      >
        {required ? `${label} *` : label}
      </StyledLabel>

      <div className="dropdown-wrapper">
        <StyledDropDownList
          id="d1"
          textField="label"
          dataItemKey="id"
          data={options}
          value={options.find((x) => x.id == value) ?? defaultItemStyled}
          defaultItem={defaultItem}
          onChange={(event) => event && onChange?.(event.target.value.id)}
          itemRender={itemRender(theme)}
          error={error}
          dense={dense}
          className="selectType m-0"
          disabled={disabled}
        />
        {helperText && <StyledSpan className="helperText mb-4 text-sm">{helperText}</StyledSpan>}
      </div>
    </ThemeProvider>
  );
};

const StyledDropDownList = styled(DropDownList)<{ error?: boolean; dense?: boolean }>`
  ${(props) => (props.dense ? '' : 'margin-top: 0; margin-bottom: 0;')};

  outline-width: unset;
  &:focus {
    outline-width: unset;
  }
  &:active {
    outline-width: unset;
  }

  span.k-dropdown-wrap {
    border: ${(props) =>
      !!props.error ? '2px solid' + props.theme.palette.error.main + ' !important' : '1px solid #afb0b1 !important'};
  }
  .k-dropdown-wrap {
    background-color: unset;
    background-image: unset;
    color: ${(props) => props.theme.palette.text.primary};

    border: ${(props) =>
      !!props.error ? '1px solid' + props.theme.palette.error.main : '1px solid ' + props.theme.palette.secondary.dark};
    border-radius: 0.25rem;
  }
  .k-dropdown-wrap:hover {
    background-image: none;
    background-color: unset;

    border: ${(props) =>
      !!props.error ? '2px solid' + props.theme.palette.error.main : '1px solid ' + props.theme.palette.text.primary};
  }

  .k-dropdown-wrap:focus {
    background-image: none;
    background-color: unset;
    border: ${(props) =>
      !!props.error ? '2px solid' + props.theme.palette.error.main : '1px solid ' + props.theme.palette.primary.main};
  }

  .k-state-focused {
    border: ${(props) =>
      !!props.error ? '2px solid' + props.theme.palette.error.main : '1px solid ' + props.theme.palette.primary.main};
    box-shadow: unset;
  }

  .k-icon {
    color: ${(props) => props.theme.palette.text.primary};
  }
  .k-input {
    padding: ${(props) => (props.dense ? '0em 0.5em' : '1.39em 0.5em')};
  }
`;

const StyledSpan = styled.span`
  color: ${(props) => props.theme.palette.error.main};
  display: block;
`;

const itemRender = (theme: Theme) => (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
  let myStyle = {};
  if (li.props.className.includes('k-state-selected')) {
    myStyle = { backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.main };
  }

  return (
    <StyledLi {...li.props} style={myStyle}>
      <span>{li.props.children}</span>
    </StyledLi>
  );
};

const StyledLi: any = styled.li`
  background-color: ${(props) => props.theme.palette.background.default};
  padding: 2px 5px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.primary};
  &.k-item:hover {
    background-color: ${(props) => props.theme.palette.primary.light};
  }
`;

const StyledLabel = styled.div`
  min-width: 3rem;
`;
