import * as React from 'react';

import { MultiSelect, MultiSelectChangeEvent, MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { intersection } from '../../helpers/set';
import { filterBy } from '@progress/kendo-data-query';

interface MultiSelectFilterCellProps extends GridFilterCellProps {
  data: Record<string, unknown>[];
}

export const myOperator = (value: string, pattern: { id: string | null; label: string }[]) => {
  const valueSet = new Set((value ?? '').split(';'));
  const patterSet = new Set(pattern.map((value) => value.id));

  return intersection(valueSet, patterSet).size > 0;
};

export const MultiSelectFilterCell = (props: MultiSelectFilterCellProps): JSX.Element => {
  const initOptions = () => {
    const values = new Set(
      props.data
        .map((item) => item[props.field ?? ''] as string)
        .filter((value: string) => !!value)
        .map((value: string) => value.split(';'))
        .flat(),
    );
    return Array.from(values).map((value) => ({ id: value, label: value }));
  };

  const [data, setData] = React.useState<{ id: string | null; label: string }[]>(initOptions);

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    if (event.filter.value) {
      setData(filterBy(data, event.filter));
    } else {
      // Reset options to its initial state
      setData(initOptions());
    }
  };

  const hasValue = (value: any[]) => value.length > 0;

  const onChange = (event: MultiSelectChangeEvent) => {
    const hasVal = hasValue(event.target.value);
    props.onChange({
      value: hasVal ? event.target.value : '',
      operator: hasVal ? myOperator : '',
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };

  const value = props.value || [];
  return (
    <div className="k-filtercell">
      <MultiSelect
        data={data}
        filterable={true}
        onFilterChange={filterChange}
        textField="label"
        dataItemKey="id"
        onChange={onChange}
        value={value}
        // placeholder="Please select..."
      />
      {/*<Button title="Clear" disabled={!hasValue(value)} onClick={onClearButtonClick} icon="filter-clear" />*/}
    </div>
  );
};
