import React, { useEffect } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button } from '../../components/ui';
import InvitationStepper from '../../components/InvitationStepper/InvitationStepper';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { selectCurrentUser, selectInfo, selectTable, selectTrackRoles } from '../../store/selectors';
import { ChairInfo, Info, InfoGetDTO, TableFriendlyName } from '../../store/info/types';
import infoSlice from '../../store/info/slice';
import { Role } from '../../store/conference/types';
import { getUserOffset, transform } from '../../helpers/timezone';
import emailSlice from '../../store/email/slice';
import errorSlice from '../../store/error/slice';
import Loading from '../../components/Loading/Loading';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import { useTranslation } from 'react-i18next';
import { getInvitationStatusName } from '../../helpers/translations';

interface Props {
  loading: boolean;
  info: Info;
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  timezone: string | null;
  removeEmailAction: () => void;
  removePreviewAction: () => void;
  clearValidationErrorsAction: () => void;
  selectedRowIds: { [key in TableFriendlyName]: number[] };
}

const RecruitmentPage: React.FC<Props> = ({
  loading,
  info,
  roleById,
  getInfoAction,
  timezone,
  removeEmailAction,
  removePreviewAction,
  clearValidationErrorsAction,
  selectedRowIds,
}) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'invitations';
  const [visible, setVisible] = React.useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(info[friendlyName].byId).length === 0) {
      getInfoAction({ friendlyName });
    }
  }, []);

  const toggleDialog = () => {
    setVisible(!visible);
  };

  if (loading) return <Loading />;

  const chairInfo = info as ChairInfo;
  const { byId } = chairInfo[friendlyName];

  const utcOffset = getUserOffset(timezone);

  const inputData: Record<string, unknown>[] = [];
  Object.values(byId).forEach((register) => {
    const newRegister = { ...register };
    // @ts-ignore
    newRegister.role = roleById[newRegister.role_id].description;

    // @ts-ignore
    newRegister.expiration_date = transform(new Date(register.expiration_date), utcOffset); //new Date(dateOffset.toDateString());

    // @ts-ignore
    newRegister.status = getInvitationStatusName(newRegister.status, t);

    inputData.push(newRegister);
  });

  return (
    <StyledContentWrapper>
      <div className="flex items-center mb-8">
        <h2 className="text-xl font-bold text-gray-700 mr-5">{t('Recruitment')}</h2>

        <Button className="w-32 place-self-start" onClick={toggleDialog}>
          <span className="px-14">{t('New invitation')}</span>
        </Button>
      </div>

      {visible && (
        <SimpleDialog handleClose={() => {}} hideCancel={true}>
          <StyledTitle>{t('New Invitation')}</StyledTitle>
          <InvitationStepper
            onClose={(formState) => {
              toggleDialog();
              removePreviewAction();
              removeEmailAction();
              clearValidationErrorsAction();
              if (formState == 'SENDING') {
                getInfoAction({ friendlyName }); // Refresh data
              }
            }}
          />
        </SimpleDialog>
      )}

      <div className="mb-2 h-full">
        <MyGridRedux
          friendlyName={friendlyName}
          inputData={inputData}
          getInfoAction={() => getInfoAction({ friendlyName })}
        />
      </div>
    </StyledContentWrapper>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading,
  info: selectInfo(state).info,
  roleById: selectTrackRoles(state),
  timezone: selectCurrentUser(state).person.timezone,
  selectedRowIds: selectTable(state).selectedRowIds,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  removeEmailAction: () => dispatch(emailSlice.actions['REMOVE:EMAIL']()),
  removePreviewAction: () => dispatch(emailSlice.actions['REMOVE:PREVIEW']()),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
});

const StyledContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTitle = styled.h2`
  border-bottom: 2px dotted #e3e5e6;
  padding: 0;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.0075em;
`;

export default connect(mapStateToProps, mapDispatchToProps)(RecruitmentPage);
