import React from 'react';
import { Announcement, Conference } from '../../store/conference/types';
import styled from 'styled-components';
import FlexSidePanel from '../ui/utils/FlexSidePanel/FlexSidePanel';
import HtmlTextEditor from '../ui/inputs/HtmlTextEditor/HtmlTextEditor';

interface Props {
  conference: Conference;
  announcement: Announcement;
}

const AnnouncementSidePanel: React.FC<Props> = ({ conference, announcement }) => {
  return (
    <FlexSidePanel open={true} panelWidthMin="1.5%" panelWidthMax="100%">
      {
        //@ts-ignore
        conference.logo_url && (
          <div className="logo w-40 h-auto mb-4 p-0">
            <img
              src={
                //@ts-ignore
                conference.logo_url
              }
              alt="Conference logo"
              className="w-full max-w-full"
            />
          </div>
        )
      }
      <StyledHtmlTextEditor value={announcement.html} editable={false} className="infoArea h-full" />
    </FlexSidePanel>
  );
};

export default AnnouncementSidePanel;

const StyledHtmlTextEditor = styled(HtmlTextEditor)`
  > div {
    flex-grow: 1;
    display: flex;
    height: 100%;
  }
`;
