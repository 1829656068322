import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Loading from '../components/Loading/Loading';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import conferenceSlice from '../store/conference/slice';

interface Props {
  unselectTrackAction: () => void;
}

const ExitConferencePage: React.FC<Props> = ({ unselectTrackAction }) => {
  useEffect(() => {
    unselectTrackAction();
  }, []);

  return <Loading />;
};

const mapStateToProps = (state: AppState) => ({});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  unselectTrackAction: () => dispatch(conferenceSlice.actions.UNSELECT_TRACK()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExitConferencePage);
