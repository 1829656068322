import React from 'react';
import { OptionsData } from '../FormBuilder/FormBuilder';
import SelectOptions from './SelectOptions';

interface Props {
  editOption: (index: number, option: OptionsData) => void;
  option: OptionsData[];
  addOption: (index: number, option: OptionsData) => void;
  deleteOptionHandler: (OptionIndex: number, index: number | undefined) => void;
  index: number;
  withNumericalValue?: boolean;
  disabled?: boolean;
}

const InputMultiSelectComponent: React.FC<Props> = ({
  editOption,
  option,
  index,
  addOption,
  deleteOptionHandler,
  withNumericalValue,
  disabled,
}) => {
  return (
    <div className="flex flex-col items-start py-2 multiSelect-wrapper">
      <SelectOptions
        editOption={editOption}
        option={option}
        addOption={addOption}
        deleteOptionHandler={deleteOptionHandler}
        index={index}
        withNumericalValue={withNumericalValue}
        disabled={disabled}
      />
    </div>
  );
};

export default InputMultiSelectComponent;
