import React, { useState } from 'react';
import MTimeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import { TimelineOppositeContent } from '@material-ui/lab';
import DateTimeWithOffset from '../DateTimeWithOffset/DateTimeWithOffset';
import { Role } from '../../store/conference/types';
import { AppState } from '../../store/state';
import { selectPhaseState, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { Select } from '../ui';
import styled from 'styled-components';
import { compareEventsFn } from '../Phase';
import { FormVisibilityOption } from '../../store/form/types';

interface FormVisibilityItem {
  date: string;
  phaseName: string;
  eventName: string;
  permission: string;
}

interface Props {
  formId: number;
  roleById: { [key: string]: Role };
  phaseById: { [key: number]: Phase };
  eventById: { [key: number]: PhaseEvent };
}

const FormVisibilityTimeline: React.FC<Props> = ({ formId, roleById, phaseById, eventById }) => {
  const roleOptions = Object.values(roleById).filter((role) => role.type !== 'chair');
  const [roleId, setRoleId] = useState<number | undefined>(roleOptions.length > 0 ? roleOptions[0].id : undefined);

  const visibilityToVerbose = (visibility: FormVisibilityOption): string => {
    switch (visibility) {
      case 'hidden':
        return 'Hidden';
      case 'read':
        return 'Visible';
      case 'write':
        return 'Editable';
    }
  };

  const items: FormVisibilityItem[] = [];
  Object.values(eventById)
    .sort(compareEventsFn)
    .forEach((event) => {
      event.actions
        .filter((action) => action.type == 'form_permission_change' && action.body.params.form_id == formId)
        .forEach((action) => {
          const params = action.body.params as {
            form_id: number;
            permissions: { [key: number]: { visibility: FormVisibilityOption } };
          };
          items.push({
            date: event.starts_at ?? '',
            phaseName: phaseById[event.phase_id].name,
            eventName: event.name,
            permission: roleId ? visibilityToVerbose(params.permissions[roleId].visibility) : '',
          });
        });
    });

  return (
    <div>
      <h2 className="font-bold text-xl mb-8">Visibility summary</h2>
      <StyledSelectWrapper className="flex mb-3 justify-between items-center">
        <Select
          label="Role"
          value={roleId?.toString()}
          onChange={(value) => setRoleId(parseInt(value))}
          options={roleOptions.map((role) => ({ id: role.id.toString(), label: role.description }))}
        />
      </StyledSelectWrapper>
      <MTimeline>
        {items.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineOppositeContent>
              <DateTimeWithOffset date={item.date} showTime={true} />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <p>Phase {item.phaseName}</p>
              <p>Event {item.eventName}</p>
              <p>{item.permission}</p>
            </TimelineContent>
          </TimelineItem>
        ))}
      </MTimeline>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  roleById: selectTrackRoles(state),
  phaseById: selectPhaseState(state).byId,
  eventById: selectPhaseState(state).eventById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormVisibilityTimeline);

const StyledSelectWrapper = styled.div`
  .labelSelect {
    width: 162px;
  }
  .selectType {
    width: 100%;

    .k-dropdown-wrap {
      border-radius: 0.25rem;
      border-color: #afb0b1;
    }
  }
`;
