import React from 'react';
import { Stepper } from '@progress/kendo-react-layout';
import styled from 'styled-components';

interface Props {
  step: number;
  items: {
    label?: string;
    icon?: string;
    optional?: boolean;
  }[];
  className: string;
}

const MyStepper: React.FC<Props> = ({ step, items, className }) => {
  return (
    <StyledStepperWrapper className={className}>
      <Stepper value={step} items={items} className="custom-stepper" />
    </StyledStepperWrapper>
  );
};

export const StyledStepperWrapper = styled.div`
  .k-step-current,
  .k-step-current:hover,
  .k-step-done,
  .k-step-done:hover {
    .k-step-indicator {
      border-color: ${(props) => props.theme.palette.primary.main};
      background-color: ${(props) => props.theme.palette.primary.main};
    }
  }
  .k-step-indicator-text {
    font-weight: 600;
    color: #e3e2e2;
  }
  // https://www.telerik.com/kendo-react-ui/components/layout/stepper/custom_rendering/
  .custom-stepper .k-progressbar .k-selected {
    background-color: ${(props) => props.theme.palette.primary.main};
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 0;
  }
`;

export default MyStepper;
