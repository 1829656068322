import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="13" viewBox="0 0 17 13">
      <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="1.4" transform="translate(-30 -98)">
          <g transform="translate(31 99)">
            <path d="M0 5.5h15M0 .5h15M7 10.5h8"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
