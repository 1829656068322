import SimpleDialog from '../SimpleDialog/SimpleDialog';
import React, { useEffect } from 'react';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  selectBidState,
  selectKeywordState,
  selectLabelState,
  selectReviewState,
  selectTable,
  selectTrackRoles,
} from '../../../store/selectors';
import {
  EditAssignmentsForSubmissionRequest,
  Review,
  AssignmentsAdditionalInfo,
  ReviewStats,
} from '../../../store/review/types';
import { Role } from '../../../store/conference/types';
import { Info, InfoGetDTO, PersonTableRow, TableFriendlyName } from '../../../store/info/types';
import { GridCellProps, GridFilterCellProps } from '@progress/kendo-react-grid';
import AssignedCell from '../../AssignedCell/AssignedCell';
import reviewSlice from '../../../store/review/slice';
import { getAssignmentsSubmissions } from '../../../store/review/sagas';
import Loading from '../../Loading/Loading';
import { BidOption } from '../../../store/bid/types';
import { Label } from '../../../store/label/types';
import { Submission } from '../../../store/submission/types';
import KeywordsCell from '../../KeywordsCell/KeywordsCell';
import { TableSettings } from '../../MyGridRedux/types';
import { formatKeywordColumn } from '../../../helpers/table';
import infoSlice from '../../../store/info/slice';
import MyGridCore from '../../MyGridCore/MyGridCore';
import { MultiSelectFilterCell } from '../../MultiSelectFilterCell/MultiSelectFilterCell';
import LabelsCell from '../../MyGridCore/LabelsCell';
import { DEFAULT_FONT_SIZE } from '../../MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';

interface Props {
  submission: Submission;
  handleClose: () => void;
  reviewsById: { [key: string]: Review };
  reviewerStats: { [key: string]: ReviewStats };
  roleById: { [key: string]: Role };
  info: Info;
  editAssignmentsAction: (data: EditAssignmentsForSubmissionRequest) => void;
  bidOptionsById: { [key: number]: BidOption };
  labelsById: { [key: number]: Label };
  labelables: { [key: string]: { [key: number]: number[] } };
  tablesSettings: { [key: string]: TableSettings };
  keywordById: { [key: number]: Keyword };
  getInfoAction: (data: InfoGetDTO) => void;
  outerFriendlyName: TableFriendlyName;
}

const EditAssignmentSubmissionDialog: React.FC<Props> = ({
  submission,
  handleClose,
  reviewsById,
  reviewerStats,
  roleById,
  info,
  editAssignmentsAction,
  bidOptionsById,
  labelsById,
  labelables,
  tablesSettings,
  keywordById,
  getInfoAction,
  outerFriendlyName,
}) => {
  const { t, i18n } = useTranslation();
  /* Table info */
  const friendlyName: TableFriendlyName = 'users';
  const tableSettings = tablesSettings[friendlyName];
  const LABELABLE_ITEM_KEY = tablesSettings[friendlyName].gridSettings.LABELABLE_ITEM_KEY;
  const DATA_ITEM_KEY = tableSettings.gridSettings.DATA_ITEM_KEY;
  const modelClass = tableSettings.model;

  const [additionalInfo, setAdditionalInfo] = React.useState<AssignmentsAdditionalInfo[]>([]);

  const submissionId = submission.id;

  const initSelection = () => {
    const reviewsByPersonAndRole: { [key: string]: { [key: string]: boolean } } = {};
    Object.values(reviewsById)
      .filter((review) => review.submission_id === submissionId)
      .forEach((review) => {
        const { person_id, role_id } = review;
        if (!(person_id in reviewsByPersonAndRole)) {
          reviewsByPersonAndRole[person_id] = {};
        }
        reviewsByPersonAndRole[person_id][role_id] = true;
      });
    return reviewsByPersonAndRole;
  };
  const [selection, setSelection] = React.useState<{ [key: string]: { [key: string]: boolean } }>(initSelection);

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
    getAssignmentsSubmissions({ submission_id: submissionId }).then((result) => {
      if (result.type === 'ok') {
        setAdditionalInfo(result.value.data);
      }
    });
  }, []);

  const isLoading = !additionalInfo.length || Object.keys(info['users'].byId).length === 0;

  /* Build input data */
  let inputData: Record<string, unknown>[] = [];
  const usersById = Object.values(info['users'].byId) as PersonTableRow[];
  Object.values(usersById).forEach((person) => {
    person.role_ids
      .map((roleId) => roleById[roleId])
      .forEach((role, index) => {
        if (role.type !== 'reviewer') return;
        const personRoleId = person.person_role_ids[index];
        const additional = additionalInfo.find((value) => value.person_role_id == personRoleId);

        // Get labels
        // @ts-ignore
        const label_ids = labelables[modelClass][person[LABELABLE_ITEM_KEY]] ?? [];
        const labels = label_ids.map((id) => labelsById[id].label).join(';');

        const newRegister = {
          full_name: person.full_name,
          email: person.email,
          role: role.description,
          assigned: person.id in selection && role.id in selection[person.id] && selection[person.id][role.id],
          person_id: person.id,
          role_id: role.id,
          person_role_id: personRoleId,
          keyword_ids: person.keyword_ids,
          affinity: additional?.affinity,
          bid: additional?.bid_option_id ? bidOptionsById[additional.bid_option_id].name : undefined,
          conflict: additional?.conflict,
          total_reviews: personRoleId in reviewerStats ? reviewerStats[personRoleId].total_reviews : 0,
          label_ids,
          labels,
        };

        formatKeywordColumn(newRegister, keywordById);

        inputData.push(newRegister);
      });
  });
  if (outerFriendlyName == 'submissions_bids') {
    inputData = inputData.filter((record: any) => !!record.bid);
  }

  /* Decide which columns to show */
  const extraGridColumns =
    outerFriendlyName == 'papers_to_reviews'
      ? [
          { field: 'conflict', title: t('Conflict'), filter: 'boolean' },
          { field: 'total_reviews', title: `# ${t('Assignments')}` },
          {
            field: 'assigned',
            title: t('Assigned'),
            filter: 'boolean',
            cell: (props: GridCellProps) => (
              <AssignedCell
                {...props}
                onChangeHandler={() =>
                  setSelection({
                    ...selection,
                    [props.dataItem.person_id]: {
                      ...selection[props.dataItem.person_id],
                      [props.dataItem.role_id]: !props.dataItem.assigned,
                    },
                  })
                }
              />
            ),
          },
        ]
      : [];

  let gridColumnProps: any[] = [
    { field: 'email', title: t('Email'), locked: true },
    { field: 'full_name', title: t('Name') },
    {
      field: 'role',
      title: t('Role'),
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
    {
      field: 'keywords',
      title: t('Keywords'),
      cell: (props: GridCellProps) => <KeywordsCell {...props} keywordById={keywordById} />,
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
    {
      field: 'main_area',
      title: t('Main area'),
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
    {
      field: 'bid',
      title: t('Bid'),
      filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
    },
  ];
  gridColumnProps = gridColumnProps.concat(extraGridColumns);
  gridColumnProps.push({
    field: 'labels',
    title: t('Labels'),
    cell: (props: GridCellProps) => (
      <LabelsCell
        {...props}
        labelsById={labelsById}
        tableFriendlyName={friendlyName}
        labelableIdKey={LABELABLE_ITEM_KEY}
      />
    ),
    filterCell: (props: GridFilterCellProps) => <MultiSelectFilterCell {...props} data={inputData} />,
  });

  return (
    <SimpleDialog
      title={outerFriendlyName == 'papers_to_reviews' ? t('Edit assignments') : t('View bids')}
      open={true}
      handleClose={handleClose}
      handleOk={
        outerFriendlyName == 'papers_to_reviews'
          ? () => editAssignmentsAction({ values: selection, submission_id: submissionId })
          : undefined
      }
    >
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="text-sm mb-6">
            {t('Submission')} #{submission.external_id} : <strong>{submission.title}</strong>
          </div>
          <MyGridCore
            allGridData={inputData}
            gridSettings={{
              gridColumnProps: gridColumnProps,
              arrayFields: [],
              advancedFilter: false,
              DATA_ITEM_KEY: DATA_ITEM_KEY,
              SELECTED_FIELD: '',
              LABELABLE_ITEM_KEY: LABELABLE_ITEM_KEY,
              fontSize: DEFAULT_FONT_SIZE,
              dataState: {},
            }}
            selectedState={{}}
            dateFormat={null}
            expandedState={{}}
            selectable={false}
            showActionsColumn={false}
          />
        </>
      )}
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  info: state.info.info,
  reviewsById: selectReviewState(state).reviewsById,
  reviewerStats: selectReviewState(state).reviewerStats ?? {},
  roleById: selectTrackRoles(state),
  bidOptionsById: selectBidState(state).bidOptions.byId,
  labelsById: selectLabelState(state).labelsById,
  labelables: selectLabelState(state).labelables,
  tablesSettings: selectTable(state).tablesSettings,
  keywordById: selectKeywordState(state).keywordById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editAssignmentsAction: (data: EditAssignmentsForSubmissionRequest) =>
    dispatch(reviewSlice.actions['ASSIGNMENTS:SUBMISSON:EDIT'](data)),
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
});

const StyledDialogContent = styled.div`
  flex-direction: column;
  display: flex;

  .MuiInputBase-input {
    width: 25rem;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(EditAssignmentSubmissionDialog);
