import React from 'react';

interface IconProps {
  stroke?: string;
}
function Icon({ stroke = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 18">
      <path
        id="Trazado_907"
        data-name="Trazado 907"
        d="M40,720a3,3,0,0,1,3-3H61a3,3,0,0,1,3,3v12a3,3,0,0,1-3,3H43a3,3,0,0,1-3-3Zm3-1.5a1.5,1.5,0,0,0-1.5,1.5v.325l10.5,6.3,10.5-6.3V720a1.5,1.5,0,0,0-1.5-1.5Zm19.5,3.575-7.062,4.237,7.062,4.346Zm-.051,10.313-8.461-5.207L52,728.375l-1.989-1.194-8.461,5.207A1.5,1.5,0,0,0,43,733.5H61A1.5,1.5,0,0,0,62.449,732.388ZM41.5,730.658l7.062-4.346L41.5,722.075Z"
        transform="translate(-40 -717)"
        fill={stroke}
      />
    </svg>
  );
}

export default Icon;
