import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import tw from 'twin.macro';
import styled from 'styled-components';
import { Button, Checkbox, Link } from '../ui';
import { InputText } from '../ui';
import { ReactComponent as GoogleIcon } from '../../images/pages/login/google.svg';
import { ReactComponent as GitHubIcon } from '../../images/pages/login/github.svg';
import { ReactComponent as OrcidIcon } from '../../images/pages/login/orcid.svg';
import { InputValidationError } from '../types';
import { useTranslation } from 'react-i18next';

const baseURL = process.env.REACT_APP_API_URL;

export interface SignInForm {
  email: string;
  password: string;
}
export interface SignInProps {
  validation_error?: {
    email?: string;
    password?: string;
  };
  sign_in_handler: (form: SignInForm) => void;
  on_change_handler: () => void;
  forgot_password_handler?: () => void;
  signup_handler?: () => void;
}

const SignIn: React.FC<SignInProps> = (props) => {
  const { handleSubmit, errors, control } = useForm<SignInForm>();
  const { t, i18n } = useTranslation();

  let passwordProps: InputValidationError = {};
  let emailProps: InputValidationError = {};

  const frontend_validation_rules = {
    email: {
      required: t('Enter your email address'),
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        message: t('Invalid email address'),
      },
    },
    password: { required: t('Enter your password') },
  };
  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.password) {
      passwordProps = {
        ...passwordProps,
        error: true,
        helperText: errors.password.message,
      };
    }
    if (errors.email) {
      emailProps = {
        ...emailProps,
        error: true,
        helperText: errors.email.message,
      };
    }
  } else {
    // Backend Validation
    if (props.validation_error) {
      if (props.validation_error.email) {
        emailProps = {
          ...emailProps,
          error: true,
          helperText: props.validation_error.email,
        };
      }
      if (props.validation_error.password) {
        passwordProps = {
          ...passwordProps,
          error: true,
          helperText: props.validation_error.password,
        };
      }
    }
  }

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">{t('Log into your account')}</h2>
      <form onSubmit={handleSubmit(props.sign_in_handler)} className="flex flex-col">
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.email}
          render={({ onChange, value }) => (
            <InputText
              label={t('E-mail')}
              autoFocus
              onChange={onChange}
              value={value}
              className="mb-7"
              error={emailProps.error}
              variant="outlined"
              helperText={emailProps.helperText}
            />
          )}
        />

        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.password}
          render={({ onChange, value }) => (
            <InputText
              type="password"
              label={t('Password')}
              onChange={onChange}
              value={value}
              className="mb-10"
              error={passwordProps.error}
              variant="outlined"
              helperText={passwordProps.helperText}
            />
          )}
        />

        <Button type="submit" className="mb-5 bg-blue-700">
          <span className="text-white">{t('Login')}</span>
        </Button>
      </form>
      <div className="flex justify-between mb-5">
        <Checkbox label={t('Keep me signed in')} />
        <Link onClick={props.forgot_password_handler} className="font-semibold text-xs cursor-pointer">
          {t('Forgot password?')}
        </Link>
      </div>
      <div className="mb-5">
        <Link onClick={props.signup_handler} className="font-semibold text-xs cursor-pointer">
          {t("Don't have an account? Sign up")}
        </Link>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center mb-8">
          <div className="border border-gray-200 flex-grow h-0">&nbsp;</div>
          <div className="flex-shrink text-gray-300 font-bold text-lg mx-2">{t('or')}</div>
          <div className="border border-gray-200 flex-grow h-0">&nbsp;</div>
        </div>
        <StyledSocialButton
          onClick={() => {
            window.location.assign(baseURL + '/oauth/redirect/google');
          }}
        >
          <GoogleIcon className="-icon" />
          <span>{t('Sign in with')} Google</span>
        </StyledSocialButton>
        <StyledSocialButton
          onClick={() => {
            window.location.assign(baseURL + '/oauth/redirect/github');
          }}
        >
          <GitHubIcon className="-icon" />
          <span>{t('Sign in with')} GitHub</span>
        </StyledSocialButton>
        <StyledSocialButton
          onClick={() => {
            window.location.assign(baseURL + '/oauth/redirect/orcid');
          }}
        >
          <OrcidIcon className="-icon" />
          <span>{t('Sign in with')} ORCID</span>
        </StyledSocialButton>
      </div>
    </div>
  );
};

const StyledSocialButton = styled.button`
  ${tw`flex items-center mb-2 border border-gray-100 rounded shadow-md`}
  .-icon:first-child {
    ${tw`my-3.5 w-20`}
  }
  span {
    ${tw`border-l px-5 py-3.5`}
  }
`;

export default SignIn;
