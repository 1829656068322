import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPaperStatusOk, GetPaperStatusRoleResponse, PaperStatus, PaperStatusState, PaperStatusDTO } from './types';

const initialState: PaperStatusState = {
  loading: false,
  paperStatusRole: {},
  paperStatusById: {},
};

const paperStatusSlice = createSlice({
  name: 'PAPER-STATUS',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_PAPER_STATUS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_PAPER_STATUS:OK']: (state, action: PayloadAction<GetPaperStatusOk>) => ({
      ...state,
      loading: false,
      paperStatusById: action.payload.data,
    }),
    ['POST:PAPER_STATUS']: (state, action: PayloadAction<PaperStatusDTO>) => ({
      ...state,
      loading: true,
    }),
    ['POST:PAPER_STATUS:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['POST:PAPER_STATUS:OK']: (state, action: PayloadAction<PaperStatus>) => ({
      ...state,
      loading: false,
      paperStatusById: {
        ...state.paperStatusById,
        [action.payload.id]: action.payload,
      },
    }),
    ['DELETE:PAPER_STATUS']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
    ['DELETE:PAPER_STATUS:OK']: (state, action: PayloadAction<number>) => {
      const { [action.payload]: _, ...rest } = state.paperStatusById;
      return {
        ...state,
        loading: false,
        paperStatusById: rest,
      };
    },
    ['DELETE:PAPER_STATUS:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['PUT:PAPER_STATUS']: (state, action: PayloadAction<PaperStatusDTO>) => ({
      ...state,
      loading: true,
    }),
    ['PUT:PAPER_STATUS:OK']: (state, action: PayloadAction<PaperStatus>) => ({
      ...state,
      loading: false,
      paperStatusById: {
        ...state.paperStatusById,
        [action.payload.id]: action.payload,
      },
    }),
    ['PUT:PAPER_STATUS:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['GET:PAPER_STATUS_ROLE']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET:PAPER_STATUS_ROLE:OK']: (state, action: PayloadAction<GetPaperStatusRoleResponse>) => ({
      ...state,
      loading: false,
      paperStatusRole: action.payload.data,
    }),
  },
});

export default paperStatusSlice;
