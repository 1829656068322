import { all, put } from '@redux-saga/core/effects';
import authSaga from './auth/sagas';
import conferenceSaga from './conference/sagas';
import dashboardSaga from './dashboard/sagas';
import infoSaga from './info/sagas';
import { _token } from './local-storage';
import permissionSaga from './permission/sagas';
import { handleLoginOk } from './auth/commons';
import tableSaga from './table/sagas';
import userSaga from './user/sagas';
import submissionSaga from './submission/sagas';
import keywordSaga from './keyword/sagas';
import formSaga from './form/sagas';
import reviewSaga from './review/sagas';
import labelSaga from './label/sagas';
import screenSaga from './screen/sagas';
import emailSaga from './email/sagas';
import bidSaga from './bid/sagas';
import paperStatusSaga from './paper-status/sagas';
import phaseSaga from './phase/sagas';
import recruitmentSaga from './recruitment/sagas';
import authSlice from './auth/slice';

export const baseURL = process.env.REACT_APP_API_URL;

function* initialSaga() {
  if (_token) {
    yield put(authSlice.actions.LOADING());
    yield handleLoginOk(_token);
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([
    initialSaga(), // Captures @@INIT event
    ...authSaga,
    ...userSaga,
    ...dashboardSaga,
    ...tableSaga,
    ...infoSaga,
    ...conferenceSaga,
    ...permissionSaga,
    ...submissionSaga,
    ...keywordSaga,
    ...formSaga,
    ...reviewSaga,
    ...labelSaga,
    ...screenSaga,
    ...emailSaga,
    ...bidSaga,
    ...paperStatusSaga,
    ...phaseSaga,
    ...recruitmentSaga,
  ]);
}
