/**
 * Parses positive number from given string. If number can't be parsed or is negative, returns null.
 * @param value
 */
export const parsePositiveNumber = (value: string): number | null => {
  const parsed: number | null = parseInt(value);
  return isNaN(parsed) || parsed <= 0 ? null : parsed;
};

export const parseNumber = (value: string, decimals: number): number | null => {
  const parsed = decimals > 0 ? parseFloat(parseFloat(value).toFixed(decimals)) : parseInt(value);
  return isNaN(parsed) ? null : parsed;
};
