import { put, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import permissionSlice from './slice';
import { GetPermissionsDTO, getPermissionsResponse, UpdatePermissionRoleDTO, UpdatePermissionRoleOK } from './types';
import { baseURL } from '../root-saga';

const getPermissionsAPICall = async (roleId: number): Promise<Result<getPermissionsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<getPermissionsResponse>(`${baseURL}/api/permissions?role_id=${roleId}`, init);
};

function* getPermissionsSaga(action: PayloadAction<GetPermissionsDTO>) {
  const result = (yield getPermissionsAPICall(action.payload.roleId)) as Await<
    ReturnType<typeof getPermissionsAPICall>
  >;

  switch (result.type) {
    case 'ok':
      yield put(permissionSlice.actions['GET_PERMISSIONS:OK'](result.value));
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [takeLatest(permissionSlice.actions['GET_PERMISSIONS'].type, getPermissionsSaga)];
