import React from 'react';
import cn from 'classnames';

export const context = React.createContext({
  open: false,
});

export type MenuProps = {
  open?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const Menu = ({ open = false, className, children }: MenuProps): JSX.Element => {
  return (
    <context.Provider value={{ open }}>
      <div className={cn(className, 'inline-flex flex-col')}>{children}</div>
    </context.Provider>
  );
};
