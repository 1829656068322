import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { SelectRadioButton } from '../../ui';
import { PaperStatus } from '../../../store/paper-status/types';
import { AppState } from '../../../store/state';
import { selectPaperStatusState } from '../../../store/selectors';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import { updatePaperStatusBulkDTO } from '../../../store/info/types';
import submissionSlice from '../../../store/submission/slice';
import { useTranslation } from 'react-i18next';

interface Props extends SimpleDialogProps {
  initialOption?: string;
  submissionIds: number[];
  paperStatusById: { [key: number]: PaperStatus };
  updatePaperStatusBulkAction: (data: updatePaperStatusBulkDTO) => void;
}

const PaperStatusChangeDialog: React.FC<Props> = ({
  initialOption,
  submissionIds,
  paperStatusById,
  updatePaperStatusBulkAction,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const [selectedOption, setSelectedOption] = React.useState<string | undefined>(initialOption);

  return (
    <SimpleDialog
      {...rest}
      title={t('Edit paper status')}
      handleOk={
        selectedOption
          ? () => updatePaperStatusBulkAction({ submissionIds, paper_status_id: parseInt(selectedOption) })
          : undefined
      }
    >
      <SelectRadioButton
        className="flex flex-col space-y-3"
        value={selectedOption}
        options={Object.values(paperStatusById)
          .filter((paperStatus) => paperStatus.name != 'Deleted')
          .map((value) => ({ id: value.id.toString(), label: value.name }))}
        onChange={(value) => setSelectedOption(value)}
      />
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  paperStatusById: selectPaperStatusState(state).paperStatusById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updatePaperStatusBulkAction: (data: updatePaperStatusBulkDTO) =>
    dispatch(submissionSlice.actions['UPDATE:BULK'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaperStatusChangeDialog);
