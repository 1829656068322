import { InputText } from '../ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  className?: string;
  placeholder?: string;
  value?: OptionDatas;
  textHandler: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number | undefined) => void;
  name?: string;
  index?: number;
}
interface OptionDatas {
  textLength?: number;
}

const InputShortTextComponent: React.FC<Props> = ({ placeholder, index, value, textHandler, name }) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="w-96 flex flex-col items-start py-2 short-text">
      <InputText
        placeholder={placeholder}
        label={t('Character limit')}
        type="number"
        className="w-96"
        defaultValue={value?.textLength}
        name={name}
        fullWidth
        onChange={(e) => textHandler(e, index)}
      />
    </div>
  );
};

export default InputShortTextComponent;
