import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  createTemplateResponse,
  EmailSendDTO,
  EmailState,
  EmailTemplate,
  getPlaceholdersResponse,
  getPreviewOK,
  getTemplatesResponse,
  sendEmailResponse,
} from './types';

export const initialState: EmailState = {
  loading: false,
  placeholdersByRecipientType: { author: [], contact_author: [], 'submission:ids': [], people: [], invitation: [] },
  templatesById: {},
  formState: 'COMPOSE',
};

const emailSlice = createSlice({
  name: 'EMAIL',
  initialState,
  reducers: {
    reset: () => initialState,
    ['SEND']: (state, action: PayloadAction<EmailSendDTO>) => ({
      ...state,
      formState: 'WAIT',
    }),
    ['SEND:OK']: (state, action: PayloadAction<sendEmailResponse>) => {
      let email;
      if (state.email) {
        email = {
          ...state.email,
          token: action.payload.data.token,
        };
      }
      return {
        ...state,
        formState: 'SENDING',
        email,
      };
    },
    ['GET:PREVIEW:OK']: (state, action: PayloadAction<getPreviewOK>) => ({
      ...state,
      preview: action.payload.preview,
      email: action.payload.email,
      formState: 'PREVIEW',
    }),
    ['REMOVE:PREVIEW']: (state) => ({
      ...state,
      formState: 'COMPOSE',
      preview: initialState.preview,
    }),
    ['UPDATE:EMAIL']: (state, action: PayloadAction<EmailSendDTO>) => ({
      ...state,
      email: action.payload,
    }),
    ['REMOVE:EMAIL']: (state) => ({
      ...state,
      formState: 'COMPOSE',
      email: initialState.email,
    }),
    ['PLACEHOLDERS:GET:OK']: (state, action: PayloadAction<getPlaceholdersResponse>) => ({
      ...state,
      loading: false,
      placeholdersByRecipientType: action.payload.data,
    }),
    ['TEMPLATES:CREATE']: (state, action: PayloadAction<EmailTemplate>) => ({
      ...state,
      loading: true,
    }),
    ['TEMPLATES:CREATE:OK']: (state, action: PayloadAction<createTemplateResponse>) => ({
      ...state,
      loading: false,
      templatesById: {
        ...state.templatesById,
        [action.payload.data.id]: action.payload.data,
      },
    }),
    ['TEMPLATES:GET:OK']: (state, action: PayloadAction<getTemplatesResponse>) => {
      const templatesById: { [key: number]: EmailTemplate } = {};
      Object.values(action.payload.data).forEach((template) => {
        templatesById[template.id] = { ...template, subject: template.subject ?? '', body: template.body ?? '' }; // Just cast nulls to empty string
      });
      return {
        ...state,
        loading: false,
        templatesById: templatesById,
      };
    },
    ['TEMPLATES:UPDATE']: (state, action: PayloadAction<EmailTemplate>) => ({
      ...state,
      loading: true,
    }),
    ['TEMPLATES:DELETE']: (state, action: PayloadAction<number>) => ({
      ...state,
      loading: true,
    }),
    ['TEMPLATES:DELETE:OK']: (state, action: PayloadAction<number>) => {
      const { [action.payload]: _, ...rest } = state.templatesById;
      return {
        ...state,
        loading: false,
        templatesById: rest,
      };
    },
    ['TEMPLATES:DELETE:KO']: (state) => ({
      ...state,
      loading: false,
    }),
  },
});

export default emailSlice;
