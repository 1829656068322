import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SignUpOAuth, { SignUpOAuthForm } from '../components/SignUpOAuth/SignUpOAuth';
import { validationErrorTransformer } from '../store/api';
import { ValidatedField } from '../store/api.d';
import { selectAuthValidationErrors } from '../store/auth/selector';
import authSlice from '../store/auth/slice';
import { OAuthFields } from '../store/auth/types';
import { selectAuth } from '../store/selectors';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';

interface SignUpOAuthPageProps {
  signUpAction: (data: OAuthFields) => void;
  validationErrors: ValidatedField[];
  clearValidationMessages: () => void;
  signupForm?: OAuthFields;
}

const SignUpOAuthPage: React.FC<SignUpOAuthPageProps> = ({
  signUpAction,
  validationErrors,
  clearValidationMessages,
  signupForm,
}) => {
  const history = useHistory();

  const login_handler = () => {
    validationErrors && clearValidationMessages();
    history.push('/login');
  };

  const on_change_handler = (): void => {
    validationErrors && clearValidationMessages();
  };

  const signup_handler = (form: SignUpOAuthForm) => {
    if (signupForm) {
      signUpAction({ ...signupForm, first_name: form.first_name, last_name: form.last_name });
    }
  };

  return (
    <SignUpOAuth
      login_handler={login_handler}
      signup_handler={signup_handler}
      validation_error={validationErrorTransformer(validationErrors)}
      on_change_handler={on_change_handler}
      signupForm={signupForm}
    />
  );
};

const mapStateToProps = (state: AppState) => ({
  validationErrors: selectAuthValidationErrors(state),
  signupForm: selectAuth(state).signup,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  signUpAction: (data: OAuthFields) => dispatch(authSlice.actions['SIGNUP_OAUTH'](data)),
  clearValidationMessages: () => dispatch(authSlice.actions['CLEAR:VALIDATION']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpOAuthPage);
