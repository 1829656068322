import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import InvitationForm from '../components/InvitationForm/InvitationForm';
import { useParams } from 'react-router-dom';
import { AppDispatch } from '../store/store';
import authSlice from '../store/auth/slice';
import {
  GetInvitationRequest,
  Invitation,
  RespondInvitationRequest,
  SignUpInvitationRequest,
} from '../store/auth/types';
import Loading from '../components/Loading/Loading';
import { AppState } from '../store/state';
import { selectAuth } from '../store/selectors';
import { validationErrorTransformer } from '../store/api';
import { ValidatedField } from '../store/api.d';
import SignUpInvitation, { SignUpInvitationForm } from '../components/SignUpInvitation/SignUpInvitation';
import { useTranslation } from 'react-i18next';

interface InvitationFormPageProps {
  getInvitationAction: (data: GetInvitationRequest) => void;
  respondInvitationAction: (data: RespondInvitationRequest) => void;
  signUpAction: (data: SignUpInvitationRequest) => void;
  clearValidationMessages: () => void;
  loading?: boolean;
  invitation?: Invitation;
  validationErrors: ValidatedField[];
}

const InvitationFormPage: React.FC<InvitationFormPageProps> = ({
  getInvitationAction,
  respondInvitationAction,
  signUpAction,
  clearValidationMessages,
  loading,
  invitation,
  validationErrors,
}) => {
  const { t, i18n } = useTranslation();
  const { token }: { token: string } = useParams();

  useEffect(() => {
    getInvitationAction({ token });
  }, []);

  const on_change_handler = (): void => {
    validationErrors && clearValidationMessages();
  };

  const signup_handler = (form: SignUpInvitationForm) => {
    const { email, ...rest } = form;
    signUpAction({ ...rest, token, comment: invitation?.comment ?? '' });
  };

  if (validationErrors.length) {
    return <div>{validationErrors[0].message}</div>;
  } else if (loading || !invitation) {
    return <Loading />;
  }

  let content;
  if (invitation.status === 'Not responded') {
    content = <InvitationForm invitation={invitation} token={token} sendHandler={respondInvitationAction} />;
  } else if (invitation.is_user_new) {
    content = (
      <SignUpInvitation
        signup_handler={signup_handler}
        validation_error={validationErrorTransformer(validationErrors)}
        on_change_handler={on_change_handler}
        signupForm={invitation}
      />
    );
  } else {
    content = <div>{t('Invitation is responded, thank you for your answer.')}</div>;
  }

  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">
        {t('Invitation for')} {invitation.conference_short_name}
      </h2>
      {content}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectAuth(state).invitationLoading,
  invitation: selectAuth(state).invitation,
  validationErrors: selectAuth(state).validationError ?? [],
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInvitationAction: (data: GetInvitationRequest) => dispatch(authSlice.actions['GET_INVITATION'](data)),
  respondInvitationAction: (data: RespondInvitationRequest): void => {
    dispatch(authSlice.actions['RESPOND_INVITATION'](data));
  },
  signUpAction: (data: SignUpInvitationRequest) => dispatch(authSlice.actions['SIGNUP_INVITATION'](data)),
  clearValidationMessages: () => dispatch(authSlice.actions['CLEAR:VALIDATION']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationFormPage);
