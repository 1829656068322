import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Invitation } from '../../store/auth/types';
import { MIN_PASSWORD_LENGTH, passwordValidationRule } from '../../helpers/password';
import { Button, InputText } from '../ui';
import { InputValidationError } from '../types';
import { useTranslation } from 'react-i18next';

export interface SignUpInvitationForm {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
}

export interface SignUpInvitationProps {
  validation_error?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
  };
  signup_handler: (form: SignUpInvitationForm) => void;
  on_change_handler: () => void;
  signupForm?: Invitation;
}

const SignUpInvitation: React.FC<SignUpInvitationProps> = (props) => {
  const { t, i18n } = useTranslation();
  const { handleSubmit, errors, control } = useForm<SignUpInvitationForm>();
  const { signup_handler, validation_error, signupForm } = props;

  let firstNameProps: InputValidationError = {};
  let lastNameProps: InputValidationError = {};
  let passwordProps: InputValidationError = {};

  const frontend_validation_rules = {
    first_name: { required: t('Enter your first name') },
    last_name: { required: t('Enter your last name') },
    password: passwordValidationRule,
  };
  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.first_name) {
      firstNameProps = {
        ...firstNameProps,
        error: true,
        helperText: errors.first_name.message,
      };
    }
    if (errors.last_name) {
      lastNameProps = {
        ...lastNameProps,
        error: true,
        helperText: errors.last_name.message,
      };
    }
    if (errors.password) {
      let message;
      switch (errors.password.type) {
        case 'minLength': {
          message = t('The password must be at least {{length}} characters.', {
            length: MIN_PASSWORD_LENGTH.toString(),
          });
          break;
        }
        default: {
          message = errors.password.message;
        }
      }
      passwordProps = {
        ...passwordProps,
        error: true,
        helperText: message,
      };
    }
  } else {
    // Backend Validation
    if (validation_error) {
      if (validation_error.first_name) {
        firstNameProps = {
          ...firstNameProps,
          error: true,
          helperText: validation_error.first_name,
        };
      }
      if (validation_error.last_name) {
        lastNameProps = {
          ...lastNameProps,
          error: true,
          helperText: validation_error.last_name,
        };
      }
      if (validation_error.password) {
        passwordProps = {
          ...passwordProps,
          error: true,
          helperText: validation_error.password,
        };
      }
    }
  }
  return (
    <div>
      <h2 className="text-2xl mb-9 font-semibold text-gray-600">
        {t('Create account for')} {signupForm?.email}
      </h2>
      <form onSubmit={handleSubmit(signup_handler)} className="flex flex-col">
        <Controller
          name="first_name"
          control={control}
          defaultValue={signupForm?.first_name}
          rules={frontend_validation_rules.first_name}
          render={({ onChange, value }) => (
            <InputText
              label={t('First name')}
              value={value}
              onChange={onChange}
              className="mb-3"
              error={firstNameProps.error}
              helperText={firstNameProps.helperText}
            />
          )}
        />
        <Controller
          name="last_name"
          control={control}
          defaultValue={signupForm?.last_name}
          rules={frontend_validation_rules.last_name}
          render={({ onChange, value }) => (
            <InputText
              label={t('Last name')}
              value={value}
              onChange={onChange}
              className="mb-3"
              error={lastNameProps.error}
              helperText={lastNameProps.helperText}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={frontend_validation_rules.password}
          render={({ onChange, value }) => (
            <InputText
              type="password"
              label={t('Password')}
              autoFocus
              onChange={onChange}
              value={value}
              className="mb-10"
              error={passwordProps.error}
              helperText={passwordProps.helperText}
            />
          )}
        />
        <Button type="submit" className="mb-5 bg-blue-700">
          <span className="text-white">{t('Sign up')}</span>
        </Button>
      </form>
    </div>
  );
};

export default SignUpInvitation;
