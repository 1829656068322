import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import styled from 'styled-components';
import { Switch, Tooltip } from '@material-ui/core';
import { InputText } from '../ui';
import cn from 'classnames';
import { DefaultQuestions } from '../../store/form/types';
import { connect } from 'react-redux';
import { AppState } from '../../store/state';
import { selectKeywordState } from '../../store/selectors';
import UploadFileComponent, { FUploadOption } from '../FormBuilderInput/UploadFileComponent';
import { useTranslation } from 'react-i18next';

interface Props {
  defaultQuestions: DefaultQuestions;
  className?: string;
  keywordById: { [key: number]: Keyword };
  is_editable?: boolean;
  onChange: (targetName: string, targetValue: any) => void;
}

const NotEditableTooltip = () => {
  const { t, i18n } = useTranslation();

  const defaultNotEditableMessage = t(
    'You cannot edit because is a default value. You can edit in future versions of ChairingTool',
  );
  return (
    <Tooltip title={defaultNotEditableMessage} placement="top-start" className="cursor-default">
      <div className="text-red-600 text-xs mb-3" style={{ marginLeft: '8.9rem', paddingTop: '5px' }}>
        {t('Not editable')}
      </div>
    </Tooltip>
  );
};

const FormBuilderSubmissionDefault: React.FC<Props> = ({
  defaultQuestions,
  className,
  keywordById,
  is_editable,
  onChange,
}): any => {
  const { t, i18n } = useTranslation();
  const [focusIndex, setFocusIndex] = useState<number | undefined>(undefined);

  const theme = useTheme();
  const style: React.CSSProperties | undefined = {};

  style.borderLeftWidth = '4px';
  style.borderColor = '#0044F0';

  const getTitle = (key: string) => {
    let title_name;
    switch (key) {
      case 'title':
        title_name = t('Title');
        break;
      case 'abstract':
        title_name = t('Abstract');
        break;
      case 'authors':
        title_name = t('Authors');
        break;
      case 'keywords':
        title_name = t('Keywords');
        break;
      case 'paper_upload':
        title_name = t('Paper upload');
        break;
      default:
        return null;
    }
    return title_name;
  };

  const getComponentByType = (type: string, data: any) => {
    switch (type) {
      case 'title':
      case 'abstract':
        return (
          <div className="w-full">
            <div>
              <InputText
                label={t('Max length')}
                defaultValue={type === 'title' ? 255 : 3000}
                className="w-full"
                fullWidth={true}
                disabled={true}
                onChange={undefined}
              />
            </div>
            <NotEditableTooltip />
          </div>
        );
      case 'authors':
        return (
          <div className="flex flex-col w-full">
            {/* MAX NUMBER AUTHORS INPUT */}
            <div className="flex flex-row items-center w-full">
              <InputText
                label={t('Max authors')}
                value={t('unlimited')}
                onChange={undefined}
                onBlur={undefined}
                className="w-full"
                fullWidth={true}
                disabled={true}
              />
            </div>
            <NotEditableTooltip />

            {/* MAX NUMBER PAPERS INPUT */}
            <div className="flex flex-row items-center mb-1 w-full">
              <InputText
                label={t('Max papers')}
                value={t('unlimited')}
                onChange={undefined}
                onBlur={undefined}
                className="w-full"
                fullWidth={true}
                disabled={true}
              />
            </div>
            <NotEditableTooltip />
          </div>
        );
      case 'keywords':
        return (
          <div className="flex flex-col w-full">
            {/* MIN KEYWORDS INPUT */}
            <div className="flex flex-row items-center">
              <InputText
                label={t('Min num')}
                value="0"
                onChange={undefined}
                onBlur={undefined}
                className="w-full"
                fullWidth={true}
                disabled={true}
              />
            </div>
            <NotEditableTooltip />

            {/* MAX KEYWORDS INPUT */}
            <div className="flex flex-row items-center">
              <InputText
                label={t('Max num')}
                value={t('unlimited')}
                onChange={undefined}
                onBlur={undefined}
                className="w-full"
                fullWidth={true}
                disabled={true}
              />
            </div>
            <NotEditableTooltip />
          </div>
        );
      case 'paper_upload':
        const { max_size, extensions, max_pages } = data;

        // Make an object with paper upload options as UploadFileComponent needs in value
        const fileTypeExtensions = FUploadOption.filter((option: { label: string }) =>
          extensions.includes(option.label),
        );
        const paperUploadOptions = { fileSize: max_size, fileType: fileTypeExtensions, pdfFile: max_pages };

        return (
          <div className="flex flex-col w-full">
            {/* SIZE LIMIT INPUT */}
            <div>
              <UploadFileComponent
                fileHandler={(values) => {
                  // Transforms the array of ids selected that this component gives, in array of extensions (strings) that needs onChange
                  const extensionsSelected = FUploadOption.filter((option: { id: string }) =>
                    values.includes(option.id),
                  ).map((x) => x.label);
                  onChange(`${type}.extensions`, extensionsSelected);
                }}
                name="fileSize"
                textHandler={(e) => {
                  const fieldName = e.target.name === 'fileSize' ? 'max_size' : 'max_pages';
                  onChange(`${type}.${fieldName}`, parseInt(e.target.value));
                }}
                value={paperUploadOptions}
                className="text-sm"
              />
            </div>
          </div>
        );
      default:
        return undefined;
    }
  };

  /*
    The keys of defaultQuestions are used as type to identify the attributes as ${type}.description, for example.
    When Formbuilder component receive targetName in onChangeHandler, it's doing and split to obtain questionType
    and questionFieldName
  */

  return (
    <ThemeProvider theme={theme}>
      <StyledFormsWrapper className="grid grid-cols-1 justify-items-start max-w-5xl">
        {Object.entries(defaultQuestions).map(([type, data], index) => {
          let switchRequiredMessage = '';

          if (type === 'paper_upload' && !is_editable) {
            switchRequiredMessage = t("You can't do this action because this form is presented to authors");
          } else if (type !== 'paper_upload') {
            switchRequiredMessage = t('You cannot change required status in this default question');
          }

          return (
            <StyledFormBuilderCard
              className={`p-5 h-auto bg-white rounded-lg border border-solid border-gray-300 w-full mb-4 ${cn(
                className,
              )}`}
              style={index === focusIndex ? style : undefined}
              key={index}
              onClick={() => {
                // TO DO
                //setFocusIndex(index);
              }}
            >
              <header className="relative">
                <h2 className={`text-lg font-semibold pb-4 text-black relative`}>
                  {`${t('Question')} ${index + 1}`}

                  {type === 'keywords' && Object.keys(keywordById).length === 0 && (
                    <Tooltip
                      title={t(
                        'There are no keywords defined. To create them, go to the keywords section of chair settings.',
                      )}
                      placement="right"
                    >
                      <span
                        className="font-semibold px-2 rounded-full text-sm mt-1 ml-1.5 text-black cursor-default absolute"
                        style={{ height: 'max-content', backgroundColor: '#fbeb02' }}
                      >
                        !
                      </span>
                    </Tooltip>
                  )}
                </h2>
                <StyledDefaultFormLabel className="absolute">{t('Default question')}</StyledDefaultFormLabel>
              </header>

              <div>
                <div className="flex flex-col">
                  {/* TITLE INPUT */}
                  <div>
                    <div className="flex flex-row items-center">
                      <InputText
                        label={t('Question name')}
                        value={getTitle(type)}
                        className="w-full"
                        fullWidth={true}
                        disabled={true}
                        onChange={undefined}
                      />
                    </div>
                    <NotEditableTooltip />
                  </div>

                  {/* DESCRIPTION INPUT */}
                  <div className="flex flex-row items-center mb-8">
                    <InputText
                      label={t('Description')}
                      defaultValue={data.description}
                      name={`${type}.description`}
                      onBlur={undefined}
                      className="w-full"
                      fullWidth={true}
                      onChange={(e) => {
                        onChange(`${type}.description`, e.target.value);
                      }}
                      inputProps={{ autoComplete: 'off' }}
                    />
                  </div>

                  <div className="flex flex-row items-center mb-1">{getComponentByType(type, data)}</div>
                </div>
              </div>

              {/* TOOLBOX */}
              <hr className="m-2" />
              <div className="flex justify-between items-center h-6">
                <div className="flex w-full">
                  <div className="flex font-medium items-center mr-4">
                    {/* REQUIRED */}
                    <Tooltip title={switchRequiredMessage} placement="right" className="cursor-default">
                      <div
                        className={`flex font-medium items-center mr-4 cursor-default ${
                          type === 'paper_upload' && is_editable ? '' : 'opacity-60'
                        }`}
                      >
                        <Switch
                          name={`${type}.required`}
                          onChange={(e) => onChange(`${type}.required`, e.target.checked)}
                          checked={type === 'paper_upload' ? data.required : type !== 'keywords' ? true : false}
                          color="primary"
                          className={`${
                            type === 'paper_upload' && is_editable ? '' : 'pointer-events-none cursor-default'
                          }`}
                        />
                        <h3>{t('Required')}</h3>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </StyledFormBuilderCard>
          );
        })}
      </StyledFormsWrapper>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: AppState) => ({
  keywordById: selectKeywordState(state).keywordById,
});

export default connect(mapStateToProps)(FormBuilderSubmissionDefault);

const StyledFormsWrapper = styled.div`
  font-size: 0.875rem;
  flex-grow: 0;

  .MuiInputBase-input {
    font-size: 0.875rem;
  }
  .formName {
    display: flex;
    flex-direction: row;

    label {
      width: 10.3rem;
      padding-top: 0.3rem;
    }
  }
  .formType {
    > div {
      width: 10rem;
      padding-top: 0.3rem;
    }
    .selectType {
      width: 98%;
    }
  }
  .selectType {
    background-color: #fff;
  }

  .paperStatus {
    label,
    .labelSelect {
      width: 8.7rem;
      padding-top: 0.3rem;
      margin-left: 0;
    }
  }
`;

const StyledFormBuilderCard = styled.div`
  .labelSelect {
    width: 10rem;
    padding-top: 0.3rem;
    margin-left: 0;
  }
  .questionName {
    display: flex;
    flex-direction: row;

    label {
      width: 10rem;
      padding-top: 0.3rem;
      margin-left: 0;
    }
    > div {
      width: 98%;
    }
  }
  .multiSelect-wrapper {
    > div {
      width: 100%;

      > div {
        > div {
          /*width: 40%;*/
        }
        .cursor-pointer {
          width: auto;
        }
      }
    }
  }
  .select-wrapper {
    > div {
      width: 100%;
      justify-content: flex-start;

      > div {
        > div {
          /*width: 19%;*/
        }
        > div:first-child {
          /*width: 31%;*/
        }
        .cursor-pointer {
          width: auto;
        }
      }
    }
  }
  .input-numeric,
  .linear-scale {
    margin-right: 0;
    > div {
      display: flex;
      flex-direction: row;
      margin-right: 2rem;

      label {
        width: 8.9rem;
        margin-top: 0.5rem;
      }

      > div {
        width: 17rem;
        margin-bottom: 0.5rem;
      }
    }
    .labelSelect {
      width: 8.9rem;
      align-self: center;
      padding-top: 0;
    }
    .selectType.k-dropdown {
      width: 17rem;
      margin-bottom: 0.5rem;
    }
  }
  .input-text,
  .short-text {
    > div {
      margin-bottom: 1rem;

      label {
        width: 14rem;
      }
    }
  }
  .upload-file {
    > div {
      justify-content: flex-start;
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5rem;

      label {
        width: 8.6rem;
        padding-top: 0.375rem;
      }
      > div {
        width: 20.8rem;
      }
    }
  }
  .option-input {
    flex-grow: 1;
  }
`;

const StyledDefaultFormLabel = styled.div`
  width: fit-content;
  padding: 0.1rem 1rem;
  border-radius: 10px;
  right: 0;
  position: absolute;
  top: 0;
  background-color: #fbeb02;
`;
