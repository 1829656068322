import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import cn from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';

export type TabsItemProps = {
  label: string;
  selected?: boolean;
  onClick?: () => void;
};

export const TabsItem = ({ label, selected = false, onClick }: TabsItemProps): JSX.Element => {
  const theme = useTheme();

  let customStyle = {};

  if (selected) {
    customStyle = {
      ...customStyle,
      borderBottomColor: theme.palette.primary.main,
      borderBottomWidth: '2px',

      color: theme.palette.primary.main,
    };
  }

  return (
    <StyledButton className={cn({ selected })} style={customStyle} onClick={onClick}>
      <span>{label}</span>
    </StyledButton>
  );
};

const StyledButton = styled.button`
  span {
    ${tw`block py-3  px-4`}
  }

  &.selected span {
    ${tw`font-bold`}
  }
`;
