import { getDashboardRequest, getDashboardResponse } from './types';
import { apiCall, APIValidationError } from '../api';
import { Result } from '../api.d';

const baseURL = process.env.REACT_APP_API_URL;

export const getDashboardApiCall = async (
  data: getDashboardRequest,
): Promise<Result<getDashboardResponse, APIValidationError>> => {
  const { section, targetRoleId, areaId, paperStatusId } = data;

  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getDashboardResponse>(
    `${baseURL}/api/dashboard/${section}?target_role_id=${targetRoleId}&area_id=${areaId}&paper_status_id=${paperStatusId}`,
    init,
  );
};
