import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { InputText } from '../../ui';
import { FormDTO, FormType } from '../../../store/form/types';
import { useTranslation } from 'react-i18next';

interface Props extends SimpleDialogProps {
  type: FormType;
  handleFormOk: (newForm: FormDTO) => void;
}

const AddFormDialog: React.FC<Props> = ({ type, handleFormOk, handleClose, ...rest }) => {
  const { t, i18n } = useTranslation();
  const initForm: FormDTO = {
    id: 0,
    form_id: null,
    name: '',
    type: type,
  };

  const [newForm, setNewForm] = React.useState<FormDTO>(initForm);

  return (
    <SimpleDialog
      {...rest}
      title={t('New {{type}} form', { type: newForm.type })}
      handleOk={() => {
        handleFormOk(newForm);
      }}
      handleClose={handleClose}
      disableOkButton={!newForm.name}
    >
      <InputText
        placeholder={t('Enter name')}
        label={t('Name')}
        value={newForm.name}
        onChange={(e) => {
          setNewForm({ ...newForm, name: e.target.value });
        }}
        className="text-sm mb-4"
      />
    </SimpleDialog>
  );
};

export default AddFormDialog;
