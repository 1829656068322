import React from 'react';

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <path
        fill="#2D53F1"
        d="M15 4v3.586l5.293-5.293a1 1 0 111.414 1.414L16.414 9H20a1 1 0 110 2h-6a.996.996 0 01-.7-.286l-.014-.014A.997.997 0 0113 10V4a1 1 0 112 0zM9 20v-3.586l-5.293 5.293a1 1 0 01-1.414-1.414L7.586 15H4a1 1 0 110-2h6a.996.996 0 01.7.286l.014.014c.177.18.286.427.286.7v6a1 1 0 11-2 0z"
      ></path>
    </svg>
  );
}

export default Icon;
