import React from 'react';
import HiddenIcon from '../../icons/Hidden';
import EditIcon from '../../icons/Edit';
import ViewIcon from '../../icons/View';
import { Role } from '../../store/conference/types';
import { Form, FormVisibilityOption } from '../../store/form/types';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { getRoleName, getVisibilityOptionName } from '../../helpers/translations';

interface Props {
  role: Role;
  roleById: { [key: string]: Role };
  permissionsData: {
    to_role_id: number | null;
    visibility: FormVisibilityOption;
  };
  form: Form;
  renderIcons?: boolean;
}

const FormRolePermission: React.FC<Props> = ({ role, roleById, permissionsData, form, renderIcons = false }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const { visibility, to_role_id } = permissionsData;
  const roleName = getRoleName(role, t);
  let icon, iconText;
  switch (visibility) {
    case 'read':
      icon = (
        <span className="ml-1.5 mr-1 inline-block mt-0.5 align-top w-5">
          <ViewIcon />
        </span>
      );
      iconText = getVisibilityOptionName(visibility, t);
      break;
    case 'write':
      icon = (
        <span className="ml-1.5 mr-1 inline-block mt-0.5 align-top w-5">
          <EditIcon color={theme.palette.primary.main} className="mr-1" />
        </span>
      );
      iconText = getVisibilityOptionName(visibility, t);
      break;
    case 'hidden':
      icon = (
        <span className="ml-1.5 mr-1 inline-block mt-0.5 align-top w-5">
          <HiddenIcon color="rgb(156 163 175)" />
        </span>
      );
      iconText = getVisibilityOptionName(visibility, t);
      break;
  }

  const isOwn = !to_role_id;
  const destRole = to_role_id ? getRoleName(roleById[to_role_id], t) : '';

  let owner = '';
  if (form.type == 'Review' && role.type == 'reviewer') {
    owner = isOwn ? ` ${t('of his own')}` : ` ${t('from other')} `;
  } else if (
    (form.type == 'Submission' && role.type == 'reviewer') ||
    (form.type == 'Review' && role.type == 'author')
  ) {
    owner = ` ${t('from')} `;
  }
  return (
    <div className="mb-1 pr-2">
      {renderIcons && icon} {roleName} {iconText} {t('answers')} {owner} {destRole}
    </div>
  );
};

export default FormRolePermission;
