import { store } from '../store/store';
import { selectCurrentConference, selectCurrentTrack } from '../store/selectors';
import { generatePath as generatePath } from 'react-router';

export function fillRoutePath(path: string, params?: { [key: string]: string }): string {
  const state = store.getState();

  const conference = selectCurrentConference(state);
  const track = selectCurrentTrack(state);

  const baseParams = { conference: conference?.slug, track: track?.slug };

  return generatePath(path, { ...baseParams, ...params });
}
