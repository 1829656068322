import { AppState } from '../state';
import { ValidatedField } from '../api.d';
import { selectAuth } from '../selectors';

export const selectAuthValidationErrors = (state: AppState): ValidatedField[] => {
  const return_array: ValidatedField[] = [];

  if (state.auth.validationError) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Object.keys(state.auth.validationError).forEach((key: any) => {
      if (state.auth.validationError && state.auth.validationError[key]) {
        const item = state.auth.validationError[key];
        return_array.push(item);
      }
    });
  }
  return return_array;
};

export const selectAuthLoggedIn = (state: AppState): boolean => {
  return selectAuth(state).loggedIn;
};
