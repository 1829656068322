import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import { ReactComponent as Logo } from '../images/logo.svg';
import { useTranslation } from 'react-i18next';
import LanguageSelector from '../components/ui/inputs/LanguageSelector/LanguageSelector';
import useTheme from '@material-ui/core/styles/useTheme';
import { availableLanguages } from '../i18n';

type Props = {
  children?: React.ReactNode;
};

const AuthLayout: React.FC<Props> = ({ children }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  return (
    <ThemeProvider theme={theme}>
      <StyledDiv>
        <div className="-left-col">
          <span className="-text">
            "{t('The art and science of asking questions is the source of all knowledge.')}" —{' '}
            <span>Thomas Berger</span>
          </span>
        </div>
        <div className="flex-1 flex flex-col px-7 py-9">
          <div className="flex flex-row justify-between">
            <div className="relative">
              <StyledBeta>{t('BETA')}</StyledBeta>
              <Logo />
            </div>
            {availableLanguages.length > 1 && <LanguageSelector changeLanguageOnChange={true} />}
          </div>

          <div className="flex-grow flex items-center justify-center">
            <div className="w-full max-w-xs flex flex-col mt-4">{children}</div>
          </div>
        </div>
      </StyledDiv>
      <StyledFooter>
        {t('Copyright')} © Brainful labs | <span>{t('Contact us at')} </span>
        <a href="mailto:support@brainful-labs.com">support@brainful-labs.com</a>
      </StyledFooter>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`flex h-screen`}

  .-left-col {
    ${tw`flex-1 bg-blue-700 relative`}
    background: url(/images/login.jpg) right bottom / cover no-repeat;

    .-text {
      ${tw`absolute bottom-0 text-gray-600 text-5xl leading-tight cursor-default pl-16 pr-28 pb-14`}
      background-color: rgba(255, 255, 255, 0.5);
      margin-left: 4rem;
      margin-right: 7rem;
      margin-bottom: 3.5rem;
      padding: 2rem;
      border-radius: 10px;

      & > span {
        ${tw`text-green-500 font-bold`}
      }
    }
  }
`;

const StyledBeta = styled.div`
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.primary.main};
  width: max-content;
  padding: 1px 11px;
  color: #fff;
  font-size: 0.6rem;
  position: absolute;
  top: 32px;
  left: 148px;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.4) -2px 2px 4px 0px;
  border-radius: 11px;
`;

const StyledFooter = styled.div`
  position: fixed;
  bottom: 1px;
  width: 100%;
  height: 2em;
  border-top: 1px solid #ddd;
  text-align: center;
  line-height: 2em;
  background: #eee;
  color: #666;
  font-size: 0.75rem;
`;

export default AuthLayout;
