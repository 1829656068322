import React from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForgotPassword, { ForgotPasswordForm } from '../components/ForgotPassword/ForgotPassword';
import authSlice from '../store/auth/slice';
import { ForgotPasswordDTO } from '../store/auth/types';
import { AppDispatch } from '../store/store';

interface ForgotPasswordPageProps {
  forgotPasswordAction: (data: ForgotPasswordDTO) => void;
}

const ForgotPasswordPage = ({ forgotPasswordAction }: ForgotPasswordPageProps) => {
  const history = useHistory();

  const fogot_password_handler = (form: ForgotPasswordForm) => {
    forgotPasswordAction(form);
  };

  const login_handler = () => {
    history.push('/login');
  };

  return <ForgotPassword fogot_password_handler={fogot_password_handler} login_handler={login_handler} />;
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  forgotPasswordAction: (data: ForgotPasswordDTO): void => {
    dispatch(authSlice.actions['FORGOT_PASSWORD'](data));
  },
});

export default connect(null, mapDispatchToProps)(ForgotPasswordPage);
