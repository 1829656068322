import React from 'react';

interface Props {
  className?: string;
  progressValue: number;
  barColor?: string;
}

const SimpleProgressBar: React.FC<Props> = ({ className, progressValue, barColor = 'bg-blue-600' }) => {
  const value = progressValue > 100 ? 100 : progressValue;

  return (
    <div className={`w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700 relative ${className}`}>
      <div className={`${barColor} h-2.5 rounded-full`} style={{ width: `${value}%` }}></div>
    </div>
  );
};

export default SimpleProgressBar;
