import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InvitationDTO, RecruitmentState, RespondInvitationDTO } from './types';

export const initialState: RecruitmentState = {
  loading: false,
};

const recruitmentSlice = createSlice({
  name: 'RECRUITMENT',
  initialState,
  reducers: {
    reset: () => initialState,
    ['CREATE_INVITATIONS']: (state, action: PayloadAction<InvitationDTO>) => ({
      ...state,
    }),
    ['DELETE_INVITATIONS']: (state, action: PayloadAction<number[]>) => ({
      ...state,
    }),
    ['RESPOND_INVITATION']: (state, action: PayloadAction<RespondInvitationDTO>) => ({
      ...state,
    }),
  },
});

export default recruitmentSlice;
