import React from 'react';
import { Answer, AnswerJson, Question, QuestionType } from '../../store/form/types';
import { InputText, MultiSelectCheckbox, Select, SelectRadioButton, TextArea } from '../ui';
import FileUploaderS3 from '../FileUploaderS3/FileUploaderS3';
import { OpenSnackBarDTO } from '../../store/error/types';
import TextAreaLatex from '../TextAreaLatex/TextAreaLatex';
import { LinearScale } from '../ui/inputs/LinearScale/LinearScale';
import QuestionHeader from './QuestionHeader';
import { parseNumber } from '../../helpers/validation';

interface Props {
  question: Question;
  answer?: Answer;
  onChange: (data: AnswerJson | null) => void;
  openSnackBarAction?: (data: OpenSnackBarDTO) => void;
  error?: string;
  disabled?: boolean;
  position: number;
  markEmptyAnswers: boolean;
}

const QuestionComponent: React.FC<Props> = ({
  question,
  answer,
  onChange,
  openSnackBarAction,
  error,
  disabled,
  position,
  markEmptyAnswers,
}) => {
  const getInputComponent = (type: QuestionType, answer?: Answer) => {
    switch (type) {
      case 'Numeric':
        return disabled ? (
          <p>
            <span className="font-bold">Answer: </span>
            {answer?.answer?.value ?? 'Not responded'}
          </p>
        ) : (
          <div style={{ maxWidth: '30rem' }}>
            <InputText
              type="number"
              defaultValue={answer?.answer?.value ?? ''}
              fullWidth
              onChange={(e) => {
                const value = parseNumber(e.target.value, question.options.num_decimals);
                onChange(value !== null ? { value } : null);
              }}
              error={!!error}
              helperText={error}
              disabled={disabled}
              className="mb-8"
            />
          </div>
        );
      case 'Comment':
        switch (question.style) {
          case 'InputText':
            return disabled ? (
              <p>
                <span className="font-bold">Answer: </span>
                {answer?.answer?.value ?? `Not responded`}
              </p>
            ) : (
              <InputText
                defaultValue={answer?.answer?.value ?? ''}
                fullWidth
                onChange={(e) => onChange({ value: e.target.value })}
                error={!!error}
                helperText={error}
                className="mb-8"
              />
            );
          case 'TextArea':
            return disabled ? (
              <p>
                <span className="font-bold">Answer: </span>
                <span className="whitespace-pre-line">{answer?.answer?.value ?? 'Not responded'}</span>
              </p>
            ) : (
              <TextArea
                defaultValue={answer?.answer?.value ?? ''}
                cols={40}
                rows={7}
                fullWidth
                onChange={(e) => onChange({ value: e.target.value })}
                error={!!error}
                helperText={error}
              />
            );
          case 'TextAreaLatex':
            const format = answer?.answer?.format ?? 'plain';
            return disabled ? (
              <p>
                <span className="font-bold">Answer: </span>
                <span className="whitespace-pre-line">{answer?.answer?.value ?? 'Not responded'}</span>
              </p>
            ) : (
              <TextAreaLatex
                value={answer?.answer?.value ?? ''}
                format={format}
                error={!!error}
                helperText={error}
                onChange={(data) => onChange(data)}
              />
            );
        }
        break;
      case 'Select':
      case 'LikertScale':
        if (disabled) {
          const value = question?.options.find(
            (x: { id: string; label: string }) => x.id == answer?.answer?.value,
          )?.label;
          return (
            <p>
              <span className="font-bold">Answer: </span>
              {value || 'Not responded'}
            </p>
          );
        }
        switch (question.style) {
          case 'Select':
            return (
              <Select
                options={question.options}
                value={answer?.answer?.value}
                onChange={(e) => !disabled && onChange({ value: e })}
                error={!!error}
                helperText={error}
                className="mb-6 select-question"
              />
            );
          case 'SelectRadioButton':
            return (
              <SelectRadioButton
                options={question.options}
                value={answer?.answer?.value}
                onChange={(e) => !disabled && onChange({ value: e })}
                error={!!error}
                helperText={error}
                className="mb-6 radio-question"
              />
            );
        }
        break;
      case 'MultiSelect':
        if (disabled && !answer?.answer?.value) {
          return (
            <p>
              <span className="font-bold">Answer: </span>
              {answer?.answer?.value ?? 'Not responded'}
            </p>
          );
        }
        return (
          <MultiSelectCheckbox
            options={question.options}
            value={answer?.answer?.value}
            onChange={(e) => !disabled && onChange({ value: e })}
            error={!!error}
            helperText={error}
            className="mb-6 multi-select-question"
          />
        );
      case 'FileUpload':
        return (
          <FileUploaderS3
            initUpload={answer?.answer ? answer.answer.value : null}
            onSuccess={(upload) => {
              const data = upload
                ? {
                    value: upload,
                  }
                : null;
              onChange(data);
            }}
            onError={(message) => openSnackBarAction?.({ message, severity: 'error' })}
            openSnackBarAction={openSnackBarAction}
            extensions={question.options.extensions}
            error={!!error}
            disabled={disabled}
            maxSize={question.options.max_size}
            maxPages={question.options.max_pages}
          />
        );
      case 'LinearScale':
        return (
          <LinearScale
            minValue={question.options.min_bound}
            maxValue={question.options.max_bound}
            value={answer?.answer?.value ?? null}
            onClickHandler={!disabled ? (value) => onChange({ value }) : undefined}
            error={!!error}
            helperText={error}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <div>
      <QuestionHeader
        position={position}
        title={question.title ?? ''}
        description={question.text ?? ''}
        required={question.required}
        error={!!error || markEmptyAnswers}
      />
      <div className="mt-2 mb-8">{getInputComponent(question.type, answer)}</div>
    </div>
  );
};

export default QuestionComponent;
