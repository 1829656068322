import * as React from 'react';

import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { GridFilterCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';

interface DropdownFilterCellProps extends GridFilterCellProps {
  defaultItem: { id?: string; label: string };
  data: { id: string | null; label: string }[];
}

export const DropdownFilterCell = (props: DropdownFilterCellProps): JSX.Element => {
  let hasValue: any = (value: any) => Boolean(value && value.id !== props.defaultItem.id);

  const onChange = (event: DropDownListChangeEvent) => {
    hasValue = hasValue(event.target.value);
    props.onChange({
      value: hasValue ? event.target.value.id : '',
      operator: hasValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onClearButtonClick = (event: any) => {
    event.preventDefault();
    props.onChange({
      value: '',
      operator: '',
      syntheticEvent: event,
    });
  };
  const value = props.data.find((e) => e.id === props.value) || props.defaultItem;
  return (
    <div className="k-filtercell">
      <DropDownList
        data={props.data}
        textField="label"
        dataItemKey="id"
        onChange={onChange}
        value={value}
        defaultItem={props.defaultItem}
      />
      <Button title="Clear" disabled={!hasValue(value)} onClick={onClearButtonClick} icon="filter-clear" />
    </div>
  );
};
