import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { AppState } from '../store/state';
import { AppDispatch } from '../store/store';
import { Conference, Role, Track } from '../store/conference/types';
import {
  selectCurrentConference,
  selectCurrentRole,
  selectCurrentTrack,
  selectCurrentUser,
  selectErrorState,
  selectFormState,
  selectReviewState,
  selectSubmissionState,
} from '../store/selectors';
import Loading from '../components/Loading/Loading';
import { Answer, storeAnswersRequest } from '../store/form/types';
import { FormTypeEnum } from '../store/form/types.d';
import errorSlice from '../store/error/slice';
import { OpenSnackBarDTO } from '../store/error/types';
import { Review } from '../store/review/types';
import reviewSlice from '../store/review/slice';
import ReviewForm from '../components/ReviewForm/ReviewForm';
import { getFormsData } from '../helpers/form';
import formSlice from '../store/form/slice';
import { ValidatedField } from '../store/api.d';
import { Prompt, useParams } from 'react-router-dom';
import { getSubmissionsRequest, Submission } from '../store/submission/types';
import submissionSlice from '../store/submission/slice';
import { getRouteByName } from '../router/routes';
import { fillRoutePath } from '../helpers/path';
import { User } from '../store/user/types';
import history from '../store/history';
import { AssignmentPermissionManager } from '../helpers/permissions';
import { initializeAnswers } from './SubmissionEditPage';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  conference: Conference | null;
  track: Track | null;
  role: Role | null;
  user: User;
  submissionsById: { [key: string]: Submission };
  reviewsById: { [key: string]: Review };
  validationErrors?: ValidatedField[];
  openSnackBarAction: (data: OpenSnackBarDTO) => void;
  storeAnswersAction: (data: storeAnswersRequest) => void;
  getReviewsAction: () => void;
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  clearValidationErrorsAction: () => void;
}

/**
 * Push a route to history to go back.
 * @param submissionExternalId
 */
export const goBackFromEditReview = (submissionExternalId: number) => {
  /*
   If path exists, it means we've landed to edit review page by means of navigating through the app and we can just "go back".
   Otherwise, it means edit review page was accessed through pasting the URL in the browser.
   */
  const canBack = !!history.location.key; // From https://stackoverflow.com/questions/37385570/how-to-know-if-react-router-can-go-back-to-display-back-button-in-react-app
  if (canBack && history.length > 1) {
    history.goBack();
  } else {
    history.replace(
      fillRoutePath(getRouteByName('RouteDetailSubmission').path, { id: submissionExternalId.toString() }),
    );
  }
};

const ReviewEditPage: React.FC<Props> = ({
  loading,
  conference,
  track,
  role,
  user,
  submissionsById,
  reviewsById,
  validationErrors,
  openSnackBarAction,
  storeAnswersAction,
  getReviewsAction,
  getSubmissionsAction,
  clearValidationErrorsAction,
}) => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<Answer[] | undefined>(undefined);
  const isBlocking = data === undefined || validationErrors !== undefined; // Initial behaviour is to be blocking

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName: 'submissions' });
    }
    if (Object.keys(reviewsById).length === 0) {
      getReviewsAction();
    }
    // Clear any validation error on exit
    return () => clearValidationErrorsAction();
  }, []);

  useEffect(() => {
    if (data) {
      // Save click
      findAssignment &&
        storeAnswersAction({ answers: data, answerable_id: findAssignment.id, form_type: FormTypeEnum.Review });
    }
  }, [data]);

  const params: any = useParams();
  const { submissionId: submission_external_id, reviewId: review_external_id } = params;

  if (!conference || !track || !role || loading) {
    return <Loading />;
  }

  // Given id must match with external_id field
  const submission = Object.values(submissionsById).find(
    (submission) => submission.external_id == submission_external_id,
  );

  if (!submission) {
    return <div>Submission #{submission_external_id} not found</div>;
  }

  // Given id must match with external_id field
  const findAssignment = Object.values(reviewsById).find(
    (review) => review.external_id == review_external_id && review.submission_id === submission.id,
  );

  if (!findAssignment) {
    return <div>Review #{review_external_id} not found</div>;
  }

  const isOwner = new AssignmentPermissionManager(findAssignment).isAuthor(user.person.id, role.id);
  const reviewFormsData = getFormsData(
    FormTypeEnum.Review,
    ['write'],
    role.id,
    isOwner ? null : findAssignment.role_id,
    user.person.id,
    findAssignment,
  );

  const assignment = reviewFormsData.answerable as Review;

  const canEdit = new AssignmentPermissionManager(assignment).canEdit(
    user.person.id,
    role,
    reviewFormsData.formsData.map((value) => value.form),
  );

  return canEdit ? (
    <>
      <Prompt when={isBlocking} message={t('Are you sure you want to leave?')} />
      <ReviewForm
        review={{ ...assignment, answers: data ?? initializeAnswers(reviewFormsData.formsData) }}
        submission={submission}
        onSaveHandler={(data) => setData(data)}
        onCancelClick={() => {
          // Cancel click
          goBackFromEditReview(submission_external_id);
        }}
        openSnackBarAction={openSnackBarAction}
        formsData={reviewFormsData.formsData}
        validationErrors={validationErrors}
        canViewFeedback={true}
      />
    </>
  ) : (
    <div>{t('Forbidden')}</div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectSubmissionState(state).loading || selectReviewState(state).loading || selectFormState(state).loading,
  conference: selectCurrentConference(state),
  track: selectCurrentTrack(state),
  role: selectCurrentRole(state),
  user: selectCurrentUser(state),
  submissionsById: selectSubmissionState(state).submissionsById,
  reviewsById: selectReviewState(state).reviewsById,
  validationErrors: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getReviewsAction: () => dispatch(reviewSlice.actions.GET_REVIEWS()),
  openSnackBarAction: (data: OpenSnackBarDTO) => dispatch(errorSlice.actions['OPEN:SNACKBAR'](data)),
  storeAnswersAction: (data: storeAnswersRequest) => dispatch(formSlice.actions.STORE_ANSWERS(data)),
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  clearValidationErrorsAction: () => dispatch(errorSlice.actions['CLEAR:VALIDATION_ERRORS']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewEditPage);
