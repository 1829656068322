import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import {
  EditAssignmentsForSubmissionRequest,
  getReviewsResponse,
  AssignmentsAdditionalInfo,
  EditAssignmentsForReviewerRequest,
} from './types';
import { put, takeLatest } from '@redux-saga/core/effects';
import reviewSlice from './slice';
import errorSlice from '../error/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import formSlice from '../form/slice';
import { FileUploadUpdaterDTO, UploadFeedbackResponse } from '../submission/types';
import { deleteReviewResponse } from '../info/types';

const baseURL = process.env.REACT_APP_API_URL;

export const postReviews = async (
  data: FileUploadUpdaterDTO,
): Promise<Result<UploadFeedbackResponse, APIValidationError>> => {
  const { file, dry_run } = data;

  const formData = new FormData();
  formData.append('file', file);

  const init = {
    method: 'POST',
    auth: true,
    role: true,
    defaultContentType: true,
    body: formData,
  };

  return apiCall<UploadFeedbackResponse>(`${baseURL}/api/assignments/bulk?dry_run=${dry_run ? 1 : 0}`, init);
};

const getReviews = async (): Promise<Result<getReviewsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getReviewsResponse>(`${baseURL}/api/assignments`, init);
};

function* getReviewsSaga() {
  const result = (yield getReviews()) as Await<ReturnType<typeof getReviews>>;

  switch (result.type) {
    case 'ok':
      const { answerById } = result.value.data;
      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      yield put(reviewSlice.actions['GET_REVIEWS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deleteReview = async (reviewId: number): Promise<Result<deleteReviewResponse, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };

  return apiCall<deleteReviewResponse>(`${baseURL}/api/assignments/${reviewId}`, init);
};

function* reviewDeleteSaga(action: PayloadAction<number>) {
  const result = (yield deleteReview(action.payload)) as Await<ReturnType<typeof deleteReview>>;

  switch (result.type) {
    case 'ok':
      yield put(reviewSlice.actions['GET_REVIEWS']()); // Refresh assignments
      break;
    default:
      //yield put(getAllSettingsKO());
      break;
  }
  return;
}

const editAssignmentsForSubmission = async (
  data: EditAssignmentsForSubmissionRequest,
): Promise<Result<{ data: any }, APIValidationError>> => {
  const { submission_id, ...rest } = data;
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/assignments/submissions/${submission_id}`, init);
};

function* editAssignmentsForSubmissionSaga(action: PayloadAction<EditAssignmentsForSubmissionRequest>) {
  const result = (yield editAssignmentsForSubmission(action.payload)) as Await<
    ReturnType<typeof editAssignmentsForSubmission>
  >;

  switch (result.type) {
    case 'ok':
      yield put(reviewSlice.actions['GET_REVIEWS']()); // Refresh assignments
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const editAssignmentsForReview = async (
  data: EditAssignmentsForReviewerRequest,
): Promise<Result<{ data: any }, APIValidationError>> => {
  const { person_role_id, ...rest } = data;
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/assignments/reviewers/${person_role_id}`, init);
};

function* editAssignmentsForReviewerSaga(action: PayloadAction<EditAssignmentsForReviewerRequest>) {
  const result = (yield editAssignmentsForReview(action.payload)) as Await<ReturnType<typeof editAssignmentsForReview>>;

  switch (result.type) {
    case 'ok':
      yield put(reviewSlice.actions['GET_REVIEWS']()); // Refresh assignments
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export const getAssignmentsSubmissions = async (data: {
  submission_id?: number;
  person_role_id?: number;
}): Promise<Result<{ data: AssignmentsAdditionalInfo[] }, APIValidationError>> => {
  const { submission_id, person_role_id } = data;
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  let params = '';
  if (submission_id) {
    params = `submission_id=${submission_id}`;
  } else if (person_role_id) {
    params = `person_role_id=${person_role_id}`;
  }

  return apiCall<{ data: AssignmentsAdditionalInfo[] }>(`${baseURL}/api/assignments-submissions?${params}`, init);
};

export default [
  takeLatest(reviewSlice.actions['GET_REVIEWS'], getReviewsSaga),
  takeLatest(reviewSlice.actions['REVIEW:DELETE'], reviewDeleteSaga),
  takeLatest(reviewSlice.actions['ASSIGNMENTS:SUBMISSON:EDIT'], editAssignmentsForSubmissionSaga),
  takeLatest(reviewSlice.actions['ASSIGNMENTS:REVIEWER:EDIT'], editAssignmentsForReviewerSaga),
];
