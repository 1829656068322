import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { nanoid } from 'nanoid';

export type RadioButtonProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  ref?: any;
};

export const RadioButton = ({ id, label = '', required, ...inputProps }: RadioButtonProps): JSX.Element => {
  const inputId = id || nanoid();
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className="radiobutton-wrapper">
        <input {...inputProps} id={inputId} type="radio" required={required} />
        {label && (
          <label htmlFor={inputId} className="mr-4">
            {required ? `${label} *` : label}
          </label>
        )}
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`inline-flex items-center text-sm`}

  label {
    ${tw`text-gray-600 ml-1`}
  }

  input {
    ${tw`focus:border-2 focus:border-blue-700 focus:ring-transparent focus:ring-0`}
  }
`;
