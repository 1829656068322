import React, { useEffect } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { selectKeywordState, selectPaperStatusState, selectSubmissionState, selectTable } from '../../store/selectors';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { TableFriendlyName } from '../../store/info/types';
import { PaperStatus } from '../../store/paper-status/types';
import { getSubmissionsRequest, Submission } from '../../store/submission/types';
import submissionSlice from '../../store/submission/slice';
import Loading from '../../components/Loading/Loading';
import { formatKeywordColumn } from '../../helpers/table';
import tableSlice from '../../store/table/slice';
import { GetCustomColumnsData } from '../../store/table/types';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName, getValidationStatusName } from '../../helpers/translations';

interface Props {
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  submissionsById: { [key: string]: Submission };
  loading: boolean;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  getCustomColumnsDataAction: (data: GetCustomColumnsData) => void;
}

const AllSubmissionsPage: React.FC<Props> = ({
  getSubmissionsAction,
  submissionsById,
  loading,
  keywordById,
  paperStatusById,
  customColumnsData,
  getCustomColumnsDataAction,
}) => {
  const { t, i18n } = useTranslation();
  const friendlyName: TableFriendlyName = 'submissions';

  useEffect(() => {
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName });
    } else if (!customColumnsData[friendlyName]) {
      getCustomColumnsDataAction({ friendlyName });
    }
  }, []);

  const byId = submissionsById;
  const inputData: unknown[] = [];

  Object.values(byId).forEach((register) => {
    const newRegister = { ...register };

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);
    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    // @ts-ignore
    newRegister.validation_status = getValidationStatusName(newRegister.validation_status, t);

    inputData.push(newRegister);
  });

  return (
    <div className="flex flex-col h-full">
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Submissions')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            getInfoAction={() => getSubmissionsAction({ friendlyName })}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  submissionsById: selectSubmissionState(state).submissionsById,
  loading: selectSubmissionState(state).loading,
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  customColumnsData: selectTable(state).customColumnsData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  getCustomColumnsDataAction: (data: GetCustomColumnsData) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AllSubmissionsPage);
