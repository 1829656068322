import { formatKeyword } from './keywords';

export const formatKeywordColumn = (newRegister: Record<any, any>, keywordById: { [key: number]: Keyword }): void => {
  newRegister.keywords = newRegister.keyword_ids.map((id: number) => formatKeyword(id, keywordById)).join(';');
  // Add main area field
  if (newRegister.keyword_ids.length > 0) {
    const firstKeyword = keywordById[newRegister.keyword_ids[0]];
    if (firstKeyword.parent_id) {
      newRegister.main_area = keywordById[firstKeyword.parent_id].name;
    }
  }
};

export const isQuestionColumn = (fieldId: string): boolean => {
  return fieldId.startsWith('question_');
};

export const isCustomColumn = (fieldId: string): boolean => {
  return fieldId.startsWith('custom_');
};
