function eqSet(as: Set<any>, bs: Set<any>) {
  if (as.size !== bs.size) return false;
  // @ts-ignore
  for (const a of as) if (!bs.has(a)) return false;
  return true;
}

const arrayEquals = (arr1: Array<any>, arr2: Array<any>): boolean => {
  return eqSet(new Set(arr1), new Set(arr2));
};

export default arrayEquals;
