import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="4" height="16" viewBox="0 0 4 16">
      <path fill="currentColor" d="M0 0H4V16H0z"></path>
    </svg>
  );
}

export default Icon;
