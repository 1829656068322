import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import {
  createFormResponse,
  deleteFormResponse,
  FormDTO,
  FormState,
  FormType,
  getFormsResponse,
  storeAnswersRequest,
  storeAnswersResponse,
  updateFormResponse,
  updateVisibilityAction,
} from './types';
import { FormTypeEnum } from './types.d';
import { put, takeLatest, select } from '@redux-saga/core/effects';
import errorSlice from '../error/slice';
import formSlice from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { baseURL } from '../root-saga';
import tableSlice from '../table/slice';
import reviewSlice from '../review/slice';
import { ReviewState } from '../review/types';
import { SubmissionState } from '../submission/types';
import { selectFormState, selectReviewState, selectSubmissionState } from '../selectors';
import { goBackFromEditReview } from '../../pages/ReviewEditPage';
import submissionSlice from '../submission/slice';
import phaseSlice from '../phase/slice';
import i18next from '../../i18n';

const getForms = async (): Promise<Result<getFormsResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getFormsResponse>(`${baseURL}/api/forms`, init);
};

function* getFormsSaga() {
  const result = (yield getForms()) as Await<ReturnType<typeof getForms>>;

  switch (result.type) {
    case 'ok':
      yield put(formSlice.actions['GET_FORMS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const updateForm = async (data: FormDTO): Promise<Result<updateFormResponse, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PATCH',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };
  return apiCall<updateFormResponse>(`${baseURL}/api/forms/${id}`, init);
};

function* updateFormSaga(data: PayloadAction<FormDTO>) {
  const result = (yield updateForm(data.payload)) as Await<ReturnType<typeof updateForm>>;
  switch (result.type) {
    case 'ok':
      const { form } = result.value.data;
      yield put(formSlice.actions['UPDATE_FORM:OK'](result.value));
      // We need to refresh table data.
      yield put(tableSlice.actions['ALL_SETTINGS:GET']());
      // Invalidate data in order to tell react to re-fetch data
      yield invalidateData(form.type);
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Form saved successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
  }
  return;
}

/**
 * The idea of this function is to touch somehow redux state so that data needs to be re-fetched.
 * @param formType
 */
function* invalidateData(formType: FormType) {
  switch (formType) {
    case FormTypeEnum.Submission:
      yield put(submissionSlice.actions.reset());
      break;
    case FormTypeEnum.Review:
      yield put(reviewSlice.actions.reset());
      break;
  }
}

const updateVisibility = async (
  data: updateVisibilityAction,
): Promise<Result<updateFormResponse, APIValidationError>> => {
  const { form_id, ...rest } = data;
  const init = {
    method: 'PATCH',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };
  return apiCall<updateFormResponse>(`${baseURL}/api/forms/${form_id}/visibility`, init);
};

function* updateVisibilitySaga(action: PayloadAction<updateVisibilityAction>) {
  const result = (yield updateVisibility(action.payload)) as Await<ReturnType<typeof updateVisibility>>;
  switch (result.type) {
    case 'ok':
      yield put(formSlice.actions['UPDATE_FORM:OK'](result.value));
      // Invalidate data in order to tell react to re-fetch data
      const { form } = result.value.data;
      yield invalidateData(form.type);
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const createForm = async (data: FormDTO): Promise<Result<createFormResponse, APIValidationError>> => {
  const { role_id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify({ ...rest, editor_role_id: role_id }),
  };
  return apiCall<createFormResponse>(`${baseURL}/api/forms`, init);
};

function* createFormSaga(action: PayloadAction<FormDTO>) {
  const result = (yield createForm(action.payload)) as Await<ReturnType<typeof createForm>>;
  switch (result.type) {
    case 'ok':
      yield put(formSlice.actions['CREATE_FORM:OK'](result.value));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Form created successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deleteForm = async (formId: number): Promise<Result<deleteFormResponse, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };
  return apiCall<deleteFormResponse>(`${baseURL}/api/forms/${formId}`, init);
};

function* deleteFormSaga(action: PayloadAction<number>) {
  const result = (yield deleteForm(action.payload)) as Await<ReturnType<typeof deleteForm>>;
  switch (result.type) {
    case 'ok':
      const formState: FormState = yield select(selectFormState);
      const form = formState.formById[action.payload];
      yield put(phaseSlice.actions.GET()); // Need to do in order to have synchronized form permissions actions
      yield put(tableSlice.actions['ALL_SETTINGS:GET']());
      yield put(formSlice.actions['DELETE_FORM:OK'](action.payload));
      // Invalidate data in order to tell react to re-fetch data
      yield invalidateData(form.type);
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Form deleted successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const storeAnswers = async (data: storeAnswersRequest): Promise<Result<storeAnswersResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };
  return apiCall<storeAnswersResponse>(`${baseURL}/api/forms/answers`, init);
};

function* storeAnswersSaga(action: PayloadAction<storeAnswersRequest>) {
  const result = (yield storeAnswers(action.payload)) as Await<ReturnType<typeof storeAnswers>>;
  switch (result.type) {
    case 'ok':
      const { answerById, validation_status } = result.value.data;
      yield put(reviewSlice.actions.UPDATE_STATUS({ review_id: action.payload.answerable_id, validation_status }));
      yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());
      yield put(formSlice.actions.GET_FORMS()); // Need to do in order to have synchronized whether form is editable

      // Generate submission detail route
      const state: ReviewState = yield select(selectReviewState);
      const review = state.reviewsById[action.payload.answerable_id];
      const state2: SubmissionState = yield select(selectSubmissionState);
      const submission = state2.submissionsById[review.submission_id];

      goBackFromEditReview(submission.external_id); //yield call(goBackFromEditReview, submission.external_id);

      yield put(formSlice.actions['GET:ANSWERS:OK']({ answerById }));
      yield put(formSlice.actions['STORE_ANSWERS:OK']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t(`Review #{{externalId}} edited successfully.`, { externalId: review.external_id }),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      yield put(formSlice.actions['STORE_ANSWERS:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('Found errors in the review. Please check them out before save.'),
          severity: 'error',
        }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(formSlice.actions.GET_FORMS, getFormsSaga),
  takeLatest(formSlice.actions.UPDATE_FORM, updateFormSaga),
  takeLatest(formSlice.actions.UPDATE_VISIBILITY, updateVisibilitySaga),
  takeLatest(formSlice.actions.CREATE_FORM, createFormSaga),
  takeLatest(formSlice.actions.DELETE_FORM, deleteFormSaga),
  takeLatest(formSlice.actions.STORE_ANSWERS, storeAnswersSaga),
];
