import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import { Info, InfoGetDTO, PersonTableRow, TableFriendlyName } from '../../store/info/types';
import { getSubmissionsRequest, Submission } from '../../store/submission/types';
import { Review, ReviewStats } from '../../store/review/types';
import { Role } from '../../store/conference/types';
import { BidOption } from '../../store/bid/types';
import { AppState } from '../../store/state';
import {
  selectBidState,
  selectInfo,
  selectKeywordState,
  selectLabelState,
  selectReviewState,
  selectSubmissionState,
  selectTable,
  selectTrackRoles,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import infoSlice from '../../store/info/slice';
import submissionSlice from '../../store/submission/slice';
import reviewSlice from '../../store/review/slice';
import { connect } from 'react-redux';
import ActionsKmenu from '../../components/ActionsKmenu/ActionsKmenu';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { Grid, GridCellProps, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import ActionsCell from '../../components/ActionsCell/ActionsCell';
import KeywordsCell from '../../components/KeywordsCell/KeywordsCell';
import IdCell from '../../components/IdCell/IdCell';
import history from '../../store/history';
import { fillRoutePath } from '../../helpers/path';
import { getRouteByName } from '../../router/routes';
import { Label } from '../../store/label/types';
import LabelsCell from '../../components/MyGridCore/LabelsCell';
import { TableSettings } from '../../components/MyGridRedux/types';
import { formatKeywordColumn } from '../../helpers/table';
import tableSlice from '../../store/table/slice';
import { getter } from '@progress/kendo-react-common';
import { useTranslation } from 'react-i18next';

interface Props {
  loading: boolean;
  info: Info;
  submissionsById: { [key: string]: Submission };
  reviewsById: { [key: string]: Review };
  reviewerStats: { [key: string]: ReviewStats };
  submissionStats: { [key: string]: { [key: string]: ReviewStats } };
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  getReviewsAction: () => void;
  deleteReviewAction: (data: number) => void;
  updateSelectionAction: (data: number[], tableFriendlyName: TableFriendlyName) => void;
  keywordById: { [key: number]: Keyword };
  bidOptionsById: { [key: number]: BidOption };
  labelsById: { [key: number]: Label };
  labelables: { [key: string]: { [key: number]: number[] } };
  tablesSettings: { [key: string]: TableSettings };
}

const ReviewersToPapersPage: React.FC<Props> = ({
  loading,
  info,
  submissionsById,
  reviewsById,
  reviewerStats,
  submissionStats,
  roleById,
  getInfoAction,
  getSubmissionsAction,
  getReviewsAction,
  deleteReviewAction,
  updateSelectionAction,
  keywordById,
  bidOptionsById,
  labelsById,
  labelables,
  tablesSettings,
}) => {
  const { t, i18n } = useTranslation();
  /* Outer table info */
  const friendlyName: TableFriendlyName = 'reviews_to_papers';
  const idGetter = getter(tablesSettings[friendlyName].gridSettings.DATA_ITEM_KEY);

  /* Inner table info*/
  const innerFriendlyName: TableFriendlyName = 'submissions';
  const LABELABLE_ITEM_KEY = tablesSettings[innerFriendlyName].gridSettings.LABELABLE_ITEM_KEY;
  const modelClass = tablesSettings[innerFriendlyName].model;

  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);
  const DetailComponent = (props: GridDetailRowProps) => {
    const data = props.dataItem.details;

    if (data) {
      return (
        <Grid data={data} resizable={true}>
          <GridColumn
            field="external_id"
            title="#"
            width="80px"
            cell={(props: GridCellProps) => {
              return (
                <IdCell
                  {...props}
                  handleOnClick={() => {
                    updateSelectionAction([idGetter(props.dataItem)], friendlyName); // Store selected parent row in redux...
                    history.push(
                      fillRoutePath(getRouteByName('RouteDetailSubmission').path, {
                        id: props.dataItem[props.field || ''], // This is the external id
                      }),
                    );
                  }}
                />
              );
            }}
          />
          <GridColumn field="title" title="Title" />
          <GridColumn field="authors_str" title="Authors" />
          <GridColumn
            field="keywords"
            title="Keywords"
            cell={(props: GridCellProps) => <KeywordsCell {...props} keywordById={keywordById} />}
          />
          <GridColumn field="main_area" title="Main area" />
          <GridColumn field="total_reviews" title="# Assignments" />
          <GridColumn field="bid" title="Bid" />
          <GridColumn
            field="labels"
            title="Labels"
            cell={(props: GridCellProps) => (
              <LabelsCell
                {...props}
                labelsById={labelsById}
                tableFriendlyName={innerFriendlyName}
                labelableIdKey={LABELABLE_ITEM_KEY}
              />
            )}
          />
          <GridColumn
            field="row_actions"
            width="100px"
            title="Actions"
            cell={ActionsCell}
            filterable={false}
            reorderable={false}
            resizable={false}
            locked={true}
            className="gcgrid"
          />
        </Grid>
      );
    }
    return (
      <div style={{ height: '50px', width: '100%' }}>
        <div style={{ position: 'absolute', width: '100%' }}>
          <div className="k-loading-image" />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName: innerFriendlyName });
    }
    if (Object.keys(reviewsById).length === 0) {
      getReviewsAction();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  // Group reviews by person and role
  const assignmentsByPersonAndRole: { [key: string]: { [key: string]: Review[] } } = {};
  Object.values(reviewsById).forEach((review) => {
    const { person_id, role_id } = review;
    if (!(person_id in assignmentsByPersonAndRole)) {
      assignmentsByPersonAndRole[person_id] = {};
    }
    if (!(role_id in assignmentsByPersonAndRole[person_id])) {
      assignmentsByPersonAndRole[person_id][role_id] = [];
    }
    assignmentsByPersonAndRole[person_id][role_id].push(review);
  });

  const byId: { [key: number]: PersonTableRow } = info['users'].byId;

  /* Build input data */
  const inputData: unknown[] = [];
  Object.values(byId).forEach((person: PersonTableRow) => {
    person.role_ids
      .map((roleId) => roleById[roleId])
      .forEach((role, index) => {
        if (role.type !== 'reviewer') return;
        const personRoleId = person.person_role_ids[index];
        const newRegister = {
          person_role_id: personRoleId,
          person_id: person.id,
          full_name: person.full_name,
          email: person.email,
          organization: person.organization,
          role: role.description,
          keyword_ids: person.keyword_ids,
          keywords: '',
          total_reviews: 0,
          completed_reviews: 0,
          percent_completed_reviews: 0,
          details: [],
        };

        formatKeywordColumn(newRegister, keywordById);

        if (
          person.id in assignmentsByPersonAndRole &&
          role.id in assignmentsByPersonAndRole[person.id] &&
          assignmentsByPersonAndRole[person.id][role.id].length > 0
        ) {
          const assignments = assignmentsByPersonAndRole[person.id][role.id];

          // Add aggregated information
          // @ts-ignore
          newRegister.total_reviews = reviewerStats[personRoleId].total_reviews;
          // @ts-ignore
          newRegister.completed_reviews = reviewerStats[personRoleId].completed_reviews;
          // @ts-ignore
          newRegister.percent_completed_reviews = reviewerStats[personRoleId].percent_completed_reviews;

          // Build detail row information
          // @ts-ignore
          newRegister.details = assignments.map((assignment, index) => {
            const submission = submissionsById[assignment.submission_id];
            if (!submission) return undefined;

            // Get labels
            // @ts-ignore
            const label_ids = labelables[modelClass][submission[LABELABLE_ITEM_KEY]] ?? [];
            const labels = label_ids.map((id) => labelsById[id].label).join(';');

            let total_reviews = 0;
            total_reviews = Object.values(submissionStats[submission.id])
              .map((stats) => stats.total_reviews)
              .reduce((previousValue, currentValue) => previousValue + currentValue, total_reviews);

            const detailRow = {
              ...submission,
              authors_str: submission.authors.map((author) => author.first_name + ' ' + author.last_name).join(';'),
              total_reviews: total_reviews,
              affinity: assignment.affinity,
              bid: assignment?.bid_option_id ? bidOptionsById[assignment.bid_option_id].name : undefined,
              label_ids,
              labels,
              person_role_id: personRoleId,
              row_actions: (
                <div>
                  <ActionsKmenu
                    actions={[
                      {
                        id: 'delete-assignment',
                        label: 'Delete assignment',
                        rowIdKey: 'id',
                        callback: () => {
                          setDialog(
                            <SimpleDialog
                              open={true}
                              handleClose={() => setDialog(undefined)}
                              handleOk={() => deleteReviewAction(assignment.id)}
                              title="Delete assignment"
                            >
                              <p>Are you sure you want to remove this assignment?</p>
                            </SimpleDialog>,
                          );
                        },
                      },
                    ]}
                    record={newRegister}
                    index={index.toString()}
                  />
                </div>
              ),
            };
            formatKeywordColumn(detailRow, keywordById);

            return detailRow;
          });
        }
        inputData.push(newRegister);
      });
  });

  return (
    <>
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Reviewers to papers')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            inputData={inputData as Record<string, unknown>[]}
            detail={DetailComponent}
            getInfoAction={() => getReviewsAction()}
          />
        )}
      </div>
      {dialog}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading || selectSubmissionState(state).loading || selectReviewState(state).loading,
  info: state.info.info,
  submissionsById: selectSubmissionState(state).submissionsById,
  reviewsById: selectReviewState(state).reviewsById,
  reviewerStats: selectReviewState(state).reviewerStats ?? {},
  submissionStats: selectReviewState(state).submissionStats ?? {},
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  bidOptionsById: selectBidState(state).bidOptions.byId,
  labelsById: selectLabelState(state).labelsById,
  labelables: selectLabelState(state).labelables,
  tablesSettings: selectTable(state).tablesSettings,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  getReviewsAction: () => dispatch(reviewSlice.actions.GET_REVIEWS()),
  deleteReviewAction: (data: number) => dispatch(reviewSlice.actions['REVIEW:DELETE'](data)),
  updateSelectionAction: (rowIds: number[], tableFriendlyName: TableFriendlyName) =>
    dispatch(tableSlice.actions['SELECTION:UPDATE']({ rowIds, tableFriendlyName })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewersToPapersPage);
