import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import cn from 'classnames';
import { nanoid } from 'nanoid';
import useTheme from '@material-ui/core/styles/useTheme';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  description?: string;
  fullWidth?: boolean;
  error?: boolean;
  helperText?: string;
  ref?: any;
  canViewFeedback?: boolean;
};

export const TextArea = ({
  id,
  label,
  description,
  fullWidth,
  error,
  helperText,
  required,
  className,
  ...inputProps
}: TextAreaProps): JSX.Element => {
  const inputId = id || nanoid();
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={cn(className, { error, fullWidth })}>
        {label && (
          <label htmlFor={inputId} className={`${theme.palette.text.primary}`}>
            {required ? `${label} *` : label}
          </label>
        )}
        {description && <p className="text-xs mb-3">{description}</p>}
        <div>
          <textarea {...inputProps} id={inputId} required={required} />
          {helperText && <span className={cn('helperText', { error })}>{helperText}</span>}
        </div>
      </StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`inline-flex flex-col`}

  label {
    ${tw`mb-1.5 ml-0.5 text-sm`}
    font-weight: normal;
  }

  div {
    ${tw`w-full`}

    textarea {
      ${tw`rounded w-full`}
      border-color: ${(props) => props.theme.palette.text.hint};
      --tw-ring-color: ${(props) => props.theme.palette.text.hint};
      &.focus {
        border: ${(props) => '1px solid ' + props.theme.palette.primary.main}};
        --tw-ring-color: transparent;
      }
    }

    textarea:focus-visible,
    textarea:focus,
    textarea:hover {
      border-color: ${(props) => props.theme.palette.text.primary};
      outline: none;
    }

    .helperText {
      ${tw`text-sm m-0.5 mb-1 leading-4 h-4`}
      color: ${(props) => props.theme.palette.text.secondary}

      &.error {
        color: ${(props) => props.theme.palette.error.main};
      }
    }
  }

  &.error {
    label {
      color: ${(props) => props.theme.palette.error.main};
    }

    div {
      textarea {
        border: ${(props) => '2px solid ' + props.theme.palette.error.main}};
      }

      span.helperText {
        color: ${(props) => props.theme.palette.error.main};
      }
    }
  }

  &.fullWidth {
    ${tw`flex flex-row`}

    label {
      width: 10rem;
      padding-top: 0.375rem;
    }
  }
`;
