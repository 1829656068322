import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import Globe from '../../../../icons/Globe';
import { availableLanguages } from '../../../../i18n';

interface Props {
  className?: string;
  onChange?: (langCode: string) => void;
  changeLanguageOnChange?: boolean;
}

const LanguageSelector: React.FC<Props> = ({ className, changeLanguageOnChange, onChange }): JSX.Element => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.resolvedLanguage);

  useEffect(() => {
    setSelectedLanguage(i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  // Language options according to available languages in i18n.js
  const options = availableLanguages.map((langCode) => {
    let optionObject;
    switch (langCode) {
      case 'en':
        optionObject = { id: langCode, label: 'English (en)' };
        break;
      case 'zh':
        optionObject = { id: langCode, label: '中文 (zh)' };
        break;
      default:
        throw new Error('Language code not valid.');
    }
    return optionObject;
  });

  const valueRender = (element: React.ReactElement<HTMLSpanElement>, value: any) => {
    if (!value) {
      return element;
    }

    const children = (
      <div key={1} className="flex">
        <Globe stroke="#000" />
        <div className="ml-1">{element.props.children as any}</div>
      </div>
    );

    return React.cloneElement(element, { ...element.props }, children);
  };

  return (
    <StyledSelector className={className}>
      <DropDownList
        textField="label"
        dataItemKey="id"
        data={options}
        value={options.find((x) => {
          return x.id === selectedLanguage;
        })}
        onChange={(event) => {
          const langCode = event.target.value.id;
          setSelectedLanguage(langCode);
          if (changeLanguageOnChange) {
            i18n.changeLanguage(langCode);
          }
          onChange?.(langCode);
        }}
        className="selectType m-0"
        valueRender={valueRender ?? undefined}
      />
    </StyledSelector>
  );
};

const StyledSelector = styled.div`
  .k-dropdown-wrap {
    background-color: #ebebeb !important;
    border-radius: 5px;
    border-color: #ccc !important;
  }
`;

export default LanguageSelector;
