import React from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { Select, SelectRadioButton } from '../../ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export type columnsOption = 'current' | 'all';
export type rowsOption = 'selected' | 'all';

interface Props extends Omit<SimpleDialogProps, 'handleOk'> {
  numSelected: number;
  handleOk?: (columns: columnsOption, rows: rowsOption) => void;
}

const ExportTableDialog: React.FC<Props> = ({ numSelected, handleOk, ...props }) => {
  const { t, i18n } = useTranslation();
  const colsOptions = [
    { id: 'all', label: t('All') },
    { id: 'current', label: t('Current view') },
  ];

  const rowsOptions = [
    { id: 'all', label: t('All') },
    { id: 'selected', label: t('Selected') },
  ];

  const formatOptions = [{ id: '1', label: t('Excel file (.xlsx)') }];
  const [selectedColsOption, setSelectedColsOption] = React.useState<columnsOption | undefined>(
    colsOptions[0].id as columnsOption,
  );
  const [selectedRowsOption, setSelectedRowsOption] = React.useState<rowsOption | undefined>(
    rowsOptions[0].id as rowsOption,
  );

  return (
    <SimpleDialog
      {...props}
      handleOk={() => selectedColsOption && selectedRowsOption && handleOk?.(selectedColsOption, selectedRowsOption)}
    >
      <>
        <div className="mb-5">
          <p className="mb-2">{t('Which columns?')}</p>
          <SelectRadioButton
            value={selectedColsOption}
            options={colsOptions}
            onChange={(value) => setSelectedColsOption(value as columnsOption)}
          />
        </div>
        <div className="mb-5">
          <p className="mb-2">{t('Which rows?')}</p>
          <StyledSelectWrapper>
            <SelectRadioButton
              value={selectedRowsOption}
              options={rowsOptions}
              onChange={(value) => setSelectedRowsOption(value as rowsOption)}
              disabled={numSelected == 0}
              className={numSelected == 0 ? 'my-grid-redux-export-all' : ''}
            />
          </StyledSelectWrapper>
        </div>
        <Select label={t('File format')} value={formatOptions[0].id} options={formatOptions} />
      </>
    </SimpleDialog>
  );
};

export default ExportTableDialog;

const StyledSelectWrapper = styled.div`
  .my-grid-redux-export-all {
    .radiobutton-wrapper {
      opacity: 0.4;
    }
    .radiobutton-wrapper:first-child {
      opacity: 1;
    }
  }
`;
