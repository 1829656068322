import React from 'react';
import { InputText, Select } from '../ui';
import DateTimePickerWithOffset from '../DateTimePickerWithOffset/DateTimePickerWithOffset';
import styled from 'styled-components';
import { getUserOffset } from '../../helpers/timezone';
import { AppState } from '../../store/state';
import { selectCurrentUser, selectTrackRoles } from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { Role } from '../../store/conference/types';
import { useTranslation } from 'react-i18next';
import { getRoleName } from '../../helpers/translations';

interface Props {
  phase: PhaseDTO;
  timezone: string | null;
  dateFormat: string | null;
  roleById: { [key: string]: Role };
  onChange: (data: PhaseDTO, isValid: boolean) => void;
}

const PhaseAddForm: React.FC<Props> = ({ phase, timezone, dateFormat, roleById, onChange }) => {
  const { t, i18n } = useTranslation();
  const utcOffset = getUserOffset(timezone);

  const handleChange = (phase: PhaseDTO) => {
    const isInvalid =
      !phase.name ||
      (isRoleNeeded && !phase.role_id) ||
      !phase.start_date ||
      !phase.end_date ||
      new Date(phase.start_date) > new Date(phase.end_date);

    onChange(phase, !isInvalid);
  };

  const isRoleNeeded = (['review', 'bidding'] as PhaseType[]).includes(phase.type);

  return (
    <StyledPhaseAddForm>
      <InputText
        label={t('Name')}
        required
        value={phase.name}
        onChange={(e) => handleChange({ ...phase, name: e.target.value })}
        className="mb-3"
        fullWidth={true}
      />
      {isRoleNeeded && (
        <StyledSelectWrapper className="flex mb-3 justify-between items-center">
          <Select
            label={t('Role')}
            required
            value={phase.role_id?.toString()}
            onChange={(value) => handleChange({ ...phase, role_id: parseInt(value) })}
            options={Object.values(roleById)
              .filter((role) => role.type === 'reviewer')
              .map((role) => ({ id: role.id.toString(), label: getRoleName(role, t) }))}
          />
        </StyledSelectWrapper>
      )}
      <DateTimePickerWithOffset
        required={true}
        utcOffset={utcOffset}
        format={dateFormat ?? undefined}
        defaultValue={phase.start_date ? new Date(phase.start_date) : undefined}
        onChange={(date) => handleChange({ ...phase, start_date: date.toISOString() })}
        className="ml-2 text-lg"
        label={t('Starting')}
      />
      <DateTimePickerWithOffset
        required={phase.type != 'author_notification'}
        utcOffset={utcOffset}
        format={dateFormat ?? undefined}
        defaultValue={phase.end_date ? new Date(phase.end_date) : undefined}
        onChange={(date) => handleChange({ ...phase, end_date: date.toISOString() })}
        className="ml-2 text-sm"
        label={t('Ending')}
        errors={
          phase.end_date && new Date(phase.start_date) > new Date(phase.end_date)
            ? t('The end date must be a date after start date.')
            : ''
        }
      />
    </StyledPhaseAddForm>
  );
};

const mapStateToProps = (state: AppState) => ({
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
  roleById: selectTrackRoles(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

const StyledSelectWrapper = styled.div`
  .labelSelect {
    width: 10rem;
  }
  .selectType {
    width: 100%;

    .k-dropdown-wrap {
      border-radius: 0.25rem;
      border-color: #afb0b1;
    }
  }
`;

const StyledPhaseAddForm = styled.div`
  label {
    margin-right: 0;
    margin-left: 0;
    width: 10rem;
  }
  .k-widget {
    flex-grow: 1;
    width: 100%;
  }
  .errorText {
    margin: 0 0 0 8rem;
    color: ${(props) => props.theme.palette.error.main};
    font-size: 0.75rem;
    text-align: left;
    font-weight: 400;
    letter-spacing: 0.03333em;
    line-height: 1;
  }
  > div:last-child .date-wrapper {
    margin-bottom: 0;
  }
  .dropdown-wrapper {
    width: 100%;
  }
`;

export default connect(mapStateToProps, mapDispatchToProps)(PhaseAddForm);
