import React from 'react';
import { Checkbox } from '../ui';
import { PaperStatus } from '../../store/paper-status/types';
import { CategoryEnum } from '../../store/paper-status/types.d';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName, getPhaseTypeName } from '../../helpers/translations';

interface Props {
  paperStatusIds: number[];
  paperStatusById: { [key: number]: PaperStatus };
  onChange: (paperStatusIds: number[]) => void;
  phaseType: PhaseType;
}

const PaperStatusPicker: React.FC<Props> = ({ paperStatusIds, paperStatusById, onChange, phaseType }) => {
  const { t, i18n } = useTranslation();
  let paperStatusOptions: PaperStatus[];
  switch (phaseType) {
    case 'bidding':
      paperStatusOptions = Object.values(paperStatusById).filter(
        (paperStatus) => paperStatus.category != CategoryEnum.Rejection,
      );
      break;
    case 'discussion':
      paperStatusOptions = Object.values(paperStatusById).filter(
        (paperStatus) => paperStatus.category == CategoryEnum.In_Progress,
      );
      break;
    default:
      paperStatusOptions = Object.values(paperStatusById);
  }
  return (
    <table className="mt-4 ml-4 py-3.5 px-0">
      <thead>
        <tr>
          <th className="pt-1.5 pb-4">
            {t('Paper status for {{phaseType}}', { phaseType: getPhaseTypeName(phaseType, t).toLowerCase() })}
          </th>
        </tr>
      </thead>
      <tbody>
        {paperStatusOptions.map((paperStatus, index) => (
          <tr key={index}>
            <td className="px-3.5 py-1">{getPaperStatusName(paperStatus.name, t)}</td>
            <td key={index} className="px-0 pt-2 pb-1">
              <Checkbox
                checked={paperStatusIds.includes(paperStatus.id)}
                onChange={(e) => {
                  let newVal: number[];
                  if (e.target.checked) {
                    newVal = [...paperStatusIds, paperStatus.id];
                  } else {
                    newVal = paperStatusIds.filter((id) => id != paperStatus.id);
                  }
                  onChange(newVal);
                }}
                className="align-middle"
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default PaperStatusPicker;
