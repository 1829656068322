import React from 'react';
import { Format } from '../../store/form/types';
import Latex from 'react-latex';

interface Props {
  value: string;
  format: Format;
}

const TextAreaDisplay: React.FC<Props> = ({ value, format }) => {
  /**
   * Find \\ o \newline and replace by $\\$
   * @param str
   */
  const processValue = (str: string) => {
    // TODO Consecutive line breaks ex: \\ \\ are not working well. Found that writing \\ $~$ \\ into TextArea apparently works...
    const regex1 = /\\\\/g;
    const regex2 = /\\newline/g;
    return str.replace(regex1, '$\\\\$').replace(regex2, '$\\\\$');
  };

  let content;
  // View mode
  const backgroundColor = 'transparent'; //Previous defined color: rgb(229 231 235)
  if (format === 'plain') {
    const html = value.replace(/(\n|\r|\r\n)/g, '<br>'); // Need to transform line breaks of textarea to html
    content = <p style={{ backgroundColor }} dangerouslySetInnerHTML={{ __html: html }} />;
  } else {
    content = (
      <div style={{ backgroundColor }} className="relative top-0 p-3 textAreaContent">
        <Latex>{processValue(value)}</Latex>
      </div>
    );
  }
  return content;
};

export default TextAreaDisplay;
