import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getUserKeywordsResponse,
  PostChangePasswordDTO,
  postUserKeywordsDTO,
  UpdateAvatarDTO,
  UpdateMainAliasDTO,
  UpdateUserAvatarDTO,
  UpdateUserDTO,
  UserAlias,
  UserDTO,
  UserState,
  postAliasUserResponse,
  verifyEmailRequest,
} from './types';
import { Language } from '@amcharts/amcharts4/core';

export const initialState: UserState = {
  data: {
    id: 0,
    person: {
      id: 0,
      person_id: 0,
      full_name: '',
      first_name: '',
      middle_name: null,
      last_name: '',
      country: null,
      language: null,
      timezone: null,
      date_format: null,
      organization: null,
      cmt_id: null,
      dblp_key: null,
      google_scholar_id: null,
      semantic_scholar_id: null,
      orcid_id: null,
      avatar_url: null,
    },
    user_alias_by_id: { 0: { id: 0, email: '', email_verified_at: null } },
    password_set: false,
    keyword_ids: undefined,
  },
  loading: false,
};

const userSlice = createSlice({
  name: 'USER',
  initialState,
  reducers: {
    ['GET']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET:OK']: (state, action: PayloadAction<UserDTO>) => {
      // Some data arrangements...
      const new_user_alias_by_id: { [key: number]: UserAlias } = {};
      action.payload.user_alias_by_id.forEach((value) => {
        new_user_alias_by_id[value.id] = value;
      });

      const { impersonated, is_superadmin, ...payload } = action.payload;

      const user = {
        ...payload,
        person: {
          ...payload.person,
          full_name: payload.person.first_name + ' ' + payload.person.last_name,
          timezone: payload.person.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        user_alias_by_id: new_user_alias_by_id,
      };
      return {
        ...state,
        data: user,
        loading: false,
      };
    },
    ['GET:KO']: (_state) => ({
      ...initialState,
    }),
    ['UPDATE']: (state, _action: PayloadAction<UpdateUserDTO>) => ({
      ...state,
    }),
    ['UPDATE:OK']: (state, action: PayloadAction<UpdateUserDTO>) => {
      return {
        ...state,
        data: {
          ...state.data,
          person: {
            ...state.data.person,
            ...action.payload,
          },
        },
      };
    },
    ['AVATAR:UPDATE']: (state, _action: PayloadAction<UpdateUserAvatarDTO>) => ({
      ...state,
    }),
    ['AVATAR:UPDATE:OK']: (state, action: PayloadAction<UpdateAvatarDTO>) => ({
      ...state,
      data: {
        ...state.data,
        person: {
          ...state.data.person,
          avatar_url: action.payload.avatar_url,
        },
      },
    }),
    ['AVATAR:DELETE']: (state) => ({
      ...state,
    }),
    ['AVATAR:DELETE:OK']: (state) => ({
      ...state,
      data: {
        ...state.data,
        person: {
          ...state.data.person,
          avatar_url: null,
        },
      },
    }),
    ['CHANGE_PASSWORD']: (state, _action: PayloadAction<PostChangePasswordDTO>) => ({
      ...state,
    }),
    ['CHANGE_PASSWORD:OK']: (state) => ({
      ...state,
      data: {
        ...state.data,
        password_set: true,
      },
    }),
    ['UPDATE_MAIN_ALIAS']: (state, _action: PayloadAction<UpdateMainAliasDTO>) => ({
      ...state,
    }),
    ['UPDATE_MAIN_ALIAS:OK']: (state, action: PayloadAction<UpdateMainAliasDTO>) => ({
      ...state,
      data: {
        ...state.data,
        id: action.payload.user_id,
      },
    }),
    ['CLEAR:VALIDATION']: (state) => ({
      ...state,
      validationErrors: undefined,
    }),
    ['GET:KEYWORDS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET:KEYWORDS:OK']: (state, action: PayloadAction<getUserKeywordsResponse>) => ({
      ...state,
      loading: false,
      data: {
        ...state.data,
        keyword_ids: action.payload.data.keyword_ids,
      },
    }),
    ['POST:KEYWORDS']: (state, action: PayloadAction<postUserKeywordsDTO>) => ({
      ...state,
    }),
    ['POST:KEYWORDS:OK']: (state, action: PayloadAction<postUserKeywordsDTO>) => ({
      ...state,
      data: {
        ...state.data,
        keyword_ids: action.payload.keyword_ids,
      },
    }),
    ['POST:ALIAS']: (state, action: PayloadAction<verifyEmailRequest>) => {
      return {
        ...state,
      };
    },
    ['POST:ALIAS:OK']: (state, action: PayloadAction<postAliasUserResponse>) => {
      return {
        ...state,
        data: {
          ...state.data,
          user_alias_by_id: {
            ...state.data.user_alias_by_id,
            ...action.payload,
          },
        },
      };
    },
    ['DELETE:ALIAS']: (state, action: PayloadAction<number>) => {
      return {
        ...state,
      };
    },
    ['DELETE:ALIAS:OK']: (state, action: PayloadAction<number>) => {
      const { [action.payload]: _, ...rest } = { ...state.data.user_alias_by_id };
      return {
        ...state,
        data: {
          ...state.data,
          user_alias_by_id: rest,
        },
      };
    },
  },
});

export default userSlice;
