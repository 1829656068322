import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';

interface Props extends GridCellProps {}

const TitleCell: React.FC<Props> = ({ ...props }) => {
  const field = props.field || '';
  const value = props.dataItem[field] || '';

  return (
    <td
      colSpan={props.colSpan}
      className={`${props.className}`}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      style={props.style}
    >
      <span className="font-semibold">{value}</span>
    </td>
  );
};

export default TitleCell;
