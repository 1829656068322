import { put, takeLatest } from '@redux-saga/core/effects';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { baseURL } from '../root-saga';
import {
  GetPaperStatusOk,
  GetPaperStatusRoleResponse,
  PaperStatusRoleDTO,
  PaperStatusDTO,
  PostPaperStatusOk,
  PostPaperStatusRoleResponse,
} from './types';
import errorSlice from '../error/slice';
import { PayloadAction } from '@reduxjs/toolkit';
import paperStatusSlice from './slice';

const getPaperStatus = async (): Promise<Result<GetPaperStatusOk, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<GetPaperStatusOk>(`${baseURL}/api/paper-statuses`, init);
};

function* getPaperStatusSaga() {
  const result = (yield getPaperStatus()) as Await<ReturnType<typeof getPaperStatus>>;
  switch (result.type) {
    case 'ok':
      yield put(paperStatusSlice.actions['GET_PAPER_STATUS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const postPaperStatus = async (data: PaperStatusDTO): Promise<Result<PostPaperStatusOk, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };
  return apiCall<PostPaperStatusOk>(`${baseURL}/api/paper-statuses`, init);
};

function* postPaperStatusSaga(data: PayloadAction<PaperStatusDTO>) {
  const result = (yield postPaperStatus(data.payload)) as Await<ReturnType<typeof postPaperStatus>>;
  switch (result.type) {
    case 'ok':
      yield put(paperStatusSlice.actions['POST:PAPER_STATUS:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      yield put(paperStatusSlice.actions['POST:PAPER_STATUS:KO']());
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const putPaperStatus = async (data: PaperStatusDTO): Promise<Result<PostPaperStatusOk, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PUT',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };
  return apiCall<PostPaperStatusOk>(`${baseURL}/api/paper-statuses/${id}`, init);
};

function* putPaperStatusSaga(data: PayloadAction<PaperStatusDTO>) {
  const result = (yield putPaperStatus(data.payload)) as Await<ReturnType<typeof putPaperStatus>>;
  switch (result.type) {
    case 'ok':
      yield put(paperStatusSlice.actions['PUT:PAPER_STATUS:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      yield put(paperStatusSlice.actions['PUT:PAPER_STATUS:KO']());
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deletePaperStatus = async (id: number): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };
  return apiCall<{ data: any }>(`${baseURL}/api/paper-statuses/${id}`, init);
};

function* deletePaperStatusSaga(data: PayloadAction<number>) {
  const result = (yield deletePaperStatus(data.payload)) as Await<ReturnType<typeof deletePaperStatus>>;
  switch (result.type) {
    case 'ok':
      yield put(paperStatusSlice.actions['DELETE:PAPER_STATUS:OK'](data.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      yield put(paperStatusSlice.actions['DELETE:PAPER_STATUS:KO']());
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const getPaperStatusRole = async (): Promise<Result<GetPaperStatusRoleResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<GetPaperStatusRoleResponse>(`${baseURL}/api/bidding/paper-status-role`, init);
};

function* getPaperStatusRoleSaga() {
  const result = (yield getPaperStatusRole()) as Await<ReturnType<typeof getPaperStatusRole>>;

  switch (result.type) {
    case 'ok':
      yield put(paperStatusSlice.actions['GET:PAPER_STATUS_ROLE:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postPaperStatusRole = async (
  data: PaperStatusRoleDTO,
): Promise<Result<PostPaperStatusRoleResponse, APIValidationError>> => {
  const { role_id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify({ ...rest, target_role_id: role_id }),
  };

  return apiCall<PostPaperStatusRoleResponse>(`${baseURL}/api/bidding/paper-status-role`, init);
};

export default [
  takeLatest(paperStatusSlice.actions['GET_PAPER_STATUS'], getPaperStatusSaga),
  takeLatest(paperStatusSlice.actions['POST:PAPER_STATUS'], postPaperStatusSaga),
  takeLatest(paperStatusSlice.actions['PUT:PAPER_STATUS'], putPaperStatusSaga),
  takeLatest(paperStatusSlice.actions['DELETE:PAPER_STATUS'], deletePaperStatusSaga),
  takeLatest(paperStatusSlice.actions['GET:PAPER_STATUS_ROLE'], getPaperStatusRoleSaga),
];
