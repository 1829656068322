import React from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';
import cn from 'classnames';
import { nanoid } from 'nanoid';

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  error?: boolean;
  ref?: any;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Checkbox = ({ id, label, className, required, error, ...inputProps }: CheckboxProps) => {
  const inputId = id || nanoid();

  return (
    <StyledDiv className={cn(className, { error })}>
      <input {...inputProps} id={inputId} type="checkbox" required={required} />
      {label && <label htmlFor={inputId}>{required ? `${label} *` : label}</label>}
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  ${tw`inline-flex items-center justify-center`}

  label {
    ${tw`text-gray-600 leading-none`}
  }

  input {
    ${tw`rounded focus:border-2 focus:border-blue-700 focus:ring-transparent focus:ring-0`}
  }

  &.error {
    label {
      ${tw`text-red-600`}
    }

    input {
      ${tw`border-red-600 ring-red-600`}
    }
  }
`;
