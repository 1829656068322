import { put, takeLatest } from '@redux-saga/core/effects';
import screenSlice from './slice';
import errorSlice from '../error/slice';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { baseURL } from '../root-saga';

const getScreen = async (): Promise<Result<getScreenResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };
  return apiCall<getScreenResponse>(`${baseURL}/api/screens`, init);
};

function* getScreenSaga() {
  const result = (yield getScreen()) as Await<ReturnType<typeof getScreen>>;

  switch (result.type) {
    case 'ok':
      yield put(screenSlice.actions['GET:SCREENS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

export default [takeLatest(screenSlice.actions['GET:SCREENS'], getScreenSaga)];
