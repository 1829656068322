import React from 'react';
import { Kmenu } from '../ui/navigation/Kmenu/Kmenu';
import { MenuItemLink } from '@progress/kendo-react-layout';
import IconButton from '@material-ui/core/IconButton';
import { RowAction, Action } from '../types';
import { MenuSelectEvent } from '@progress/kendo-react-layout/dist/npm/menu/events';

function renderMenuIconIfApplicable(props: any, key: string) {
  const icon = (
    <IconButton key={'0'} className="tooltip_icon" aria-label="actions" color="primary" style={{ fontSize: '1rem' }}>
      <span className={`k-icon k-i-${props.item.icon}`} role="presentation" key={key} />
    </IconButton>
  );
  return props.item.icon ? icon : null;
}

interface Props {
  actions: RowAction[] | Action[];
  onSelect?: (event: MenuSelectEvent) => void;
}

const ActionsKmenu: React.FC<Props> = ({ actions, onSelect }) => {
  return (
    <Kmenu
      openOnClick
      linkRender={(props: any) => {
        return (
          <MenuItemLink url={props.item.url} opened={props.opened}>
            {[renderMenuIconIfApplicable(props, props.item.text), props.item.text]}
          </MenuItemLink>
        );
      }}
      items={[
        {
          //text: 'Actions',
          icon: 'more-vertical',
          items: actions.map((x) => {
            return { text: x.label, data: { id: x.id } };
          }),
        },
      ]}
      onSelect={onSelect}
    />
  );
};

export default ActionsKmenu;
