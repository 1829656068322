import React, { useEffect, useState } from 'react';
import { Info, InfoGetDTO, PersonTableRow, TableFriendlyName } from '../../store/info/types';
import { getSubmissionsRequest, Submission } from '../../store/submission/types';
import { Review, ReviewStats } from '../../store/review/types';
import { Role } from '../../store/conference/types';
import { Grid, GridCellProps, GridColumn, GridDetailRowProps } from '@progress/kendo-react-grid';
import MyGridRedux from '../../components/MyGridRedux/MyGridRedux';
import { AppState } from '../../store/state';
import {
  selectBidState,
  selectInfo,
  selectKeywordState,
  selectLabelState,
  selectPaperStatusState,
  selectReviewState,
  selectSubmissionState,
  selectTable,
  selectTrackRoles,
} from '../../store/selectors';
import { AppDispatch } from '../../store/store';
import infoSlice from '../../store/info/slice';
import submissionSlice from '../../store/submission/slice';
import reviewSlice from '../../store/review/slice';
import { connect } from 'react-redux';
import ActionsKmenu from '../../components/ActionsKmenu/ActionsKmenu';
import ActionsCell from '../../components/ActionsCell/ActionsCell';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import { BidOption } from '../../store/bid/types';
import KeywordsCell from '../../components/KeywordsCell/KeywordsCell';
import LabelsCell from '../../components/MyGridCore/LabelsCell';
import { Label } from '../../store/label/types';
import { TableSettings } from '../../components/MyGridRedux/types';
import Loading from '../../components/Loading/Loading';
import { PaperStatus } from '../../store/paper-status/types';
import { difference } from '../../helpers/set';
import { formatKeywordColumn } from '../../helpers/table';
import { GetCustomColumnsData } from '../../store/table/types';
import tableSlice from '../../store/table/slice';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName, getTableColumnName } from '../../helpers/translations';

interface Props {
  loading: boolean;
  info: Info;
  submissionsById: { [key: string]: Submission };
  reviewsById: { [key: string]: Review };
  reviewerStats: { [key: string]: ReviewStats };
  submissionStats: { [key: string]: { [key: string]: ReviewStats } };
  roleById: { [key: string]: Role };
  getInfoAction: (data: InfoGetDTO) => void;
  getSubmissionsAction: (data: getSubmissionsRequest) => void;
  getReviewsAction: () => void;
  deleteReviewAction: (data: number) => void;
  keywordById: { [key: number]: Keyword };
  paperStatusById: { [key: number]: PaperStatus };
  bidOptionsById: { [key: number]: BidOption };
  labelsById: { [key: number]: Label };
  labelables: { [key: string]: { [key: number]: number[] } };
  tablesSettings: { [key: string]: TableSettings };
  customColumnsData: { [key: string]: { [key: number]: { [key: string]: any } } };
  getCustomColumnsDataAction: (data: GetCustomColumnsData) => void;
}

const PapersToReviewersPage: React.FC<Props> = ({
  loading,
  info,
  submissionsById,
  reviewsById,
  reviewerStats,
  submissionStats,
  roleById,
  getInfoAction,
  getSubmissionsAction,
  getReviewsAction,
  deleteReviewAction,
  keywordById,
  paperStatusById,
  bidOptionsById,
  labelsById,
  labelables,
  tablesSettings,
  customColumnsData,
  getCustomColumnsDataAction,
}) => {
  const { t, i18n } = useTranslation();
  /* Outer table info */
  const friendlyName: TableFriendlyName = 'papers_to_reviews';

  /* Inner table info */
  const innerFriendlyName: TableFriendlyName = 'users';
  const LABELABLE_ITEM_KEY = tablesSettings[innerFriendlyName].gridSettings.LABELABLE_ITEM_KEY;
  const modelClass = tablesSettings[innerFriendlyName].model;

  const DetailComponent = (props: GridDetailRowProps) => {
    const data = props.dataItem.details;
    if (data) {
      return (
        <div className="detail-grid">
          <Grid data={data} resizable={true}>
            <GridColumn field="external_id" title="#" width="80px" />
            <GridColumn field="full_name" title="Name" />
            <GridColumn field="email" title="Email" />
            <GridColumn field="role" title="Role" />
            <GridColumn
              field="keywords"
              title="Keywords"
              cell={(props: GridCellProps) => <KeywordsCell {...props} keywordById={keywordById} />}
            />
            <GridColumn field="main_area" title="Main area" />
            <GridColumn field="total_reviews" title="# Assignments" />
            <GridColumn field="bid" title="Bid" />
            <GridColumn
              field="labels"
              title="Labels"
              cell={(props: GridCellProps) => (
                <LabelsCell
                  {...props}
                  labelsById={labelsById}
                  tableFriendlyName={innerFriendlyName}
                  labelableIdKey={LABELABLE_ITEM_KEY}
                />
              )}
            />
            <GridColumn
              field="row_actions"
              width="100px"
              title="Actions"
              cell={ActionsCell}
              filterable={false}
              reorderable={false}
              resizable={false}
              locked={true}
              className="gcgrid"
            />
          </Grid>
        </div>
      );
    }
    return (
      <div style={{ height: '50px', width: '100%' }}>
        <div style={{ position: 'absolute', width: '100%' }}>
          <div className="k-loading-image" />
        </div>
      </div>
    );
  };
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);

  useEffect(() => {
    if (Object.keys(info['users'].byId).length === 0) {
      getInfoAction({ friendlyName: 'users' });
    }
    if (Object.keys(submissionsById).length === 0) {
      getSubmissionsAction({ friendlyName });
    } else if (!customColumnsData[friendlyName]) {
      getCustomColumnsDataAction({ friendlyName });
    }
    if (Object.keys(reviewsById).length === 0) {
      getReviewsAction();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  const reviewerRolesById: { [key: string]: Role } = {};
  Object.values(roleById)
    .filter((role) => role.type == 'reviewer')
    .forEach((role) => {
      reviewerRolesById[role.id] = role;
    });

  // Group reviews by submission
  const assignmentsBySubmissionId: { [key: number]: Review[] } = {};
  Object.values(reviewsById).forEach((value) => {
    if (!(value.submission_id in assignmentsBySubmissionId)) {
      assignmentsBySubmissionId[value.submission_id] = [];
    }
    assignmentsBySubmissionId[value.submission_id].push(value);
  });

  const tableSettings = tablesSettings[friendlyName];

  // Add summary reviews fields for each role
  const gridColumnProps = [...tableSettings.gridSettings.gridColumnProps];
  const current = gridColumnProps
    .filter((columnProps) => columnProps.id.startsWith('reviews_summary_'))
    .map((columnProps) => parseInt(columnProps.id.split('_')[2]));
  const expected = Object.keys(reviewerRolesById).map((roleId) => parseInt(roleId));
  const missing = Array.from(difference(new Set(expected), new Set(current)));
  let maxOrderIndex = Math.max(...gridColumnProps.map((columnProp) => columnProp.orderIndex));
  missing.forEach((roleId) => {
    const id = `reviews_summary_${roleId}`;
    gridColumnProps.push({
      id,
      locked: false,
      show: true,
      orderIndex: ++maxOrderIndex,
      children: [],
    });
  });

  const newTableSettings = {
    ...tableSettings,
    gridSettings: {
      ...tableSettings.gridSettings,
      gridColumnProps: gridColumnProps.map((columnProps, index) => {
        const newColumnProps = { ...columnProps };
        if (columnProps.id.startsWith('reviews_summary_')) {
          const roleId = parseInt(columnProps.id.split('_')[2]);
          newColumnProps['title'] = t('{{roleName}} reviews summary', { roleName: roleById[roleId].description });
          newColumnProps['children'] = [
            {
              field: `reviews_summary.${roleId}.total_reviews`,
              title: getTableColumnName('total_reviews', t),
              width: '175px',
              filter: 'numeric',
            },
            {
              field: `reviews_summary.${roleId}.completed_reviews`,
              title: getTableColumnName('completed_reviews', t),
              width: '175px',
              filter: 'numeric',
            },
            {
              field: `reviews_summary.${roleId}.percent_completed_reviews`,
              title: getTableColumnName('percent_completed_reviews', t),
              width: '175px',
              filter: 'numeric',
            },
          ];
        }
        return newColumnProps;
      }),
    },
  };

  const inputData: unknown[] = [];
  Object.values(submissionsById).forEach((submission, index) => {
    const newRegister = {
      ...submission,
    };

    formatKeywordColumn(newRegister, keywordById);

    // @ts-ignore
    newRegister.authors_str = newRegister.authors.map((author) => author.first_name + ' ' + author.last_name).join(';');
    newRegister.paper_status = getPaperStatusName(paperStatusById[newRegister.paper_status_id].name, t);

    // @ts-ignore
    newRegister.file_upload_str = newRegister.file_upload?.filename_original;

    // Initialize aggregated information
    // @ts-ignore
    newRegister.reviews_summary = {};
    const fields = ['total_reviews', 'completed_reviews', 'percent_completed_reviews'];
    Object.values(reviewerRolesById).map((role) => {
      // @ts-ignore
      newRegister.reviews_summary[role.id] = {};
      fields.forEach((field) => {
        let value = 0;
        if (submission.id in submissionStats && role.id in submissionStats[submission.id]) {
          // @ts-ignore
          value = submissionStats[submission.id][role.id][field];
        }
        // @ts-ignore
        newRegister.reviews_summary[role.id][field] = value;
      });
    });

    /* Fill detail component data */
    const assignments = assignmentsBySubmissionId[newRegister.id] ?? [];
    // @ts-ignore
    newRegister.details = assignments.map((assignment) => {
      const person: PersonTableRow = info.users.byId[assignment.person_id];
      if (!person) return undefined;

      // Get labels
      // @ts-ignore
      const label_ids = labelables[modelClass][person[LABELABLE_ITEM_KEY]] ?? [];
      const labels = label_ids.map((id) => labelsById[id].label).join(';');

      const detailRow = {
        external_id: assignment.external_id,
        full_name: person.full_name,
        email: person.email,
        role: roleById[assignment.role_id].description,
        keyword_ids: person.keyword_ids,
        total_reviews: reviewerStats[assignment.person_role_id].total_reviews,
        affinity: assignment.affinity,
        bid: assignment?.bid_option_id ? bidOptionsById[assignment.bid_option_id].name : undefined,
        label_ids,
        labels,
        row_actions: (
          <div>
            <ActionsKmenu
              actions={[
                {
                  id: 'delete-assignment',
                  label: 'Delete assignment',
                  rowIdKey: 'id',
                  callback: () => {
                    setDialog(
                      <SimpleDialog
                        open={true}
                        handleClose={() => setDialog(undefined)}
                        handleOk={() => deleteReviewAction(assignment.id)}
                        title="Delete assignment"
                      >
                        <p>Are you sure you want to remove this assignment?</p>
                      </SimpleDialog>,
                    );
                  },
                },
              ]}
              record={newRegister}
              index={index.toString()}
            />
          </div>
        ),
      };
      formatKeywordColumn(detailRow, keywordById);

      return detailRow;
    });

    inputData.push(newRegister);
  });

  return (
    <>
      <h2 className="text-xl mb-8 font-bold text-gray-700">{t('Papers to reviewers')}</h2>
      <div className="mb-2 h-full">
        {loading ? (
          <Loading />
        ) : (
          <MyGridRedux
            friendlyName={friendlyName}
            initTableSettings={newTableSettings}
            inputData={inputData as Record<string, unknown>[]}
            detail={DetailComponent}
            getInfoAction={() => getReviewsAction()}
          />
        )}
      </div>
      {dialog}
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectInfo(state).loading || selectSubmissionState(state).loading || selectReviewState(state).loading,
  info: state.info.info,
  submissionsById: selectSubmissionState(state).submissionsById,
  reviewsById: selectReviewState(state).reviewsById,
  reviewerStats: selectReviewState(state).reviewerStats ?? {},
  submissionStats: selectReviewState(state).submissionStats ?? {},
  roleById: selectTrackRoles(state),
  keywordById: selectKeywordState(state).keywordById,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
  bidOptionsById: selectBidState(state).bidOptions.byId,
  labelsById: selectLabelState(state).labelsById,
  labelables: selectLabelState(state).labelables,
  tablesSettings: selectTable(state).tablesSettings,
  customColumnsData: selectTable(state).customColumnsData,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getInfoAction: (data: InfoGetDTO) => dispatch(infoSlice.actions.GET(data)),
  getSubmissionsAction: (data: getSubmissionsRequest) => dispatch(submissionSlice.actions.GET_SUBMISSIONS(data)),
  getReviewsAction: () => dispatch(reviewSlice.actions.GET_REVIEWS()),
  deleteReviewAction: (data: number) => dispatch(reviewSlice.actions['REVIEW:DELETE'](data)),
  getCustomColumnsDataAction: (data: GetCustomColumnsData) =>
    dispatch(tableSlice.actions['CUSTOMCOLUMNS:DATA:GET'](data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PapersToReviewersPage);
