import React from 'react';
import { faUsersLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IconProps {
  stroke: string;
}

function Icon({ stroke = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg width="20" height="20" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6785 8.1061C16.6408 8.1061 18.2316 6.51535 18.2316 4.55305C18.2316 2.59076 16.6408 1 14.6785 1C12.7162 1 11.1255 2.59076 11.1255 4.55305C11.1255 6.51535 12.7162 8.1061 14.6785 8.1061Z"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.357 12.0828C18.1189 10.8369 16.435 10.1363 14.6785 10.1363C12.922 10.1363 11.2381 10.8369 10 12.0828"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.59485 8.46022C6.10265 8.46022 7.32496 7.23791 7.32496 5.73011C7.32496 4.22231 6.10265 3 4.59485 3C3.08706 3 1.86475 4.22231 1.86475 5.73011C1.86475 7.23791 3.08706 8.46022 4.59485 8.46022Z"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.18981 11.5159C7.23845 10.5585 5.94455 10.0202 4.5949 10.0202C3.24526 10.0202 1.95135 10.5585 1 11.5159"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5949 8.46022C26.1027 8.46022 27.325 7.23791 27.325 5.73011C27.325 4.22231 26.1027 3 24.5949 3C23.0871 3 21.8647 4.22231 21.8647 5.73011C21.8647 7.23791 23.0871 8.46022 24.5949 8.46022Z"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1898 11.5159C27.2385 10.5585 25.9446 10.0202 24.5949 10.0202C23.2453 10.0202 21.9514 10.5585 21 11.5159"
        stroke={stroke}
        strokeWidth="1.0625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="0.5" y1="16.5" x2="28.5" y2="16.5" stroke={stroke} strokeLinecap="round" />
    </svg>
  );
}

export default Icon;
