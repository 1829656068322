import React from 'react';
import styled from 'styled-components';
import { Role } from '../../store/conference/types';
import { Form, FormVisibilityOption } from '../../store/form/types';
import DateTimeWithOffset from '../DateTimeWithOffset/DateTimeWithOffset';
import FormPermissionsPlain from '../FormPermissionsPlain/FormPermissionsPlain';
import { useTranslation } from 'react-i18next';
import { getActionName, getFormName } from '../../helpers/translations';

interface Props {
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  initEvent: PhaseEvent;
  phaseEvents?: PhaseEvent[];
  actionsState: { [key in PhaseActionType]: boolean };
  allPhasesEvents?: PhaseEvent[];
}

const ResultingState: React.FC<Props> = ({
  roleById,
  formById,
  initEvent,
  phaseEvents = [],
  actionsState,
  allPhasesEvents = [],
}) => {
  const { t, i18n } = useTranslation();
  // Render actions
  const stateElements: JSX.Element[] = [];
  Object.entries(actionsState)
    .filter(([actionType]) => (actionType as PhaseActionType) !== 'form_permission_change')
    .forEach(([actionType, value], index) => {
      stateElements.push(
        <div key={index}>
          {getActionName(actionType as PhaseActionType, t)}:
          <span className={`${value ? 'text-green-600' : 'text-red-600'}`}>
            {value ? ' ' + t('Enabled') : ' ' + t('Disabled')}
          </span>
        </div>,
      );
    });

  /*
    RESULTING STATE FOR ATTACHED FORMS
  */

  // Function that compute the state of each attached form since event.starts_at
  const computeNextFormPermissionsState = (
    form_id: any,
    eventStartsAt: string | null,
  ): {
    [key: number]: {
      to_role_id: number | null;
      visibility: FormVisibilityOption;
    }[];
  } => {
    const nextFormState: {
      [key: number]: {
        to_role_id: number | null;
        visibility: FormVisibilityOption;
      }[];
    } = {};

    const phasesEvents = Object.values(allPhasesEvents).filter(
      (data) => data.starts_at && eventStartsAt && data.starts_at <= eventStartsAt,
    );

    // Iterate through all the events
    phasesEvents.forEach(({ actions }: PhaseEvent) => {
      actions
        // Filter only the actions of 'form_permission_change' with the form_id passed
        .filter((action) => action.type === 'form_permission_change' && action.body.params.form_id === form_id)
        // Iterate over the filtered actions
        .forEach(
          ({
            body: {
              params: { permissions },
            },
          }: PhaseAction) => {
            // Update the nextFormState object with the new permissions
            Object.keys(permissions).forEach((roleId) => {
              nextFormState[parseInt(roleId)] = permissions[roleId].map(
                (permission: { to_role_id: string | null; visibility: FormVisibilityOption }) => ({
                  to_role_id: permission.to_role_id,
                  visibility: permission.visibility,
                }),
              );
            });
          },
        );
    });

    return nextFormState;
  };

  /* 
    Put all unique form_ids of the phase in an array
    to show in resulting state all forms of the phase
    since clicked event
  */
  const formIds = new Set<number>();
  phaseEvents.forEach((event) => {
    const phaseAttachedForms = event.actions.filter((action) => action.type === 'form_permission_change');

    phaseAttachedForms.forEach((action) => {
      formIds.add(action.body.params.form_id);
    });
  });

  const phaseFormIds: number[] = Array.from(formIds);

  return (
    <div>
      <div className="flex flex-row pb-4">
        <h2 className="w-40 text-base font-semibold">{t('After')}</h2>
        <div className="text-sm pt-0.5 font-semibold">
          <DateTimeWithOffset date={initEvent.starts_at} showTime={true} />
        </div>
      </div>
      <div className="flex flex-row py-4">
        <h2 className="w-40 text-base font-semibold">{t('Event name')}</h2>
        <div className="text-sm pt-0.5 font-semibold">{initEvent.name}</div>
      </div>
      {stateElements.length > 0 && (
        <div className="flex flex-row py-4">
          <h2 className="w-40 text-base font-semibold">{t('State')}</h2>
          <div className="pt-0.5">
            <div className="text-sm">{stateElements}</div>
          </div>
        </div>
      )}

      {phaseFormIds.length > 0 && (
        <div className="flex flex-row pt-4">
          <h2 className="w-40 text-base font-semibold">{t('Forms')}</h2>
          <div className="pt-0.5">
            {phaseFormIds.map((form_id, index) => {
              const form = formById[form_id];
              const attachedFormState = computeNextFormPermissionsState(form_id, initEvent.starts_at);
              // Render attached form state
              return (
                <div className="mb-4" key={index}>
                  <h3 className="text-sm font-semibold">{getFormName(form.name, form.is_default, t)}</h3>
                  <StyledFormPermissions className="text-sm">
                    {Object.entries(attachedFormState).length === 0 ? (
                      <div className="flex items-center mb-1 cursor-default">
                        <span className="fp-hidden">{t('Nobody can read')}</span>
                      </div>
                    ) : (
                      <FormPermissionsPlain roleById={roleById} permissions={attachedFormState} form={form} />
                    )}
                  </StyledFormPermissions>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultingState;

const StyledFormPermissions = styled.div`
  > div {
    margin: 0;
  }
  .fp-read,
  .fp-write {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .fp-hidden {
    --tw-text-opacity: 1;
    /*color: rgba(220, 38, 38, var(--tw-text-opacity));*/
  }
`;
