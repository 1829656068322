import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initialState: ScreenState = {
  loading: false,
  screenByName: {},
};

const screenSlice = createSlice({
  name: 'SCREEN',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET:SCREENS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET:SCREENS:OK']: (state, action: PayloadAction<getScreenResponse>) => ({
      ...state,
      loading: false,
      screenByName: action.payload.data.screenByName,
    }),
    ['UPDATE:SCREEN:OK']: (state, action: PayloadAction<updateScreenRequest>) => ({
      ...state,
      screenByName: {
        ...state.screenByName,
        [action.payload.screen.name]: action.payload.screen,
      },
    }),
  },
});

export default screenSlice;
