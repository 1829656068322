import React from 'react';

interface IconProps {
  stroke: string;
}

function Icon({ stroke = 'currentColor' }: IconProps): JSX.Element {
  return (
    <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1C9.76962 1 9.54125 1.00866 9.31522 1.02566C4.82918 1.36316 1.26065 4.98928 1.01365 9.5C1.00459 9.66551 1 9.83222 1 10C1 10.1678 1.00459 10.3345 1.01365 10.5C1.26065 15.0107 4.82917 18.6368 9.31522 18.9743C9.54125 18.9913 9.76962 19 10 19C10.2304 19 10.4587 18.9913 10.6848 18.9743C15.1708 18.6368 18.7393 15.0107 18.9863 10.5C18.9954 10.3345 19 10.1678 19 10C19 9.83222 18.9954 9.66551 18.9863 9.5C18.7393 4.98928 15.1708 1.36316 10.6848 1.02566C10.4587 1.00866 10.2304 1 10 1ZM10.171 2.00179C10.4226 2.35157 10.7371 2.84221 11.0528 3.47361C11.7304 4.82893 12.4155 6.83643 12.4928 9.5H7.50722C7.58448 6.83643 8.26955 4.82893 8.94721 3.47361C9.26291 2.84221 9.57743 2.35157 9.82897 2.00179C9.88584 2.0006 9.94285 2 10 2C10.0572 2 10.1142 2.0006 10.171 2.00179ZM7.50722 10.5H12.4928C12.4155 13.1636 11.7304 15.1711 11.0528 16.5264C10.7371 17.1578 10.4226 17.6484 10.171 17.9982C10.1142 17.9994 10.0572 18 10 18C9.94285 18 9.88583 17.9994 9.82897 17.9982C9.57743 17.6484 9.26291 17.1578 8.94721 16.5264C8.26955 15.1711 7.58448 13.1636 7.50722 10.5ZM6.50682 9.5C6.58457 6.66126 7.31494 4.50208 8.05279 3.02639C8.2194 2.69317 8.38629 2.39498 8.54599 2.13181C4.98138 2.78633 2.24332 5.80358 2.01537 9.5H6.50682ZM2.01537 10.5H6.50682C6.58457 13.3387 7.31494 15.4979 8.05279 16.9736C8.2194 17.3068 8.38629 17.605 8.54599 17.8682C4.98138 17.2137 2.24332 14.1964 2.01537 10.5ZM11.454 17.8682C15.0186 17.2137 17.7567 14.1964 17.9846 10.5H13.4932C13.4154 13.3387 12.6851 15.4979 11.9472 16.9736C11.7806 17.3068 11.6137 17.605 11.454 17.8682ZM13.4932 9.5C13.4154 6.66126 12.6851 4.50208 11.9472 3.02639C11.7806 2.69317 11.6137 2.39498 11.454 2.13181C15.0186 2.78633 17.7567 5.80358 17.9846 9.5H13.4932Z"
        fill={stroke}
      />
    </svg>
  );
}

export default Icon;
