import React from 'react';

interface IconProps {
  color?: string;
  className?: string;
}

function Icon({ color = '#202021', className }: IconProps): JSX.Element {
  return (
    <svg
      id="Componente_28_3"
      data-name="Componente 28 – 3"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14.65 14.5"
      className={className}
    >
      <path
        id="Trazado_3812"
        data-name="Trazado 3812"
        d="M1344.5,6677.94a.5.5,0,0,1,0,.71l-1.04,1.04-2-2,1.04-1.04a.5.5,0,0,1,.71,0Z"
        transform="translate(-1330 -6676.5)"
        fill={color}
      />
      <path
        id="Trazado_3813"
        data-name="Trazado 3813"
        d="M1342.75,6680.4l-2-2-6.81,6.81a.387.387,0,0,0-.12.19l-.81,2.42a.249.249,0,0,0,.32.31l2.41-.8a.513.513,0,0,0,.2-.12Z"
        transform="translate(-1330 -6676.5)"
        fill={color}
      />
      <path
        id="Trazado_3814"
        data-name="Trazado 3814"
        d="M1330,6689.5a1.5,1.5,0,0,0,1.5,1.5h11a1.5,1.5,0,0,0,1.5-1.5v-6a.5.5,0,0,0-1,0v6a.5.5,0,0,1-.5.5h-11a.5.5,0,0,1-.5-.5v-11a.5.5,0,0,1,.5-.5h6.5a.5.5,0,0,0,0-1h-6.5a1.5,1.5,0,0,0-1.5,1.5Z"
        transform="translate(-1330 -6676.5)"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default Icon;
