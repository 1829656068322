import React from 'react';
import {
  Editor,
  EditorTools,
  EditorChangeEvent,
  EditorMountEvent,
  ProseMirror,
  EditorPasteEvent,
  EditorUtils,
  PasteCleanupSettings,
} from '@progress/kendo-react-editor';
import cn from 'classnames';
import styled from 'styled-components';
import { insertImageFiles } from './utils';
import { insertImagePlugin } from './insertImagePlugin';
import { InsertImage } from './insertImageTool';

const { EditorState, EditorView, Plugin, PluginKey } = ProseMirror;
const { Bold, Italic, Link, Unlink, Undo, Redo, OrderedList, UnorderedList } = EditorTools;
const { pasteCleanup, sanitize, sanitizeClassAttr, sanitizeStyleAttr, removeAttribute, replaceImageSourcesFromRtf } =
  EditorUtils;

const pasteSettings: PasteCleanupSettings = {
  convertMsLists: true,
  stripTags: 'span|font|pre',
  attributes: {
    class: sanitizeClassAttr,
    style: sanitizeStyleAttr,

    // keep `width`, `height` and `src` attributes
    width: () => {},
    height: () => {},
    src: () => {},

    // Removes `lang` attribute
    // lang: removeAttribute,

    // removes other (unspecified above) attributes
    '*': removeAttribute,
  },
};

interface Props {
  label?: string;
  description?: string;
  error?: boolean;
  helperText?: string;
  ref?: any;
  defaultValue?: any;
  value?: string;
  onChange?: (html: string) => void;
  required?: boolean;
  className?: string;
  editable?: boolean;
}

const HtmlTextEditor = ({
  label,
  description,
  error,
  helperText,
  required,
  className,
  value, // This should be interpreted as an initial value
  onChange,
  editable = true,
}: Props): JSX.Element => {
  const styles = `
    p {
        font-size: 0.875rem;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif
    }
  `;

  // Turn editor in read-only https://www.telerik.com/kendo-react-ui/components/editor/plugins/#toc-read-only
  const editableRef = React.useRef<boolean>(editable);
  const view = React.useRef<any>(null);

  // https://www.telerik.com/kendo-react-ui/components/editor/images/
  const onImageInsert = (args: { files: any; view: any; event: any }) => {
    const { files, view, event } = args;
    const nodeType = view.state.schema.nodes.image;

    const position = event.type === 'drop' ? view.posAtCoords({ left: event.clientX, top: event.clientY }) : null;

    insertImageFiles({ view, files, nodeType, position });

    return files.length > 0;
  };

  const onMount = (event: EditorMountEvent) => {
    const iframeDocument = event.dom.ownerDocument;
    const style = iframeDocument.createElement('style');
    style.appendChild(iframeDocument.createTextNode(styles));
    iframeDocument.head.appendChild(style);

    const state = event.viewProps.state;
    const plugins = [
      ...state.plugins,
      new Plugin({
        key: new PluginKey('readonly'),
        props: { editable: () => editableRef.current },
        filterTransaction: (tr, _st) => editableRef.current || !tr.docChanged,
      }),
      insertImagePlugin(onImageInsert), // https://www.telerik.com/kendo-react-ui/components/editor/images/
    ];
    view.current = new EditorView(
      { mount: event.dom },
      {
        ...event.viewProps,
        state: EditorState.create({ doc: state.doc, plugins }),
      },
    );

    return view.current;
  };

  return (
    <div className={cn(className, { error })}>
      {label && <label>{required ? `${label} *` : label}</label>}
      {description && <p className="text-xs mb-3">{description}</p>}

      <div>
        <Editor
          // @ts-ignore
          value={value}
          onPasteHtml={(event: EditorPasteEvent) => {
            let html = pasteCleanup(sanitize(event.pastedHtml), pasteSettings);
            //html = html.replace(/(<([^>]+)>)/gi, ''); // Strip all HTML Tags

            // If the pasted HTML contains images with sources pointing to the local file system,
            // `replaceImageSourcesFromRtf` will extract the sources from the RTF and place them to images 'src' attribute in base64 format.
            if (event.nativeEvent.clipboardData) {
              html = replaceImageSourcesFromRtf(html, event.nativeEvent.clipboardData);
            }

            return html;
          }}
          onMount={onMount}
          onChange={(event: EditorChangeEvent) => {
            // Set target='_blank' to all anchors in html
            const parser = new DOMParser();
            const htmlDoc = parser.parseFromString(event.html, 'text/html');
            htmlDoc.querySelectorAll('a').forEach((anchor) => anchor.setAttribute('target', '_blank'));
            const html = htmlDoc.body.innerHTML;

            onChange?.(html);
          }}
          contentStyle={{ height: 200 }}
          tools={
            editableRef.current
              ? [[Bold, Italic, Link, Unlink], [Undo, Redo], [OrderedList, UnorderedList], [InsertImage]]
              : []
          }
          className={`ml-0 rounded-sm ${error ? 'border-2 border-red-400' : 'border border-gray-200'}`}
        />
        {helperText && <StyledSpan className={`helperText text-sm pt-1.5`}>{helperText}</StyledSpan>}
      </div>
    </div>
  );
};

const StyledSpan = styled.span`
  color: #f44336;
  display: block;
`;

export default HtmlTextEditor;
