import React from 'react';

function Icon(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
      <g fill="none" fillRule="evenodd" stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1">
        <g stroke="currentColor" strokeWidth="1.667" transform="translate(-109 -25)">
          <g transform="translate(110 26)">
            <g transform="translate(.25)">
              <path d="M11.667 19.9c-.92 0-1.667-.761-1.667-1.7V9.7c0-.939.746-1.7 1.667-1.7h11.666C24.253 8 25 8.761 25 9.7v8.5c0 .939-.746 1.7-1.667 1.7h-1.666V25l-5-5.1h-5z"></path>
              <path d="M6.667 13.6L3.333 17v-5.1H1.667C.747 11.9 0 11.139 0 10.2V1.7C0 .761.746 0 1.667 0h11.666C14.253 0 15 .761 15 1.7v3.4"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Icon;
