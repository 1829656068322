export const isPastDate = (date: Date): boolean => {
  const today = new Date();

  return date < today;
};

export const isInProgress = (startDate: Date, endDate: Date): boolean => {
  const today = new Date();

  return startDate < today && endDate >= today;
};
