/**
 * Remove accent characters from given string.
 * Taken from https://ricardometring.com/javascript-replace-special-characters
 * @param str
 */
export const stripAccents = (str: string): string => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
