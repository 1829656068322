import React from 'react';

interface IconProps {
  width?: string;
  height?: string;
}

function Icon({ width = '18', height = '18' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" viewBox="0 0 24 24">
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
    </svg>
  );
}

export default Icon;
